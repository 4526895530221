import React, { useEffect, useState } from "react";
import ButtonField from "../../../components/FormFields/ButtonField";
import { useAuth } from "../../../contexts/auth";
import OrganizationRow from "../CreateOrganization/OrganizationRow";
import styles from "./UserOrganizationTitle.module.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Resource } from "../../../api/common";
import useAxios from "axios-hooks";
import { Auth } from "aws-amplify";
import { message } from "antd";
import { useCookies } from "react-cookie";
interface IProps {
   newOrganization?: { name: ""; typeId: null; title?: "" };
   newOrganizationUpdate?: (value: any, key: string) => any;
   requestedOrganizations?: [];
   showAddingTitlePage: (e: any) => any;
   className?: string;
   modal?: boolean;
   closeModal?: (e?: any) => any;
}

const UserOrganizationTitle = ({
   newOrganization,
   newOrganizationUpdate,
   requestedOrganizations,
   showAddingTitlePage,
   className,
   modal,
   closeModal
}: IProps) => {
   const { organizationTypes, setUser, setOnBoarded } = useAuth();
   const [t] = useTranslation();
   const history = useHistory();
   const [organizationsList, setOrganizationsList] = useState<any>([]);
   const { joinedOrganizationsList } = useAuth();
   const [, setCookie] = useCookies(["user"]);
   const goBack = () => {
      showAddingTitlePage(false);
   };

   const changeUserTitle = (id: any, value: any) => {
      const current = organizationsList.find(
         (item: { id: any }) => item.id == id
      );
      if (value) current["title"] = value;
      setOrganizationsList([...organizationsList]);
   };

   const handleApplyToAllTitle = (value: string) => {
      organizationsList.map((item: { title: string }) => {
         if (value) item["title"] = value;
      });
      setOrganizationsList([...organizationsList]);
   };

   const [{ loading: createOrJoinLoading }, onboardingJoinOrCreateAPI] =
      useAxios(
         {
            url: `${Resource.path.joinCreateOrganization}`,
            method: "POST"
         },
         { manual: true }
      );

   const [{ loading: joinOrganizationLoading }, onboardedJoinAPI] = useAxios(
      {
         url: Resource.path.organization.join,
         method: "POST"
      },
      { manual: true }
   );

   const [{ loading: createOrganizationLoading }, onboardedCreateAPI] =
      useAxios(
         {
            url: Resource.path.organization.create,
            method: "POST"
         },
         { manual: true }
      );

   useEffect(() => {
      const currentList =
         joinedOrganizationsList.length && !requestedOrganizations?.length
            ? joinedOrganizationsList
            : requestedOrganizations;
      currentList && setOrganizationsList([...currentList]);
   }, [joinedOrganizationsList, requestedOrganizations]);

   const onboardingSubmit = async () => {
      const data: { create?: any; join?: any } = {};
      if (newOrganization) {
         data["create"] = {
            name: newOrganization?.name,
            organization_type_id: newOrganization?.typeId,
            title: newOrganization?.title
         };
      }
      if (organizationsList.length && !newOrganization) {
         data["join"] = organizationsList.map((item: any) => ({
            organization_id: item.id,
            title: item?.title,
            status:
               item?.invited && Number(item?.invited) === 1
                  ? "ACCEPTED"
                  : "PENDING",
            user_role: item?.role || "USER",
            invited: item?.invited == "1" ? true : false
         }));
      }
      await onboardingJoinOrCreateAPI({
         data: data
      })
         .then(async () => {
            await Auth.currentUserInfo().then((user) => {
               setUser(user);
               setOnBoarded(true);
               setCookie("user", user, { secure: true, maxAge: 14400 });
               history.push("/onboarding/dashboard");
            });
         })
         .catch((err) => {
            err.response.data.message.includes("ER_DUP_ENTRY")
               ? message.info(t("settings.organization.alreadyExist"))
               : message.error(t("settings.errorMessage"));
         });
   };

   const onboardedSubmit = async () => {
      if (newOrganization) {
         const item = {
            name: newOrganization?.name,
            organization_type_id: newOrganization?.typeId,
            title: newOrganization?.title
         };
         await onboardedCreateAPI({ data: item })
            .then(async (resp) => {
               const createdItem = {
                  organization_id: resp.data.data.id,
                  title: newOrganization?.title,
                  status: "ACCEPTED",
                  user_role: "ADMIN"
               };
               await onboardedJoinAPI({
                  data: { data: [createdItem] }
               })
                  .then(async () => {
                     message.success(t("settings.organization.successCreate"));
                     closeModal && closeModal();
                  })
                  .catch(() => {
                     message.error(t("settings.errorMessage"));
                  });
               closeModal && closeModal();
            })
            .catch((err) => {
               err.response.data.message.includes("ER_DUP_ENTRY")
                  ? message.info(t("settings.organization.alreadyExist"))
                  : message.error(t("settings.errorMessage"));
            });
      } else {
         const items = organizationsList.map((item: any) => ({
            organization_id: item.id,
            title: item?.title,
            status:
               item?.invited && Number(item?.invited) === 1
                  ? "ACCEPTED"
                  : "PENDING",
            user_role: item?.role || "USER",
            invited: item?.invited == "1" ? true : false
         }));
         await onboardedJoinAPI({
            data: { data: items }
         })
            .then(async () => {
               message.success(t("settings.organization.successJoin"));
               closeModal && closeModal();
            })
            .catch(() => {
               message.error(t("settings.errorMessage"));
            });
      }
   };

   const submit = async () => {
      if (modal) {
         await onboardedSubmit();
      } else {
         await onboardingSubmit();
      }
   };

   if (createOrJoinLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <div className={`${styles.userTitles} ${className}`}>
         <div className={styles.userRole}>
            {newOrganization ? (
               <OrganizationRow
                  item={{
                     name: newOrganization?.name,
                     type: organizationTypes.find(
                        (item: { id: any }) =>
                           (item.id = newOrganization?.typeId)
                     )?.label,
                     title: newOrganization?.title
                  }}
                  edit={goBack}
                  current
                  changeTitle={(_, value) =>
                     newOrganizationUpdate &&
                     newOrganizationUpdate(value, "title")
                  }
               />
            ) : (
               <>
                  {organizationsList.map(
                     (
                        item: {
                           id: number;
                           name: string;
                           logo: string;
                           title: string;
                           type: string;
                        },
                        index: number
                     ) => {
                        return (
                           <OrganizationRow
                              item={item}
                              key={index}
                              changeTitle={changeUserTitle}
                              showApplyToAll={
                                 index == 0 && organizationsList.length > 1
                              }
                              applyToAll={handleApplyToAllTitle}
                           />
                        );
                     }
                  )}
               </>
            )}
         </div>

         <div className={styles.bottomButtons}>
            <ButtonField
               className={styles.goBack}
               type="link"
               icon={<ArrowLeftOutlined />}
               onClick={goBack}
            >
               <span>{t("onboarding.goBack")}</span>
            </ButtonField>
            <ButtonField
               type="default"
               onClick={submit}
               loading={
                  createOrganizationLoading ||
                  joinOrganizationLoading ||
                  createOrJoinLoading
               }
            >
               <span>{t("default.submit")}</span>
            </ButtonField>
         </div>
      </div>
   );
};

export default UserOrganizationTitle;
