import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PageHeader, Tag, Image } from "antd";
import { PassportList } from "./PassportList";
import { useTranslation } from "react-i18next";
import { getImagePath } from "../../../utils/getImagePath";
import { useEffect } from "react";

export const getDocumentsList = (documents: any[]) => {
   return documents.map((item: any, index) => {
      return (
         <div key="index">
            <a
               href={item.documents_files?.object_url}
               target="_blank"
               rel="noreferrer"
            >
               {item.document_type} ({index + 1})
            </a>
         </div>
      );
   });
};

export const getMaterialReuseForTag = (tags: any[]) => {
   return tags.map((tag: any) => {
      return <Tag>{tag}</Tag>;
   });
};

export const MaterialDetails = () => {
   const {
      state: { material }
   }: any = useLocation();
   const [t] = useTranslation();
   const [materialImages, setMaterialImages] = useState<any>();

   let materialGroupSpecification;
   if (material?.specification_group) {
      materialGroupSpecification = material.specification_group
         .map((item: any, index: number) => {
            if (!item) return null;
            const keys = Object.keys(item);

            return (
               <>
                  {keys.map((key: any, index: number) => {
                     const keyValue = item[key];

                     if (typeof keyValue === "object" && keyValue !== null) {
                        if ("unit" in keyValue && "unitValue" in keyValue) {
                           return (
                              <dl key={index}>
                                 <dt>{key}</dt>
                                 <dd>
                                    {keyValue["unitValue"]} {keyValue["unit"]}
                                 </dd>
                              </dl>
                           );
                        }
                     } else {
                        // test if color
                        var ddClass;
                        if (/^#([0-9A-F]{3}){1,2}$/i.test(item[key])) {
                           ddClass = "color";
                           return (
                              <dl key={index}>
                                 <dt>{t(`${key}`)}</dt>
                                 <dd>
                                    <div
                                       className={ddClass}
                                       style={{
                                          ["background" as any]: item[key],
                                          display: "inline",
                                          padding: "0px 30px 0px",
                                          border: "1px solid #d9d9d9"
                                       }}
                                    ></div>{" "}
                                    <span style={{ display: "inline" }}>
                                       &nbsp;
                                       {item.isRalColorMatched
                                          ? t(
                                               `colors:${item[
                                                  key
                                               ].toUpperCase()}`
                                            )
                                          : t(
                                               `colors:${item[
                                                  key
                                               ].toUpperCase()}`
                                            ).substr(
                                               0,
                                               t(
                                                  `colors:${item[
                                                     key
                                                  ].toUpperCase()}`
                                               ).indexOf("(")
                                            )}
                                    </span>
                                 </dd>
                              </dl>
                           );
                        } else if (key !== "isRalColorMatched") {
                           return (
                              <dl>
                                 <dt>{key}</dt>
                                 <dd>{item[key]}</dd>
                              </dl>
                           );
                        }
                     }
                  })}
               </>
            );
         })
         .filter(Boolean);
   }

   const setPassportImageList = (images: any) => {
      const materialImages = images
         ?.map((item: any, index: number) => {
            return (
               item?.object_url && (
                  <Image src={getImagePath(item)} key={index} />
               )
            );
         })
         .filter(Boolean);

      materialImages && setMaterialImages(materialImages);
   };

   useEffect(() => {
      if (material?.images) {
         setPassportImageList(material.images);
      }
   }, [material]);

   return (
      <>
         <div className="content-wrap material-overview-wrapper">
            <PageHeader
               title={t("common.back")}
               onBack={() => window.history.back()}
            >
               <div className="page-title">
                  <h1>
                     {material.manufacturer} {material.product_name}
                  </h1>
                  <span>{material?.product_group_name}</span>
               </div>

               <div className="manage-menu"></div>
            </PageHeader>
            <div className="page-content">
               <div className="grid col-4">
                  {material.manufacturer && (
                     <div className="grid-item widget data">
                        <span>{t("materialDashboard.manufacturer")}</span>
                        <strong>{material.manufacturer}</strong>
                     </div>
                  )}
                  {material.ean && (
                     <div className="grid-item widget data">
                        <span>{t("materialDashboard.ean")}</span>
                        <strong>{material.ean}</strong>
                     </div>
                  )}
                  {material.yearOfManufacturing && (
                     <div className="grid-item widget data">
                        <span>
                           {t("materialDashboard.yearOfManufacturing")}
                        </span>
                        <strong>{material.yearOfManufacturing}</strong>n
                     </div>
                  )}
               </div>
               <div className="grid material-specifications">
                  {materialImages && (
                     <div
                        className={
                           materialImages.length > 1
                              ? "image-scroll"
                              : "images-scroll single"
                        }
                     >
                        {materialImages}
                     </div>
                  )}
                  <div className="specs widget">
                     <h4>{t("materialDashboard.specifications")}</h4>
                     {material.weight_per_unit && (
                        <dl>
                           <dt>{t("materialDashboard.weight")}</dt>
                           <dd>{material.weight_per_unit} kg</dd>
                        </dl>
                     )}
                     {materialGroupSpecification}
                     {material.materials && (
                        <h4>{t("productDetails.material.form.materials")}</h4>
                     )}
                     {material.materials && (
                        <dl className="material">
                           {material.materials.map((item: any) => {
                              return (
                                 <>
                                    <dt>{item.material_type}</dt>
                                    {item?.percentage && (
                                       <dd>
                                          {item?.material_sub_type && (
                                             <span>
                                                {item.material_sub_type}:{" "}
                                             </span>
                                          )}
                                          <span>{item?.percentage}%</span>
                                       </dd>
                                    )}
                                 </>
                              );
                           })}
                        </dl>
                     )}
                  </div>
                  <div className="description">
                     {material?.need_for_reuse && (
                        <div className="widget">
                           <h4>
                              {t("reusePotentialSteps.form.neededForReuse")}
                           </h4>
                           {getMaterialReuseForTag(material?.need_for_reuse)}
                        </div>
                     )}

                     {material?.used_as && (
                        <div className="widget">
                           <h4>
                              {t("reusePotentialSteps.form.couldBeUsedAs")}
                           </h4>
                           {material?.used_as.map((item: any) => {
                              return <Tag>{item}</Tag>;
                           })}
                        </div>
                     )}
                     {material?.notes && (
                        <div className="widget">
                           <h4>{t("materialDashboard.notes")}</h4>
                           {material?.notes}
                        </div>
                     )}

                     {material.documents && (
                        <div className="widget">
                           <h4>{t("materialDashboard.documents")}</h4>
                           {getDocumentsList(material.documents)}
                        </div>
                     )}
                  </div>
               </div>
            </div>
            <PassportList
               material={material}
               setPassportImageList={setPassportImageList}
               showRequest
            ></PassportList>
         </div>
      </>
   );
};
