import React from "react";
import { useLocation } from "react-router-dom";
import { PageHeader, Tag, Image } from "antd";
import { useTranslation } from "react-i18next";
import { getDocumentsList, getMaterialReuseForTag } from "./MaterialDetails";
import { getImagePath } from "../../../utils/getImagePath";
import ImageGallery from "react-image-gallery";

export const MaterialPassportDetails = () => {
   const {
      state: { material, passport }
   }: any = useLocation();
   const [t] = useTranslation();

   let materialGroupSpecification;

   if (material?.specification_group) {
      materialGroupSpecification = material.specification_group
         .map((item: any, index: number) => {
            if (!item) return null;

            const keys = Object.keys(item);

            return (
               <>
                  {keys.map((key: any, index: number) => {
                     const keyValue = item[key];

                     if (typeof keyValue === "object" && keyValue !== null) {
                        if ("unit" in keyValue && "unitValue" in keyValue) {
                           return (
                              <dl>
                                 <dt>{key}</dt>
                                 <dd>
                                    {keyValue["unitValue"]} {keyValue["unit"]}
                                 </dd>
                              </dl>
                           );
                        }
                     } else {
                        // test if color
                        var ddClass;
                        if (/^#([0-9A-F]{3}){1,2}$/i.test(item[key])) {
                           ddClass = "color";
                           return (
                              <dl>
                                 <dt>{t(`${key}`)}</dt>
                                 <dd>
                                    <div
                                       className={ddClass}
                                       style={{
                                          ["background" as any]: item[key],
                                          display: "inline",
                                          padding: "0px 30px 0px",
                                          border: "1px solid #d9d9d9"
                                       }}
                                    ></div>{" "}
                                    <span style={{ display: "inline" }}>
                                       &nbsp;
                                       {item.isRalColorMatched
                                          ? t(
                                               `colors:${item[
                                                  key
                                               ].toUpperCase()}`
                                            )
                                          : t(
                                               `colors:${item[
                                                  key
                                               ].toUpperCase()}`
                                            ).substr(
                                               0,
                                               t(
                                                  `colors:${item[
                                                     key
                                                  ].toUpperCase()}`
                                               ).indexOf("(")
                                            )}
                                    </span>
                                 </dd>
                              </dl>
                           );
                        } else if (key !== "isRalColorMatched") {
                           return (
                              <dl>
                                 <dt>{key}</dt>
                                 <dd>{item[key]}</dd>
                              </dl>
                           );
                        }
                     }
                  })}
               </>
            );
         })
         .filter(Boolean);
   }

   const getPassportImages = () => {
      return (
         passport &&
         passport.passport_images?.map((item: any) => {
            return <Image src={getImagePath(item)} />;
         })
      );
   };

   const getPassportImagesList = () => {
      return (
         passport &&
         passport.passport_images?.map((item: any) => {
            return {
               original: getImagePath(item),
               thumbnail: getImagePath(item)
            };
         })
      );
   };

   return (
      <div className="content-wrap passport-overview-wrapper">
         <PageHeader
            title={t("common.back")}
            onBack={() => window.history.back()}
         >
            <div className="page-title">
               {passport?.passport_identifier && (
                  <strong className="passport-id">
                     <span className="light">#</span>
                     {passport.passport_identifier}
                  </strong>
               )}
               <h1>
                  {material.manufacturer} {material.product_name}
               </h1>
               <span>{material?.product_group_name}</span>
            </div>

            <div className="manage-menu"></div>
         </PageHeader>

         <div className="page-content">
            <h4>{t("passportDetails.currentCondition")}</h4>
            <div className="grid col-4">
               {passport.other_note && (
                  <div className="grid-item widget data">
                     <span>{t("passportDetails.otherNote")}</span>
                     <strong>{passport.other_note}</strong>
                  </div>
               )}
               {passport.deconstructability && (
                  <div className="grid-item widget data">
                     <span>{t("passportDetails.deconstructability")}</span>
                     <strong>{passport.deconstructability}</strong>
                  </div>
               )}
               {passport.custom_pollutance && (
                  <div className="grid-item widget data">
                     <span>{t("passportDetails.customPollutance")}</span>
                     <strong>{passport.custom_pollutance}</strong>
                  </div>
               )}
               {passport.condition && (
                  <div className="grid-item widget data">
                     <span>{t("passportDetails.condition")}</span>
                     <strong>{passport.condition}</strong>
                  </div>
               )}
               {passport.location_note && (
                  <div className="grid-item widget data">
                     <span>{t("passportDetails.locationNote")}</span>
                     <strong>{passport.location_note}</strong>
                  </div>
               )}
            </div>
            <h4>{t("passportDetails.specifications")}</h4>
            <div className="grid col-4">
               <div className="grid-item widget data">
                  <span>{t("materialDashboard.manufacturer")}</span>
                  <strong>{material.manufacturer}</strong>
               </div>
               <div className="grid-item widget data">
                  <span>{t("materialDashboard.name")}</span>
                  <strong>{material.product_name}</strong>
               </div>
               {material.ean && (
                  <div className="grid-item widget data">
                     <span>{t("materialDashboard.ean")}</span>
                     <strong>{material.ean}</strong>
                  </div>
               )}
               {material.yearOfManufacturing && (
                  <div className="grid-item widget data">
                     <span>{t("materialDashboard.yearOfManufacturing")}</span>
                     <strong>{material.yearOfManufacturing}</strong>n
                  </div>
               )}
            </div>
            <div className="grid material-specifications">
               {passport?.passport_images?.length && (
                  <div className="images widget">
                     <ImageGallery
                        items={getPassportImagesList()}
                        lazyLoad
                        thumbnailPosition="bottom"
                        useBrowserFullscreen="false"
                     />
                  </div>
               )}
               <div className="specs widget">
                  <h4>{t("materialDashboard.specifications")}</h4>
                  <dl>
                     <dt>{t("materialDashboard.weight")}</dt>
                     <dd>{material.weight_per_unit} kg</dd>
                  </dl>
                  {materialGroupSpecification}
                  {material.materials && (
                     <h4>{t("productDetails.material.form.materials")}</h4>
                  )}
                  {material.materials && (
                     <dl className="material">
                        {material.materials.map((item: any) => {
                           return (
                              <>
                                 <dt>{item.material_type}</dt>
                                 {item?.percentage && (
                                    <dd>
                                       {item?.material_sub_type && (
                                          <span>
                                             {item.material_sub_type}:{" "}
                                          </span>
                                       )}
                                       <span>{item.percentage}%</span>
                                    </dd>
                                 )}
                              </>
                           );
                        })}
                     </dl>
                  )}
               </div>
               <div className="description">
                  {material?.need_for_reuse && (
                     <div className="widget">
                        <h4>{t("reusePotentialSteps.form.neededForReuse")}</h4>
                        {getMaterialReuseForTag(material?.need_for_reuse)}
                     </div>
                  )}

                  {material?.used_as && (
                     <div className="widget">
                        <h4>{t("reusePotentialSteps.form.couldBeUsedAs")}</h4>
                        {material?.used_as.map((item: any) => {
                           return <Tag>{item}</Tag>;
                        })}
                     </div>
                  )}
                  {material?.notes && (
                     <div className="widget">
                        <h4>{t("materialDashboard.notes")}</h4>
                        {material?.notes}
                     </div>
                  )}

                  {material.documents && (
                     <div className="widget">
                        <h4>{t("materialDashboard.documents")}</h4>
                        {getDocumentsList(material.documents)}
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};
