import mixpanel from "mixpanel-browser";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
   batch_requests: false,
   api_host: "https://api-eu.mixpanel.com",
   debug: true,
   ignore_dnt: true
});

let isMixpanelActivated =
   (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_MIXPANEL_TEST_ENV === "production") &&
   process.env.REACT_APP_MIXPANEL_TOKEN; // "production";

export const Mixpanel = {
   identify: (id: string) => {
      if (isMixpanelActivated) mixpanel.identify(id);
   },
   alias: (id: string) => {
      if (isMixpanelActivated) mixpanel.alias(id);
   },
   track: (name: string, props: any) => {
      try {
         if (isMixpanelActivated) mixpanel.track(name, props);
      } catch (error) {
         console.log(error);
      }
   },
   people: {
      set: (props: any) => {
         if (isMixpanelActivated) mixpanel.people.set(props);
      }
   }
};
