import deDECommon from "../../locales/de/index.json";
import enGBCommon from "../../locales/en/index.json";
import enGBColors from "../../locales/en/colors.json";
import deDEColors from "../../locales/de/colors.json";

export default {
   en: {
      translation: enGBCommon,
      colors: enGBColors
   },
   de: {
      translation: deDECommon,
      colors: deDEColors
   }
};
