import React, { useCallback, useEffect, useState } from "react";
import { Input, AutoComplete } from "antd";
import { SelectProps } from "antd/es/select";
import useAxios from "axios-hooks";
import { Resource } from "../../../../../../../api/common";
import { useTranslation } from "react-i18next";

const buildOptionsElm = (results: any) => {
   return results
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
      .map((result: any) => {
         return {
            value: `${result.id}_${result.name}`,
            label: result.name
         };
      });
};

const BrandSearchField: React.FC<any> = ({ value, onChange }) => {
   const [t] = useTranslation();
   const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
   const [searchTerm, setSearchTerm] = useState<any>(value);

   const [{ data, loading, error }, refetch] = useAxios(
      { url: Resource.path.getBrands },
      { useCache: true }
   );

   if (loading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   useEffect(() => {
      if (data?.data.data) {
         const otherBrand = t("brand.otherBrandLiteral");
         const noBrand = t("brand.noBrandLiteral");
         const brands = data?.data.data;
         brands.push({
            id: otherBrand,
            name: otherBrand
         });
         brands.push({
            id: noBrand,
            name: noBrand
         });

         const options = buildOptionsElm(brands);

         setOptions(options);
      }
   }, [data]);

   const handleSearch = (value: string) => {
      const brands = data?.data?.data;
      const filteredResults = brands.filter((item: any) => {
         return item?.name?.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      setOptions(buildOptionsElm(filteredResults));
   };

   const onSelect = useCallback((value: any) => {
      const [, brandName] = value.split("_");
      setSearchTerm(brandName);
      onChange && onChange(brandName);
   }, []);

   const onValueChange = (value: any) => {
      setSearchTerm(value);
      onChange && onChange(value);
   };

   return (
      <AutoComplete
         value={searchTerm}
         options={options}
         onChange={onValueChange}
         onSelect={onSelect}
         onSearch={handleSearch}
      >
         <Input
            size="large"
            placeholder={t(
               "productDetails.productCategory.brandSearchFieldPlaceholder"
            )}
         />
      </AutoComplete>
   );
};

export default BrandSearchField;
