import React from "react";
import { Button, Form, Select, Space, Upload } from "antd";
import {
   MinusCircleOutlined,
   UploadOutlined,
   FileAddOutlined
} from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";

export const Documents = () => {
   const [t] = useTranslation();

   const documentsType = [
      t("staticDropdown.documentsType.C2CCertificate"),
      t("staticDropdown.documentsType.manufacturerDataSheets"),
      t("staticDropdown.documentsType.others")
   ];

   const handleBeforeUpload = () => {
      return false;
   };

   return (
      <div>
         <Text>{t("productDetails.documents.documentTitle")}</Text>
         <Form.List name="documents">
            {(fields, { add, remove }) => (
               <>
                  {fields.map((field) => (
                     <Space
                        key={field.key}
                        style={{ display: "flex", marginBottom: "-12px" }}
                        align="baseline"
                     >
                        <Form.Item
                           {...field}
                           name={[field.name, "document_type"]}
                           fieldKey={[field.fieldKey, "document_type"]}
                           rules={[
                              {
                                 required: true,
                                 message: t(
                                    "productDetails.documents.form.selectDocumentTypes"
                                 )
                              }
                           ]}
                        >
                           <Select
                              placeholder={t(
                                 "productDetails.documents.form.placeholderDocumentTypes"
                              )}
                           >
                              {documentsType.map((docType: string) => (
                                 <Select.Option value={docType} key={docType}>
                                    {docType}
                                 </Select.Option>
                              ))}
                           </Select>
                        </Form.Item>
                        <Form.Item
                           {...field}
                           name={[field.name, "documents_files"]}
                           fieldKey={[field.fieldKey, "documents_files"]}
                           rules={[
                              {
                                 required: true,
                                 message: t(
                                    "productDetails.documents.form.missingDocumentFiles"
                                 )
                              }
                           ]}
                        >
                           <Upload beforeUpload={handleBeforeUpload}>
                              <Button icon={<UploadOutlined />}>
                                 {" "}
                                 {t(
                                    "productDetails.documents.form.clickToUploadBtn"
                                 )}
                              </Button>
                           </Upload>
                        </Form.Item>
                        <MinusCircleOutlined
                           onClick={() => remove(field.name)}
                        />
                     </Space>
                  ))}
                  <Form.Item>
                     <Button
                        type="primary"
                        shape="round"
                        icon={<FileAddOutlined />}
                        onClick={() => add()}
                     >
                        {t("productDetails.documents.form.addDocumentsBtn")}
                     </Button>
                  </Form.Item>
               </>
            )}
         </Form.List>
      </div>
   );
};
