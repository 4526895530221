import React, { useState, useEffect } from "react";
import {
   PageHeader,
   Button,
   message,
   Tabs,
   Input,
   Select,
   Form,
   Modal,
   DatePicker,
   Popconfirm,
   Checkbox,
   Spin
} from "antd";
import { useParams } from "react-router-dom";
import useAxios from "axios-hooks";
import { useHistory } from "react-router";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./style.scss";

import { useTranslation } from "react-i18next";
import { Resource } from "../../../api/common";
import { Materials } from "./Details/Materials";
import { AddMaterials } from "./Details/Drawer/AddMaterials";
import { useBuilding } from "../../../contexts/building/index";
const moment = require("moment");

export const BuildingOperationDetailsView = () => {
   const [t] = useTranslation();
   const [isModalVisible, setIsModalVisible] = useState(false);
   const { Option } = Select;
   const [selectionType, setSelectionType] = useState("checkbox");
   const { id: buildingId, operation_id }: any = useParams<string>();
   const [operation, setOperation] = useState<any>({});
   const [activeTab, setActiveTab] = useState<any>(1);
   const [activeTabInner, setActiveTabInner] = useState<any>(1);
   const [state, setState] = useState({ showAddSelectAll: true });
   const { getBuildingDetail } = useBuilding();
   const buildingDetails = buildingId && getBuildingDetail(buildingId);
   const [thirdPartyDeconst, setThirdPartyDeconst] = useState(false);
   const [startDateSelfDeconstruction, setStartDateSelfDeconstruction] =
      useState<any>();
   const [endDateSelfDeconstruction, setEndDateSelfDeconstruction] =
      useState<any>();
   const [form] = Form.useForm();
   const history = useHistory();
   // tabs
   const { TabPane } = Tabs;
   const callback = (key: any) => {
      setActiveTab(key);
   };
   const callbackInnerTab = (key: any) => {
      setActiveTabInner(key);
      refetchSelectedMaterials();
      refetch();
   };
   // drawer
   const [visible, setVisible] = useState(false);

   const [{ data, loading }, refetch] = useAxios(
      `${Resource.path.operations}/${buildingId}/operation/${operation_id}`
   );

   const [
      { data: dataSelectedMaterials, loading: loadingSelectedMaterials },
      refetchSelectedMaterials
   ] = useAxios(
      `${Resource.path.getMaterialPassportOperations(buildingId, operation_id)}`
   );

   const [{ loading: confirmLoading }, updateOperations] = useAxios(
      {
         url: `${Resource.path.operations}/${buildingId}/operation/${operation_id}`,
         method: "PUT"
      },
      { manual: true, useCache: false }
   );

   const [{ loading: deleteLoading }, deleteOperation] = useAxios(
      {
         url: `${Resource.path.operations}/${buildingId}/operation/${operation_id}`,
         method: "DELETE"
      },
      { manual: true, useCache: false }
   );

   const [
      { data: dataMaterials, loading: getLoadingMaterials },
      fetchMaterials
   ] = useAxios(
      {
         url: Resource.path.getMaterialInventoryOp(buildingId, operation_id),
         method: "GET"
      },
      { useCache: false }
   );

   const showDrawer = async () => {
      await fetchMaterials();
      setVisible(true);
   };

   const onClose = () => {
      setVisible(false);
   };

   // modal
   const showModal = () => {
      setIsModalVisible(true);
   };

   const handleOk = async () => {
      form.submit();
      await form
         .validateFields()
         .then(async () => {
            const data: any = {
               name: form.getFieldValue("name"),
               operation_type: form.getFieldValue("operation_type"),
               start_date_construction: null,
               start_date_deconstruction: null,
               end_date_construction: null,
               end_date_deconstruction: null,
               building_name: buildingDetails?.name
            };
            if (form.getFieldValue("start_date_construction") !== undefined) {
               data.start_date_construction = form.getFieldValue(
                  "start_date_construction"
               );
            }
            if (form.getFieldValue("start_date_deconstruction") !== undefined) {
               data.start_date_deconstruction = form.getFieldValue(
                  "start_date_deconstruction"
               );
            }
            if (form.getFieldValue("end_date_construction") !== undefined) {
               data.end_date_construction = form.getFieldValue(
                  "end_date_construction"
               );
            }
            if (form.getFieldValue("end_date_deconstruction") !== undefined) {
               data.end_date_deconstruction = form.getFieldValue(
                  "end_date_deconstruction"
               );
            }
            data.third_party_deconstruction = thirdPartyDeconst ? 1 : 0;

            if (
               form.getFieldValue("self_start_date_deconstruction") !==
               undefined
            ) {
               data.self_start_date_deconstruction = form.getFieldValue(
                  "self_start_date_deconstruction"
               );
            }
            if (
               form.getFieldValue("self_end_date_deconstruction") !== undefined
            ) {
               data.self_end_date_deconstruction = form.getFieldValue(
                  "self_end_date_deconstruction"
               );
            }

            if (data.third_party_deconstruction === 0) {
               data.self_end_date_deconstruction = null;
               data.self_start_date_deconstruction = null;
            }

            await updateOperations({
               data
            })
               .then(() => {
                  setOperation({});
                  refetch();
                  setIsModalVisible(false);
               })
               .catch((error) => {
                  console.log(error);
                  message.error(t("buildingOperations.errorMessage"));
               });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const handleCancel = () => {
      setIsModalVisible(false);
   };

   const confirmDelete = async () => {
      await deleteOperation()
         .then(() => {
            message.success("Deleted successfully");
            history.push(`/buildings/${buildingId}/operations`);
         })
         .catch((error) => {
            console.log(error);
            message.error(t("buildingOperations.errorMessage"));
         });
   };

   useEffect(() => {
      if (data?.data?.result) {
         setOperation(data.data.result);
         if (data?.data?.result?.third_party_deconstruction) {
            setThirdPartyDeconst(
               data?.data?.result?.third_party_deconstruction
            );
         }
      }
      if (dataMaterials?.data) {
         dataMaterials.data.map((dataMaterial: any) => {
            const balance = Number(dataMaterial.amount);
            if (balance > 0) {
               setState({ showAddSelectAll: false });
            }
         });
      }
   }, [data, dataMaterials]);

   if (loading || loadingSelectedMaterials || getLoadingMaterials) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   if (loading)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );
   // console.log(operation)
   const {
      name,
      operation_type,
      start_date,
      end_date,
      start_date_deconstruction,
      start_date_construction,
      end_date_construction,
      end_date_deconstruction,
      self_start_date_deconstruction,
      self_end_date_deconstruction
   } = operation;

   return (
      <div className="page-wrap building-operations-overview-wrapper">
         <PageHeader
            title={t("common.back")}
            onBack={() => window.history.back()}
         >
            <div className="page-title">
               <h1>{name}</h1>
               <h3>
                  {t(`staticDropdown.upcomingActivities.${operation_type}`)}
               </h3>
            </div>
            <div className="manage-menu">
               <Button
                  onClick={showModal}
                  shape="circle"
                  icon={<EditOutlined />}
               ></Button>
               <Popconfirm
                  title="Are you sure to delete?"
                  onConfirm={confirmDelete}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{ loading: deleteLoading }}
               >
                  <Button shape="circle" icon={<DeleteOutlined />}></Button>
               </Popconfirm>

               {isModalVisible && (
                  <Modal
                     title={t("default.edit")}
                     confirmLoading={confirmLoading}
                     visible={isModalVisible}
                     onOk={handleOk}
                     onCancel={handleCancel}
                  >
                     <Form
                        name="operation"
                        layout="vertical"
                        form={form}
                        initialValues={{
                           operation_type,
                           end_date: end_date ? moment(end_date) : null,
                           start_date: start_date ? moment(start_date) : null,
                           name,
                           start_date_construction: start_date_construction
                              ? moment(start_date_construction)
                              : null,
                           start_date_deconstruction: start_date_deconstruction
                              ? moment(start_date_deconstruction)
                              : null,
                           end_date_construction: end_date_construction
                              ? moment(end_date_construction)
                              : null,
                           end_date_deconstruction: end_date_deconstruction
                              ? moment(end_date_deconstruction)
                              : null,
                           third_party_deconstruction: thirdPartyDeconst,
                           self_start_date_deconstruction:
                              self_start_date_deconstruction
                                 ? moment(self_start_date_deconstruction)
                                 : null,
                           self_end_date_deconstruction:
                              self_end_date_deconstruction
                                 ? moment(self_end_date_deconstruction)
                                 : null
                        }}
                     >
                        <Form.Item
                           label={t("operations.form.selectType")}
                           name="operation_type"
                           rules={[{ required: true }]}
                        >
                           <Select
                              disabled={
                                 operation_type === "construction" ||
                                 operation_type === "deconstruction"
                              }
                           >
                              {operation_type === "renovation" && (
                                 <Option value="renovation">
                                    {t("operations.renovation")} (
                                    {t("operations.sourcingSelling")})
                                 </Option>
                              )}
                              {operation_type === "extension" && (
                                 <Option value="extension">
                                    {t("operations.extension")} (
                                    {t("operations.sourcingSelling")})
                                 </Option>
                              )}

                              <Option value="construction">
                                 {t("operations.erection")} (
                                 {t("operations.sourcingOnly")})
                              </Option>

                              <Option value="deconstruction">
                                 {t("operations.deconstruction")} (
                                 {t("operations.sellingOnly")})
                              </Option>
                           </Select>
                        </Form.Item>
                        <Form.Item
                           label={t("operations.form.operationName")}
                           name="name"
                        >
                           <Input
                              placeholder={t(
                                 "operations.form.operationNameExample"
                              )}
                           />
                        </Form.Item>
                        {operation_type === "construction" && (
                           <>
                              <Input.Group compact>
                                 <strong>{t("operations.construction")}</strong>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t(
                                       "operations.form.expectedStartOfConstruction"
                                    )}
                                    name="start_date_construction"
                                 >
                                    <DatePicker />
                                 </Form.Item>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t("operations.form.expectedEnd")}
                                    name="end_date_construction"
                                 >
                                    <DatePicker />
                                 </Form.Item>
                              </Input.Group>
                           </>
                        )}
                        {operation_type === "deconstruction" && (
                           <>
                              <Input.Group compact>
                                 <strong>
                                    {t("operations.deconstruction")}
                                 </strong>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t(
                                       "operations.form.expectedStartOfConstruction"
                                    )}
                                    name="start_date_deconstruction"
                                 >
                                    <DatePicker />
                                 </Form.Item>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t("operations.form.expectedEnd")}
                                    name="end_date_deconstruction"
                                 >
                                    <DatePicker />
                                 </Form.Item>
                              </Input.Group>
                           </>
                        )}
                        {(operation_type === "renovation" ||
                           operation_type === "extension") && (
                           <>
                              <Input.Group compact>
                                 <strong>{t("operations.construction")}</strong>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t(
                                       "operations.form.expectedStartOfConstruction"
                                    )}
                                    name="start_date_construction"
                                 >
                                    <DatePicker />
                                 </Form.Item>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t("operations.form.expectedEnd")}
                                    name="end_date_construction"
                                 >
                                    <DatePicker />
                                 </Form.Item>
                              </Input.Group>
                              <Input.Group compact>
                                 <strong>
                                    {t("operations.deconstruction")}
                                 </strong>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t(
                                       "operations.form.expectedStartOfDeconstruction"
                                    )}
                                    name="start_date_deconstruction"
                                 >
                                    <DatePicker />
                                 </Form.Item>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t("operations.form.expectedEnd")}
                                    name="end_date_deconstruction"
                                 >
                                    <DatePicker />
                                 </Form.Item>
                              </Input.Group>
                           </>
                        )}
                        {operation_type === "deconstruction" && (
                           <Form.Item
                              label={t(
                                 "operations.form.third_party_deconstruction"
                              )}
                              name="third_party_deconstruction"
                           >
                              <Checkbox
                                 checked={!!thirdPartyDeconst}
                                 onChange={(event) => {
                                    setThirdPartyDeconst(event.target.checked);
                                 }}
                              />
                           </Form.Item>
                        )}
                        {operation_type === "deconstruction" &&
                           !!thirdPartyDeconst && (
                              <>
                                 <strong>self-deconstruction</strong>
                                 <Input.Group compact>
                                    <Form.Item
                                       rules={[
                                          () => ({
                                             validator: (_rule, value) => {
                                                if (value)
                                                   return Promise.resolve();
                                                return Promise.reject(
                                                   t(
                                                      "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                   )
                                                );
                                             }
                                          })
                                       ]}
                                       label={t(
                                          "operations.form.expectedStartOfDeconstruction"
                                       )}
                                       name="self_start_date_deconstruction"
                                    >
                                       <DatePicker
                                          onChange={(value: any) => {
                                             setStartDateSelfDeconstruction(
                                                value
                                             );
                                          }}
                                       />
                                    </Form.Item>
                                    <Form.Item
                                       rules={[
                                          () => ({
                                             validator: (_rule, value) => {
                                                if (value) {
                                                   if (
                                                      startDateSelfDeconstruction &&
                                                      value <
                                                         startDateSelfDeconstruction
                                                   ) {
                                                      return Promise.reject(
                                                         t(
                                                            "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                         )
                                                      );
                                                   }
                                                   return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                   t(
                                                      "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                   )
                                                );
                                             }
                                          })
                                       ]}
                                       label={t("operations.form.expectedEnd")}
                                       name="self_end_date_deconstruction"
                                    >
                                       <DatePicker
                                          onChange={(value: any) => {
                                             setEndDateSelfDeconstruction(
                                                value
                                             );
                                          }}
                                       />
                                    </Form.Item>
                                 </Input.Group>
                              </>
                           )}
                     </Form>
                  </Modal>
               )}
            </div>
         </PageHeader>
         <Materials
            showDrawerLoading={getLoadingMaterials}
            callbackInnerTab={callbackInnerTab}
            operation={operation}
            refetch={refetch}
            tabKey={activeTabInner}
            showDrawer={showDrawer}
            dataSelectedMaterials={dataSelectedMaterials}
            fetchMaterials={fetchMaterials}
            refetchSelectedMaterials={refetchSelectedMaterials}
         />
         <AddMaterials
            dataMaterials={dataMaterials}
            operation={operation}
            fetchMaterials={fetchMaterials}
            onClose={onClose}
            refetchSelectedMaterials={refetchSelectedMaterials}
            visible={visible}
            setVisible={onClose}
            showSelectAll={state.showAddSelectAll}
            loadingSelectedMaterials={loadingSelectedMaterials}
            getLoadingMaterials={getLoadingMaterials}
         />
      </div>
   );
};
