import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./style.scss";
import { Menu, Layout, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useParams, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/auth/index";
import { InsertRowBelowOutlined, LineChartOutlined } from "@ant-design/icons";
import { usePermissionStatus } from "../../hooks/usePermissionStatus";
import { UnAuthorizedMessage } from "../../components/UnAuthorizedMessage/index";
import { InventoryViews } from "./Inventory";
import { BuildingOperationsView } from "./Pilot";

const { Sider, Content } = Layout;

const NavigationKeyMap = {
   material_inventory: "material_inventory",
   material_pilot: "material_pilot"
};

const SideBar = () => {
   let param: any = useParams();
   const [t] = useTranslation();
   const location = useLocation();

   const history = useHistory();
   const { user: currentUser } = useAuth();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";

   const selectedKeys = () => {
      return (
         Object.keys(NavigationKeyMap).find((key) => {
            if (location.pathname.includes(key)) {
               return key;
            }
         }) || "material_pilot"
      );
   };

   const navigateTo = (type: string, links: string) => {
      if (type === "material_inventory") {
         history.push(links);
      }
      if (type === "material_pilot") {
         history.push(links);
      }
   };

   return (
      <Sider className="page-sidebar">
         <div className="view-header materialhub"></div>
         <Menu className="main" mode="vertical" selectedKeys={[selectedKeys()]}>
            <Menu.Item key="material_pilot" icon={<LineChartOutlined />}>
               <Link to={`/hub/material_pilot`}>
                  {t("navigation.materialPilot")}
               </Link>
            </Menu.Item>

            <Menu.Item
               key="material_inventory"
               icon={<InsertRowBelowOutlined />}
               onClick={() =>
                  navigateTo("material_inventory", `/hub/material_inventory`)
               }
            >
               {t("navigation.materialInventory")}
            </Menu.Item>
         </Menu>
      </Sider>
   );
};

export const MaterialHubViews = () => {
   const { loadingUserInfo, onBoarded, user: currentUser } = useAuth();
   const [t] = useTranslation();
   const history = useHistory();
   const hasPermission = usePermissionStatus();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";
   const [isLoading, setIsLoading] = React.useState<boolean>(true);

   useEffect(() => {
      if (currentUser) {
         setIsLoading(false);
      }
      if (!onBoarded && !loadingUserInfo && !isPrivateOwner) {
         history.push("/onboarding");
      }
   }, [onBoarded, loadingUserInfo, isPrivateOwner, currentUser]);

   if (isLoading)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   if (hasPermission !== undefined && !hasPermission) {
      const pageName = t("sidebarMenus.materialHub");
      return <UnAuthorizedMessage currentPageTitle={pageName} />;
   }

   return (
      <Spin spinning={!hasPermission}>
         <Layout className="wrapper-style grey fixed-sidebar">
            <SideBar></SideBar>
            <Content>
               <Switch>
                  <Route
                     exact
                     path="/hub"
                     render={() => {
                        return <Redirect to="/hub/material_pilot"></Redirect>;
                     }}
                  ></Route>
                  <Route
                     path={`/hub/material_inventory`}
                     component={InventoryViews}
                  ></Route>
                  <Route
                     path={`/hub/material_pilot`}
                     component={BuildingOperationsView}
                  ></Route>
               </Switch>
            </Content>
         </Layout>
      </Spin>
   );
};
