import { getAxiosInstance } from "../../../../utils/axios";
import { Resource } from "../../../../api/common";
import { getUploadedMaterialDocumentsLinks } from "../ReusePotential/getUploadedMaterialDocumentsLinks";
import { getMaterialImagesLinks } from "../ReusePotential/getMaterialImagesLinks";

export const saveMaterialDetails = async ({
   building_id,
   wizard_steps_product_details,
   wizard_steps_reuse_details
}: {
   building_id: string;
   wizard_steps_product_details: any;
   wizard_steps_reuse_details: any;
}) => {
   // If Any documents are uploaded then upload document to S3
   if (
      wizard_steps_product_details.documents &&
      wizard_steps_product_details.documents.length
   ) {
      const documents = await getUploadedMaterialDocumentsLinks(
         wizard_steps_product_details
      );

      wizard_steps_product_details = {
         ...wizard_steps_product_details,
         documents
      };
   }

   const axios = await getAxiosInstance();
   const result = await axios.post(Resource.path.createMaterial, {
      ...wizard_steps_product_details,
      building_id,
      material_reuse_details: wizard_steps_reuse_details
   });

   return [
      null,
      {
         ...result,
         id: result?.data?.data?.material_unique_id
      }
   ];
};

export const getFormattedMaterialsDetails = async ({
   building_id,
   wizard_steps_product_details
}: {
   building_id: string;
   wizard_steps_product_details: any;
}) => {
   // If Any documents are uploaded then upload document to S3
   if (
      wizard_steps_product_details.documents &&
      wizard_steps_product_details.documents.length
   ) {
      const documents = await getUploadedMaterialDocumentsLinks(
         wizard_steps_product_details
      );

      wizard_steps_product_details = {
         ...wizard_steps_product_details,
         documents
      };
   }

   // If exist any material general images
   if (
      wizard_steps_product_details.images &&
      wizard_steps_product_details.images.length
   ) {
      const images = await getMaterialImagesLinks(wizard_steps_product_details);

      wizard_steps_product_details = {
         ...wizard_steps_product_details,
         images
      };
   }

   return {
      ...wizard_steps_product_details,
      building_id
   };
};
