import React, { useEffect, useState } from "react";
import { Image, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import { ShareAltOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getImagePath } from "../../../../utils/getImagePath";
import NA_BUILDING_IMAGE from "../../na-building-image.png";

import "./index.scss";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { Resource } from "../../../../api/common";

export type BuildingImage = {
   file_path: string;
   object_url: string;
   bucket_name?: string;
};

export interface BuildingItemProps {
   building_images?: BuildingImage[];
   id: string;
   name: string;
   postal_code: string;
   city: string;
}

const BuildingItem = ({ building, shared }: any) => {
   const [firstBuildingImage] = building.building_images || [];
   const [t] = useTranslation();
   const [plan, setPlan] = useState<any>();
   const [onLoad, setOnLoad] = useState<boolean>(true);

   const [{ data: planData, loading: loading }, getPlanInfo] = useAxios(
      {
         url: `${Resource.path.plansURL}/sub/${building?.subscription_id}`,
         method: "GET"
      },
      { useCache: true, manual: true }
   );

   useEffect(() => {
      console.log(building);
      if (building?.subscription_status && building?.subscription_id) {
         setPlan({
            status: building?.subscription_status,
            product_name: building?.product_name
         });
      } else if (
         building?.subscription_id &&
         onLoad &&
         !building?.subscription_status
      ) {
         setOnLoad(false);
         building?.subscription_id &&
            getPlanInfo()
               .then((res) => setPlan(res.data?.data))
               .catch((err) => console.log(err));
      }
   }, [building, onLoad]);

   return (
      <div className="building-item">
         <Link to={`/buildings/${building.id}`}>
            <div className="tags">
               {shared ? (
                  <span className="tagItem shared">
                     <ShareAltOutlined /> {t("buildingList.shared")}
                  </span>
               ) : (
                  <span></span>
               )}
               {building.subscription_id ? (
                  plan?.product_name && plan?.status !== "canceled" ? (
                     <span className="tagItem plan">{plan?.product_name}</span>
                  ) : null
               ) : null}{" "}
               {(!!building.has_subscription_feature &&
                  !building.subscription_id) ||
               plan?.status === "canceled" ? (
                  <span className="tagItem activate">
                     {t("buildingList.activate")}
                  </span>
               ) : null}
            </div>
            <Spin spinning={loading}>
               <Image
                  preview={false}
                  src={
                     (firstBuildingImage?.object_url &&
                        getImagePath(firstBuildingImage, {
                           resize: {
                              fit: "cover",
                              width: 200,
                              height: 200
                           }
                        })) ||
                     NA_BUILDING_IMAGE
                  }
                  alt={building.name}
                  className={
                     (building.has_subscription_feature &&
                        !building.subscription_id) ||
                     plan?.status === "canceled"
                        ? "activateLabeled"
                        : ""
                  }
               />
            </Spin>
            <div className="item-content">
               <Title level={5} style={{ marginBottom: "0px" }}>
                  {building.name}
               </Title>
               <Text className="city-name">
                  {building?.location_places_api?.PostalCode}{" "}
                  {building?.location_places_api?.Municipality}
               </Text>
            </div>
         </Link>
      </div>
   );
};

export default BuildingItem;
