import * as uuid from "uuid";
import axios from "axios";
import { Resource } from "../../api/common";
import { Auth } from "aws-amplify";

export async function getUploadedOrgImagesLinks(data: any) {
   const assetId = `${uuid.v4()}_${Date.now()}`;
   const assetType = "organization_image";
   const orgImages: any[] = [];

   const accessToken = (await Auth.currentSession()).getIdToken();
   const signedUrlPromiseList = data.map(async (item: any) => {
      if (item?.object_url) {
         orgImages.push({
            file_name: item.file_name,
            file_path: item.file_path,
            object_url: item.object_url,
            bucket_name: item.bucket_name
         });
      } else {
         // Get Upload Signed URL
         const response = await axios.post(
            Resource.path.organization.getSignedUrl,
            {
               assetType,
               assetId,
               fileName: item.name
            },
            { headers: { Authorization: accessToken.getJwtToken() } }
         );
         const result = response?.data?.data;
         if (result) {
            return axios.put(result.signedUrl, item.originFileObj).then(() => {
               orgImages.push({
                  file_name: result.fileName,
                  file_path: result.filePath,
                  object_url: result.objectUrl,
                  bucket_name: result?.bucketName
               });
            });
         }
      }
   });

   await Promise.all(signedUrlPromiseList);
   return orgImages;
}
