import React, { useState, useEffect } from "react";
import {
   Button,
   message,
   Input,
   Checkbox,
   Select,
   Form,
   DatePicker,
   Collapse,
   Radio
} from "antd";
import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBuilding } from "../../../../../contexts/building/index";
import { Resource } from "../../../../../api/common";
import CategoriesTreeSearchField from "../../../../MaterialInventory/AddMaterialWizard/ProductDetails/ProductCategory/CategoriesTreeSearchField";
import ProductGroupField from "../../../../MaterialInventory/AddMaterialWizard/ProductDetails/ProductCategory/ProductGroupSelectField";
import Drawer from "../../../../../components/ui@concular/Drawer";
import { useAuth } from "../../../../../contexts/auth/index";
import BrandMultiSelect from "../../../../../components/BrandMultiSelect";
import { MaterialDropdown } from "../../../../../components/MaterialDropdown";

const CheckboxGroup = Checkbox.Group;

const moment = require("moment");

export const AddDemand = ({
   visible,
   onClose,
   operation,
   getAllDemands
}: any) => {
   const { Panel } = Collapse;
   const [t] = useTranslation();
   const { Option } = Select;
   const { getBuildingDetail } = useBuilding();
   const { user: currentUser } = useAuth();
   const { id: buildingId }: any = useParams<string>();
   const buildingDetails = buildingId && getBuildingDetail(buildingId);

   const plainOptions = [
      { label: "Aufbereitung", value: "Refurbishment" },
      { label: "Prüfung", value: "Testing" },
      { label: "Upcycling", value: "Upcycling" },
      { label: "(Re)zertifizierung", value: "(Re)certification" },
      { label: "Lieferung", value: "Delivery" }
   ];

   const defaultCheckedList = ["Delivery"];

   const [selectedCategoryId, setSelectedCategoryId] = useState<any>(undefined);

   const [form] = Form.useForm();

   const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
   const [indeterminate, setIndeterminate] = React.useState(true);
   const [checkAll, setCheckAll] = React.useState(false);
   const [resetProduct, setResetProduct] = useState<any>(false);
   const [productUnit, setProductUnit] = useState<any>();
   const { start_date_construction, start_date_deconstruction } = operation;
   const { id: building_id, operation_id }: any = useParams<string>();
   const [showForm, setShowForm] = useState<any>(false);
   const [showProductGroup, setShowProductGroup] = useState<boolean>(false);
   const [isReseller, setIsReseller] = useState<boolean>(false);
   const [productGroup, setProductGroup] = useState<any>();
   const [lcaMetaMaterials, setLcaMetaMaterials] = useState<any>();
   const [deconstructionType, setDeconstructionType] = useState<any>();
   const [deliveryType, setDeliveryType] = useState<any>();
   const [inActiveProductGroup, setInActiveProductGroup] = useState<any>();

   const [
      { data, loading: getProductDetailsLoading },
      fetchProductGroupDetails
   ] = useAxios({}, { useCache: false, manual: true });

   const [
      { data: materialsData, loading: ingredientsLoading },
      fetchIngredients
   ] = useAxios(
      {
         url: `${Resource.path.getMaterialIngredients}`,
         method: "GET"
      },
      { manual: true }
   );

   const [{ loading: confirmLoading }, add] = useAxios(
      {
         url: `${Resource.path.demandApiUrl(building_id, operation_id)}`,
         method: "POST"
      },
      { manual: true }
   );

   useEffect(() => {
      if (data?.data) {
         setProductGroup(data.data);
         setProductUnit(data.data.unit);
         setShowForm(true);
      }
      if (currentUser) {
         if (
            currentUser?.attributes &&
            currentUser?.attributes["custom:user_type"]
         ) {
            setIsReseller(
               currentUser?.attributes["custom:user_type"] === "reseller"
            );
         }
      }
      if (materialsData?.data) {
         const materials: any = materialsData?.data.data;
         const temp = materials
            ? productGroup?.lca_meta
                 ?.filter((material: any) => material.sub_material_id)
                 .map((material: any) => {
                    return materials?.find(
                       (m: any) => m.id === material.sub_material_id
                    );
                 })
            : [];
         setLcaMetaMaterials(temp);
      }
   }, [data, currentUser, materialsData]);

   const onChangeCheckboxGroup = (list: any) => {
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length < plainOptions.length);
      setCheckAll(list.length === plainOptions.length);
   };

   /* const onCheckAllChange = (e: any) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }; */

   const dateFormat = "DD.MM.YYYY";

   const children = [];
   for (let i = 10; i < 36; i++) {
      children.push(
         <Option value={i.toString(36) + i} key={i.toString(36) + i}>
            {i.toString(36) + i}
         </Option>
      );
   }

   const onCategorySelect = (value: {
      id: string;
      name: string;
      dropDownId: string;
      reset: boolean;
   }) => {
      setShowProductGroup(false);
      const { id: categoryId, reset } = value;
      setSelectedCategoryId(categoryId);
      setResetProduct(reset);
      setShowForm(false);
      setTimeout(() => {
         setShowProductGroup(true);
      }, 1500);
   };

   const onProductGroupSelect = async (value: any) => {
      setShowForm(false);
      if (value && value.key) {
         await fetchProductGroupDetails({
            url: `${Resource.path.getProductGroupDetails}/${value.key}`
         });
         form.setFieldsValue({ product_group: value });
         await fetchIngredients();
      }
   };

   if (getProductDetailsLoading || ingredientsLoading || confirmLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   const onFinish = async () => {
      form
         .validateFields()
         .then(async () => {
            const data = form.getFieldsValue();
            console.log("data", data);
            let delivery = 0;
            if (isReseller) {
               delivery = deliveryType && deliveryType === "delivery" ? 1 : 0;
            } else {
               delivery =
                  data?.services && data?.services.indexOf("Delivery") !== -1
                     ? 1
                     : 0;
            }
            await add({
               data: {
                  product_group: data.product_group.label,
                  categories: JSON.stringify(data.categories),
                  product_group_id: data.product_group.value,
                  amount: data?.minimum_amount ? data.minimum_amount : 0,
                  target_price: data.target_price,
                  delivery,
                  refurbishment:
                     data?.services &&
                     data?.services.indexOf("Refurbishment") !== -1
                        ? 1
                        : 0,
                  testing:
                     data?.services && data?.services.indexOf("Testing") !== -1
                        ? 1
                        : 0,
                  upcycling:
                     data?.services &&
                     data?.services.indexOf("Upcycling") !== -1
                        ? 1
                        : 0,
                  recertification:
                     data?.services &&
                     data?.services.indexOf("(Re)certification") !== -1
                        ? 1
                        : 0,
                  delivery_date: data?.delivery_date
                     ? data?.delivery_date
                     : null,
                  unit: productUnit,
                  building_name: buildingDetails?.name,
                  measurements: data?.measurements ? data.measurements : null,
                  manufacturers: data?.manufacturers
                     ? data?.manufacturers
                     : null,
                  selected_material: data?.label_material
                     ? data?.label_material
                     : null,
                  deconstruction_service:
                     deconstructionType && deconstructionType === "managed"
                        ? 1
                        : 0,
                  self_deconstruction:
                     deconstructionType && deconstructionType === "self"
                        ? 1
                        : 0,
                  pickup: deliveryType && deliveryType === "pickup" ? 1 : 0
               }
            })
               .then((response) => {
                  getAllDemands();
                  console.log(response);
                  form.resetFields();
                  closeMe();
                  message.success("Demand added successfully");
               })
               .catch((error) => {
                  console.log(error);
                  message.error(t("buildingOperations.errorMessage"));
               });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const closeMe = () => {
      form.resetFields();
      setShowForm(false);
      setShowProductGroup(false);
      onClose();
   };

   const setInActive = (data: any) => {
      //console.log('inavtice', data)
      setInActiveProductGroup(data);
   };

   return (
      <Drawer
         placement="right"
         closable={true}
         width={450}
         visible={visible}
         onClose={closeMe}
         title={t("demands.drawer.title")}
      >
         <div className="add-demand-form">
            <Form
               layout="vertical"
               form={form}
               initialValues={{
                  delivery_date: start_date_deconstruction
                     ? moment(start_date_deconstruction)
                     : moment(start_date_construction),
                  minimum_amount: "1",
                  services: checkedList
               }}
            >
               <CategoriesTreeSearchField
                  form={form}
                  onChange={onCategorySelect}
                  operationType={"ADD"}
                  setInActive={setInActive}
               ></CategoriesTreeSearchField>

               {showProductGroup && (
                  <Form.Item
                     label={t("productDetails.productCategory.productGroup")}
                     name="product_group"
                     rules={[
                        {
                           required: true,
                           message: t(
                              "productDetails.productCategory.validations.productGroup"
                           )
                        }
                     ]}
                  >
                     <ProductGroupField
                        onChange={onProductGroupSelect}
                        categoryId={selectedCategoryId}
                        reset={resetProduct}
                        operationType={"ADD"}
                        inActive={inActiveProductGroup}
                     ></ProductGroupField>
                  </Form.Item>
               )}
               {showForm && (
                  <>
                     {!isReseller && (
                        <Form.Item
                           rules={[
                              {
                                 required: true,
                                 message: t(
                                    "demands.addDemand.minimum_amount_error"
                                 )
                              }
                           ]}
                           className="inline"
                           label={t("demands.addDemand.minimum_amount_label")}
                           name="minimum_amount"
                        >
                           <Input
                              placeholder="1"
                              suffix={<b>{productUnit}</b>}
                           />
                        </Form.Item>
                     )}

                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: t("demands.addDemand.target_price_error")
                           }
                        ]}
                        className="inline"
                        label={t("demands.addDemand.target_price_label")}
                        name="target_price"
                     >
                        <Input
                           min="0"
                           type="number"
                           suffix={<b>€/{productUnit}</b>}
                        />
                     </Form.Item>
                     <Collapse defaultActiveKey={[3]} ghost>
                        <Panel
                           header={t(
                              "productDetails.productCategory.specification.manufacturerName"
                           )}
                           key="1"
                        >
                           <Form.Item name="manufacturers">
                              <BrandMultiSelect></BrandMultiSelect>
                           </Form.Item>
                        </Panel>
                        <Panel header="measurements" key="2">
                           <Form.Item label="Width">
                              <Input.Group compact>
                                 <Form.Item
                                    noStyle
                                    rules={[
                                       {
                                          required: false
                                       },
                                       {
                                          validator(_, value) {
                                             if (
                                                value &&
                                                form.getFieldValue(
                                                   "measurements"
                                                )?.width?.maximum &&
                                                Number(value) >
                                                   Number(
                                                      form.getFieldValue(
                                                         "measurements"
                                                      )["width"]["maximum"]
                                                   )
                                             ) {
                                                return Promise.reject(
                                                   "Please enter valid minimum value"
                                                );
                                             }
                                             return Promise.resolve();
                                          }
                                       }
                                    ]}
                                    className="inline"
                                    name={["measurements", "width", "minimum"]}
                                 >
                                    <Input
                                       placeholder="Minimum"
                                       style={{
                                          width: "50%",
                                          textAlign: "center"
                                       }}
                                       min="0"
                                       type="number"
                                       suffix={<b>mm</b>}
                                    />
                                 </Form.Item>
                                 <Form.Item
                                    noStyle
                                    rules={[
                                       {
                                          required: false
                                       },
                                       {
                                          validator(_, value) {
                                             if (
                                                value &&
                                                form.getFieldValue(
                                                   "measurements"
                                                )?.width?.minimum &&
                                                Number(value) <
                                                   Number(
                                                      form.getFieldValue(
                                                         "measurements"
                                                      )["width"]["minimum"]
                                                   )
                                             ) {
                                                return Promise.reject(
                                                   "Please enter valid maximum value"
                                                );
                                             }
                                             return Promise.resolve();
                                          }
                                       }
                                    ]}
                                    className="inline"
                                    name={["measurements", "width", "maximum"]}
                                 >
                                    <Input
                                       placeholder="Maximum"
                                       style={{
                                          width: "50%",
                                          textAlign: "center"
                                       }}
                                       min={
                                          form.getFieldValue("measurements")
                                             ?.width?.minimum
                                             ? form.getFieldValue(
                                                  "measurements"
                                               )["width"]["minimum"]
                                             : 0
                                       }
                                       type="number"
                                       suffix={<b>mm</b>}
                                    />
                                 </Form.Item>
                              </Input.Group>
                           </Form.Item>

                           <Form.Item label="Height">
                              <Input.Group compact>
                                 <Form.Item
                                    noStyle
                                    rules={[
                                       {
                                          required: false
                                       },
                                       {
                                          validator(_, value) {
                                             if (
                                                value &&
                                                form.getFieldValue(
                                                   "measurements"
                                                )?.height?.maximum &&
                                                Number(value) >
                                                   Number(
                                                      form.getFieldValue(
                                                         "measurements"
                                                      )["height"]["maximum"]
                                                   )
                                             ) {
                                                return Promise.reject(
                                                   "Please enter valid minimum value"
                                                );
                                             }
                                             return Promise.resolve();
                                          }
                                       }
                                    ]}
                                    className="inline"
                                    name={["measurements", "height", "minimum"]}
                                 >
                                    <Input
                                       placeholder="Minimum"
                                       style={{
                                          width: "50%",
                                          textAlign: "center"
                                       }}
                                       min="0"
                                       type="number"
                                       suffix={<b>mm</b>}
                                    />
                                 </Form.Item>
                                 <Form.Item
                                    noStyle
                                    rules={[
                                       {
                                          required: false
                                       },
                                       {
                                          validator(_, value) {
                                             if (
                                                value &&
                                                form.getFieldValue(
                                                   "measurements"
                                                )?.height?.minimum &&
                                                Number(value) <
                                                   Number(
                                                      form.getFieldValue(
                                                         "measurements"
                                                      )["height"]["minimum"]
                                                   )
                                             ) {
                                                return Promise.reject(
                                                   "Please enter valid maximum value"
                                                );
                                             }
                                             return Promise.resolve();
                                          }
                                       }
                                    ]}
                                    className="inline"
                                    name={["measurements", "height", "maximum"]}
                                 >
                                    <Input
                                       placeholder="Maximum"
                                       style={{
                                          width: "50%",
                                          textAlign: "center"
                                       }}
                                       min={
                                          form.getFieldValue("measurements")
                                             ?.height?.minimum
                                             ? form.getFieldValue(
                                                  "measurements"
                                               )["height"]["minimum"]
                                             : 0
                                       }
                                       type="number"
                                       suffix={<b>mm</b>}
                                    />
                                 </Form.Item>
                              </Input.Group>
                           </Form.Item>

                           <Form.Item label="Depth">
                              <Input.Group compact>
                                 <Form.Item
                                    noStyle
                                    rules={[
                                       {
                                          required: false
                                       },
                                       {
                                          validator(_, value) {
                                             if (
                                                value &&
                                                form.getFieldValue(
                                                   "measurements"
                                                )?.depth?.maximum &&
                                                Number(value) >
                                                   Number(
                                                      form.getFieldValue(
                                                         "measurements"
                                                      )["depth"]["maximum"]
                                                   )
                                             ) {
                                                return Promise.reject(
                                                   "Please enter valid minimum value"
                                                );
                                             }
                                             return Promise.resolve();
                                          }
                                       }
                                    ]}
                                    className="inline"
                                    name={["measurements", "depth", "minimum"]}
                                 >
                                    <Input
                                       placeholder="Minimum"
                                       style={{
                                          width: "50%",
                                          textAlign: "center"
                                       }}
                                       min="0"
                                       type="number"
                                       suffix={<b>mm</b>}
                                    />
                                 </Form.Item>
                                 <Form.Item
                                    noStyle
                                    rules={[
                                       {
                                          required: false
                                       },
                                       {
                                          validator(_, value) {
                                             if (
                                                value &&
                                                form.getFieldValue(
                                                   "measurements"
                                                )?.depth?.minimum &&
                                                Number(value) <
                                                   Number(
                                                      form.getFieldValue(
                                                         "measurements"
                                                      )["depth"]["minimum"]
                                                   )
                                             ) {
                                                return Promise.reject(
                                                   "Please enter valid maximum value"
                                                );
                                             }
                                             return Promise.resolve();
                                          }
                                       }
                                    ]}
                                    className="inline"
                                    name={["measurements", "depth", "maximum"]}
                                 >
                                    <Input
                                       placeholder="Maximum"
                                       style={{
                                          width: "50%",
                                          textAlign: "center"
                                       }}
                                       min={
                                          form.getFieldValue("measurements")
                                             ?.depth?.minimum
                                             ? form.getFieldValue(
                                                  "measurements"
                                               )["depth"]["minimum"]
                                             : 0
                                       }
                                       type="number"
                                       suffix={<b>mm</b>}
                                    />
                                 </Form.Item>
                              </Input.Group>
                           </Form.Item>
                        </Panel>

                        {productGroup &&
                           lcaMetaMaterials &&
                           lcaMetaMaterials.length > 0 && (
                              <Panel header="material" key="3">
                                 <Form.Item
                                    label={productGroup?.lca_meta_label}
                                 >
                                    <MaterialDropdown
                                       name={["label_material"]}
                                       materials={lcaMetaMaterials}
                                       setMaterialGroup={() => {
                                          const fields = form.getFieldsValue();
                                          if (
                                             fields["label_material"]
                                                ?.sub_material_id
                                          ) {
                                             fields[
                                                "label_material"
                                             ].sub_material_id = undefined;
                                             form.setFieldsValue(fields);
                                          }
                                       }}
                                    />
                                 </Form.Item>
                              </Panel>
                           )}
                     </Collapse>
                     {!isReseller && (
                        <Form.Item>
                           <strong>
                              {t("demands.addDemand.services.title")}
                           </strong>
                           <p>{t("demands.addDemand.services.message")}</p>
                        </Form.Item>
                     )}

                     {!isReseller && (
                        <Form.Item className="checkboxgroup" name="services">
                           <CheckboxGroup
                              options={plainOptions}
                              onChange={onChangeCheckboxGroup}
                           />
                        </Form.Item>
                     )}

                     {!isReseller && (
                        <Form.Item
                           label={t("demands.addDemand.delivery_date_label")}
                           name="delivery_date"
                           rules={[
                              {
                                 required: true,
                                 message: "Please enter date"
                              }
                           ]}
                        >
                           <DatePicker format={dateFormat} />
                        </Form.Item>
                     )}
                  </>
               )}
               {showForm && (
                  <>
                     {!!isReseller && (
                        <Form.Item label={"Deconstruction"}>
                           <Radio.Group
                              options={[
                                 { label: "managed", value: "managed" },
                                 { label: "self deconstruction", value: "self" }
                              ]}
                              onChange={(event: any) => {
                                 setDeconstructionType(event.target.value);
                              }}
                              optionType="button"
                              buttonStyle="solid"
                           />
                        </Form.Item>
                     )}
                     {!!isReseller && (
                        <Form.Item label={"Delivery"}>
                           <Radio.Group
                              options={[
                                 { label: "self pickup", value: "pickup" },
                                 { label: "delivery", value: "delivery" }
                              ]}
                              onChange={(event: any) => {
                                 setDeliveryType(event.target.value);
                              }}
                              optionType="button"
                              buttonStyle="solid"
                           />
                        </Form.Item>
                     )}
                     <Form.Item>
                        <Button
                           type="primary"
                           shape="round"
                           size="large"
                           htmlType="submit"
                           loading={confirmLoading}
                           onClick={onFinish}
                        >
                           {t("demands.addDemand.save_demand")}
                        </Button>
                     </Form.Item>
                  </>
               )}
            </Form>
         </div>
      </Drawer>
   );
};
