import React from "react";
import "./index.scss";

const QRCode: React.FC<any> = ({ src }) => {
   return (
      <div className="qr-code">
         <img src={src} />
      </div>
   );
};

export default QRCode;
