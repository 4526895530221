import React from "react";
import { FormElementFieldMap } from "../../../../../../../components/FormFields/FormElementFieldMap";
import * as uuid from "uuid";
import { Form } from "antd";

export const layout = {
   labelCol: { span: 8 },
   wrapperCol: { span: 16 }
};

export const FormView = React.memo(({ schema, form }: any) => {
   const elements = schema?.map((item: any, index: number) => {
      const TagName = FormElementFieldMap.get(item.field.type);

      return (
         <div className="form-elements-wrapper" key={`${uuid.v4()}`}>
            <TagName
               key={`${item.formItem.name}_${index}}`}
               {...item}
            ></TagName>
         </div>
      );
   });

   return (
      <Form.List name="specification">
         {(fields, { add, remove }) => <>{elements || ""}</>}
      </Form.List>
   );
});
