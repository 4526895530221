import { List, Skeleton, Spin, Button } from "antd";
import React from "react";
import { useIfcViewer, GrouppedObjects } from "../IfcViewerContext";

import "./style.scss";
import {
   ifcApi,
   PagedIFCFileOUT,
   IFCObjectIN,
   IFCObjectOUT,
   ifcObjectApi
} from "../../api-client";
import { useParams } from "react-router";
import { ResponseStatus } from "../types";

export const getGrouppedByName = (result: (IFCObjectIN | IFCObjectOUT)[]) =>
   result.reduce((previous, current) => {
      if (previous[current.ifc_class]) {
         previous[current.ifc_class].push(current);
      } else {
         previous[current.ifc_class] = [current];
      }

      return previous;
   }, {} as GrouppedObjects);

export const IfcFiles = () => {
   const {
      setCurrentFile,
      setCurrentIfcFile,
      currentIfcFile,
      viewer,
      setParsedData
   } = useIfcViewer();
   const [files, setFiles] = React.useState<PagedIFCFileOUT>();
   const { id } = useParams();
   const inputRef = React.useRef<HTMLInputElement>(null);
   const [uploading, setUploading] = React.useState<ResponseStatus>({
      isLoading: false
   });

   const fetchFiles = async () => {
      const response = await ifcApi.djIfcBaseApiV1RoutersIfcFileGetAllIfcFiles(
         id
      );
      setFiles(response.data);
   };

   React.useEffect(() => {
      fetchFiles();
   }, [id]);

   React.useLayoutEffect(() => {
      const inputElement = inputRef.current;
      if (!inputElement) {
         return;
      }

      const loadFile = async (event: Event) => {
         setUploading({ isLoading: true });
         const file = (event.target as HTMLInputElement)?.files?.[0];
         setCurrentFile?.(file);
         if (file) {
            (async () => {
               try {
                  const response =
                     await ifcApi.djIfcBaseApiV1RoutersIfcFileUploadIfcFile(
                        id,
                        file
                     );
                  setCurrentIfcFile?.(response.data);
               } catch (e) {
                  setUploading({
                     isLoading: false,
                     error: { message: e.message }
                  });
               } finally {
                  setUploading({ isLoading: false });
               }
            })();
         }
      };

      inputRef?.current?.addEventListener("change", loadFile, false);

      return () => {
         inputElement.removeEventListener("change", loadFile, false);
      };
   }, [
      currentIfcFile,
      id,
      setCurrentFile,
      setCurrentIfcFile,
      setParsedData,
      viewer,
      inputRef
   ]);

   return (
      <div className="ifc-container__file-input">
         {uploading.isLoading ? (
            <Spin />
         ) : (
            <div className="upload-wrapper">
               <div className="label">Upload BIM File</div>
               <div className="hint">
                  Drop file here or select from computer
               </div>
               <label>
                  Choose a file...
                  <input
                     id="fileupload"
                     ref={inputRef}
                     className="upload"
                     type="file"
                     accept=".ifc"
                  />
               </label>
            </div>
         )}
         {uploading.error?.message && <span>{uploading.error?.message}</span>}
         {files?.items?.map((fileTmp) => {
            return (
               <div key={fileTmp.id} className="file-list-item">
                  <List.Item
                     key={fileTmp.id}
                     actions={[
                        <Button
                           onClick={() => {
                              setCurrentIfcFile?.(fileTmp);

                              ifcObjectApi
                                 .djIfcBaseApiV1RoutersIfcObjectGetIfcObjects(
                                    fileTmp.id
                                 )
                                 .then((response) => {
                                    debugger;
                                    const result = response.data;
                                    if (result && result?.length > 0) {
                                       setParsedData?.(
                                          getGrouppedByName(result)
                                       );
                                    }
                                 });
                           }}
                           type="primary"
                        >
                           Solve
                        </Button>,
                        <Button
                           onClick={() => {
                              ifcApi
                                 .djIfcBaseApiV1RoutersIfcFileDeleteIfcFile(
                                    fileTmp.id
                                 )
                                 .then((response) => {
                                    const result = response.status;
                                    if (result == 200) {
                                       console.log(
                                          `${fileTmp.id} - has been deleted!`
                                       );
                                       fetchFiles();
                                    }
                                 });
                           }}
                           type="default"
                        >
                           Delete
                        </Button>
                     ]}
                  >
                     <Skeleton loading={false} avatar title={false} active>
                        <List.Item.Meta
                           title={`${fileTmp.name} - ${fileTmp.id.slice(0, 4)}`}
                        />
                     </Skeleton>
                  </List.Item>
               </div>
            );
         })}
      </div>
   );
};
