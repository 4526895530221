import React from "react";
import { Layout } from "antd";

import "./style.scss";
import { SideBarMenu } from "./SidebarMenu";
import { setInterval } from "timers";
import { Auth } from "aws-amplify";
import { configure } from "axios-hooks";
import axios from "axios";

const { Content } = Layout;

setInterval(async () => {
   try {
      const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
      configure({
         axios: axios.create({
            timeout: 900000,
            headers: { Authorization: jwtToken }
         }),
         cache: false
      });
   } catch (error) {
      if (!document.getElementById("login_page_hidden")) {
         window.location.reload();
      }
   }
}, 10000);

export const MainLayout = ({ children }: any) => {
   return (
      <Layout>
         <SideBarMenu></SideBarMenu>
         <Layout>
            <Content id="main">
               <div className="site-layout-background">{children}</div>
            </Content>
         </Layout>
      </Layout>
   );
};
