import React, { useState, useEffect } from "react";
import { Resource } from "../../../../../api/common";
import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";
import { MaterialDetails } from "../MaterialDetails";
import Drawer from "../../../../../components/ui@concular/Drawer";

export const PassportDetail = ({
   visible,
   onClose,
   passportSpecification,
   showRequestBtn,
   demand_id,
   dataDemand
}: any) => {
   const [t] = useTranslation();
   const [material, setMaterial] = useState<any>();
   const { id, building_id } = passportSpecification;
   const [{ data, loading: getLoading, error: getError }] = useAxios(
      {
         url: `${
            building_id
               ? Resource.path.getPassportDetailsListAuth
               : Resource.path.getPassportDetailsListAuthHub
         }/${id}`,
         method: "GET"
      },
      { useCache: true }
   );

   useEffect(() => {
      if (data?.data) {
         setMaterial(data?.data.pop());
      }
   }, [data]);

   if (getLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <Drawer closable={true} onClose={onClose} visible={visible} width={600}>
         {passportSpecification && (
            <MaterialDetails
               closeMe={() => {
                  console.log("Close-me:passport-detials");
                  onClose();
               }}
               material={passportSpecification}
               showRequestBtn={showRequestBtn}
               demand_id={demand_id}
               dataDemand={dataDemand}
            />
         )}
      </Drawer>
   );
};
