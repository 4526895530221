import { Button } from "antd";
import React, { ReactNode } from "react";
import styles from "./style.module.scss";

interface IProps {
   icon?: ReactNode;
   type?:
      | "primary"
      | "default"
      | "light"
      | "alternative"
      | "negative"
      | "state"
      | "link";
   className?: string;
   disable?: boolean;
   size?: "large" | "middle" | "small";
   onClick?: (e: any) => any;
   children?: ReactNode;
   htmlType?: "button" | "submit" | "reset";
   loading?: boolean;
   style?: any;
}

const ButtonField = ({
   icon = null,
   type = "primary",
   className,
   disable,
   size = "middle",
   onClick,
   children,
   htmlType = "button",
   loading = false,
   style
}: IProps) => {
   return (
      <Button
         icon={icon}
         className={`${className} 
         ${styles.buttonField} 
         ${icon ? styles.hasIcon : ""}
         ${styles[type]}
         ${styles[size]}
         `}
         type={type == "link" ? "link" : "default"}
         disabled={disable}
         onClick={onClick}
         htmlType={htmlType}
         loading={loading}
         style={{ ...style }}
      >
         {children}
      </Button>
   );
};

export default ButtonField;
