import React from "react";
import "./index.scss";

const BigAddButton: React.FC<any> = ({ children, onClick }) => {
   return (
      <div className="building-button" onClick={onClick}>
         <div>{children}</div>
      </div>
   );
};

export default BigAddButton;
