import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
   // PageHeader,
   // Button,
   Table,
   // Tag,
   Space,
   message,
   Popconfirm,
   // Typography,
   Col,
   Row
   // Result
} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import useAxios from "axios-hooks";
import { useParams, useHistory } from "react-router-dom";
// import InviteMember from "./InviteMember";
import { Resource } from "../../../api/common";
import { useAuth } from "../../../contexts/auth/index";
import styles from "./UserManagement.module.scss";
import InputField from "../../../components/FormFields/InputField";
import ButtonField from "../../../components/FormFields/ButtonField";
import Organization from "../../Onboarding/CreateOrganization/Organization";
import { Organization as OrganizationType } from "./../../../types";
import TagField from "../../../components/FormFields/TagField";
import DropdownField from "../../../components/FormFields/DropdownField";
import { rolesList } from "../../Settings/consts.d";
import { InviteUserModal } from "../../Settings/InviteUserModal";
import { useBuilding } from "../../../contexts/building/index";

enum InvitationStatusValue {
   "PENDING" = "PENDING",
   "CONFIRMED" = "CONFIRMED"
}

// const { Text } = Typography;

export const BuildingUsersView = () => {
   const [t] = useTranslation();
   const [inviteMemberModal, setInviteMemberModal] = useState<boolean>(false);
   const [listData, setListData] = useState<Array<any> | undefined>();
   const { id: buildingId }: any = useParams<string>();
   const [loadingTable, setloadingTable] = useState<boolean>(false);
   const { user: currentUser, joinedOrganizationsList } = useAuth();
   const hasDeleteFeatureEnabled = true;
   const { getBuildingDetail, refetchBuildings } = useBuilding();
   const buildingDetails = getBuildingDetail(buildingId);
   const [currentRole, setCurrentRole] = useState<string>();
   const [buildingOrganization, setBuildingOrganization] =
      useState<OrganizationType>();
   const [title, setTitle] = useState<undefined | string>();
   const [titleLoading, setTitleLoading] = useState<boolean>(false);
   const history = useHistory();
   const [{ data, loading, error }, getAllMembers] = useAxios(
      {
         url: `${Resource.path.getBuildingMembers(buildingId)}`,
         method: "get"
      },
      { manual: false }
   );

   const [{ loading: loadingUpdate }, updateInviteUser] = useAxios(
      {
         url: `${Resource.path.patchBuildingMember("")}`,
         method: "patch"
      },
      { manual: true }
   );

   useEffect(() => {
      const buildingOrganization = joinedOrganizationsList?.find(
         (item: OrganizationType) =>
            item?.organizations_id == buildingDetails?.organisation_id
      );
      setBuildingOrganization(buildingOrganization);
   }, [buildingDetails]);

   const createList = async () => {
      setloadingTable(true);
      await getAllMembers()
         .then(({ data }: any) => {
            let recordsList = [];
            const {
               username: currentUserId,
               attributes: currentUserAttributes
            } = currentUser;
            const {
               email: currentUserEmail,
               family_name: currentUserFamilyName,
               given_name: currentUserGivenName
            } = currentUserAttributes;
            if (data?.data) {
               const { data: records } = data;
               if (records.length > 0) {
                  recordsList = records.map((value: any) => {
                     const {
                        user,
                        email,
                        user_id,
                        invited_to_title,
                        status,
                        invite_id,
                        invited_to_role
                     } = value;
                     const { given_name, family_name } = user;
                     return {
                        key: user_id,
                        name:
                           given_name && family_name
                              ? `${given_name} ${family_name}`
                              : email,
                        email,
                        title: invited_to_title,
                        roles: [invited_to_role],
                        status,
                        invite_id
                     };
                  });

                  const currentUserExists = records.filter((value: any) => {
                     const { user_id } = value;
                     return user_id === currentUserId;
                  });
                  setListData(recordsList);
               } else {
                  setListData([]);
               }
            }
            setloadingTable(false);
         })
         .catch((error: any) => {
            setloadingTable(false);
            console.log(error);
            if (!axios.isCancel(error)) {
               message.error(t("buildingUsersView.message.errorMemberList"));
            }
         });
   };

   const DeleteAction = (props: { record: any }) => {
      const { record } = props;
      const { key } = record;
      const [{ data, loading, error }, removeMember] = useAxios(
         {
            url: `${Resource.path.removeBuildingMember(buildingId, key)}`,
            method: "delete"
         },
         { manual: true }
      );
      const deleteMember = () => {
         removeMember()
            .then((response) => {
               message.success(
                  t("buildingUsersView.deleteMember.message.success")
               );
               createList();
            })
            .catch((error) => {
               message.error(t("buildingUsersView.deleteMember.message.error"));
               console.log(error);
            });
      };

      return (
         <Popconfirm
            title={t("buildingUsersView.deleteMember.title")}
            onConfirm={() => deleteMember()}
            okText={t("buildingUsersView.deleteMember.okText")}
            cancelText={t("buildingUsersView.deleteMember.cancelText")}
         >
            <ButtonField type="link">
               {t("default.remove").toLowerCase()}
            </ButtonField>
         </Popconfirm>
      );
   };

   const InvitationStatus = (props: { record: any }) => {
      const { record } = props;
      const { invite_id, status } = record;
      const [showInvite, setShowInvite] = useState<boolean>(true);
      const [inviteSend, setInviteSend] = useState<boolean>(false);
      const [showLoading, setShowLoading] = useState<boolean>(false);
      const [{ data, loading, error }, resendInviteMail] = useAxios(
         {
            url: `${Resource.path.resendInvitation(invite_id)}`,
            method: "get"
         },
         { manual: true }
      );
      const resendInvite = async () => {
         setInviteSend(true);
         setShowLoading(true);
         await resendInviteMail()
            .then((response) => {
               message.success(t("inviteMembers.message.inviteSuccess"));
               setShowLoading(false);
               setInviteSend(false);
               setShowInvite(true);
            })
            .catch((error) => {
               message.success(t("inviteMembers.message.inviteFailed"));
               setShowLoading(false);
               setInviteSend(false);
               setShowInvite(true);
            });
      };
      if (status === InvitationStatusValue.PENDING) {
         // return showInvite ? (
         // <Tag
         //    color="red"
         //    onMouseEnter={() => {
         //       setShowInvite(false);
         //    }}
         // >
         return (
            <div className="d-flex">
               <TagField status="attention">
                  {t("inviteMembers.status.invited")}
               </TagField>
               {/* ) : ( */}
               {/* </Tag> */}
               <ButtonField
                  type="link"
                  className={styles["resend-invite-btn"]}
                  loading={showLoading}
                  // onMouseLeave={() => {
                  //    !inviteSend && setShowInvite(true);
                  // }}
                  onClick={resendInvite}
               >
                  {t("inviteMembers.status.resend")}
               </ButtonField>
            </div>
         );
         // );
      } else {
         // return <Tag color="green">{t("inviteMembers.status.confirmed")}</Tag>;
         return (
            <TagField status={"success"}>
               {t("inviteMembers.status.active")}
            </TagField>
         );
      }
   };

   const columns = [
      {
         title: t("buildingUsersView.grid.headers.user"),
         dataIndex: "name",
         key: "name",
         render: (text: any, record: any) => (
            <span
               className={
                  record.status !== InvitationStatusValue.CONFIRMED
                     ? styles.pending
                     : ""
               }
            >
               {text}
            </span>
         )
      },
      {
         title: t("buildingUsersView.grid.headers.title"),
         dataIndex: "title",
         key: "title",
         render: (title: string, record: any) => (
            <span
               className={
                  record.status !== InvitationStatusValue.CONFIRMED
                     ? styles.pending
                     : ""
               }
            >
               {title}
            </span>
         )
      },
      {
         title: t("buildingUsersView.grid.headers.email"),
         dataIndex: "email",
         key: "email",
         render: (email: string, record: any) => (
            <span
               className={
                  record.status !== InvitationStatusValue.CONFIRMED
                     ? styles.pending
                     : ""
               }
            >
               {email}
            </span>
         )
      },
      {
         title: t("buildingUsersView.grid.headers.userManagement"),
         key: "roles",
         dataIndex: "roles",
         render: (roles: any, record: any) => (
            <>
               {roles.map((role: any) => {
                  console.log(role, record);
                  return currentUser.username == record.key ? (
                     <span>{role}</span>
                  ) : (
                     <DropdownField
                        items={rolesList}
                        selected={rolesList.find(
                           (item) =>
                              item.value.toLowerCase() == role.toLowerCase()
                        )}
                        onChange={(value: number) => {
                           changeUserRole(value, record);
                        }}
                        className={styles.userTypeSelect}
                        noMargin
                     />
                  );
               })}
            </>
         )
      },
      {
         title: t("buildingUsersView.grid.headers.status"),
         key: "status",
         render: (text: any, record: any) => {
            return (
               <Space size="large">
                  <InvitationStatus record={record} />
               </Space>
            );
         }
      },
      {
         title: "",
         key: "",
         width: 30,
         render: (text: any, record: any) =>
            hasDeleteFeatureEnabled &&
            record?.email !== currentUser?.attributes?.email && (
               <DeleteAction record={record} />
            )
      }
   ];

   const changeUserRole = async (value: number, data: any) => {
      console.log(data);
      const rselected = rolesList
         .find((item) => item.id == value)
         ?.value.toUpperCase();
      await updateInviteUser({
         data: {
            user_id: data?.key,
            invited_to_role: rselected,
            building_id: buildingId
         },
         url: `${Resource.path.patchBuildingMember(data?.invite_id)}`
      })
         .then(async () => {
            await createList();
         })
         .catch(() => {});
   };
   const handleInvite = () => {
      setInviteMemberModal(!inviteMemberModal);
   };

   useEffect(() => {
      if (!listData && currentUser) {
         createList();
      }
      if (
         joinedOrganizationsList &&
         buildingDetails &&
         buildingDetails?.organisation_id
      ) {
         const currentRole = joinedOrganizationsList.filter((rec: any) => {
            return rec.organizations_id == buildingDetails?.organisation_id;
         });
         const roleUser = currentRole?.pop();
         setCurrentRole(roleUser?.role || buildingDetails?.role);
      } else {
         setCurrentRole(buildingDetails?.role);
      }
      if (data?.data) {
         const rec = data?.data
            .filter((rec: any) => {
               return (
                  rec.user_id == currentUser?.username &&
                  rec.building_id == buildingId
               );
            })
            ?.pop();
         setTitle(rec?.invited_to_title || undefined);
         console.log(title);
      }
   }, [listData, currentUser, joinedOrganizationsList, buildingDetails, data]);

   const inviteMemberSuccess = () => {
      createList();
      setInviteMemberModal(!inviteMemberModal);
   };

   const [{ loading: leaveLoading }, leaveBuildingApi] = useAxios(
      {
         url: `${Resource.path.removeBuildingMember(
            buildingId,
            currentUser?.username
         )}`,
         method: "delete"
      },
      { manual: true }
   );

   const confirmLeave = async () => {
      await leaveBuildingApi()
         .then(() => {
            history.push("/");
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const updateTitle = async () => {
      setTitleLoading(true);
      const rec = data?.data
         .filter((rec: any) => {
            return (
               rec.user_id == currentUser?.username &&
               rec.building_id == buildingId
            );
         })
         ?.pop();
      await updateInviteUser({
         data: {
            user_id: currentUser?.username,
            invited_to_role: rec?.invited_to_role,
            invited_to_title: title,
            building_id: buildingId
         },
         url: `${Resource.path.patchBuildingMember(rec?.invite_id)}`
      })
         .then(async () => {
            await refetchBuildings();
            await createList();
            setTitleLoading(false);
         })
         .catch(() => {
            setTitleLoading(false);
         });
   };

   const onChangeTitle = (value: string) => {
      setTitle(value);
   };

   return (
      <div className={styles.accessWrapper}>
         <div className={styles.pageHeader}>
            <h1 className={styles.pageTitle}>
               {t("buildingUsersView.userManagement")}
            </h1>
         </div>
         {currentRole && buildingDetails?.isShared && (
            <>
               <Row className={styles.section}>
                  <Col span={24}>
                     <h3 className={styles.sectionTitle}>
                        {t("buildingUsersView.personalSettings")}
                     </h3>
                  </Col>
                  <Col sm={5} className="d-flex flex-align-end">
                     <span
                        className={styles.username}
                     >{`${currentUser?.attributes?.given_name} ${currentUser?.attributes?.family_name}`}</span>
                  </Col>
                  <Col sm={13} className="d-flex flex-align-end">
                     <InputField
                        value={title}
                        defaultValue={title}
                        label={t("buildingUsersView.title")}
                        noMargin
                        className={styles.userTitle}
                        onChange={onChangeTitle}
                        formItem={false}
                     />
                     <ButtonField
                        type="default"
                        onClick={updateTitle}
                        loading={titleLoading}
                     >
                        {t("default.update")}
                     </ButtonField>
                  </Col>
               </Row>
               <Row className={styles.section}>
                  <Col sm={5} className="d-flex flex-align-end">
                     {t("buildingUsersView.leaveBuilding")}
                  </Col>
                  <Col sm={13} className="d-flex flex-align-end">
                     <Popconfirm
                        title={t("default.areYouSure")}
                        onConfirm={confirmLeave}
                        icon={null}
                        okText={t("default.yes")}
                        cancelText={t("default.no")}
                        overlayClassName={"confirm-no-icon"}
                        okButtonProps={{ loading: leaveLoading }}
                     >
                        <ButtonField
                           icon={<LogoutOutlined />}
                           type="negative"
                           size="small"
                        >
                           {t("buildingUsersView.leaveBuilding")}
                        </ButtonField>
                     </Popconfirm>
                  </Col>
               </Row>
            </>
         )}
         {currentRole && currentRole !== "USER" && (
            <Row className={styles.section}>
               <Col span={24}>
                  <h3 className={styles.sectionTitle}>
                     {t("buildingUsersView.accessByOrganization")}
                  </h3>
               </Col>
               <Col span={24}>
                  <Row className={styles.organizationRow}>
                     {buildingOrganization && (
                        <>
                           <Col lg={5} xs={24}>
                              <Organization
                                 name={buildingOrganization?.name}
                                 type={buildingOrganization?.type}
                              />
                           </Col>
                           <Col sm={13}>
                              <span className={styles.tag}>
                                 {t("buildingUsersView.allAdmins")}
                              </span>
                           </Col>
                        </>
                     )}
                  </Row>
               </Col>
            </Row>
         )}
         {currentRole && currentRole !== "USER" && (
            <div className={styles.section}>
               <div className="d-flex-between">
                  <h3 className={styles.sectionTitle}>
                     {t("buildingUsersView.accessByInvitation")}
                  </h3>

                  <ButtonField
                     icon={<PlusOutlined />}
                     type="default"
                     onClick={handleInvite}
                  >
                     {t("buildingUsersView.addUserButton")}
                  </ButtonField>
               </div>
               <Table
                  columns={columns}
                  dataSource={listData}
                  loading={loadingTable}
                  pagination={false}
                  className={"new-style"}
               />
            </div>
         )}
         {/* <InviteMember
            showModal={inviteMemberModal}
            handleClose={handleInvite}
            onSuccess={inviteMemberSuccess}
         /> */}
         {currentRole && currentRole !== "USER" && (
            <InviteUserModal
               showModal={inviteMemberModal}
               handleCancelModal={handleInvite}
               getUsersAPI={inviteMemberSuccess}
               type="building"
               buildingDetails={buildingDetails}
            />
         )}
      </div>
   );
};
