import React from "react";
import Drawer from "../../../../../components/ui@concular/Drawer";

export const SellingRequestDetails = ({ data, visible, onClose }: any) => {
   console.log("data", data);
   console.log("hubspot_status", data.status);
   console.log("hubspot_paid", data.paid);

   const closeMe = () => {
      onClose();
   };

   return (
      <Drawer
         placement="right"
         closable={true}
         width={600}
         visible={visible}
         onClose={closeMe}
      >
         <div className="request-status">
            <div className="sale-details">
               <h4>Order details</h4>
               <div className="order-volume">
                  <div className="amount">
                     {data.amount} {data.amount_unit}
                  </div>
                  {data.proposal_price && (
                     <div>
                        {data.proposal_price} €/{data.amount_unit}
                     </div>
                  )}
               </div>
            </div>
            <div className="material-details">
               {data?.hubspot_data?.properties?.progress_update && (
                  <>
                     <h4>Status</h4>
                     <div>
                        <span className="status tag">{data.status}</span>
                        <span className="progress-update tag">
                           {data.hubspot_data.properties.progress_update.value}
                        </span>
                     </div>
                  </>
               )}
               {data?.hubspot_data?.properties?.service_update && (
                  <>
                     <h4>Zustand</h4>
                     <div>
                        <span className="service-update tag">
                           {data.hubspot_data.properties.service_update.value}
                        </span>
                     </div>
                  </>
               )}
               {data?.hubspot_data?.properties?.current_location && (
                  <>
                     <h4>Adresse</h4>
                     <div>
                        {data.hubspot_data.properties.current_location.value}
                     </div>
                  </>
               )}
            </div>
         </div>
      </Drawer>
   );
};
