import { Col, Form, Row, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import InputField from "../../components/FormFields/InputField";
import DropdownField from "../../components/FormFields/DropdownField";
import SettingsSideBar from "./SettingsSideBar";
import styles from "./Settings.module.scss";
import { ImageUploader } from "../../components/ImageUploader";
import { useAuth } from "../../contexts/auth";
import { useParams } from "react-router";
import NumberInputField from "../../components/FormFields/NumberInputField";
import CountryPhoneInput, {
   CountryPhoneInputValue,
   ConfigProvider
} from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import useAxios from "axios-hooks";
import "antd/dist/antd.css";
import "antd-country-phone-input/dist/index.css";
import { useForm } from "antd/lib/form/Form";
import ButtonField from "../../components/FormFields/ButtonField";
import { getUploadedOrgImagesLinks } from "./getUploadedOrgImagesLinks";
import { Resource } from "../../api/common";
import { useTranslation } from "react-i18next";
import { Organization as OrganizationType } from "./../../types";

const OrganizationAccount = () => {
   const { organizationTypes, getJoinedOrganizationsAPI } = useAuth();
   const [t] = useTranslation();
   const params: { id: string } = useParams();
   const [organization, setOrganization] = useState<OrganizationType>({
      name: ""
   });
   const [loading, setLoading] = useState<boolean>(false);
   const [values, setValues] = useState<any>({
      accountName: "",
      accountType: {},
      organizationName: "",
      streetNumber: "",
      zip: "",
      city: "",
      ustId: "",
      image: null
   });

   const [phoneNumber, setPhoneNumber] = useState<CountryPhoneInputValue>({
      code: 49
   });

   const [form] = useForm();

   useEffect(() => {
      setValues((state: any) => ({
         ...state,
         accountType: organizationTypes?.find(
            (item: { id: any; label: string }) =>
               item.id == organization?.organization_type_id
         ),
         accountName: organization?.name,
         organizationName: organization?.address?.name || "",
         streetNumber: organization?.address?.streetNumber || "",
         zip: organization?.address?.zip || "",
         city: organization?.address?.city || "",
         ustId: organization?.ust_id || "",
         image: organization?.image || null
      }));
      setPhoneNumber({
         phone: organization?.address?.phone || "",
         code: organization?.address?.phoneExt || 49
      });
   }, [organizationTypes, organization, params]);

   useEffect(() => {
      form.setFieldsValue({
         accountName: values?.accountName,
         accountType: values?.accountType?.id,
         organizationName: values?.organizationName,
         streetNumber: values?.streetNumber,
         zip: values?.zip,
         city: values?.city,
         ustId: values?.ustId,
         image: values?.image || null
      });
   }, [values]);

   const [{ loading: putLoading }, executePut] = useAxios(
      {
         url: `${Resource.path.organization.update}/${+params.id}`,
         method: "PUT"
      },
      {
         manual: true,
         useCache: false
      }
   );

   const [{ loading: loadingGet, data: currentOrganization }, getOrganization] =
      useAxios(
         {
            url: Resource.path.organization.getOrganizationDetails(params.id),
            method: "GET"
         },
         { manual: true, useCache: false }
      );

   useEffect(() => {
      getOrganization();
   }, [params]);

   useEffect(() => {
      setOrganization(currentOrganization?.data);
   }, [currentOrganization]);

   const handleOnSubmit = async () => {
      form.submit();
      const data = form.getFieldsValue();
      setLoading(true);
      await form.validateFields().then(async () => {
         let orgImages: any[] | null = null;
         if (data.image) {
            orgImages = await getUploadedOrgImagesLinks(
               data?.image || [values?.image]
            );
         }
         await executePut({
            data: {
               name: data.accountName,
               organization_type_id: data.accountType,
               address: {
                  name: data?.organizationName || null,
                  streetNumber: data?.streetNumber || null,
                  zip: data?.zip || null,
                  city: data?.city || null,
                  phone: phoneNumber?.phone || null,
                  phoneExt: phoneNumber?.code || null
               },
               image: orgImages || null,
               ust_id: data?.ustId || null
            }
         })
            .then((resp: any) => {
               console.log(resp);
               message.success(t("settings.successMessage"));
               setLoading(false);
               getOrganization();
               getJoinedOrganizationsAPI();
            })
            .catch((err) => {
               console.log(err);
               message.error(t("settings.errorMessage"));
               setLoading(false);
            });
      });
   };

   if (putLoading || loadingGet) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <SettingsSideBar title={t("settings.organization.account")}>
         <Row>
            <Col sm={{ span: 16 }} xs={{ span: 24 }}>
               <Spin spinning={!values?.accountName}>
                  <Form
                     form={form}
                     onFinish={handleOnSubmit}
                     className={styles.accountForm}
                  >
                     <div className={styles.section}>
                        <h3 className={styles.fieldsTitle}>
                           {t("settings.organization.organization")}
                        </h3>
                        <Row className={styles.row}>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.label}
                           >
                              Logo
                           </Col>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={`d-flex ${styles.field}`}
                           >
                              <Form.Item name={"image"} className="d-flex">
                                 <ImageUploader maxCount={1} multiple={false} />
                              </Form.Item>
                           </Col>
                        </Row>
                        <Row className={styles.row}>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.label}
                           >
                              {t("settings.organization.accountName")}
                           </Col>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.field}
                           >
                              <InputField
                                 required
                                 noMargin
                                 fieldName="accountName"
                                 fieldErrorMessage={t(
                                    "settings.organization.accountNameMissing"
                                 )}
                              />
                           </Col>
                        </Row>
                        <Row className={styles.row}>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.label}
                           >
                              {t("settings.organization.typeOfOrganization")}
                           </Col>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.field}
                           >
                              <DropdownField
                                 items={organizationTypes}
                                 noMargin
                                 fieldName="accountType"
                                 size="middle"
                              />
                           </Col>
                        </Row>
                     </div>
                     <div className={styles.section}>
                        <h3 className={styles.fieldsTitle}>
                           {t("settings.organization.contactDetails")}
                        </h3>
                        <Row className={styles.row}>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.label}
                           >
                              {t("settings.organization.organizationName")}
                           </Col>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.field}
                           >
                              <InputField
                                 required
                                 noMargin
                                 fieldName="organizationName"
                                 fieldErrorMessage={t(
                                    "settings.organization.organizationNameMissing"
                                 )}
                              />
                           </Col>
                        </Row>

                        <Row className={styles.row}>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.label}
                           >{`${t("default.street")}, ${t(
                              "default.number"
                           )}`}</Col>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.field}
                           >
                              <InputField noMargin fieldName="streetNumber" />
                           </Col>
                        </Row>

                        <Row className={styles.row}>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.label}
                           >{`${t("default.zip")}, ${t("default.city")}`}</Col>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={`${styles.zipCity} ${styles.field}`}
                           >
                              <NumberInputField
                                 noMargin
                                 className={styles.zip}
                                 fieldName="zip"
                                 hideArrow
                              />
                              <InputField
                                 className={styles.city}
                                 fieldName="city"
                                 noMargin
                              />
                           </Col>
                        </Row>

                        <Row className={styles.row}>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.label}
                           >
                              {t("default.phone")}
                           </Col>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.field}
                           >
                              <ConfigProvider locale={en}>
                                 <CountryPhoneInput
                                    type={"number"}
                                    value={phoneNumber}
                                    onChange={(v) => {
                                       setPhoneNumber(v);
                                    }}
                                 />
                              </ConfigProvider>
                           </Col>
                        </Row>
                     </div>
                     <div className={styles.section}>
                        <h3 className={styles.fieldsTitle}>
                           {t("settings.organization.additionalInformation")}
                        </h3>
                        <Row className={styles.row}>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.label}
                           >
                              USt-ID
                           </Col>
                           <Col
                              sm={{ span: 12 }}
                              xs={{ span: 24 }}
                              className={styles.field}
                           >
                              <InputField
                                 noMargin
                                 className={styles.ust}
                                 fieldName="ustId"
                              />
                           </Col>
                        </Row>
                     </div>
                     <Row>
                        <Col span={24} className={styles.submitForm}>
                           <ButtonField
                              type="default"
                              size="large"
                              loading={putLoading || loading}
                              htmlType="submit"
                           >
                              {t("default.submit")}
                           </ButtonField>
                        </Col>
                     </Row>
                  </Form>
               </Spin>
            </Col>
         </Row>
      </SettingsSideBar>
   );
};

export default OrganizationAccount;
