import React from "react";
import "antd/dist/antd.less";
import "./app.less";

import Amplify, { I18n } from "aws-amplify";
import { configure } from "axios-hooks";
import axios from "axios";
import { Auth } from "aws-amplify";
import { AuthorizedAppViews } from "./views/Auth/AuthorizedAppViews";
import { Button, Spin } from "antd";
import { useCookies } from "react-cookie";
import * as Sentry from "@sentry/react";
import { useHistory, useLocation } from "react-router-dom";
import { Mixpanel } from "./utils/tracking/mixpanel";
import { Integrations } from "@sentry/tracing";
import { useFlagsmith } from "flagsmith-react";
import { Resource } from "./api/common";
import {
   Authenticator,
   TextField,
   AmplifyProvider,
   Theme
} from "@aws-amplify/ui-react";
import { WelcomePage } from "./components/WelcomePage";
import { CognitoUserAmplify } from "@aws-amplify/ui-react/node_modules/@aws-amplify/ui";
import "@aws-amplify/ui-react/styles.css";
import { Switch, Route, Redirect } from "react-router-dom";
import AcceptInvitations from "./views/AcceptInvitations";
import { authLocale } from "./components/WelcomePage/locale";
import "./app.scss";
import { SignUp as DemandSignUp } from "./views/Public/Demand/SignUp";
import "moment/locale/de";
import { AdminAuth } from "./views/Auth/AdminAuth";
import { useTranslation } from "react-i18next";

const theme: Theme = {
   name: "my-theme",
   tokens: {
      colors: {
         font: {
            primary: { value: "#008080" }
            // ...
         }
      }
   }
};

const commonSentryConfig = {
   autoSessionTracking: true,
   normalizeDepth: 5,
   integrations: [new Integrations.BrowserTracing()],

   // We can adjusting this value in production, or using tracesSampler
   // for finer control between 0 - 1
   tracesSampleRate: 1.0
};

process.env.NODE_ENV === "production"
   ? Sentry.init({
        dsn: "https://17af7784aaf54edca5b3916033e40f3c@o498554.ingest.sentry.io/5576227",
        release: process.env.REACT_APP_SENTRY_RELEASE,
        ...commonSentryConfig
     })
   : Sentry.init({
        dsn: "https://4b816b140a0e46e9a754bf106f8de92e@o498554.ingest.sentry.io/5576238",
        ...commonSentryConfig
     });

Amplify.configure({
   aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
   aws_cognito_identity_pool_id:
      process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
   aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
   aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
   aws_user_pools_web_client_id:
      process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
   oauth: process.env.REACT_APP_OAUTH || {}
});

const App = () => {
   const history = useHistory();
   const location = useLocation();
   I18n.setLanguage(navigator.language === "en-GB" ? "en" : "de");
   I18n.putVocabularies(authLocale);
   const [cookies, setCookie, removeCookie] = useCookies(["user", "modules"]);
   const { identify, setTrait } = useFlagsmith();
   const [t] = useTranslation();
   const [user, setUser] = React.useState<CognitoUserAmplify | null>(null);
   const [axiosTokenSet, setAxiosTokenSet] = React.useState<boolean>();
   const [isLoading, setIsLoading] = React.useState<boolean>(true);

   React.useEffect(() => {
      if (!user) {
         Auth.currentAuthenticatedUser()
            .then((response) => {
               setUser(response);
               setCookie("user", response, { secure: true, maxAge: 14400 });
               setIsLoading(false);
            })
            .catch((error) => {
               console.log(error);
               if (cookies.user) {
                  removeCookie("user");
               }
               if (cookies.modules) {
                  removeCookie("modules");
               }
               setIsLoading(false);
            });
         return;
      }
      const setAxiosToken = async () => {
         const jwtToken = (await Auth.currentSession())
            .getIdToken()
            .getJwtToken();
         configure({
            axios: axios.create({
               timeout: 900000,
               headers: { Authorization: jwtToken }
            }),
            cache: false
         });
         axios
            .get(`${Resource.path.syncURL}`, {
               headers: { Authorization: jwtToken }
            })
            .then((response: any) => {
               if (response?.data?.data) {
                  setCookie("modules", response?.data?.data);
               }
            });
         setAxiosTokenSet(true);
         setIsLoading(false);
      };
      setAxiosToken();
   }, [user]);

   React.useEffect(() => {
      if (!user) {
         return;
      }
      const { attributes }: any = user;

      attributes?.sub && Mixpanel.identify(attributes.sub);

      attributes?.email &&
         attributes?.sub &&
         Mixpanel.people.set({
            $email: attributes.email,
            $first_name: attributes?.given_name,
            $last_name: attributes?.family_name,
            "Last Login Date": new Date(),
            "Family Name": attributes?.family_name,
            "Given Name": attributes?.given_name,
            "User Id": attributes.sub,
            Environment: process.env.REACT_APP_MIXPANEL_TEST_ENV
         });

      // Set Sentry User context
      process.env.NODE_ENV === "production" &&
         attributes?.email &&
         attributes?.sub &&
         Sentry.setUser({
            email: attributes.email,
            "Family Name": attributes?.family_name,
            "Given Name": attributes?.given_name,
            "User Id": attributes.sub,
            Environment: process.env.NODE_ENV
         });

      if (process.env.NODE_ENV === "production" && attributes?.email) {
         identify(attributes?.email);
         setTrait("email", attributes?.email);
      }
   }, [user]);

   const services = {
      async handleSignUp(formData: any) {
         let { username, password, attributes } = formData;
         username = username.toLowerCase();
         attributes["email"] = username.toLowerCase();
         attributes["locale"] = navigator.language === "en-GB" ? "en" : "de";

         if (location?.pathname === "/marketplace-auth") {
            attributes["custom:project"] = "bauteilkreisel-dadi.de";
            attributes["custom:user_type"] = "private-owner";
         }
         if (location?.pathname !== "/marketplace-auth") {
            attributes["custom:scopes"] = "material-hub,marketplace";
         } else if (location?.pathname === "/marketplace-auth") {
            attributes["custom:scopes"] = "material-hub,material-passport";
         }
         return Auth.signUp({
            username,
            password,
            attributes
         });
      }
   };
   if (isLoading || (user && !axiosTokenSet))
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   if (isLoading || (user && !axiosTokenSet))
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <AmplifyProvider theme={theme}>
         <div className="App">
            <Switch>
               <Route
                  exact
                  path="/invite/:invitation_id"
                  component={AcceptInvitations}
               />
               <Route
                  exact
                  path="/public/demand/sign-up"
                  component={DemandSignUp}
               />
               <Route exact path="/admin/login" component={AdminAuth} />
               <Route
                  exact
                  path={["/login", "/marketplace-auth"]}
                  render={() => {
                     if (cookies.user) {
                        removeCookie("user");
                     }
                     if (cookies.modules) {
                        removeCookie("modules");
                     }
                     return (
                        <WelcomePage
                           button={
                              location?.pathname === "/marketplace-auth" ? (
                                 <Button href="https://www.bauteilkreisel-dadi.net">
                                    Zurück zum Marktplatz
                                 </Button>
                              ) : undefined
                           }
                           title={
                              location?.pathname === "/marketplace-auth" ? (
                                 <h1>
                                    Willkomen beim Bauteilkreisel
                                    Darmstadt-Dieburg
                                 </h1>
                              ) : undefined
                           }
                        >
                           <Authenticator
                              loginMechanisms={["email"]}
                              services={services}
                              components={{
                                 SignUp: {
                                    FormFields() {
                                       return (
                                          <>
                                             <TextField
                                                label=""
                                                placeholder={I18n.get(
                                                   "First name"
                                                )}
                                                name="given_name"
                                             />
                                             <TextField
                                                label=""
                                                placeholder={I18n.get(
                                                   "Last name"
                                                )}
                                                name="family_name"
                                             />
                                             <Authenticator.SignUp.FormFields />
                                          </>
                                       );
                                    }
                                 }
                              }}
                           >
                              {({ user }) => {
                                 if (user) {
                                    setUser(user);
                                    setCookie("user", user, {
                                       secure: true,
                                       maxAge: 14400
                                    });
                                    history.push("/buildings");
                                 }
                                 return <></>;
                              }}
                           </Authenticator>
                        </WelcomePage>
                     );
                  }}
               />
               <Route
                  path="/"
                  render={() => {
                     return user && axiosTokenSet ? (
                        <AuthorizedAppViews
                           locale={(user as any).attributes?.locale || "de"}
                        ></AuthorizedAppViews>
                     ) : location?.pathname === "/marketplace-auth" ? (
                        <Redirect to="/marketplace-auth" />
                     ) : (
                        <Redirect to="/login" />
                     );
                  }}
               />
            </Switch>
         </div>
      </AmplifyProvider>
   );
};

export default App;
