import React from "react";
import { IfcViewerAPI } from "web-ifc-viewer";
import { Color } from "three";
import "./style.scss";
import { useIfcViewer } from "../IfcViewerContext";
import { Button } from "antd";

export function IfcViewer() {
   const {
      viewer,
      setViewer,
      currentFile,
      setModel,
      setCurrentIfcFile,
      currentIfcFile
   } = useIfcViewer();
   const containerRef = React.useRef<HTMLDivElement>(null);

   React.useLayoutEffect(() => {
      if (!containerRef.current) {
         return;
      }

      const viewerTmp = new IfcViewerAPI({
         container: containerRef.current,
         backgroundColor: new Color(200, 200, 200)
      });
      viewerTmp.grid.setGrid();
      viewerTmp.IFC.setWasmPath("../../");
      viewerTmp.IFC.loader.ifcManager.useWebWorkers(
         true,
         "../../../IFCWorker.js"
      );

      // @ts-ignore
      window.viewer = viewerTmp;
      setViewer?.(viewerTmp);
   }, [setViewer]);

   React.useEffect(() => {
      if (!window || !viewer) {
         return;
      }
      // window.onmousemove = () => {
      //    viewer.IFC.selector.prePickIfcItem();
      // };
      window.ondblclick = async () => {
         if (!viewer) {
            return;
         }
         const result = await viewer.IFC.selector.pickIfcItem(true);
         if (!result) return;
         const { modelID, id } = result;
         const props = await viewer.IFC.getProperties(modelID, id, true, false);
         console.log(props);
      };
   }, [viewer]);

   React.useEffect(() => {
      if (!window || !viewer) {
         return;
      }
      // window.onmousemove = () => {
      //    viewer.IFC.selector.prePickIfcItem();
      // };
      window.ondblclick = async () => {
         if (!viewer) {
            return;
         }
         const result = await viewer.IFC.selector.pickIfcItem(true);
         if (!result) return;
         const { modelID, id } = result;
         const props = await viewer.IFC.getProperties(modelID, id, true, false);
         console.log(props);
      };
   }, [viewer]);

   React.useEffect(() => {
      if (!currentIfcFile || !viewer) {
         return;
      }
      (async () => {
         const modelTmp = await viewer.IFC.loadIfcUrl(
            `https://concularifcstack-devconcularbaseifcbucket267e3895-it2q40zeixut.s3.eu-central-1.amazonaws.com/${currentIfcFile.s3_path}`,
            true
         );
         setModel?.(modelTmp);
      })();
   }, [currentFile, viewer, setModel, currentIfcFile]);

   return (
      <div>
         <Button onClick={() => setCurrentIfcFile?.(undefined)}>
            {"<- Back"}
         </Button>
         <div className="container" ref={containerRef} />
      </div>
   );
}
