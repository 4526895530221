import React from "react";
import { useContext } from "react";
import { useMaterialWizardAction } from "./useMaterialWizardAction";
import { PassportFormType } from "../../views/MaterialInventory/AddMaterialWizard/PassportDetails/PassportFormType";

export const MaterialWizardContext = React.createContext({} as any);

export const INITIAL_STATE = {
   wizard_steps_product_details: null,
   wizard_steps_passport_details: null,
   wizard_steps_reuse_details: null,
   material_passport_type: PassportFormType.WITH_SAME_CONDITION
};

export const MaterialWizardProvider = ({ children }: any) => {
   const {
      state: {
         wizard_steps_product_details,
         wizard_steps_passport_details,
         wizard_steps_reuse_details,
         material_passport_type
      },

      updateProductDetailsWizardDetails,
      updatePassportWizardDetails,
      updateReuseWizardDetails,
      updatePassportTypeDetails,
      resetMaterialPassportWizard,

      getProductDetailsWizardDetails,
      getPassportWizardDetails,
      getReuseWizardDetails
   } = useMaterialWizardAction();

   return (
      <MaterialWizardContext.Provider
         value={{
            wizard_steps_product_details,
            wizard_steps_passport_details,
            wizard_steps_reuse_details,
            material_passport_type,

            updateProductDetailsWizardDetails,
            updatePassportWizardDetails,
            updateReuseWizardDetails,
            updatePassportTypeDetails,
            resetMaterialPassportWizard,

            getProductDetailsWizardDetails,
            getPassportWizardDetails,
            getReuseWizardDetails
         }}
      >
         {children}
      </MaterialWizardContext.Provider>
   );
};

export const useMaterialWizard = () => useContext(MaterialWizardContext);
