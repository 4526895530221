import { Auth } from "aws-amplify";
import axios from "axios";
import * as uuid from "uuid";
import { Resource } from "../../../../../api/common";

export async function getMaterialImagesLinks(values: any) {
   const assetId = `${uuid.v4()}_${Date.now()}`;
   const assetType = "material_images";
   const materialImages: any[any] = [];

   const accessToken = (await Auth.currentSession()).getIdToken();

   const signedUrlPromiseList = values.images
      .map(async (item: any, index: any) => {
         if (!item.object_url) {
            // Get Upload Signed URL
            const response = await axios.post(
               Resource.path.getSignedUrlForMaterialAssetsHub,
               {
                  assetType,
                  assetId,
                  fileName: item.name
               },
               { headers: { Authorization: accessToken.getJwtToken() } }
            );
            const result = response?.data?.data;
            if (result) {
               return axios
                  .put(result.signedUrl, item.originFileObj)
                  .then(() => {
                     materialImages.push({
                        file_name: result.fileName,
                        file_path: result.filePath,
                        object_url: result.objectUrl,
                        bucket_name: result.bucketName,
                        sortIndex: index
                     });
                  });
            }
         } else {
            materialImages.push({ ...item, sortIndex: index });
         }
      })
      .filter(Boolean);

   await Promise.all(signedUrlPromiseList);

   const materialImagesTemp = values.images.map((value: any, index: any) => {
      return materialImages
         .filter((materialImage: any) => {
            return materialImage.sortIndex === index;
         })
         ?.pop();
   });

   return materialImagesTemp.map((materialImagesData: any) => {
      if (materialImagesData.sortIndex !== undefined) {
         delete materialImagesData.sortIndex;
      }
      return materialImagesData;
   });
}
