import React, { useState } from "react";
import styles from "./CreateOrganization.module.scss";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import ButtonField from "../../../components/FormFields/ButtonField";
import { useLocale } from "../../../contexts/language";
import DropdownField from "../../../components/FormFields/DropdownField";
import InputField from "../../../components/FormFields/InputField";
import Welcome from "../Welcome";
import { useAuth } from "../../../contexts/auth";
import UserOrganizationTitle from "../UserOrganizationTitle";
import { useHistory } from "react-router";

interface IProps {
   modal?: boolean;
   showModal?: boolean;
   handleCancelModal?: (e?: any) => any;
   openJoin?: () => any;
}

const CreateOrganization = ({
   modal,
   showModal,
   handleCancelModal,
   openJoin
}: IProps) => {
   const [t] = useTranslation();
   const [newOrganizationForm] = Form.useForm();
   const locale = useLocale();
   const history = useHistory();
   const [newOrganization, setNewOrganization] = useState<any>({
      name: "",
      typeId: null
   });
   const [showAddTitlePage, setShowAddTitlePage] = useState(false);
   const { organizationTypes } = useAuth();

   const validateMessages = {
      required:
         locale == "en" ? "${label} is missing!" : "${label} wird vermisst!"
   };

   const continueSubmit = async () => {
      newOrganizationForm.submit();
      await newOrganizationForm.validateFields().then(async () => {
         setShowAddTitlePage(true);
      });
   };

   const newOrganizationUpdate = (value: any, key: string) => {
      setNewOrganization((state: any) => ({ ...state, [key]: value }));
   };

   const goBackToJoin = () => {
      modal
         ? openJoin && openJoin()
         : history.push("/onboarding/join_organization");
   };

   return (
      <>
         {modal ? (
            <Modal
               visible={showModal}
               title={""}
               footer={null}
               className={`${styles.joinOranization} ${styles.modalView}`}
               width={540}
               onCancel={handleCancelModal}
               centered
               style={{ minHeight: "auto" }}
            >
               <div style={{ padding: "20px" }}>{createComponent()}</div>
            </Modal>
         ) : (
            <Welcome className={styles.createOrganization}>
               {createComponent()}
            </Welcome>
         )}
      </>
   );

   function createComponent() {
      return (
         <div className={styles.createComponent}>
            {!showAddTitlePage ? (
               <>
                  <h3 className={styles.title}>
                     {t("onboarding.createNewOrganization")}
                  </h3>
                  <Form
                     form={newOrganizationForm}
                     validateMessages={validateMessages}
                     className={styles.inputForm}
                  >
                     <InputField
                        value={newOrganization.name}
                        onChange={(e) => newOrganizationUpdate(e, "name")}
                        label={t("onboarding.enterOrganizationName")}
                        required
                        size="large"
                        fieldName={t("onboarding.organizationName")}
                        placeholder={t("onboarding.exampleCompany")}
                     />
                     <DropdownField
                        items={organizationTypes}
                        selected={newOrganization.type}
                        onChange={(e) => newOrganizationUpdate(e, "typeId")}
                        label={t("onboarding.selectOrganizationType")}
                        required
                        size="large"
                        fieldName={t("onboarding.organizationType")}
                     />
                  </Form>
                  <div className={styles.bottomButtons}>
                     <ButtonField
                        className={styles.goBack}
                        type="link"
                        icon={<ArrowLeftOutlined />}
                        onClick={goBackToJoin}
                     >
                        <span>{t("onboarding.goBack")}</span>
                     </ButtonField>
                     <ButtonField type="default" onClick={continueSubmit}>
                        <span>{t("default.continue")}</span>
                     </ButtonField>
                  </div>
               </>
            ) : (
               <UserOrganizationTitle
                  newOrganization={newOrganization}
                  newOrganizationUpdate={newOrganizationUpdate}
                  showAddingTitlePage={setShowAddTitlePage}
                  modal={modal}
                  closeModal={handleCancelModal}
               />
            )}
         </div>
      );
   }
};

export default CreateOrganization;
