import { useTranslation } from "react-i18next";
import React from "react";
import { Image } from "antd";
import CoverImage from "./public_demand_bg.jpg";
import "./auth.scss";

type WelcomePageProps = {
   children: React.ReactNode;
   button?: React.ReactNode;
   title?: React.ReactNode;
};

export const WelcomePage = ({ children, button, title }: WelcomePageProps) => {
   const [t] = useTranslation();
   return (
      <div className="public-demand-page">
         <div className="welcome-title">
            {title || <h1>{t("loginPage.addPublicDemand")}</h1>}
            {button}
         </div>
         <div className="auth-container">{children}</div>
         <div className="background">
            <Image
               src={CoverImage}
               height="100vh"
               width="100%"
               preview={false}
            ></Image>
         </div>
         {/* This span element is required to track login page  */}
         <span id="login_page_hidden" style={{ display: "none" }}></span>
      </div>
   );
};
