import * as uuid from "uuid";
import axios from "axios";
import { Auth } from "aws-amplify";
import { Resource } from "../../../../../api/common";

export async function getPassportWithUploadedMaterialImagesLinks(
   values: any,
   materialId: string
) {
   const assetId = `${uuid.v4()}_${Date.now()}`;
   const assetType = "passport_images";
   const passportImages: any[] = [];

   if (values?.passport_images) {
      const accessToken = (await Auth.currentSession()).getIdToken();
      const signedUrlPromiseList = values.passport_images
         .map(async (item: any) => {
            if (!item.object_url) {
               // Get Upload Signed URL
               const response = await axios.post(
                  Resource.path.getSignedUrlForPassportAssetsHub(materialId),
                  {
                     assetType,
                     assetId,
                     fileName: item.name
                  },
                  { headers: { Authorization: accessToken.getJwtToken() } }
               );
               const result = response?.data?.data;
               if (result) {
                  return axios
                     .put(result.signedUrl, item.originFileObj)
                     .then(() => {
                        passportImages.push({
                           file_name: result.fileName,
                           file_path: result.filePath,
                           object_url: result.objectUrl,
                           bucket_name: result.bucketName
                        });
                     });
               }
            } else {
               passportImages.push(item);
            }
         })
         .filter(Boolean);

      await Promise.all(signedUrlPromiseList);
   }
   return passportImages;
}
