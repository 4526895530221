import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { SelectProps } from "antd/es/select";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { useTranslation } from "react-i18next";

const buildOptionsElm = (results: any) => {
   return results
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
      .map((result: any) => {
         return {
            value: `${result.id}`,
            label: result.name
         };
      });
};

const BrandMultiSelect: React.FC<any> = ({ defaultValue, onChange }) => {
   const [t] = useTranslation();
   const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
   const [value, setValue] = useState<any>(defaultValue);

   const [{ data, loading }] = useAxios(
      { url: Resource.path.getBrands },
      { useCache: true }
   );

   if (loading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   useEffect(() => {
      if (data?.data.data) {
         const otherBrand = t("brand.otherBrandLiteral");
         const noBrand = t("brand.noBrandLiteral");
         const brands = data?.data.data;
         brands.push({
            id: otherBrand,
            name: otherBrand
         });
         brands.push({
            id: noBrand,
            name: noBrand
         });
         const options = buildOptionsElm(brands);
         setOptions(options);
      }
   }, [data]);

   const handleSearch = (value: string) => {
      const brands = data?.data?.data;
      const filteredResults = brands.filter((item: any) => {
         return item?.name?.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      const options = buildOptionsElm(filteredResults);
      setOptions(options);
   };

   const onValueChange = (value: any) => {
      setValue(value);
      onChange && onChange(value);
   };

   return (
      <Select
         mode="multiple"
         allowClear
         style={{ width: "100%" }}
         placeholder="Please select"
         onSearch={handleSearch}
         onChange={onValueChange}
         value={value}
      >
         {options?.map((rec: any) => (
            <Select.Option key={rec.value} value={rec.value}>
               {rec.label}
            </Select.Option>
         ))}
      </Select>
   );
};

export default BrandMultiSelect;
