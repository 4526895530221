import React from "react";
import TagField from "../../../components/FormFields/TagField";
import styles from "./CreateOrganization.module.scss";

const Organization = ({
   logo,
   name,
   type,
   className,
   tag
}: {
   logo?: string | undefined;
   name: string;
   type?: string;
   className?: string;
   tag?: string;
}) => {
   return (
      <div className={styles.organizationItem}>
         <div
            className={`${className} ${styles.wrapperInfo} ${
               !type && styles.singleRow
            }`}
         >
            <div className={`${styles.logo} ${!logo && styles.nonLogo}`}>
               {logo ? (
                  <img src={logo} />
               ) : (
                  <div className={styles.defaultLogo}></div>
               )}
            </div>
            <div className={styles.companyInfo}>
               <>
                  <span className={styles.name}>{name}</span>
                  <span className={styles.type}>{type}</span>
               </>
            </div>
         </div>
         {tag && (
            <div className={styles.tagWrapper}>
               <TagField status="attention" className={styles.tag}>
                  {tag}
               </TagField>
            </div>
         )}
      </div>
   );
};

export default Organization;
