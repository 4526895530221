import React from "react";
import { Lca, Unit, CalcResult } from "../../types";

type Quantity = "length" | "area" | "volume" | "mass" | "piece";

type UnitValue = {
   unit: Unit;
   value: number;
   quantity: Quantity;
};

export const missingWidth = "Please select a width larger than 0.";
export const missingHeight = "Please select a height larger than 0.";
export const missingDepth = "Please select a depth larger than 0.";
//export const missingDensity = "Edit material and select ingredient.";
export const missingDensity = "Please select a density larger than 0.";

const normalizeUnitToMeter = (unit: Unit, value: number): UnitValue => {
   switch (unit) {
      // Length
      case "mm":
         return {
            unit: "m",
            quantity: "length",
            value: value / 1000
         };
      case "cm":
         return {
            unit: "m",
            quantity: "length",
            value: value / 100
         };
      case "length":
      case "m":
      case "lfm":
         return {
            unit: "m",
            quantity: "length",
            value: value
         };
      // Area
      case "mm²":
         return {
            unit: "m²",
            quantity: "area",
            value: value / Math.pow(10, 6)
         };
      case "cm²":
         return {
            unit: "m²",
            quantity: "area",
            value: value / Math.pow(10, 4)
         };
      case "m²":
         return {
            unit: "m²",
            quantity: "area",
            value
         };
      // Volume
      case "mm³":
         return {
            unit: "m³",
            quantity: "volume",
            value: value / Math.pow(10, 9)
         };
      case "m³":
         return {
            unit: "m³",
            quantity: "volume",
            value
         };
      case "cm³":
         return {
            unit: "m³",
            quantity: "volume",
            value: value / Math.pow(10, 6)
         };
      case "L":
         return {
            unit: "m³",
            quantity: "volume",
            value: value / Math.pow(10, 3)
         };
      case "kg":
         return {
            unit: "kg",
            quantity: "mass",
            value: value
         };
      case "t":
      case "tons":
         return {
            unit: "kg",
            quantity: "mass",
            value: value * 1000
         };
      case "piece/s":
      case "Stück":
         return {
            unit: "piece",
            quantity: "piece",
            value
         };
   }
   throw new Error(`${unit} is unknown`);
};

const lengthToArea = (width: number, height?: number): CalcResult => {
   if (!height) {
      return { status: "error", message: missingHeight };
   }
   return { status: "ok", result: width * height };
};

const areaToVolume = (area: number, depth?: number): CalcResult => {
   if (!depth) {
      return { status: "error", message: missingDepth };
   }
   return { status: "ok", result: area * depth };
};

const areaToLength = (area: number, height?: number): CalcResult => {
   if (!height) {
      return { status: "error", message: missingHeight };
   }

   return { status: "ok", result: area / height };
};

const lengthToMass = (
   width?: number,
   density?: number,
   height?: number,
   depth?: number,
   amount?: number
): CalcResult => {
   if (!height) {
      return { status: "error", message: missingHeight };
   }
   if (!depth) {
      return { status: "error", message: missingDepth };
   }
   if (!density) {
      return { status: "error", message: missingDensity };
   }
   if (!amount) {
      return { status: "error", message: "Please add passports" };
   }
   if (!width) {
      return { status: "error", message: missingWidth };
   }
   // console.log((height * width * depth * density), height , width , depth , density);
   return { status: "ok", result: height * width * depth * density * amount };
};

const lengthToVolume = (
   width: number,
   height?: number,
   depth?: number
): CalcResult => {
   if (!height) {
      return { status: "error", message: missingHeight };
   }
   if (!depth) {
      return { status: "error", message: missingDepth };
   }
   return { status: "ok", result: height * width * depth };
};

const areaToMass = (
   area: number,
   density?: number,
   depth?: number
): CalcResult => {
   if (!depth) {
      return { status: "error", message: missingDepth };
   }
   if (!density) {
      return { status: "error", message: missingDensity };
   }

   return { status: "ok", result: area * depth * density };
};

const volumeToLength = (
   volume: number,
   density?: number,
   height?: number,
   depth?: number
): CalcResult => {
   if (!density) {
      return { status: "error", message: missingDensity };
   }
   if (!depth) {
      return { status: "error", message: missingDepth };
   }
   if (!height) {
      return { status: "error", message: missingHeight };
   }

   return { status: "ok", result: volume / density / height / depth };
};

const volumeToArea = (
   volume: number,
   density?: number,
   depth?: number
): CalcResult => {
   if (!density) {
      return { status: "error", message: missingDensity };
   }
   if (!depth) {
      return { status: "error", message: missingDepth };
   }

   return { status: "ok", result: volume / density / depth };
};

const volumeToMass = (volume: number, density?: number): CalcResult => {
   if (!density) {
      return { status: "error", message: missingDensity };
   }

   return { status: "ok", result: volume * density };
};

const pieceToArea = (
   amount: number,
   width?: number,
   height?: number
): CalcResult => {
   if (!height) {
      return { status: "error", message: missingHeight };
   }
   if (!width) {
      return { status: "error", message: missingWidth };
   }

   return { status: "ok", result: amount * width * height };
};

const pieceToVolume = (
   amount: number,
   width?: number,
   height?: number,
   depth?: number
): CalcResult => {
   if (!depth) {
      return { status: "error", message: missingDepth };
   }
   if (!height) {
      return { status: "error", message: missingHeight };
   }
   if (!width) {
      return { status: "error", message: missingWidth };
   }

   return { status: "ok", result: amount * width * height * depth };
};

const convertQuantities = ({
   sourceQuantity,
   targetQuantity,
   amount,
   width,
   height,
   depth,
   density
}: {
   sourceQuantity: Quantity;
   targetQuantity: Quantity;
   amount: number;
   width?: number;
   height?: number;
   depth?: number;
   density?: number;
}): CalcResult => {
   switch (sourceQuantity) {
      case "piece":
         switch (targetQuantity) {
            case "piece":
               return { status: "ok", result: amount };
            case "length":
               if (!width) {
                  return { status: "error", message: missingWidth };
               }
               return { status: "ok", result: amount * width };
            case "area":
               return pieceToArea(amount, width, height);
            case "volume":
               return pieceToVolume(amount, width, height, depth);
            case "mass":
               return lengthToMass(width, density, height, depth, amount);
         }
         break;
      case "length":
         switch (targetQuantity) {
            case "length":
               return { status: "ok", result: amount };
            case "area":
               return lengthToArea(amount, height);
            case "volume":
               return lengthToVolume(amount, height, depth);
            case "mass":
               return lengthToMass(width, density, height, depth, amount);
         }
         break;
      case "area":
         switch (targetQuantity) {
            case "length":
               return areaToLength(amount, height);
            case "area":
               return { status: "ok", result: amount };
            case "volume":
               return areaToVolume(amount, depth);
            case "mass":
               return areaToMass(amount, density, depth);
         }
         break;
      case "volume":
         switch (targetQuantity) {
            case "length":
               return volumeToLength(amount, density, height, depth);
            case "area":
               return volumeToArea(amount, density, height);
            case "mass":
               return volumeToMass(amount, density);
            case "volume":
               return { status: "ok", result: amount };
         }
         break;
   }

   return { status: "error", message: "unknown conversion" };
};

export const calculateLca = (
   amount: number,
   productGroupUnit: Unit,
   conversion: any,
   materialInfo: Lca,
   productGroupLcaUnit?: Unit
): CalcResult => {
   if (!productGroupUnit) {
      throw new Error("Missing product group unit.");
   }
   if (!materialInfo.lca_unit && !productGroupLcaUnit) {
      throw new Error("Missing material LCA and Product Group LCA unit.");
   }
   const productGroupMUnit = normalizeUnitToMeter(productGroupUnit, 1);

   const lcaUnit = normalizeUnitToMeter(
      materialInfo.lca_unit || productGroupLcaUnit,
      1
   );
   const { width, height, depth, multiplier } = conversion || {};
   const multiplierNumber =
      typeof multiplier === "string" ? parseFloat(multiplier) : multiplier || 1;

   const conversionWidth = width && normalizeUnitToMeter("mm", width).value;
   //console.log(conversionWidth, width)
   const conversionHeight = height && normalizeUnitToMeter("mm", height)?.value;

   const conversionDepth = depth && normalizeUnitToMeter("mm", depth)?.value;

   console.log({
      sourceQuantity: productGroupMUnit.quantity,
      targetQuantity: lcaUnit.quantity,
      amount: (amount * productGroupMUnit?.value) / lcaUnit?.value,
      width: conversionWidth,
      height: conversionHeight,
      depth: conversionDepth,
      density:
         materialInfo.lca_unit === "kg" &&
         materialInfo.max === undefined &&
         materialInfo.min === undefined
            ? materialInfo?.density_max && materialInfo?.density_min
               ? ((materialInfo.density_max + materialInfo.density_min) / 2) *
                 1000
               : materialInfo.density_max * 1000 ||
                 materialInfo.density_min * 1000
            : materialInfo.max * 1000 || materialInfo.min * 1000
   });
   const conversionResult = convertQuantities({
      sourceQuantity: productGroupMUnit.quantity,
      targetQuantity: lcaUnit.quantity,
      amount: (amount * productGroupMUnit?.value) / lcaUnit?.value,
      width: conversionWidth,
      height: conversionHeight,
      depth: conversionDepth,
      density:
         materialInfo.lca_unit === "kg" &&
         materialInfo.max === undefined &&
         materialInfo.min === undefined
            ? materialInfo?.density_max && materialInfo?.density_min
               ? ((materialInfo.density_max + materialInfo.density_min) / 2) *
                 1000
               : materialInfo.density_max * 1000 ||
                 materialInfo.density_min * 1000
            : materialInfo.max * 1000 || materialInfo.min * 1000
   });

   if (conversionResult.status === "ok" && conversionResult.result) {
      if (
         !materialInfo.gwp_a1a3 &&
         !materialInfo.gwp_c4 &&
         !materialInfo.gwp_c1 &&
         !materialInfo.gwp_c3
      ) {
         throw new Error("Missing LCA values");
      }
      console.log(
         materialInfo.gwp_a1a3 +
            materialInfo.gwp_c4 +
            (materialInfo.gwp_c3 || 0)
      );
      console.log(conversionResult?.result);
      return {
         status: "ok",
         result:
            (materialInfo.gwp_a1a3 +
               materialInfo.gwp_c4 +
               (materialInfo.gwp_c3 || 0)) *
            conversionResult?.result *
            multiplierNumber
      };
   } else {
      return {
         status: "error",
         message: conversionResult.message
      };
   }
};

export function useCalculateLca() {
   const calculateTotalLca = React.useCallback(
      (
         sourceUnit: Unit,
         targetUnit: Unit,
         material: Lca,
         amount: number,
         conversion: any
      ): CalcResult => {
         let totalLca = 0;
         const { result, status, message } = calculateLca(
            amount,
            sourceUnit,
            conversion,
            material,
            targetUnit
         );
         if (status === "ok" && result) {
            totalLca += result;
         } else {
            return { result, status, message };
         }

         if (totalLca === 0 || isNaN(totalLca)) {
            throw new Error("Error: check!");
         }
         return { status: "ok", result: totalLca };
      },
      []
   );

   return { calculateTotalLca };
}
