/* tslint:disable */
/* eslint-disable */
/**
 * Concular API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
   AxiosPromise,
   AxiosInstance,
   AxiosRequestConfig
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
   DUMMY_BASE_URL,
   assertParamExists,
   setApiKeyToObject,
   setBasicAuthToObject,
   setBearerAuthToObject,
   setOAuthToObject,
   setSearchParams,
   serializeDataIfNeeded,
   toPathString,
   createRequestFunction
} from "./common";
// @ts-ignore
import {
   BASE_PATH,
   COLLECTION_FORMATS,
   RequestArgs,
   BaseAPI,
   RequiredError
} from "./base";

/**
 *
 * @export
 * @interface AccessoryOUT
 */
export interface AccessoryOUT {
   /**
    *
    * @type {string}
    * @memberof AccessoryOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof AccessoryOUT
    */
   name?: string;
   /**
    *
    * @type {string}
    * @memberof AccessoryOUT
    */
   name_de: string;
}
/**
 *
 * @export
 * @interface BuildingIN
 */
export interface BuildingIN {
   /**
    *
    * @type {string}
    * @memberof BuildingIN
    */
   name: string;
   /**
    *
    * @type {number}
    * @memberof BuildingIN
    */
   construction_year: number;
   /**
    *
    * @type {string}
    * @memberof BuildingIN
    */
   building_type: string;
   /**
    *
    * @type {string}
    * @memberof BuildingIN
    */
   address_id: string;
}
/**
 *
 * @export
 * @interface BuildingOUT
 */
export interface BuildingOUT {
   /**
    *
    * @type {string}
    * @memberof BuildingOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof BuildingOUT
    */
   name: string;
   /**
    *
    * @type {number}
    * @memberof BuildingOUT
    */
   construction_year: number;
   /**
    *
    * @type {string}
    * @memberof BuildingOUT
    */
   building_type: string;
   /**
    *
    * @type {LocationOUT}
    * @memberof BuildingOUT
    */
   address: LocationOUT;
}
/**
 *
 * @export
 * @interface CategoryOUT
 */
export interface CategoryOUT {
   /**
    *
    * @type {string}
    * @memberof CategoryOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof CategoryOUT
    */
   name?: string;
}
/**
 *
 * @export
 * @interface FieldOUT
 */
export interface FieldOUT {
   /**
    *
    * @type {string}
    * @memberof FieldOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof FieldOUT
    */
   name?: string;
   /**
    *
    * @type {string}
    * @memberof FieldOUT
    */
   name_de: string;
   /**
    *
    * @type {Array<OptionOUT>}
    * @memberof FieldOUT
    */
   options: Array<OptionOUT>;
}
/**
 *
 * @export
 * @interface IFCFileOUT
 */
export interface IFCFileOUT {
   /**
    *
    * @type {string}
    * @memberof IFCFileOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof IFCFileOUT
    */
   external_building_id: string;
   /**
    *
    * @type {string}
    * @memberof IFCFileOUT
    */
   name: string;
   /**
    *
    * @type {string}
    * @memberof IFCFileOUT
    */
   s3_path: string;
}
/**
 *
 * @export
 * @interface IFCMaterialIN
 */
export interface IFCMaterialIN {
   /**
    *
    * @type {string}
    * @memberof IFCMaterialIN
    */
   material_name: string;
   /**
    *
    * @type {string}
    * @memberof IFCMaterialIN
    */
   name: string;
   /**
    *
    * @type {number}
    * @memberof IFCMaterialIN
    */
   thickness: number;
}
/**
 *
 * @export
 * @interface IFCMaterialOUT
 */
export interface IFCMaterialOUT {
   /**
    *
    * @type {string}
    * @memberof IFCMaterialOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof IFCMaterialOUT
    */
   material_name: string;
   /**
    *
    * @type {string}
    * @memberof IFCMaterialOUT
    */
   name: string;
   /**
    *
    * @type {number}
    * @memberof IFCMaterialOUT
    */
   thickness: number;
}
/**
 *
 * @export
 * @interface IFCObjectIN
 */
export interface IFCObjectIN {
   /**
    *
    * @type {string}
    * @memberof IFCObjectIN
    */
   ifc_class: string;
   /**
    *
    * @type {string}
    * @memberof IFCObjectIN
    */
   name: string;
   /**
    *
    * @type {string}
    * @memberof IFCObjectIN
    */
   ifc_express_id?: string;
   /**
    *
    * @type {number}
    * @memberof IFCObjectIN
    */
   overall_height?: number;
   /**
    *
    * @type {number}
    * @memberof IFCObjectIN
    */
   overall_width?: number;
   /**
    *
    * @type {number}
    * @memberof IFCObjectIN
    */
   overall_length?: number;
   /**
    *
    * @type {number}
    * @memberof IFCObjectIN
    */
   surface_area?: number;
   /**
    *
    * @type {number}
    * @memberof IFCObjectIN
    */
   volume?: number;
   /**
    *
    * @type {Array<IFCMaterialIN>}
    * @memberof IFCObjectIN
    */
   ifc_materials: Array<IFCMaterialIN>;
   /**
    *
    * @type {string}
    * @memberof IFCObjectIN
    */
   product_id?: string;
}
/**
 *
 * @export
 * @interface IFCObjectOUT
 */
export interface IFCObjectOUT {
   /**
    *
    * @type {string}
    * @memberof IFCObjectOUT
    */
   ifc_class: string;
   /**
    *
    * @type {string}
    * @memberof IFCObjectOUT
    */
   name: string;
   /**
    *
    * @type {IFCFileOUT}
    * @memberof IFCObjectOUT
    */
   ifc_file: IFCFileOUT;
   /**
    *
    * @type {string}
    * @memberof IFCObjectOUT
    */
   ifc_express_id?: string;
   /**
    *
    * @type {number}
    * @memberof IFCObjectOUT
    */
   overall_height?: number;
   /**
    *
    * @type {number}
    * @memberof IFCObjectOUT
    */
   overall_width?: number;
   /**
    *
    * @type {number}
    * @memberof IFCObjectOUT
    */
   overall_length?: number;
   /**
    *
    * @type {number}
    * @memberof IFCObjectOUT
    */
   surface_area?: number;
   /**
    *
    * @type {number}
    * @memberof IFCObjectOUT
    */
   volume?: number;
   /**
    *
    * @type {Array<IFCMaterialOUT>}
    * @memberof IFCObjectOUT
    */
   ifcmaterial_set: Array<IFCMaterialOUT>;
}
/**
 *
 * @export
 * @interface LCAEntryOUT
 */
export interface LCAEntryOUT {
   /**
    *
    * @type {string}
    * @memberof LCAEntryOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof LCAEntryOUT
    */
   name_de: string;
   /**
    *
    * @type {string}
    * @memberof LCAEntryOUT
    */
   name_en: string;
   /**
    *
    * @type {string}
    * @memberof LCAEntryOUT
    */
   type: string;
   /**
    *
    * @type {number}
    * @memberof LCAEntryOUT
    */
   valid_until: number;
}
/**
 *
 * @export
 * @interface LocationIN
 */
export interface LocationIN {
   /**
    *
    * @type {string}
    * @memberof LocationIN
    */
   name: string;
   /**
    *
    * @type {string}
    * @memberof LocationIN
    */
   region: string;
   /**
    *
    * @type {string}
    * @memberof LocationIN
    */
   street: string;
   /**
    *
    * @type {string}
    * @memberof LocationIN
    */
   house_number: string;
   /**
    *
    * @type {number}
    * @memberof LocationIN
    */
   latitude: number;
   /**
    *
    * @type {number}
    * @memberof LocationIN
    */
   longitude: number;
   /**
    *
    * @type {string}
    * @memberof LocationIN
    */
   postal_code: string;
}
/**
 *
 * @export
 * @interface LocationOUT
 */
export interface LocationOUT {
   /**
    *
    * @type {string}
    * @memberof LocationOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof LocationOUT
    */
   name: string;
   /**
    *
    * @type {string}
    * @memberof LocationOUT
    */
   region: string;
   /**
    *
    * @type {string}
    * @memberof LocationOUT
    */
   street: string;
   /**
    *
    * @type {string}
    * @memberof LocationOUT
    */
   house_number: string;
   /**
    *
    * @type {number}
    * @memberof LocationOUT
    */
   latitude: number;
   /**
    *
    * @type {number}
    * @memberof LocationOUT
    */
   longitude: number;
   /**
    *
    * @type {string}
    * @memberof LocationOUT
    */
   postal_code: string;
}
/**
 *
 * @export
 * @interface ManufacturerOUT
 */
export interface ManufacturerOUT {
   /**
    *
    * @type {string}
    * @memberof ManufacturerOUT
    */
   name: string;
}
/**
 *
 * @export
 * @interface OptionOUT
 */
export interface OptionOUT {
   /**
    *
    * @type {string}
    * @memberof OptionOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof OptionOUT
    */
   name?: string;
   /**
    *
    * @type {string}
    * @memberof OptionOUT
    */
   name_de: string;
}
/**
 *
 * @export
 * @interface PagedBuildingOUT
 */
export interface PagedBuildingOUT {
   /**
    *
    * @type {Array<BuildingOUT>}
    * @memberof PagedBuildingOUT
    */
   items?: Array<BuildingOUT>;
   /**
    *
    * @type {number}
    * @memberof PagedBuildingOUT
    */
   count: number;
}
/**
 *
 * @export
 * @interface PagedFieldOUT
 */
export interface PagedFieldOUT {
   /**
    *
    * @type {Array<FieldOUT>}
    * @memberof PagedFieldOUT
    */
   items?: Array<FieldOUT>;
   /**
    *
    * @type {number}
    * @memberof PagedFieldOUT
    */
   count: number;
}
/**
 *
 * @export
 * @interface PagedIFCFileOUT
 */
export interface PagedIFCFileOUT {
   /**
    *
    * @type {Array<IFCFileOUT>}
    * @memberof PagedIFCFileOUT
    */
   items?: Array<IFCFileOUT>;
   /**
    *
    * @type {number}
    * @memberof PagedIFCFileOUT
    */
   count: number;
}
/**
 *
 * @export
 * @interface PagedLCAEntryOUT
 */
export interface PagedLCAEntryOUT {
   /**
    *
    * @type {Array<LCAEntryOUT>}
    * @memberof PagedLCAEntryOUT
    */
   items?: Array<LCAEntryOUT>;
   /**
    *
    * @type {number}
    * @memberof PagedLCAEntryOUT
    */
   count: number;
}
/**
 *
 * @export
 * @interface PagedLocationOUT
 */
export interface PagedLocationOUT {
   /**
    *
    * @type {Array<LocationOUT>}
    * @memberof PagedLocationOUT
    */
   items?: Array<LocationOUT>;
   /**
    *
    * @type {number}
    * @memberof PagedLocationOUT
    */
   count: number;
}
/**
 *
 * @export
 * @interface PagedProductGroupOUT
 */
export interface PagedProductGroupOUT {
   /**
    *
    * @type {Array<ProductGroupOUT>}
    * @memberof PagedProductGroupOUT
    */
   items?: Array<ProductGroupOUT>;
   /**
    *
    * @type {number}
    * @memberof PagedProductGroupOUT
    */
   count: number;
}
/**
 *
 * @export
 * @interface PagedProductOUT
 */
export interface PagedProductOUT {
   /**
    *
    * @type {Array<ProductOUT>}
    * @memberof PagedProductOUT
    */
   items?: Array<ProductOUT>;
   /**
    *
    * @type {number}
    * @memberof PagedProductOUT
    */
   count: number;
}
/**
 *
 * @export
 * @interface ProductGroupOUT
 */
export interface ProductGroupOUT {
   /**
    *
    * @type {string}
    * @memberof ProductGroupOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof ProductGroupOUT
    */
   name?: string;
   /**
    *
    * @type {string}
    * @memberof ProductGroupOUT
    */
   name_de: string;
   /**
    *
    * @type {string}
    * @memberof ProductGroupOUT
    */
   description?: string;
   /**
    *
    * @type {Array<FieldOUT>}
    * @memberof ProductGroupOUT
    */
   fields: Array<FieldOUT>;
   /**
    *
    * @type {Array<CategoryOUT>}
    * @memberof ProductGroupOUT
    */
   categories: Array<CategoryOUT>;
   /**
    *
    * @type {ReusePotentialOUT}
    * @memberof ProductGroupOUT
    */
   reuse_potential?: ReusePotentialOUT;
   /**
    *
    * @type {Array<AccessoryOUT>}
    * @memberof ProductGroupOUT
    */
   accessories: Array<AccessoryOUT>;
}
/**
 *
 * @export
 * @interface ProductOUT
 */
export interface ProductOUT {
   /**
    *
    * @type {string}
    * @memberof ProductOUT
    */
   id: string;
   /**
    *
    * @type {ProductGroupOUT}
    * @memberof ProductOUT
    */
   product_group?: ProductGroupOUT;
   /**
    *
    * @type {ManufacturerOUT}
    * @memberof ProductOUT
    */
   manufacturer?: ManufacturerOUT;
   /**
    *
    * @type {string}
    * @memberof ProductOUT
    */
   product_type: string;
   /**
    *
    * @type {string}
    * @memberof ProductOUT
    */
   name: string;
   /**
    *
    * @type {string}
    * @memberof ProductOUT
    */
   name_de: string;
   /**
    *
    * @type {string}
    * @memberof ProductOUT
    */
   default_unit: string;
   /**
    *
    * @type {object}
    * @memberof ProductOUT
    */
   custom_specifications: object;
   /**
    *
    * @type {string}
    * @memberof ProductOUT
    */
   description: string;
   /**
    *
    * @type {Array<LCAEntryOUT>}
    * @memberof ProductOUT
    */
   lca_entries: Array<LCAEntryOUT>;
}
/**
 *
 * @export
 * @interface ResultOUT
 */
export interface ResultOUT {
   /**
    *
    * @type {boolean}
    * @memberof ResultOUT
    */
   success: boolean;
   /**
    *
    * @type {string}
    * @memberof ResultOUT
    */
   message: string;
}
/**
 *
 * @export
 * @interface ReusePotentialOUT
 */
export interface ReusePotentialOUT {
   /**
    *
    * @type {string}
    * @memberof ReusePotentialOUT
    */
   id: string;
   /**
    *
    * @type {string}
    * @memberof ReusePotentialOUT
    */
   name?: string;
   /**
    *
    * @type {string}
    * @memberof ReusePotentialOUT
    */
   name_de: string;
}

/**
 * IFCFileApi - axios parameter creator
 * @export
 */
export const IFCFileApiAxiosParamCreator = function (
   configuration?: Configuration
) {
   return {
      /**
       *
       * @summary Bulk Create Ifc Object
       * @param {string} ifcFileId
       * @param {Array<IFCObjectIN>} iFCObjectIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject: async (
         ifcFileId: string,
         iFCObjectIN: Array<IFCObjectIN>,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'ifcFileId' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject",
            "ifcFileId",
            ifcFileId
         );
         // verify required parameter 'iFCObjectIN' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject",
            "iFCObjectIN",
            iFCObjectIN
         );
         const localVarPath =
            `/api/v1/ifc/files/{ifc_file_id}/objects/bulk`.replace(
               `{${"ifc_file_id"}}`,
               encodeURIComponent(String(ifcFileId))
            );
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "POST",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         localVarHeaderParameter["Content-Type"] = "application/json";

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };
         localVarRequestOptions.data = serializeDataIfNeeded(
            iFCObjectIN,
            localVarRequestOptions,
            configuration
         );

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Delete Ifc File
       * @param {string} ifcFileId
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileDeleteIfcFile: async (
         ifcFileId: string,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'ifcFileId' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileDeleteIfcFile",
            "ifcFileId",
            ifcFileId
         );
         const localVarPath = `/api/v1/ifc/files/{ifc_file_id}`.replace(
            `{${"ifc_file_id"}}`,
            encodeURIComponent(String(ifcFileId))
         );
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "DELETE",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Download Ifc File
       * @param {string} ifcFileId
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileDownloadIfcFile: async (
         ifcFileId: string,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'ifcFileId' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileDownloadIfcFile",
            "ifcFileId",
            ifcFileId
         );
         const localVarPath =
            `/api/v1/ifc/files/{ifc_file_id}/download`.replace(
               `{${"ifc_file_id"}}`,
               encodeURIComponent(String(ifcFileId))
            );
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Generate Ifc
       * @param {number} width
       * @param {number} height
       * @param {number} depth
       * @param {string} filename
       * @param {string} [fileExtension]
       * @param {string} [shape]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileGenerateIfc: async (
         width: number,
         height: number,
         depth: number,
         filename: string,
         fileExtension?: string,
         shape?: string,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'width' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileGenerateIfc",
            "width",
            width
         );
         // verify required parameter 'height' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileGenerateIfc",
            "height",
            height
         );
         // verify required parameter 'depth' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileGenerateIfc",
            "depth",
            depth
         );
         // verify required parameter 'filename' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileGenerateIfc",
            "filename",
            filename
         );
         const localVarPath = `/api/v1/ifc/files/generate`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         if (width !== undefined) {
            localVarQueryParameter["width"] = width;
         }

         if (height !== undefined) {
            localVarQueryParameter["height"] = height;
         }

         if (depth !== undefined) {
            localVarQueryParameter["depth"] = depth;
         }

         if (filename !== undefined) {
            localVarQueryParameter["filename"] = filename;
         }

         if (fileExtension !== undefined) {
            localVarQueryParameter["file_extension"] = fileExtension;
         }

         if (shape !== undefined) {
            localVarQueryParameter["shape"] = shape;
         }

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Get All Ifc Files
       * @param {string} externalBuildingId
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileGetAllIfcFiles: async (
         externalBuildingId: string,
         limit?: number,
         offset?: number,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'externalBuildingId' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileGetAllIfcFiles",
            "externalBuildingId",
            externalBuildingId
         );
         const localVarPath = `/api/v1/ifc/files/`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         if (externalBuildingId !== undefined) {
            localVarQueryParameter["external_building_id"] = externalBuildingId;
         }

         if (limit !== undefined) {
            localVarQueryParameter["limit"] = limit;
         }

         if (offset !== undefined) {
            localVarQueryParameter["offset"] = offset;
         }

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Upload Ifc File
       * @param {string} externalBuildingId
       * @param {any} file
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileUploadIfcFile: async (
         externalBuildingId: string,
         file: any,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'externalBuildingId' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileUploadIfcFile",
            "externalBuildingId",
            externalBuildingId
         );
         // verify required parameter 'file' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcFileUploadIfcFile",
            "file",
            file
         );
         const localVarPath = `/api/v1/ifc/files/upload`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "POST",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;
         const localVarFormParams = new ((configuration &&
            configuration.formDataCtor) ||
            FormData)();

         if (externalBuildingId !== undefined) {
            localVarQueryParameter["external_building_id"] = externalBuildingId;
         }

         if (file !== undefined) {
            localVarFormParams.append("file", file as any);
         }

         localVarHeaderParameter["Content-Type"] = "multipart/form-data";

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };
         localVarRequestOptions.data = localVarFormParams;

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      }
   };
};

/**
 * IFCFileApi - functional programming interface
 * @export
 */
export const IFCFileApiFp = function (configuration?: Configuration) {
   const localVarAxiosParamCreator = IFCFileApiAxiosParamCreator(configuration);
   return {
      /**
       *
       * @summary Bulk Create Ifc Object
       * @param {string} ifcFileId
       * @param {Array<IFCObjectIN>} iFCObjectIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject(
         ifcFileId: string,
         iFCObjectIN: Array<IFCObjectIN>,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<Array<IFCObjectOUT>>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject(
               ifcFileId,
               iFCObjectIN,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Delete Ifc File
       * @param {string} ifcFileId
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersIfcFileDeleteIfcFile(
         ifcFileId: string,
         options?: AxiosRequestConfig
      ): Promise<
         (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersIfcFileDeleteIfcFile(
               ifcFileId,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Download Ifc File
       * @param {string} ifcFileId
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersIfcFileDownloadIfcFile(
         ifcFileId: string,
         options?: AxiosRequestConfig
      ): Promise<
         (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersIfcFileDownloadIfcFile(
               ifcFileId,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Generate Ifc
       * @param {number} width
       * @param {number} height
       * @param {number} depth
       * @param {string} filename
       * @param {string} [fileExtension]
       * @param {string} [shape]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersIfcFileGenerateIfc(
         width: number,
         height: number,
         depth: number,
         filename: string,
         fileExtension?: string,
         shape?: string,
         options?: AxiosRequestConfig
      ): Promise<
         (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersIfcFileGenerateIfc(
               width,
               height,
               depth,
               filename,
               fileExtension,
               shape,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Get All Ifc Files
       * @param {string} externalBuildingId
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersIfcFileGetAllIfcFiles(
         externalBuildingId: string,
         limit?: number,
         offset?: number,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<PagedIFCFileOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersIfcFileGetAllIfcFiles(
               externalBuildingId,
               limit,
               offset,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Upload Ifc File
       * @param {string} externalBuildingId
       * @param {any} file
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersIfcFileUploadIfcFile(
         externalBuildingId: string,
         file: any,
         options?: AxiosRequestConfig
      ): Promise<
         (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFCFileOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersIfcFileUploadIfcFile(
               externalBuildingId,
               file,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      }
   };
};

/**
 * IFCFileApi - factory interface
 * @export
 */
export const IFCFileApiFactory = function (
   configuration?: Configuration,
   basePath?: string,
   axios?: AxiosInstance
) {
   const localVarFp = IFCFileApiFp(configuration);
   return {
      /**
       *
       * @summary Bulk Create Ifc Object
       * @param {string} ifcFileId
       * @param {Array<IFCObjectIN>} iFCObjectIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject(
         ifcFileId: string,
         iFCObjectIN: Array<IFCObjectIN>,
         options?: any
      ): AxiosPromise<Array<IFCObjectOUT>> {
         return localVarFp
            .djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject(
               ifcFileId,
               iFCObjectIN,
               options
            )
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Delete Ifc File
       * @param {string} ifcFileId
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileDeleteIfcFile(
         ifcFileId: string,
         options?: any
      ): AxiosPromise<ResultOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersIfcFileDeleteIfcFile(ifcFileId, options)
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Download Ifc File
       * @param {string} ifcFileId
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileDownloadIfcFile(
         ifcFileId: string,
         options?: any
      ): AxiosPromise<void> {
         return localVarFp
            .djIfcBaseApiV1RoutersIfcFileDownloadIfcFile(ifcFileId, options)
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Generate Ifc
       * @param {number} width
       * @param {number} height
       * @param {number} depth
       * @param {string} filename
       * @param {string} [fileExtension]
       * @param {string} [shape]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileGenerateIfc(
         width: number,
         height: number,
         depth: number,
         filename: string,
         fileExtension?: string,
         shape?: string,
         options?: any
      ): AxiosPromise<void> {
         return localVarFp
            .djIfcBaseApiV1RoutersIfcFileGenerateIfc(
               width,
               height,
               depth,
               filename,
               fileExtension,
               shape,
               options
            )
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Get All Ifc Files
       * @param {string} externalBuildingId
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileGetAllIfcFiles(
         externalBuildingId: string,
         limit?: number,
         offset?: number,
         options?: any
      ): AxiosPromise<PagedIFCFileOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersIfcFileGetAllIfcFiles(
               externalBuildingId,
               limit,
               offset,
               options
            )
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Upload Ifc File
       * @param {string} externalBuildingId
       * @param {any} file
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcFileUploadIfcFile(
         externalBuildingId: string,
         file: any,
         options?: any
      ): AxiosPromise<IFCFileOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersIfcFileUploadIfcFile(
               externalBuildingId,
               file,
               options
            )
            .then((request) => request(axios, basePath));
      }
   };
};

/**
 * IFCFileApi - object-oriented interface
 * @export
 * @class IFCFileApi
 * @extends {BaseAPI}
 */
export class IFCFileApi extends BaseAPI {
   /**
    *
    * @summary Bulk Create Ifc Object
    * @param {string} ifcFileId
    * @param {Array<IFCObjectIN>} iFCObjectIN
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof IFCFileApi
    */
   public djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject(
      ifcFileId: string,
      iFCObjectIN: Array<IFCObjectIN>,
      options?: AxiosRequestConfig
   ) {
      return IFCFileApiFp(this.configuration)
         .djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject(
            ifcFileId,
            iFCObjectIN,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Delete Ifc File
    * @param {string} ifcFileId
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof IFCFileApi
    */
   public djIfcBaseApiV1RoutersIfcFileDeleteIfcFile(
      ifcFileId: string,
      options?: AxiosRequestConfig
   ) {
      return IFCFileApiFp(this.configuration)
         .djIfcBaseApiV1RoutersIfcFileDeleteIfcFile(ifcFileId, options)
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Download Ifc File
    * @param {string} ifcFileId
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof IFCFileApi
    */
   public djIfcBaseApiV1RoutersIfcFileDownloadIfcFile(
      ifcFileId: string,
      options?: AxiosRequestConfig
   ) {
      return IFCFileApiFp(this.configuration)
         .djIfcBaseApiV1RoutersIfcFileDownloadIfcFile(ifcFileId, options)
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Generate Ifc
    * @param {number} width
    * @param {number} height
    * @param {number} depth
    * @param {string} filename
    * @param {string} [fileExtension]
    * @param {string} [shape]
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof IFCFileApi
    */
   public djIfcBaseApiV1RoutersIfcFileGenerateIfc(
      width: number,
      height: number,
      depth: number,
      filename: string,
      fileExtension?: string,
      shape?: string,
      options?: AxiosRequestConfig
   ) {
      return IFCFileApiFp(this.configuration)
         .djIfcBaseApiV1RoutersIfcFileGenerateIfc(
            width,
            height,
            depth,
            filename,
            fileExtension,
            shape,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Get All Ifc Files
    * @param {string} externalBuildingId
    * @param {number} [limit]
    * @param {number} [offset]
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof IFCFileApi
    */
   public djIfcBaseApiV1RoutersIfcFileGetAllIfcFiles(
      externalBuildingId: string,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
   ) {
      return IFCFileApiFp(this.configuration)
         .djIfcBaseApiV1RoutersIfcFileGetAllIfcFiles(
            externalBuildingId,
            limit,
            offset,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Upload Ifc File
    * @param {string} externalBuildingId
    * @param {any} file
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof IFCFileApi
    */
   public djIfcBaseApiV1RoutersIfcFileUploadIfcFile(
      externalBuildingId: string,
      file: any,
      options?: AxiosRequestConfig
   ) {
      return IFCFileApiFp(this.configuration)
         .djIfcBaseApiV1RoutersIfcFileUploadIfcFile(
            externalBuildingId,
            file,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }
}

/**
 * IFCObjectApi - axios parameter creator
 * @export
 */
export const IFCObjectApiAxiosParamCreator = function (
   configuration?: Configuration
) {
   return {
      /**
       *
       * @summary Create Ifc Object
       * @param {string} ifcFileId
       * @param {IFCObjectIN} iFCObjectIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcObjectCreateIfcObject: async (
         ifcFileId: string,
         iFCObjectIN: IFCObjectIN,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'ifcFileId' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcObjectCreateIfcObject",
            "ifcFileId",
            ifcFileId
         );
         // verify required parameter 'iFCObjectIN' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcObjectCreateIfcObject",
            "iFCObjectIN",
            iFCObjectIN
         );
         const localVarPath = `/api/v1/ifc/objects/{ifc_file_id}`.replace(
            `{${"ifc_file_id"}}`,
            encodeURIComponent(String(ifcFileId))
         );
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "POST",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         localVarHeaderParameter["Content-Type"] = "application/json";

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };
         localVarRequestOptions.data = serializeDataIfNeeded(
            iFCObjectIN,
            localVarRequestOptions,
            configuration
         );

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Get Ifc Objects
       * @param {string} ifcFileId
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcObjectGetIfcObjects: async (
         ifcFileId: string,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'ifcFileId' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcObjectGetIfcObjects",
            "ifcFileId",
            ifcFileId
         );
         const localVarPath = `/api/v1/ifc/objects/{ifc_file_id}`.replace(
            `{${"ifc_file_id"}}`,
            encodeURIComponent(String(ifcFileId))
         );
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Update Ifc Object
       * @param {string} ifcObjectId
       * @param {IFCObjectIN} iFCObjectIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcObjectUpdateIfcObject: async (
         ifcObjectId: string,
         iFCObjectIN: IFCObjectIN,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'ifcObjectId' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcObjectUpdateIfcObject",
            "ifcObjectId",
            ifcObjectId
         );
         // verify required parameter 'iFCObjectIN' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersIfcObjectUpdateIfcObject",
            "iFCObjectIN",
            iFCObjectIN
         );
         const localVarPath = `/api/v1/ifc/objects/{ifc_object_id}`.replace(
            `{${"ifc_object_id"}}`,
            encodeURIComponent(String(ifcObjectId))
         );
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "PATCH",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         localVarHeaderParameter["Content-Type"] = "application/json";

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };
         localVarRequestOptions.data = serializeDataIfNeeded(
            iFCObjectIN,
            localVarRequestOptions,
            configuration
         );

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      }
   };
};

/**
 * IFCObjectApi - functional programming interface
 * @export
 */
export const IFCObjectApiFp = function (configuration?: Configuration) {
   const localVarAxiosParamCreator =
      IFCObjectApiAxiosParamCreator(configuration);
   return {
      /**
       *
       * @summary Create Ifc Object
       * @param {string} ifcFileId
       * @param {IFCObjectIN} iFCObjectIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersIfcObjectCreateIfcObject(
         ifcFileId: string,
         iFCObjectIN: IFCObjectIN,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<IFCObjectOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersIfcObjectCreateIfcObject(
               ifcFileId,
               iFCObjectIN,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Get Ifc Objects
       * @param {string} ifcFileId
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersIfcObjectGetIfcObjects(
         ifcFileId: string,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<Array<IFCObjectOUT>>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersIfcObjectGetIfcObjects(
               ifcFileId,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Update Ifc Object
       * @param {string} ifcObjectId
       * @param {IFCObjectIN} iFCObjectIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersIfcObjectUpdateIfcObject(
         ifcObjectId: string,
         iFCObjectIN: IFCObjectIN,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<IFCObjectOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersIfcObjectUpdateIfcObject(
               ifcObjectId,
               iFCObjectIN,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      }
   };
};

/**
 * IFCObjectApi - factory interface
 * @export
 */
export const IFCObjectApiFactory = function (
   configuration?: Configuration,
   basePath?: string,
   axios?: AxiosInstance
) {
   const localVarFp = IFCObjectApiFp(configuration);
   return {
      /**
       *
       * @summary Create Ifc Object
       * @param {string} ifcFileId
       * @param {IFCObjectIN} iFCObjectIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcObjectCreateIfcObject(
         ifcFileId: string,
         iFCObjectIN: IFCObjectIN,
         options?: any
      ): AxiosPromise<IFCObjectOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersIfcObjectCreateIfcObject(
               ifcFileId,
               iFCObjectIN,
               options
            )
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Get Ifc Objects
       * @param {string} ifcFileId
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcObjectGetIfcObjects(
         ifcFileId: string,
         options?: any
      ): AxiosPromise<Array<IFCObjectOUT>> {
         return localVarFp
            .djIfcBaseApiV1RoutersIfcObjectGetIfcObjects(ifcFileId, options)
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Update Ifc Object
       * @param {string} ifcObjectId
       * @param {IFCObjectIN} iFCObjectIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersIfcObjectUpdateIfcObject(
         ifcObjectId: string,
         iFCObjectIN: IFCObjectIN,
         options?: any
      ): AxiosPromise<IFCObjectOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersIfcObjectUpdateIfcObject(
               ifcObjectId,
               iFCObjectIN,
               options
            )
            .then((request) => request(axios, basePath));
      }
   };
};

/**
 * IFCObjectApi - object-oriented interface
 * @export
 * @class IFCObjectApi
 * @extends {BaseAPI}
 */
export class IFCObjectApi extends BaseAPI {
   /**
    *
    * @summary Create Ifc Object
    * @param {string} ifcFileId
    * @param {IFCObjectIN} iFCObjectIN
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof IFCObjectApi
    */
   public djIfcBaseApiV1RoutersIfcObjectCreateIfcObject(
      ifcFileId: string,
      iFCObjectIN: IFCObjectIN,
      options?: AxiosRequestConfig
   ) {
      return IFCObjectApiFp(this.configuration)
         .djIfcBaseApiV1RoutersIfcObjectCreateIfcObject(
            ifcFileId,
            iFCObjectIN,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Get Ifc Objects
    * @param {string} ifcFileId
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof IFCObjectApi
    */
   public djIfcBaseApiV1RoutersIfcObjectGetIfcObjects(
      ifcFileId: string,
      options?: AxiosRequestConfig
   ) {
      return IFCObjectApiFp(this.configuration)
         .djIfcBaseApiV1RoutersIfcObjectGetIfcObjects(ifcFileId, options)
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Update Ifc Object
    * @param {string} ifcObjectId
    * @param {IFCObjectIN} iFCObjectIN
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof IFCObjectApi
    */
   public djIfcBaseApiV1RoutersIfcObjectUpdateIfcObject(
      ifcObjectId: string,
      iFCObjectIN: IFCObjectIN,
      options?: AxiosRequestConfig
   ) {
      return IFCObjectApiFp(this.configuration)
         .djIfcBaseApiV1RoutersIfcObjectUpdateIfcObject(
            ifcObjectId,
            iFCObjectIN,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }
}

/**
 * LCAApi - axios parameter creator
 * @export
 */
export const LCAApiAxiosParamCreator = function (
   configuration?: Configuration
) {
   return {
      /**
       * Search for LCA data.
       * @summary Search Lca Data
       * @param {string} searchTerm
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersLcaSearchLcaData: async (
         searchTerm: string,
         limit?: number,
         offset?: number,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'searchTerm' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersLcaSearchLcaData",
            "searchTerm",
            searchTerm
         );
         const localVarPath = `/api/v1/lca/search`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         if (searchTerm !== undefined) {
            localVarQueryParameter["search_term"] = searchTerm;
         }

         if (limit !== undefined) {
            localVarQueryParameter["limit"] = limit;
         }

         if (offset !== undefined) {
            localVarQueryParameter["offset"] = offset;
         }

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      }
   };
};

/**
 * LCAApi - functional programming interface
 * @export
 */
export const LCAApiFp = function (configuration?: Configuration) {
   const localVarAxiosParamCreator = LCAApiAxiosParamCreator(configuration);
   return {
      /**
       * Search for LCA data.
       * @summary Search Lca Data
       * @param {string} searchTerm
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersLcaSearchLcaData(
         searchTerm: string,
         limit?: number,
         offset?: number,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<PagedLCAEntryOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersLcaSearchLcaData(
               searchTerm,
               limit,
               offset,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      }
   };
};

/**
 * LCAApi - factory interface
 * @export
 */
export const LCAApiFactory = function (
   configuration?: Configuration,
   basePath?: string,
   axios?: AxiosInstance
) {
   const localVarFp = LCAApiFp(configuration);
   return {
      /**
       * Search for LCA data.
       * @summary Search Lca Data
       * @param {string} searchTerm
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersLcaSearchLcaData(
         searchTerm: string,
         limit?: number,
         offset?: number,
         options?: any
      ): AxiosPromise<PagedLCAEntryOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersLcaSearchLcaData(
               searchTerm,
               limit,
               offset,
               options
            )
            .then((request) => request(axios, basePath));
      }
   };
};

/**
 * LCAApi - object-oriented interface
 * @export
 * @class LCAApi
 * @extends {BaseAPI}
 */
export class LCAApi extends BaseAPI {
   /**
    * Search for LCA data.
    * @summary Search Lca Data
    * @param {string} searchTerm
    * @param {number} [limit]
    * @param {number} [offset]
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof LCAApi
    */
   public djIfcBaseApiV1RoutersLcaSearchLcaData(
      searchTerm: string,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
   ) {
      return LCAApiFp(this.configuration)
         .djIfcBaseApiV1RoutersLcaSearchLcaData(
            searchTerm,
            limit,
            offset,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }
}

/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (
   configuration?: Configuration
) {
   return {
      /**
       * Get all product groups
       * @summary Get All Product Groups
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersProductsGetAllProductGroups: async (
         limit?: number,
         offset?: number,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         const localVarPath = `/api/v1/products/product_groups`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         if (limit !== undefined) {
            localVarQueryParameter["limit"] = limit;
         }

         if (offset !== undefined) {
            localVarQueryParameter["offset"] = offset;
         }

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       * Get all fields for product group.
       * @summary Get Fields For Product Group
       * @param {string} productGroupId
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersProductsGetFieldsForProductGroup: async (
         productGroupId: string,
         limit?: number,
         offset?: number,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'productGroupId' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersProductsGetFieldsForProductGroup",
            "productGroupId",
            productGroupId
         );
         const localVarPath = `/api/v1/products/product_groups/fields`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         if (productGroupId !== undefined) {
            localVarQueryParameter["product_group_id"] = productGroupId;
         }

         if (limit !== undefined) {
            localVarQueryParameter["limit"] = limit;
         }

         if (offset !== undefined) {
            localVarQueryParameter["offset"] = offset;
         }

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       * Search for LCA data.
       * @summary Search Products
       * @param {string} searchTerm
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersProductsSearchProducts: async (
         searchTerm: string,
         limit?: number,
         offset?: number,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'searchTerm' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersProductsSearchProducts",
            "searchTerm",
            searchTerm
         );
         const localVarPath = `/api/v1/products/search`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         if (searchTerm !== undefined) {
            localVarQueryParameter["search_term"] = searchTerm;
         }

         if (limit !== undefined) {
            localVarQueryParameter["limit"] = limit;
         }

         if (offset !== undefined) {
            localVarQueryParameter["offset"] = offset;
         }

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      }
   };
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function (configuration?: Configuration) {
   const localVarAxiosParamCreator =
      ProductsApiAxiosParamCreator(configuration);
   return {
      /**
       * Get all product groups
       * @summary Get All Product Groups
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersProductsGetAllProductGroups(
         limit?: number,
         offset?: number,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<PagedProductGroupOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersProductsGetAllProductGroups(
               limit,
               offset,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       * Get all fields for product group.
       * @summary Get Fields For Product Group
       * @param {string} productGroupId
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersProductsGetFieldsForProductGroup(
         productGroupId: string,
         limit?: number,
         offset?: number,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<PagedFieldOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersProductsGetFieldsForProductGroup(
               productGroupId,
               limit,
               offset,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       * Search for LCA data.
       * @summary Search Products
       * @param {string} searchTerm
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersProductsSearchProducts(
         searchTerm: string,
         limit?: number,
         offset?: number,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<PagedProductOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersProductsSearchProducts(
               searchTerm,
               limit,
               offset,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      }
   };
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (
   configuration?: Configuration,
   basePath?: string,
   axios?: AxiosInstance
) {
   const localVarFp = ProductsApiFp(configuration);
   return {
      /**
       * Get all product groups
       * @summary Get All Product Groups
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersProductsGetAllProductGroups(
         limit?: number,
         offset?: number,
         options?: any
      ): AxiosPromise<PagedProductGroupOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersProductsGetAllProductGroups(
               limit,
               offset,
               options
            )
            .then((request) => request(axios, basePath));
      },
      /**
       * Get all fields for product group.
       * @summary Get Fields For Product Group
       * @param {string} productGroupId
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersProductsGetFieldsForProductGroup(
         productGroupId: string,
         limit?: number,
         offset?: number,
         options?: any
      ): AxiosPromise<PagedFieldOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersProductsGetFieldsForProductGroup(
               productGroupId,
               limit,
               offset,
               options
            )
            .then((request) => request(axios, basePath));
      },
      /**
       * Search for LCA data.
       * @summary Search Products
       * @param {string} searchTerm
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersProductsSearchProducts(
         searchTerm: string,
         limit?: number,
         offset?: number,
         options?: any
      ): AxiosPromise<PagedProductOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersProductsSearchProducts(
               searchTerm,
               limit,
               offset,
               options
            )
            .then((request) => request(axios, basePath));
      }
   };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
   /**
    * Get all product groups
    * @summary Get All Product Groups
    * @param {number} [limit]
    * @param {number} [offset]
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ProductsApi
    */
   public djIfcBaseApiV1RoutersProductsGetAllProductGroups(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
   ) {
      return ProductsApiFp(this.configuration)
         .djIfcBaseApiV1RoutersProductsGetAllProductGroups(
            limit,
            offset,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    * Get all fields for product group.
    * @summary Get Fields For Product Group
    * @param {string} productGroupId
    * @param {number} [limit]
    * @param {number} [offset]
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ProductsApi
    */
   public djIfcBaseApiV1RoutersProductsGetFieldsForProductGroup(
      productGroupId: string,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
   ) {
      return ProductsApiFp(this.configuration)
         .djIfcBaseApiV1RoutersProductsGetFieldsForProductGroup(
            productGroupId,
            limit,
            offset,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    * Search for LCA data.
    * @summary Search Products
    * @param {string} searchTerm
    * @param {number} [limit]
    * @param {number} [offset]
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ProductsApi
    */
   public djIfcBaseApiV1RoutersProductsSearchProducts(
      searchTerm: string,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
   ) {
      return ProductsApiFp(this.configuration)
         .djIfcBaseApiV1RoutersProductsSearchProducts(
            searchTerm,
            limit,
            offset,
            options
         )
         .then((request) => request(this.axios, this.basePath));
   }
}

/**
 * SetupApi - axios parameter creator
 * @export
 */
export const SetupApiAxiosParamCreator = function (
   configuration?: Configuration
) {
   return {
      /**
       *
       * @summary Create Building
       * @param {BuildingIN} buildingIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersBuildingsCreateBuilding: async (
         buildingIN: BuildingIN,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'buildingIN' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersBuildingsCreateBuilding",
            "buildingIN",
            buildingIN
         );
         const localVarPath = `/api/v1/buldings/`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "POST",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         localVarHeaderParameter["Content-Type"] = "application/json";

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };
         localVarRequestOptions.data = serializeDataIfNeeded(
            buildingIN,
            localVarRequestOptions,
            configuration
         );

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Get All Buildings
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersBuildingsGetAllBuildings: async (
         limit?: number,
         offset?: number,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         const localVarPath = `/api/v1/buldings/`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         if (limit !== undefined) {
            localVarQueryParameter["limit"] = limit;
         }

         if (offset !== undefined) {
            localVarQueryParameter["offset"] = offset;
         }

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Create Location
       * @param {LocationIN} locationIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersLocationsCreateLocation: async (
         locationIN: LocationIN,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         // verify required parameter 'locationIN' is not null or undefined
         assertParamExists(
            "djIfcBaseApiV1RoutersLocationsCreateLocation",
            "locationIN",
            locationIN
         );
         const localVarPath = `/api/v1/addresses/`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "POST",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         localVarHeaderParameter["Content-Type"] = "application/json";

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };
         localVarRequestOptions.data = serializeDataIfNeeded(
            locationIN,
            localVarRequestOptions,
            configuration
         );

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      },
      /**
       *
       * @summary Get All Locations
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersLocationsGetAllLocations: async (
         limit?: number,
         offset?: number,
         options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
         const localVarPath = `/api/v1/addresses/`;
         // use dummy base URL string because the URL constructor only accepts absolute URLs.
         const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
         let baseOptions;
         if (configuration) {
            baseOptions = configuration.baseOptions;
         }

         const localVarRequestOptions = {
            method: "GET",
            ...baseOptions,
            ...options
         };
         const localVarHeaderParameter = {} as any;
         const localVarQueryParameter = {} as any;

         if (limit !== undefined) {
            localVarQueryParameter["limit"] = limit;
         }

         if (offset !== undefined) {
            localVarQueryParameter["offset"] = offset;
         }

         setSearchParams(localVarUrlObj, localVarQueryParameter);
         let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {};
         localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers
         };

         return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions
         };
      }
   };
};

/**
 * SetupApi - functional programming interface
 * @export
 */
export const SetupApiFp = function (configuration?: Configuration) {
   const localVarAxiosParamCreator = SetupApiAxiosParamCreator(configuration);
   return {
      /**
       *
       * @summary Create Building
       * @param {BuildingIN} buildingIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersBuildingsCreateBuilding(
         buildingIN: BuildingIN,
         options?: AxiosRequestConfig
      ): Promise<
         (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersBuildingsCreateBuilding(
               buildingIN,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Get All Buildings
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersBuildingsGetAllBuildings(
         limit?: number,
         offset?: number,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<PagedBuildingOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersBuildingsGetAllBuildings(
               limit,
               offset,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Create Location
       * @param {LocationIN} locationIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersLocationsCreateLocation(
         locationIN: LocationIN,
         options?: AxiosRequestConfig
      ): Promise<
         (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersLocationsCreateLocation(
               locationIN,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      },
      /**
       *
       * @summary Get All Locations
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async djIfcBaseApiV1RoutersLocationsGetAllLocations(
         limit?: number,
         offset?: number,
         options?: AxiosRequestConfig
      ): Promise<
         (
            axios?: AxiosInstance,
            basePath?: string
         ) => AxiosPromise<PagedLocationOUT>
      > {
         const localVarAxiosArgs =
            await localVarAxiosParamCreator.djIfcBaseApiV1RoutersLocationsGetAllLocations(
               limit,
               offset,
               options
            );
         return createRequestFunction(
            localVarAxiosArgs,
            globalAxios,
            BASE_PATH,
            configuration
         );
      }
   };
};

/**
 * SetupApi - factory interface
 * @export
 */
export const SetupApiFactory = function (
   configuration?: Configuration,
   basePath?: string,
   axios?: AxiosInstance
) {
   const localVarFp = SetupApiFp(configuration);
   return {
      /**
       *
       * @summary Create Building
       * @param {BuildingIN} buildingIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersBuildingsCreateBuilding(
         buildingIN: BuildingIN,
         options?: any
      ): AxiosPromise<BuildingOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersBuildingsCreateBuilding(buildingIN, options)
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Get All Buildings
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersBuildingsGetAllBuildings(
         limit?: number,
         offset?: number,
         options?: any
      ): AxiosPromise<PagedBuildingOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersBuildingsGetAllBuildings(
               limit,
               offset,
               options
            )
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create Location
       * @param {LocationIN} locationIN
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersLocationsCreateLocation(
         locationIN: LocationIN,
         options?: any
      ): AxiosPromise<BuildingOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersLocationsCreateLocation(locationIN, options)
            .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Get All Locations
       * @param {number} [limit]
       * @param {number} [offset]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      djIfcBaseApiV1RoutersLocationsGetAllLocations(
         limit?: number,
         offset?: number,
         options?: any
      ): AxiosPromise<PagedLocationOUT> {
         return localVarFp
            .djIfcBaseApiV1RoutersLocationsGetAllLocations(
               limit,
               offset,
               options
            )
            .then((request) => request(axios, basePath));
      }
   };
};

/**
 * SetupApi - object-oriented interface
 * @export
 * @class SetupApi
 * @extends {BaseAPI}
 */
export class SetupApi extends BaseAPI {
   /**
    *
    * @summary Create Building
    * @param {BuildingIN} buildingIN
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof SetupApi
    */
   public djIfcBaseApiV1RoutersBuildingsCreateBuilding(
      buildingIN: BuildingIN,
      options?: AxiosRequestConfig
   ) {
      return SetupApiFp(this.configuration)
         .djIfcBaseApiV1RoutersBuildingsCreateBuilding(buildingIN, options)
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Get All Buildings
    * @param {number} [limit]
    * @param {number} [offset]
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof SetupApi
    */
   public djIfcBaseApiV1RoutersBuildingsGetAllBuildings(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
   ) {
      return SetupApiFp(this.configuration)
         .djIfcBaseApiV1RoutersBuildingsGetAllBuildings(limit, offset, options)
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Create Location
    * @param {LocationIN} locationIN
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof SetupApi
    */
   public djIfcBaseApiV1RoutersLocationsCreateLocation(
      locationIN: LocationIN,
      options?: AxiosRequestConfig
   ) {
      return SetupApiFp(this.configuration)
         .djIfcBaseApiV1RoutersLocationsCreateLocation(locationIN, options)
         .then((request) => request(this.axios, this.basePath));
   }

   /**
    *
    * @summary Get All Locations
    * @param {number} [limit]
    * @param {number} [offset]
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof SetupApi
    */
   public djIfcBaseApiV1RoutersLocationsGetAllLocations(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
   ) {
      return SetupApiFp(this.configuration)
         .djIfcBaseApiV1RoutersLocationsGetAllLocations(limit, offset, options)
         .then((request) => request(this.axios, this.basePath));
   }
}
