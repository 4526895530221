import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout, Spin } from "antd";

import DemandForm from "./add";
import { useAuth } from "../../contexts/auth/index";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Sider, Content } = Layout;

const SideBar = () => {
   return <Sider className="page-sidebar"></Sider>;
};

export const DemandViews = () => {
   const { loadingUserInfo, onBoarded, user: currentUser } = useAuth();
   const [t] = useTranslation();
   const history = useHistory();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";
   const [isLoading, setIsLoading] = React.useState<boolean>(true);

   useEffect(() => {
      if (currentUser) {
         setIsLoading(false);
      }
      if (!onBoarded && !loadingUserInfo && !isPrivateOwner) {
         history.push("/onboarding");
      }
   }, [onBoarded, loadingUserInfo, isPrivateOwner, currentUser]);

   if (isLoading)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <Layout className="wrapper-style grey fixed-sidebar">
         <SideBar></SideBar>
         <Content>
            <Switch>
               <Route
                  exact
                  path="/demand"
                  render={() => {
                     return <Redirect to="/demand/add"></Redirect>;
                  }}
               ></Route>
               <Route path={`/demand/add`} component={DemandForm}></Route>
            </Switch>
         </Content>
      </Layout>
   );
};
