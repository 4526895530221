import { Result, Layout, Button } from "antd";
import React, { useEffect, useState } from "react";
import {
   Route,
   Switch,
   useParams,
   useHistory,
   Redirect
} from "react-router-dom";
import SmileOutlined from "@ant-design/icons/SmileOutlined";

import { BuildingOverview } from "./BuildingOverview";
import { BuildingFilesViews } from "./Files/BuildingFilesViews";
import { BuildingUsersView } from "./Users/BuildingUsersViews";
import { MaterialInventoryView } from "../MaterialInventory";
import { BuildingOperationsView } from "./Operations";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import "./style.scss";
import { useBuilding } from "../../contexts/building/index";
import { useAuth } from "../../contexts/auth/index";
import { SideBar } from "./BuildingSidebar";
import { Resource } from "../../api/common";

const { Content } = Layout;

export const Building = () => {
   const { id }: any = useParams();
   const { getBuildingDetail, acl } = useBuilding();
   const [t] = useTranslation();
   const history = useHistory();
   const { joinedOrganizationsList } = useAuth();
   const [loadingPage, setLoadingPage] = useState<boolean>(true);
   const [onLoad, setOnLoad] = useState<boolean>(true);
   const aclOrg = (organisation_id: number) => {
      if (organisation_id && joinedOrganizationsList) {
         const response = joinedOrganizationsList.filter((rec: any) => {
            return rec.organizations_id == organisation_id;
         });
         if (response.length) {
            if (
               response[0].role === "ADMIN" &&
               response[0].status === "ACCEPTED"
            ) {
               return true;
            } else if (
               response[0].role === "USER" &&
               response[0].invited &&
               response[0].status === "ACCEPTED"
            ) {
               return true;
            } else {
               return false;
            }
         } else {
            return false;
         }
      } else {
         return false;
      }
   };

   const buildingData: any = getBuildingDetail(id);

   const [{ data: dataSub }, fetchSubData] = useAxios(
      {
         url: `${Resource.path.plansURL}/sub/`,
         method: "get",
         timeout: 900000
      },
      { useCache: false, manual: true, autoCancel: false }
   );

   useEffect(() => {
      if (
         buildingData &&
         buildingData?.subscription_id &&
         buildingData?.has_subscription_feature == 1 &&
         onLoad
      ) {
         setOnLoad(false);
         fetchSubData({
            url: `${Resource.path.plansURL}/sub/${buildingData?.subscription_id}`,
            method: "get",
            timeout: 900000
         });
      }
      if (
         buildingData &&
         !buildingData?.subscription_id &&
         buildingData?.has_subscription_feature == 1
      ) {
         setLoadingPage(false);
      }
      if (buildingData && buildingData?.has_subscription_feature == 0) {
         setLoadingPage(false);
      }
      if (dataSub?.data) {
         buildingData.subscription = {};
         buildingData.services = dataSub?.data?.services;

         buildingData.subscription.status = dataSub?.data?.status;
         setLoadingPage(false);
      }
   }, [dataSub, buildingData, onLoad]);

   if (loadingPage) return <div className="loading">{t("common.loading")}</div>;

   //ToDo: Remove true after migration
   const orgPermission = buildingData?.organisation_id
      ? acl(buildingData?.organisation_id)
      : true;
   if (
      buildingData === undefined ||
      (!orgPermission && aclOrg(buildingData?.organisation_id))
   ) {
      return (
         <Result
            status="403"
            title="403"
            subTitle={t("403.subTitle")}
            extra={
               <Button
                  type="primary"
                  onClick={() => {
                     history.push("/");
                  }}
               >
                  {t("403.homeButton")}
               </Button>
            }
         />
      );
   }

   if (
      buildingData &&
      buildingData?.subscription?.status !== "active" &&
      buildingData?.has_subscription_feature === 1
   ) {
      history.push(`/subscription/${buildingData?.organisation_id}/${id}`);
   }

   if (
      buildingData &&
      buildingData?.subscription?.status !== "active" &&
      buildingData?.has_subscription_feature === 1
   ) {
      history.push(`/subscription/${buildingData?.organisation_id}/${id}`);
   }

   if (
      buildingData &&
      buildingData?.subscription?.status !== "active" &&
      buildingData?.has_subscription_feature === 1
   ) {
      history.push(`/subscription/${buildingData?.organisation_id}/${id}`);
   }

   return (
      <>
         <Layout className="wrapper-style grey fixed-sidebar">
            <SideBar />
            <Content>
               <Switch>
                  <Route
                     exact
                     path="/buildings/:id"
                     component={BuildingOverview}
                  ></Route>
                  <Route
                     path="/buildings/:id/material_inventory"
                     render={() => {
                        return buildingData.has_subscription_feature == 1 ? (
                           buildingData?.services?.inventory_mgmt ? (
                              <MaterialInventoryView />
                           ) : (
                              <Redirect
                                 to={`/subscription/${buildingData?.organisation_id}/${id}`}
                              />
                           )
                        ) : (
                           <MaterialInventoryView />
                        );
                     }}
                  ></Route>
                  <Route
                     path="/buildings/:id/operations"
                     render={() => {
                        return buildingData.has_subscription_feature == 1 ? (
                           buildingData?.services?.operations &&
                           buildingData.has_subscription_feature == 1 ? (
                              <BuildingOperationsView />
                           ) : (
                              <Redirect
                                 to={`/subscription/${buildingData?.organisation_id}/${id}`}
                              />
                           )
                        ) : (
                           <BuildingOperationsView />
                        );
                     }}
                  ></Route>
                  <Route
                     path="/buildings/:id/material_passport"
                     render={() => {
                        return buildingData.has_subscription_feature == 1 ? (
                           buildingData?.services?.material_passport &&
                           buildingData.has_subscription_feature === 1 ? (
                              <BuildingMaterialPassportView />
                           ) : (
                              <Redirect
                                 to={`/subscription/${buildingData?.organisation_id}/${id}`}
                              />
                           )
                        ) : (
                           <BuildingMaterialPassportView />
                        );
                     }}
                  ></Route>
                  <Route
                     path="/buildings/:id/financial_report"
                     component={BuildingFinancialReportView}
                  ></Route>
                  <Route
                     path="/buildings/:id/files"
                     component={BuildingFilesViews}
                  ></Route>
                  <Route
                     path="/buildings/:id/access"
                     component={BuildingUsersView}
                  ></Route>
               </Switch>
            </Content>
         </Layout>
      </>
   );
};

const BuildingMaterialPassportView = () => (
   <>
      <Result
         icon={<SmileOutlined />}
         title="Building Material Passport Views are coming soon"
      />
   </>
);

const BuildingFinancialReportView = () => {
   return (
      <>
         <Result
            icon={<SmileOutlined />}
            title="Material Financial report views are coming soon"
         />
      </>
   );
};
