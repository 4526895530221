import React, { useState, useEffect } from "react";
import { Empty, Button, message, InputNumber, Collapse, Spin } from "antd";
import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";
import {
   UpOutlined,
   PlusOutlined,
   CloseOutlined,
   SyncOutlined,
   DownOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Resource } from "../../../../../api/common";
import { PassportDetail } from "../Drawer/PassportDetail";
import Drawer from "../../../../../components/ui@concular/Drawer";
import { useBuilding } from "../../../../../contexts/building/index";
import { useAuth } from "../../../../../contexts/auth/index";

export const AddMaterials = ({
   onClose,
   visible,
   setVisible,
   refetchSelectedMaterials,
   dataMaterials,
   fetchMaterials,
   operation,
   showSelectAll,
   getLoadingMaterials,
   loadingSelectedMaterials
}: any) => {
   const [materials, setMaterials] = useState<any>([]);
   const [showPassportDetail, setShowPassportDetail] = useState<boolean>(false);
   const { id: buildingId, operation_id }: any = useParams<string>();
   const { Panel } = Collapse;
   const [t] = useTranslation();
   const [passportSpecification, setPassportSpecification] = useState<any>();
   const { getBuildingDetail } = useBuilding();
   const buildingDetails = buildingId && getBuildingDetail(buildingId);
   const { user: currentUser } = useAuth();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";

   const [{ loading: confirmLoading }, addAllPassports] = useAxios(
      {
         url: `${Resource.path.operations}/${buildingId}/selling/${operation_id}/materials`,
         method: "POST"
      },
      { manual: true }
   );

   useEffect(() => {
      if (dataMaterials?.data) {
         const temp: any = [];
         dataMaterials?.data.map((rec: any) => {
            if (temp[rec.category_id] == undefined) {
               temp[rec.category_id] = {
                  name: rec.category_name,
                  id: rec.category_id,
                  data: []
               };
            }
            temp[rec.category_id].data.push(rec);
         });
         setMaterials(Object.values(temp));
      }
      console.log("buildingDetails", buildingDetails);
   }, [dataMaterials, buildingDetails]);

   const PanelItem = (props: any) => {
      const { material } = props;
      const [selectedCount, setSelectedCount] = useState(0);
      const [showSelectByCondition, setShowSelectByCondition] = useState(false);
      const [onLoad, setOnLoad] = useState(true);
      const [{ loading }, addPassport] = useAxios(
         {
            url: `${Resource.path.operations}/${buildingId}/selling/${operation_id}/all`,
            method: "POST"
         },
         { manual: true }
      );

      const [{ loading: deleteLoading }, removePassport] = useAxios(
         {
            url: `${Resource.path.operations}/${buildingId}/selling/${operation_id}/remove/${material.id}`,
            method: "DELETE"
         },
         { manual: true }
      );

      useEffect(() => {
         if (material?.selectedAmount && material.selectedAmount > 0) {
            setSelectedCount(material.selectedAmount);
         } else {
            setSelectedCount(0);
         }
      }, [material]);

      const selectItemAll = async () => {
         const hide = message.loading("Adding", 0);
         await addPassport({
            data: {
               passport_specification_id: `${material.id}`,
               selling_status: operation.selling_started === 0 ? 0 : 1,
               building_name: buildingDetails.name
            }
         })
            .then(() => {
               setTimeout(hide, 0);

               fetchMaterials();
               refetchSelectedMaterials();
            })
            .catch((error) => {
               console.log(error);
               message.error(t("buildingOperations.errorMessage"));
            });
      };

      const unselectItemAll = async () => {
         setSelectedCount(0);
         material.selectedAmount = 0;
         console.log("selectedCount", selectedCount);
         await removePassport()
            .then(() => {
               setSelectedCount(0);
               fetchMaterials();
               refetchSelectedMaterials();
            })
            .catch((error) => {
               console.log(error);
               message.error(t("buildingOperations.errorMessage"));
            });
      };

      const showDetailPage = (material: any) => {
         setPassportSpecification(material);
         setShowPassportDetail(true);
      };

      const selectItemCondition = () => {
         setShowSelectByCondition(!showSelectByCondition);
      };

      const ProductDetail = (props: any) => {
         const { materialData, productDetail } = props;
         const {
            amount_available,
            amount_unit,
            condition,
            deconstructability,
            custom_pollutance,
            op_amount,
            selling_id,
            airtable_sync_status,
            op_selling_price
         } = productDetail;
         const [amountSelected, setAmountSelected] = useState(
            op_amount ? op_amount : amount_available
         );
         const [disableBtn, setDisableBtn] = useState(true);
         const [sellingPrice, setSellingPrice] = useState(op_selling_price);
         console.log("productDetail", productDetail);
         const [{ loading }, addPassportCondition] = useAxios(
            {
               url: `${Resource.path.operations}/${buildingId}/selling/${operation_id}/condition`,
               method: "POST"
            },
            { manual: true }
         );

         const [{ loading: loadingAll }, addPassportConditionAll] = useAxios(
            {
               url: `${Resource.path.operations}/${buildingId}/selling/${operation_id}/condition`,
               method: "POST"
            },
            { manual: true }
         );

         const selectAllCondition = async () => {
            const finalAmount = amount_available;
            setAmountSelected(finalAmount);
            const hide = message.loading("Hinzufügen", 0);
            await addPassportConditionAll({
               data: {
                  building_id: buildingId,
                  operations_id: operation_id,
                  passport_specification_id: `${materialData.id}`,
                  amount: finalAmount,
                  condition,
                  deconstructability,
                  custom_pollutance,
                  amount_unit,
                  selling_status: operation.selling_started === 0 ? 0 : 1,
                  building_name: buildingDetails.name
               }
            })
               .then((response) => {
                  console.log(response);
                  refetchSelectedMaterials();
                  fetchMaterials();
                  setTimeout(hide, 0);
               })
               .catch((error) => {
                  console.log(error);
                  setTimeout(hide, 0);
                  message.error(t("buildingOperations.errorMessage"));
               });
         };

         const selectCondition = async () => {
            const hide = message.loading("Hinzufügen", 0);
            await addPassportCondition({
               data: {
                  building_id: buildingId,
                  operations_id: operation_id,
                  passport_specification_id: `${materialData.id}`,
                  amount: amountSelected,
                  condition,
                  deconstructability,
                  custom_pollutance,
                  amount_unit,
                  selling_status: operation.selling_started === 0 ? 0 : 1,
                  building_name: buildingDetails.name,
                  selling_price: sellingPrice ? sellingPrice : null,
                  location: buildingDetails?.location || null,
                  selling_id: selling_id ? selling_id : null
               }
            })
               .then(async (response) => {
                  await refetchSelectedMaterials();
                  await fetchMaterials();
                  console.log(response);
                  setTimeout(hide, 0);
               })
               .catch((error) => {
                  console.log(error);
                  setTimeout(hide, 0);
                  message.error(t("buildingOperations.errorMessage"));
               });
         };

         const [{ loading: loadingDelete }, removeFromSale] = useAxios(
            {
               url: `${Resource.path.operations}/selling/remove/${selling_id}`,
               method: "DELETE"
            },
            { manual: true }
         );

         const deleteFromSale = async () => {
            const hide = message.loading("Hinzufügen", 0);
            await removeFromSale()
               .then(async () => {
                  await refetchSelectedMaterials();
                  await fetchMaterials();
                  setTimeout(hide, 0);
               })
               .catch((error) => {
                  console.log(error);
                  setTimeout(hide, 0);
                  message.error(t("buildingOperations.errorMessage"));
               });
         };

         return (
            <div className="item-cluster">
               <div className="passport-specs">
                  <div>
                     <div className="availability">
                        {amount_available
                           ? t("passportDetails.available")
                           : t("passportDetails.notAvailable")}
                     </div>
                     <div className="pieces">
                        {amount_available
                           ? `${amount_available} ${t(
                                "passportDetails.pieces"
                             )}`
                           : null}
                     </div>
                     <dl>
                        <dt>{t("passportDetails.condition")}:</dt>
                        <dd>{condition}</dd>
                     </dl>
                     <dl>
                        <dt>
                           {deconstructability}, {custom_pollutance}
                        </dt>
                     </dl>
                  </div>
               </div>
               <div
                  className={`select-cluster-wrapper ${op_amount && "on-sale"}`}
               >
                  <div className="select-cluster-title">
                     {op_amount
                        ? showOnSaleMessage()
                        : t("passportDetails.addOnSale")}
                  </div>
                  <div className="select-cluster">
                     <div className="left-side">
                        <div>
                           <InputNumber
                              style={{
                                 width: "70px",
                                 height: "46px",
                                 lineHeight: "46px"
                              }}
                              disabled={amount_available === 0}
                              min={
                                 material.total_requested > 0
                                    ? material.total_requested
                                    : 1
                              }
                              max={amount_available}
                              value={amountSelected}
                              onChange={(value) => {
                                 setAmountSelected(value);
                                 setDisableBtn(false);
                              }}
                           />
                           <span className="text-span">{`${t(
                              "passportDetails.pieces"
                           )} ${t("passportDetails.for")}`}</span>
                        </div>
                        <div>
                           <InputNumber
                              style={{
                                 width: "65px",
                                 height: "46px",
                                 lineHeight: "46px"
                              }}
                              min="0"
                              type="number"
                              onChange={(value: any) => {
                                 setSellingPrice(value);
                              }}
                              value={sellingPrice}
                           />
                           <span className="text-span">{`€/${t(
                              "passportDetails.piece"
                           )}`}</span>
                        </div>
                     </div>
                     <Button
                        className={isUpdating() ? "update-button" : ""}
                        type="default"
                        shape="circle"
                        loading={loading}
                        onClick={
                           op_amount
                              ? isUpdating()
                                 ? selectCondition
                                 : deleteFromSale
                              : selectCondition
                        }
                        disabled={
                           disableBtn &&
                           (amount_available === 0 || amountSelected === 0)
                        }
                        icon={
                           op_amount ? (
                              isUpdating() ? (
                                 <SyncOutlined />
                              ) : (
                                 <CloseOutlined />
                              )
                           ) : (
                              <PlusOutlined />
                           )
                        }
                     />
                  </div>
               </div>
            </div>
         );

         function showOnSaleMessage(): React.ReactNode {
            return (
               <>
                  <span className="green-circle"></span>
                  {`${t("passportDetails.onSale")}: ${op_amount} ${t(
                     "passportDetails.pieces"
                  )}, ${op_amount * op_selling_price} € ${t(
                     "passportDetails.totalLowerCase"
                  )}`}
               </>
            );
         }

         function isUpdating() {
            return (
               (op_amount && op_amount != amountSelected) ||
               (op_amount && op_selling_price != sellingPrice)
            );
         }
      };

      return (
         <>
            <div className="collapse-panel material-item">
               <div className="collapse-header">
                  <figure>
                     {material?.images && (
                        <img src={material?.images[0]?.object_url} />
                     )}
                  </figure>
                  <div className="item-inner">
                     <div className="item-title">
                        <Button
                           onClick={() => {
                              showDetailPage(material);
                           }}
                           style={{ padding: "0px" }}
                           type="link"
                        >
                           <strong>{material?.product_name}</strong>
                        </Button>
                     </div>
                     <div className="item-unit-select">
                        <span>
                           {material?.amount ? material.amount : 0}{" "}
                           {material?.product_unit}
                        </span>
                        <div className="actions">
                           {selectedCount ? (
                              <Button
                                 type="primary"
                                 size="small"
                                 shape="round"
                                 disabled={!material?.amount}
                                 onClick={selectItemCondition}
                                 icon={
                                    showSelectByCondition ? (
                                       <UpOutlined />
                                    ) : (
                                       <DownOutlined />
                                    )
                                 }
                                 className={`selected ${
                                    showSelectByCondition ? "open" : "close"
                                 }`}
                              >
                                 {`${selectedCount} ${t(
                                    "passportDetails.pieces"
                                 )} ${t(
                                    "passportDetails.onSale"
                                 ).toLocaleLowerCase()}`}
                              </Button>
                           ) : (
                              <Button
                                 type="default"
                                 size="small"
                                 shape="round"
                                 disabled={!material?.amount}
                                 onClick={selectItemCondition}
                                 icon={
                                    showSelectByCondition ? (
                                       <UpOutlined />
                                    ) : (
                                       <PlusOutlined />
                                    )
                                 }
                                 className={
                                    showSelectByCondition ? "open" : "close"
                                 }
                              >
                                 {t("default.selectItems")}
                              </Button>
                           )}
                           {/*      <span>|</span>
                                {!isPrivateOwner && <Button type="link" size="small" disabled={!material?.amount} onClick={selectItemAll}>{t("default.selectAll")}</Button>}
                            */}{" "}
                        </div>
                     </div>
                  </div>
               </div>

               {showSelectByCondition &&
                  material.product_details &&
                  material.product_details.length > 0 && (
                     <div className="collapse-content">
                        {material.product_details.map((product_detail: any) => (
                           <ProductDetail
                              materialData={material}
                              productDetail={product_detail}
                           />
                        ))}
                     </div>
                  )}
            </div>
         </>
      );
   };

   const selectAll = async () => {
      await addAllPassports({
         data: {
            selling_status: operation.selling_started === 0 ? 0 : 1,
            building_name: buildingDetails.name
         }
      })
         .then(() => {
            refetchSelectedMaterials();
            setVisible();
            fetchMaterials();
         })
         .catch((error) => {
            console.log(error);
            message.error(t("buildingOperations.errorMessage"));
         });
   };

   const onCloseDetail = () => {
      setShowPassportDetail(false);
   };
   console.log("Visible", visible);
   return (
      <Drawer
         /* title="Add materials to sell" */
         placement="right"
         closable={true}
         onClose={onClose}
         visible={visible}
         width={600}
      >
         <Spin spinning={getLoadingMaterials || loadingSelectedMaterials}>
            <div className="inventory-lookup">
               {/* <h2>
               {t("default.sellFromInventory")}{" "}
               {materials.length > 0 && !isPrivateOwner && (
                  <Button
                     loading={confirmLoading}
                     shape="round"
                     disabled={showSelectAll}
                     onClick={selectAll}
                  >
                     {t("default.selectAll")}
                  </Button>
               )}
            </h2> */}
               {passportSpecification && (
                  <PassportDetail
                     visible={showPassportDetail}
                     onClose={onCloseDetail}
                     passportSpecification={passportSpecification}
                  />
               )}
               <div className="collapse material-inventory-list">
                  {materials.length > 0 && (
                     <Collapse defaultActiveKey={["0"]} ghost>
                        {materials.map((rec: any, indexRec: any) => (
                           <Panel header={`${rec.name}`} key={indexRec}>
                              {rec.data.map((material: any, index: any) => (
                                 <PanelItem
                                    material={material}
                                    key={`${indexRec}-${index}`}
                                 />
                              ))}
                           </Panel>
                        ))}
                     </Collapse>
                  )}
                  {materials.length === 0 && <Empty />}
               </div>
            </div>
         </Spin>
      </Drawer>
   );
};
