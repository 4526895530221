import { getLcaMeta } from ".";
import { useCalculateLca } from "../../hooks/useCalculateLca";
import "./style.scss";

export const missingWidth = "Please select a width larger than 0.";
export const missingHeight = "Please select a height larger than 0.";
export const missingDepth = "Please select a depth larger than 0.";

export function useGetLca() {
   const { calculateTotalLca } = useCalculateLca();

   function getLCA(
      productGroup: any[],
      amount: any[],
      selectedMaterial: (string | undefined)[],
      passports: any[]
   ) {
      let lcaResult: any;
      let total = 0;
      try {
         if (productGroup && amount) {
            if (
               Array.isArray(productGroup) &&
               Array.isArray(amount) &&
               Array.isArray(selectedMaterial) &&
               Array.isArray(passports)
            ) {
               // for summing up LCA for whole building
               productGroup.forEach((pg, i) => {
                  try {
                     const material = getLcaMeta(pg, undefined);

                     if (material) {
                        const conversion: any = {
                           width: null,
                           height: null,
                           depth: null,
                           multiplier: null
                        };
                        if (
                           passports[i]?.specification &&
                           pg?.templateData &&
                           pg?.conversion
                        ) {
                           const heightField = pg.templateData.template
                              .filter((rec: any) => {
                                 return (
                                    pg?.conversion?.height?.field_id === rec.id
                                 );
                              })
                              .pop();
                           if (
                              heightField !== undefined &&
                              passports[i].specification[
                                 heightField.formItem.name
                              ] !== undefined
                           ) {
                              conversion.height =
                                 passports[i].specification[
                                    heightField.formItem.name
                                 ].unitValue;
                           }

                           const widthField = pg.templateData.template
                              .filter((rec: any) => {
                                 return (
                                    pg?.conversion?.width?.field_id === rec.id
                                 );
                              })
                              .pop();
                           if (
                              widthField !== undefined &&
                              passports[i].specification[
                                 widthField.formItem.name
                              ] !== undefined
                           ) {
                              conversion.width =
                                 passports[i].specification[
                                    widthField.formItem.name
                                 ].unitValue;
                           }

                           const depthField = pg.templateData.template
                              .filter((rec: any) => {
                                 return (
                                    pg?.conversion?.depth?.field_id === rec.id
                                 );
                              })
                              .pop();
                           if (
                              depthField !== undefined &&
                              passports[i].specification[
                                 depthField.formItem.name
                              ] !== undefined
                           ) {
                              conversion.depth =
                                 passports[i].specification[
                                    depthField.formItem.name
                                 ].unitValue;
                           }
                        }
                        conversion.multiplier = pg.conversion?.multiplier;

                        const lcaValue = calculateTotalLca(
                           pg.unit,
                           material.lca_unit || pg.lca_unit,
                           pg?.lca_meta && pg?.lca_meta.length > 0
                              ? material
                              : pg,
                           amount[i],
                           conversion
                        );
                        if (lcaValue?.status === "ok" && lcaValue?.result) {
                           lcaResult = lcaValue;
                           total += lcaValue?.result;
                           total = +total.toFixed(2);
                        }
                     } else {
                        lcaResult = {
                           status: "error",
                           message:
                              "Selected material is not available any more."
                        };
                     }
                  } catch (e) {
                     console.log(e);
                  }
               });
            }
         }
      } catch (e: any) {}
      return { lcaResult, total };
   }

   return {
      getLCA
   };
}
