import { Upload } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";

const uploadButton = (
   <div>
      <PlusOutlined />
   </div>
);

function getBase64(file: any) {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });
}

export const ImageUploader = ({
   value,
   onChange,
   maxCount = 10,
   multiple = true
}: any) => {
   const [previewVisible, setPreviewVisible] = useState(false);
   const [previewImage, setPreviewImage] = useState<any>();
   const [previewTitle, setPreviewTitle] = useState();
   const [fileList, setFileList] = useState(value || []);

   const handleOnChange = ({ fileList }: any) => {
      onChange && onChange(fileList);
      setFileList(fileList);
   };

   useEffect(() => {
      if (value && value.length) {
         const fileList = value.map((item: any, index: number) => {
            if (item?.object_url) {
               return {
                  ...item,
                  id: index,
                  url: item.object_url
               };
            } else {
               return item;
            }
         });
         setFileList([...fileList]);
      } else {
         setFileList([]);
      }
   }, [value]);

   const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
         file.preview = await getBase64(file.originFileObj);
      }

      setPreviewVisible(true);
      setPreviewImage(file.url || file.preview);
      setPreviewTitle(
         file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
   };

   const handleCancel = () => {
      setPreviewVisible(false);
   };

   const handleBeforeUpload = () => {
      return false;
   };

   return (
      <>
         <Upload
            fileList={fileList}
            listType="picture-card"
            accept=".png, .jpeg, .jpg"
            multiple={multiple}
            beforeUpload={handleBeforeUpload}
            onPreview={handlePreview}
            onChange={handleOnChange}
            maxCount={maxCount}
         >
            {uploadButton}
         </Upload>
         <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
         >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
         </Modal>
      </>
   );
};
