import React from "react";
import { BuildingFileListItem } from "./BuildingFilesViews";

export const createBuildingFileList = (
   files: any[],
   setReloadList: any,
   refetchBuildingList: Function
) => {
   return files.map((file) => {
      return (
         <BuildingFileListItem
            file={file}
            key={file.id}
            setReloadList={setReloadList}
            refetchBuildingList={refetchBuildingList}
         />
      );
   });
};
