import React, { useEffect, useState } from "react";
import { Select, Input, Form } from "antd";
import useAxios from "axios-hooks";
import { Resource } from "../../../../../../api/common";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";

const CategoriesTreeSearchField: React.FC<any> = ({
   value = {},
   onChange,
   form,
   operationType,
   user,
   setInActive
}) => {
   const [t] = useTranslation();
   const initalValues = {
      childSelect: [],
      childSelected: {}
   };
   const [options, setOptions] = useState<any>(undefined);
   const [searchTerm, setSearchTerm] = useState<any>(value.id);
   const [childSelect, setChildSelect] = useState<any>(
      initalValues.childSelect
   );
   const [childSelected, setChildSelected] = useState<any>(
      initalValues.childSelected
   );
   const [currentUser, setCurrentUser] = useState<any>(user);
   useEffect(() => {
      if (currentUser) {
         console.log("currentUser", currentUser);
         const { attributes } = currentUser;
         executeGet({
            url: `${Resource.path.getCategoriesByTreeId}/${
               attributes["custom:category_tree_types"] ||
               (process.env.REACT_APP_ENVIRONMENT === "dev"
                  ? "2ccb4a35-3b82-433b-859a-7d5e907c8c08"
                  : "28680da1-41a1-494e-8cd8-ea08b267e463")
            }`
         });
      } else {
         Auth.currentUserInfo().then((user) => {
            setCurrentUser(user);
         });
         executeGet({
            url:
               process.env.REACT_APP_ENVIRONMENT === "dev"
                  ? `${Resource.path.getCategoriesByTreeId}/2ccb4a35-3b82-433b-859a-7d5e907c8c08`
                  : `${Resource.path.getCategoriesByTreeId}/28680da1-41a1-494e-8cd8-ea08b267e463`
         });
      }
   }, [currentUser]);

   const [{ data, loading: loadingCategories, error: getError }, executeGet] =
      useAxios(
         {
            method: "GET"
         },
         { manual: true, useCache: false }
      );

   const addChilds = (item: any, reset: boolean = false) => {
      if (reset) {
         setChildSelect(initalValues.childSelect);
         setChildSelected(initalValues.childSelected);
      }
      if (item && item.children) {
         setChildSelect((childSelect: any) => [...childSelect, item]);
         const tempSelected = reset ? {} : { ...childSelected };
         if (tempSelected[item.id]) {
            delete tempSelected[item.id];
         }
         setChildSelected(tempSelected);
         if (form && form.getFieldValue("categories")) {
            const categories = form.getFieldValue("categories");
            if (categories[item.id]) {
               delete categories[item.id];
            }
         }
      } else {
         let indexValue = -1;
         childSelect.forEach((rec: any, index: any) => {
            if (rec.id === item.parent_id && indexValue === -1) {
               indexValue = index;
            }
         });
         if (indexValue !== -1) {
            const temp = childSelect.filter((rec: any, index: any) => {
               return index <= indexValue;
            });
            setChildSelect([...temp]);
         }
      }
   };

   const addChildsAll = (item: any) => {
      const { id } = item;
      const categories = form.getFieldValue("categories");
      if (item && item.children && categories[id]) {
         setChildSelect((childSelect: any) => [...childSelect, item]);
         item.children.forEach((child: any) => {
            addChildsAll(child);
         });
      }
   };

   useEffect(() => {
      if (data?.data.treeData) {
         const categoryData: any = [];
         data?.data.treeData.forEach((parentData: any) => {
            categoryData.push({
               label: parentData.name,
               value: parentData.value
            });
         });
         setOptions(categoryData);
         if (data?.data?.inActive) {
            setInActive(data?.data?.inActive);
         }
      }
      if (
         form &&
         form.getFieldValue("categories") &&
         !searchTerm &&
         data?.data.treeData
      ) {
         const categories = form.getFieldValue("categories");
         const item = data?.data.treeData.filter((records: any) => {
            return records.id === categories["root"];
         });
         if (item && item[0]) {
            addChildsAll(item[0]);
         }
         setSearchTerm(categories["root"]);
         const { id, name } = form.getFieldValue("category");
         onChange &&
            onChange({
               id,
               name,
               dropDownId: categories.length > 1 ? id : "category",
               reset: false
            });
      }
      //  console.log(form.getFieldsValue())
   }, [data, searchTerm]);

   const onValueChange = (key: any) => {
      const item = data?.data.treeData.filter((records: any) => {
         return records.id === key;
      });
      setSearchTerm(key);
      addChilds(item[0], true);
      form.setFieldsValue({ categories: { root: key } });
      form.setFieldsValue({
         category: { id: item[0].value, name: item[0].title }
      });
      onChange &&
         onChange({
            id: item[0].value,
            name: item[0].title,
            dropDownId: "category",
            reset: true
         });
   };

   if (loadingCategories) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <>
         <Form.Item name="category" hidden>
            <Input type="hidden" />
         </Form.Item>
         <Form.Item
            label={t("productDetails.productCategory.category")}
            name={["categories", "root"]}
            rules={[
               {
                  required: true,
                  message: t(
                     "productDetails.productCategory.validations.category"
                  )
               }
            ]}
         >
            <Select
               disabled={operationType === "EDIT"}
               onChange={onValueChange}
               placeholder={t(
                  "productDetails.productCategory.categoryPlaceholder"
               )}
               style={{ width: "100%" }}
               size="large"
               options={options}
               value={searchTerm}
               labelInValue={false}
            />
         </Form.Item>
         {childSelect &&
            childSelect.map((rec: any, index: Number) => {
               const { children, id } = rec;
               const options = children.map((child: any) => {
                  return { label: child.title, value: child.value };
               });
               return (
                  <Form.Item
                     label={t(
                        "productDetails.productCategory.subcategory.title"
                     )}
                     key={id}
                     name={["categories", String(id)]}
                     fieldKey={["categories", String(id)]}
                     rules={[
                        {
                           required: true,
                           message: t(
                              "productDetails.productCategory.subcategory.error"
                           )
                        }
                     ]}
                  >
                     <Select
                        labelInValue={false}
                        key={id}
                        value={childSelected[id]}
                        options={options}
                        onChange={(key: any) => {
                           const item = children.filter((rec: any) => {
                              return rec.id === key;
                           });
                           addChilds(item[0]);
                           childSelected[id] = key;
                           setChildSelected(childSelected);
                           form.setFieldsValue({
                              category: {
                                 id: item[0].value,
                                 name: item[0].title
                              }
                           });
                           onChange &&
                              onChange({
                                 id: item[0].value,
                                 name: item[0].title,
                                 dropDownId: id,
                                 reset: true
                              });
                        }}
                     />
                  </Form.Item>
               );
            })}
      </>
   );
};

export default CategoriesTreeSearchField;
