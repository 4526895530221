import { PageHeader } from "antd";
import React, { useState, useEffect } from "react";
import { ProductDetails } from "./ProductDetails";

import "./style.scss";
import { useMaterialWizard } from "../../../contexts/materialWizard/index";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const AddMaterialWizard = () => {
   const { pathname, search }: any = useLocation();
   const history = useHistory();
   const { id }: any = useParams();
   const [forceUpdateFlag, setForceUpdateFlag] = useState(0);

   const [t] = useTranslation();
   const currentStatePath = pathname.split("/").pop();

   let {
      wizard_steps_product_details,
      wizard_steps_passport_details,
      wizard_steps_reuse_details,
      resetMaterialPassportWizard
   } = useMaterialWizard();

   console.log({
      wizard_steps_product_details,
      wizard_steps_passport_details,
      wizard_steps_reuse_details
   });

   useEffect(() => {
      if (
         wizard_steps_product_details === null &&
         currentStatePath !== "product_details"
      ) {
         history.replace(
            `/buildings/${id}/material_inventory/add/product_details?state=new`
         );
      }
   }, [wizard_steps_product_details]);

   useEffect(() => {
      if (search.indexOf("state=new") !== -1) {
         resetMaterialPassportWizard();
         if (forceUpdateFlag === 0) {
            setForceUpdateFlag(1);
         }
      }
   }, [search]);

   const navigateBack = () => {
      history.push(`/buildings/${id}/material_inventory`);
   };

   return (
      <div className="form-wrap material-inventory-wizard-wrapper wrapper-style blackborder">
         <div className="form-wrap-inner">
            <PageHeader
               title={t("common.back")}
               onBack={navigateBack}
            ></PageHeader>

            <div className="steps-wrapper-content">
               <Switch>
                  <Route
                     path={`/buildings/:id/material_inventory/add/product_details`}
                  >
                     <ProductDetails
                        key={forceUpdateFlag}
                        initialValues={wizard_steps_product_details}
                        pageTitle={t("productDetails.addMaterialTitle")}
                     />
                  </Route>
               </Switch>
            </div>
         </div>
      </div>
   );
};
