import { Auth } from "aws-amplify";
import axios, { AxiosInstance } from "axios";

let axiosInstance: any = null;
export const getAxiosInstance = async () => {
   if (axiosInstance) {
      return axiosInstance as AxiosInstance;
   } else {
      const accessToken = (await Auth.currentSession()).getIdToken();

      axiosInstance = axios.create();
      axiosInstance.defaults.headers = {
         ...axiosInstance.defaults.headers,
         Authorization: accessToken.getJwtToken()
      };
      axiosInstance.defaults.timeout = 900000;
      return axiosInstance as AxiosInstance;
   }
};
