import { Auth } from "aws-amplify";
import Axios from "axios";
import { Resource } from "../../api/common";

export const getOrganizationsDetails = async (listData: any[]) => {
   const accessToken = (await Auth.currentSession()).getIdToken();
   let result: any[] = [];
   const promiseList = listData.map(async (id: any) => {
      const response = await Axios.get(
         Resource.path.organization.getOrganizationDetails(id),
         { headers: { Authorization: accessToken.getJwtToken() } }
      );
      const usersResponse = await Axios.get(
         Resource.path.organization.getUsers(id),
         {
            headers: { Authorization: accessToken.getJwtToken() }
         }
      );
      const data = response?.data?.data;
      if (data) {
         data.users = usersResponse?.data?.data || [];
         result.push(data);
      }
   });
   await Promise.all(promiseList);
   return result;
};
