import React from "react";
import { IfcViewerAPI } from "web-ifc-viewer";
import { ProductGroupMap } from "../types";
import { IfcPropertySingleValue, IfcRootObject } from "./types";
import {
   productGroupApi,
   PagedProductGroupOUT,
   IFCFileOUT,
   IFCObjectIN,
   IFCObjectOUT
} from "../api-client";

const IfcViewerContext = React.createContext<{
   viewer?: IfcViewerAPI;
   setViewer?: (value: IfcViewerAPI) => void;
   setModel?: (value: any) => void;
   model?: any;
   propertySingleValueMap?: IfcPropertySingleValueMap;
   rootObjectsKeyed?: RootObjectsByTypeMap;
   productGroups?: PagedProductGroupOUT;
   setMappingMap?: (current: MappingMap) => void;
   mappingMap?: MappingMap;
   currentFile?: File;
   setCurrentFile?: (current: File | undefined) => void;
   currentIfcFile?: IFCFileOUT;
   setCurrentIfcFile?: (current: IFCFileOUT | undefined) => void;
   parsedData?: GrouppedObjects;
   setParsedData?: (current: GrouppedObjects | undefined) => void;
}>({});

export type GrouppedObjects = {
   [key: string]: (IFCObjectIN | IFCObjectOUT)[];
};

export type MappingMap = {
   [key: string]: {
      productGroupId: string;
      mappings?: ProductGroupMap;
   };
};

export function useIfcViewer() {
   return React.useContext(IfcViewerContext);
}

export type RootObjectsByTypeMap = {
   [key: string]: RootObjectMap;
};

type RootObjectMap = {
   [key: string]: IfcRootObject;
};

export type IfcPropertySingleValueMap = {
   [key: string]: { [key: string]: IfcPropertySingleValue[] };
};

export type AddProductGroupParameters = {
   key: string;
   value: string;
   fileId: string;
   objectType: string;
   propertySource: "properties" | "productGroups";
};

export function IfcViewerProvider({ children }: { children: React.ReactNode }) {
   const [viewer, setViewer] = React.useState<IfcViewerAPI>();
   const [model, setModel] = React.useState<any>();
   const [currentFile, setCurrentFile] = React.useState<File>();
   const [currentIfcFile, setCurrentIfcFile] = React.useState<IFCFileOUT>();
   const [parsedData, setParsedData] = React.useState<GrouppedObjects>();

   const [productGroups, setProductGroups] =
      React.useState<PagedProductGroupOUT>();

   React.useEffect(() => {
      productGroupApi
         .djIfcBaseApiV1RoutersProductsGetAllProductGroups()
         .then(function (response) {
            setProductGroups(response.data);
         });
   }, []);

   const [mappingMap, setMappingMap] = React.useState<MappingMap>();

   const value = {
      viewer,
      setViewer,
      model,
      setModel,
      productGroups,
      setMappingMap,
      mappingMap,
      currentFile,
      setCurrentFile,
      currentIfcFile,
      setCurrentIfcFile,
      parsedData,
      setParsedData
   };
   return (
      <IfcViewerContext.Provider value={value}>
         {children}
      </IfcViewerContext.Provider>
   );
}
