import {
   // Button,
   Col,
   DatePicker,
   Form,
   Input,
   Radio,
   Row,
   // Select,
   PageHeader,
   AutoComplete,
   Spin,
   Select
} from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ImageUploader } from "../../../components/ImageUploader";
import { useForm } from "antd/lib/form/Form";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
import {
   BuildingProvider,
   useBuilding
} from "../../../contexts/building/index";
// import "./style.scss";
import { RadioChangeEvent } from "antd/lib/radio";
import { submitBuildingForm } from "./submitBuildingForm";
import TextArea from "antd/lib/input/TextArea";
import { useHistory, useLocation, useParams } from "react-router";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Text from "antd/lib/typography/Text";
import { useAuth } from "../../../contexts/auth/index";
import ButtonField from "../../../components/FormFields/ButtonField";
import styles from "./AddEditBuildingForm.module.scss";
import DropdownField from "../../../components/FormFields/DropdownField";
import InputField from "../../../components/FormFields/InputField";
import { ScreenSize } from "../../../utils/screenSizes";

enum BuildingStatus {
   "none" = "none",
   "planned" = "planned",
   "existing" = "existing"
}

const AddEditBuilding = () => {
   const { id }: any = useParams(); // If id is available means it's edit building screen
   const [form] = useForm();
   const [loading, updateLoading] = useState(false);
   const [buildingDetails, setBuildingDetails] = useState<any>({
      user_id: ""
   });
   const [buildingStatus, updateBuildingStatus] = useState<any>("planned");
   const { refetchBuildings } = useBuilding();
   const { buildingDataLoading, getBuildingDetail } = useBuilding();
   const history = useHistory();
   const location = useLocation();
   const [t] = useTranslation();
   const [organisationId, setOrganisationId] = useState<string>();
   const [operationType, setOperationType] = useState<string>("none");
   const [startDateConstruction, setStartDateConstruction] = useState<string>();
   const [startDateDeconstruction, setStartDateDeconstruction] =
      useState<string>();
   const [endDateConstruction, setEndDateConstruction] = useState<string>();
   const [endDateDeconstruction, setEndDateDeconstruction] = useState<string>();
   const [locationDropDownOpen, setLocationDropDownOpen] = useState<any>(false);
   const [valueLocation, setValueLocation] = useState<any>();
   const [locations, setLocations] = useState<any>();
   const [isNewLocation, setIsNewLocation] = useState<any>(false);
   const [locationAPI, setLocationAPI] = useState<any>([]);
   const [selectedLocation, setSelectedLocation] = useState<any>();
   const [locationsOrg, setLocationsOrg] = useState<any>();
   const { joinedOrganizationsList, user: currentUser } = useAuth();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";

   const [{ data: dataLocations, loading: dataLocationsLoading }] = useAxios(
      {
         url: `${Resource.path.getLocationsDemandHub}`,
         method: "GET"
      },
      { useCache: false, manual: false }
   );

   useEffect(() => {
      if (!id) {
         form.resetFields();

         // Clear the other or Fields value
         updateBuildingStatus(BuildingStatus.planned);
         form.resetFields([
            "upcoming_activities",
            "expected_start_of_construction",
            "construction_year",
            "expected_date_of_construction"
         ]);
      } else {
         if (!buildingDataLoading) {
            const buildingDetails = getBuildingDetail(id);
            setBuildingDetails(buildingDetails);
            form.setFieldsValue({ ...buildingDetails });

            if (buildingDetails) {
               if (buildingDetails?.locations_id) {
                  setSelectedLocation(buildingDetails?.locations_id);
               }
               console.log("buildingDetails", buildingDetails);
               if (buildingDetails.is_planned) {
                  updateBuildingStatus(BuildingStatus.planned);
                  form.setFieldsValue({
                     expected_date_of_construction: moment(
                        buildingDetails.expected_date_of_construction
                     )
                  });
               } else if (buildingDetails.is_existing_building) {
                  updateBuildingStatus(BuildingStatus.existing);
                  form.setFieldsValue({
                     expected_start_of_construction: moment(
                        buildingDetails.expected_start_of_construction
                     )
                  });
                  form.setFieldsValue({
                     construction_year: moment(
                        buildingDetails.construction_year
                     )
                  });
               }
               if (buildingDetails?.building_images) {
                  form.setFieldsValue({
                     buildingImages: buildingDetails.building_images
                  });
               }
            }
         }
      }
      console.log("buildingDetails", buildingDetails);
      if (dataLocations?.data && dataLocations?.data) {
         const temp = dataLocations?.data.map((value: any) => {
            const { id, name } = value;
            return {
               value: `existing_${id}`,
               label: name
            };
         });
         setLocations(temp);
         setLocationsOrg(temp);
      }
   }, [id, buildingDetails, buildingDataLoading, dataLocations]);

   const [{ data, loading: postLoading, error: postError }, executePost] =
      useAxios(
         {
            url: Resource.path.createBuilding,
            method: "POST",
            headers: {
               "X-Custom-Has-Operation": true
            }
         },
         {
            manual: true,
            useCache: true
         }
      );

   const [{ data: putData, loading: putLoading, error: putError }, executePut] =
      useAxios(
         {
            url: `${Resource.path.updateBuilding}/${id}`,
            method: "PUT"
         },
         {
            manual: true,
            useCache: true
         }
      );

   const handleFileChange = (fileList: any) => {
      form.setFieldsValue({ buildingImages: fileList });
   };

   const handlePlannedChange = (e: RadioChangeEvent) => {
      form.setFieldsValue({ is_planned: e.target.checked });
      form.setFieldsValue({ is_existing_building: !e.target.checked });
      updateBuildingStatus(BuildingStatus.planned);

      // Reset the other or Fields value
      form.resetFields([
         "upcoming_activities",
         "expected_start_of_construction",
         "construction_year"
      ]);
   };

   const handleExistingBuildingChange = (e: RadioChangeEvent) => {
      form.setFieldsValue({ is_planned: !e.target.checked });
      form.setFieldsValue({ is_existing_building: e.target.checked });
      updateBuildingStatus(BuildingStatus.existing);
      form.resetFields(["expected_date_of_construction"]);
   };

   const handleOnSubmit = submitBuildingForm(
      form,
      updateLoading,
      executePost,
      executePut,
      refetchBuildings,
      id,
      history,
      t,
      buildingDetails?.user_id,
      isNewLocation ? selectedLocation : {},
      isNewLocation,
      !isNewLocation ? selectedLocation : null
   );

   const [
      { data: dataPlaces, loading: getPlaces },
      findPlace,
      cancelRequestLocation
   ] = useAxios(
      {
         url: `${Resource.path.getLocationsHub}`,
         method: "POST"
      },
      { useCache: false, manual: true }
   );

   useEffect(() => {
      if (dataPlaces?.data && dataPlaces?.data?.locations) {
         setLocationDropDownOpen(true);
      }
   }, [dataPlaces]);

   if (postLoading || putLoading || getPlaces || dataLocationsLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <div
         className={`form-wrap ${styles.addBuilding} wrapper-style blackborder`}
      >
         <Row>
            {ScreenSize.mobile ? (
               <h1 className="responsivePageTitle">
                  {t("buildingAddEditForm.addBuilding")}
               </h1>
            ) : null}

            <Col xl={6} lg={4} xs={24}>
               <ButtonField
                  type="link"
                  onClick={() => window.history.back()}
                  icon={<ArrowLeftOutlined />}
                  className={styles.backButton}
               >
                  {t("buildingAddEditForm.goBack")}
               </ButtonField>
            </Col>
            <Col xl={11} lg={15} xs={24} className={styles.addForm}>
               {ScreenSize.desktop && (
                  <PageHeader
                     ghost={false}
                     // title={t("common.back")}
                     // onBack={() => window.history.back()}
                  >
                     <div className="page-title">
                        <h1>{t("buildingAddEditForm.addBuilding")}</h1>
                     </div>
                  </PageHeader>
               )}
               <div className={styles.formStyles}>
                  <Form
                     layout="vertical"
                     name="addEditBuilding_form"
                     onFinish={handleOnSubmit}
                     form={form}
                     size="large"
                     initialValues={{
                        upcoming_activities: "none",
                        organisation_id: location?.state?.organizationId
                     }}
                  >
                     <Row>
                        <Col xs={24}>
                           {/* <h3>{"Gebäudedaten"}</h3> */}
                           <div className={styles.section}>
                              <Row gutter={12}>
                                 <Col className={styles.sectionTitle}>
                                    {t("buildingAddEditForm.item.owner")}
                                 </Col>
                              </Row>
                              {joinedOrganizationsList && (
                                 <Row gutter={12}>
                                    <Col
                                       xs={24}
                                       sm={12}
                                       className={styles.label}
                                    >
                                       {t(
                                          "buildingAddEditForm.item.organization"
                                       )}
                                    </Col>
                                    <Col xs={24} sm={12}>
                                       <DropdownField
                                          fieldName="organisation_id"
                                          required={!isPrivateOwner && !id}
                                          items={joinedOrganizationsList
                                             .filter((rec: any) => {
                                                return rec.owner === "ADMIN";
                                             })
                                             .map((rec: any) => ({
                                                id: rec.organizations_id,
                                                label: rec.name
                                             }))}
                                          onChange={(value: string) => {
                                             setOrganisationId(value);
                                          }}
                                          selected={{
                                             id: location?.state?.organizationId
                                          }}
                                          disabled={
                                             buildingDetails?.organisation_id &&
                                             id
                                          }
                                          size="large"
                                          fieldErrorMessage={t(
                                             "buildingAddEditForm.errorMessageOrganization"
                                          )}
                                       />
                                    </Col>
                                 </Row>
                              )}
                           </div>
                           <div className={styles.section}>
                              <Row gutter={12}>
                                 <Col className={styles.sectionTitle}>
                                    {t(
                                       "buildingAddEditForm.item.buildingDetails"
                                    )}
                                 </Col>
                              </Row>
                              <Row gutter={12} className={styles.row}>
                                 <Col xs={24} sm={12} className={styles.label}>
                                    {t("buildingAddEditForm.item.buildingName")}
                                 </Col>
                                 <Col xs={24} sm={12}>
                                    <InputField
                                       required
                                       fieldName="name"
                                       // noMargin
                                       size="large"
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={12} className={styles.row}>
                                 <Col xs={24} sm={12} className={styles.label}>
                                    {t(
                                       "buildingAddEditForm.item.placeholder.buildingType"
                                    )}
                                 </Col>
                                 <Col xs={24} sm={12}>
                                    <Form.Item
                                       // label={t(
                                       //    "buildingAddEditForm.item.typeOfBuilding"
                                       // )}
                                       rules={[{ required: true }]}
                                       name="type"
                                    >
                                       <Select
                                          placeholder={t(
                                             "buildingAddEditForm.item.placeholder.buildingType"
                                          )}
                                          allowClear
                                       >
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.residential"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.residential"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.private"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.private"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.commercialHotel"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.commercialHotel"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.commercialMall"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.commercialMall"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.commercialOffice"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.commercialOffice"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicSchool"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicSchool"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicUniversity"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicUniversity"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicMuseum"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicMuseum"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicHospital"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicHospital"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicMunicipalityBuilding"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicMunicipalityBuilding"
                                             )}
                                          </Select.Option>
                                       </Select>
                                    </Form.Item>
                                    {/* <DropdownField
                                       required
                                       fieldName="type"
                                       items={[
                                          {
                                             id: 1,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.residential"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.residential"
                                             )!
                                          },
                                          {
                                             id: 2,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.private"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.private"
                                             )!
                                          },
                                          {
                                             id: 3,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.commercialHotel"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.commercialHotel"
                                             )!
                                          },
                                          {
                                             id: 4,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.commercialMall"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.commercialMall"
                                             )!
                                          },
                                          {
                                             id: 5,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.commercialOffice"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.commercialOffice"
                                             )!
                                          },
                                          {
                                             id: 6,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.publicSchool"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.publicSchool"
                                             )!
                                          },
                                          {
                                             id: 7,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.publicUniversity"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.publicUniversity"
                                             )!
                                          },
                                          {
                                             id: 8,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.publicMuseum"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.publicMuseum"
                                             )!
                                          },
                                          {
                                             id: 9,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.publicHospital"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.publicHospital"
                                             )!
                                          },
                                          {
                                             id: 10,
                                             label: t(
                                                "staticDropdown.typeOfBuilding.publicMunicipalityBuilding"
                                             ),
                                             value: t(
                                                "staticDropdown.typeOfBuilding.publicMunicipalityBuilding"
                                             )!
                                          }
                                       ]}
                                       noMargin
                                       size="large"
                                    /> */}

                                    {/* <Form.Item
                                       // label={t(
                                       //    "buildingAddEditForm.item.typeOfBuilding"
                                       // )}
                                       rules={[{ required: true }]}
                                       name="type"
                                    >
                                       <Select
                                          placeholder={t(
                                             "buildingAddEditForm.item.placeholder.buildingType"
                                          )}
                                          allowClear
                                       >
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.residential"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.residential"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.private"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.private"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.commercialHotel"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.commercialHotel"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.commercialMall"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.commercialMall"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.commercialOffice"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.commercialOffice"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicSchool"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicSchool"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicUniversity"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicUniversity"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicMuseum"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicMuseum"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicHospital"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicHospital"
                                             )}
                                          </Select.Option>
                                          <Select.Option
                                             value={
                                                t(
                                                   "staticDropdown.typeOfBuilding.publicMunicipalityBuilding"
                                                )!
                                             }
                                          >
                                             {t(
                                                "staticDropdown.typeOfBuilding.publicMunicipalityBuilding"
                                             )}
                                          </Select.Option>
                                       </Select>
                                    </Form.Item> */}
                                 </Col>
                              </Row>
                           </div>
                           <div className={styles.section}>
                              <Row gutter={12}>
                                 <Col className={styles.sectionTitle}>
                                    {t("buildingAddEditForm.item.currentState")}
                                 </Col>
                              </Row>

                              {!id && (
                                 <Row gutter={12} className={styles.stateState}>
                                    <Col lg={12} xs={24}>
                                       <Radio.Group
                                          defaultValue="is_planned"
                                          buttonStyle="solid"
                                       >
                                          <Radio.Button
                                             onChange={(e) => {
                                                console.log(e);
                                                handlePlannedChange(e);
                                             }}
                                             checked={
                                                buildingStatus === "planned"
                                             }
                                             name="is_planned"
                                             value="is_planned"
                                          >
                                             {t(
                                                "buildingAddEditForm.item.planned"
                                             )}
                                          </Radio.Button>
                                          <Radio.Button
                                             onChange={(e) =>
                                                handleExistingBuildingChange(e)
                                             }
                                             checked={
                                                buildingStatus === "existing"
                                             }
                                             name="is_existing_building"
                                             value="is_existing_building"
                                          >
                                             {t(
                                                "buildingAddEditForm.item.existingBuilding"
                                             )}
                                          </Radio.Button>
                                       </Radio.Group>
                                       {/* <div style={{ display: "none" }}>
                                          <Col>
                                             <Form.Item name="is_planned">
                                                <Radio
                                                   onChange={
                                                      handlePlannedChange
                                                   }
                                                   checked={
                                                      buildingStatus ===
                                                      "planned"
                                                   }
                                                >
                                                   {t(
                                                      "buildingAddEditForm.item.planned"
                                                   )}
                                                </Radio>
                                             </Form.Item>
                                          </Col>
                                          <Col>
                                             <Form.Item name="is_existing_building">
                                                <Radio
                                                   onChange={
                                                      handleExistingBuildingChange
                                                   }
                                                   checked={
                                                      buildingStatus ===
                                                      "existing"
                                                   }
                                                >
                                                   {t(
                                                      "buildingAddEditForm.item.existingBuilding"
                                                   )}
                                                </Radio>
                                             </Form.Item>
                                          </Col>
                                       </div> */}
                                    </Col>
                                    <Col lg={12} xs={24}>
                                       <div
                                          style={{
                                             visibility: "hidden",
                                             height: 0
                                          }}
                                       >
                                          <Form.Item name="is_planned"></Form.Item>
                                          <Form.Item name="is_existing_building"></Form.Item>
                                       </div>
                                    </Col>
                                 </Row>
                              )}
                              {!id && (
                                 <Row gutter={12}>
                                    {buildingStatus === "planned" && (
                                       <>
                                          <Col
                                             xs={24}
                                             sm={12}
                                             className={`${styles.label} ${styles.plannedErrection}`}
                                          >
                                             {t(
                                                "operations.form.plannedErection"
                                             )}
                                          </Col>
                                          <Col xs={24} sm={12}>
                                             <Form.Item
                                                name="start_date_construction"
                                                rules={[
                                                   ({ getFieldValue }) => ({
                                                      validator: (
                                                         _rule,
                                                         value
                                                      ) => {
                                                         const isPlanned =
                                                            getFieldValue(
                                                               "is_planned"
                                                            );
                                                         if (
                                                            value ||
                                                            !isPlanned
                                                         )
                                                            return Promise.resolve();
                                                         return Promise.reject(
                                                            t(
                                                               "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                            )
                                                         );
                                                      }
                                                   })
                                                ]}
                                             >
                                                <DatePicker
                                                   picker="month"
                                                   onChange={(value: any) => {
                                                      setStartDateConstruction(
                                                         value
                                                      );
                                                   }}
                                                   style={{ width: "100%" }}
                                                />
                                             </Form.Item>
                                          </Col>
                                       </>
                                    )}
                                    {buildingStatus === "existing" && (
                                       <Col xs={24}>
                                          <Row
                                             className={styles.row}
                                             style={{ paddingBottom: 0 }}
                                          >
                                             <Col
                                                xs={24}
                                                sm={12}
                                                className={`${styles.label} ${styles.yearOfContruction}`}
                                             >
                                                {t(
                                                   "buildingAddEditForm.item.yearOfConstruction"
                                                )}
                                             </Col>
                                             <Col xs={24} sm={12}>
                                                <Form.Item
                                                   name="construction_year"
                                                   rules={[
                                                      ({ getFieldValue }) => ({
                                                         validator(
                                                            _rule,
                                                            value
                                                         ) {
                                                            const isExistingBuilding =
                                                               getFieldValue(
                                                                  "is_existing_building"
                                                               );
                                                            if (
                                                               value ||
                                                               !isExistingBuilding
                                                            )
                                                               return Promise.resolve();
                                                            return Promise.reject(
                                                               t(
                                                                  "buildingAddEditForm.item.upcomingActivitiesErrMessage"
                                                               )
                                                            );
                                                         }
                                                      })
                                                   ]}
                                                >
                                                   <DatePicker
                                                      picker="year"
                                                      style={{ width: "100%" }}
                                                   />
                                                </Form.Item>
                                             </Col>
                                          </Row>
                                          <Row
                                             gutter={12}
                                             className={styles.row}
                                             style={{ marginTop: 10 }}
                                          >
                                             <Col
                                                xs={24}
                                                className={styles.topLabel}
                                             >
                                                {t(
                                                   "buildingAddEditForm.item.upcomingOperation"
                                                )}
                                             </Col>
                                             <Col xs={24}>
                                                <Form.Item
                                                   // label={t(
                                                   //    "buildingAddEditForm.item.upcomingActivities"
                                                   // )}
                                                   name="upcoming_activities"
                                                   rules={[
                                                      ({ getFieldValue }) => ({
                                                         validator(
                                                            _rule,
                                                            value
                                                         ) {
                                                            const isExistingBuilding =
                                                               getFieldValue(
                                                                  "is_existing_building"
                                                               );
                                                            if (
                                                               value ||
                                                               !isExistingBuilding
                                                            )
                                                               return Promise.resolve();
                                                            return Promise.reject(
                                                               t(
                                                                  "buildingAddEditForm.item.upcomingActivitiesErrMessage"
                                                               )
                                                            );
                                                         }
                                                      })
                                                   ]}
                                                >
                                                   <Radio.Group
                                                      defaultValue="none"
                                                      buttonStyle="solid"
                                                   >
                                                      <Radio.Button
                                                         onChange={(e: any) => {
                                                            console.log(
                                                               e.target.value,
                                                               "Vali3"
                                                            );

                                                            setOperationType(
                                                               e.target.value
                                                            );
                                                         }}
                                                         value={"none"}
                                                         checked={
                                                            operationType ==
                                                            "none"
                                                         }
                                                      >
                                                         {t(
                                                            "staticDropdown.upcomingActivities.none"
                                                         )}
                                                      </Radio.Button>
                                                      <Radio.Button
                                                         onChange={(e: any) => {
                                                            console.log(
                                                               e.target.value,
                                                               "Valie"
                                                            );
                                                            setOperationType(
                                                               e.target.value
                                                            );
                                                         }}
                                                         checked={
                                                            operationType ==
                                                            "construction"
                                                         }
                                                         value="construction"
                                                      >
                                                         {`${t(
                                                            "staticDropdown.upcomingActivities.renovation"
                                                         )}${
                                                            window.innerWidth >
                                                            768
                                                               ? `/${t(
                                                                    "staticDropdown.upcomingActivities.extension"
                                                                 )}`
                                                               : ""
                                                         }`}
                                                      </Radio.Button>
                                                      <Radio.Button
                                                         onChange={(e: any) => {
                                                            console.log(
                                                               e.target.value,
                                                               "Vali2"
                                                            );

                                                            setOperationType(
                                                               e.target.value
                                                            );
                                                         }}
                                                         checked={
                                                            operationType ==
                                                            "deconstruction"
                                                         }
                                                         name="is_existing_building"
                                                         value="deconstruction"
                                                      >
                                                         {t(
                                                            "staticDropdown.upcomingActivities.deconstruction"
                                                         )}
                                                      </Radio.Button>
                                                   </Radio.Group>
                                                   {/* 
                                                   <Select
                                                      onChange={(
                                                         value: string
                                                      ) => {
                                                         setOperationType(
                                                            value
                                                         );
                                                      }}
                                                      placeholder={t(
                                                         "buildingAddEditForm.item.placeholder.upcomingActivities"
                                                      )}
                                                      allowClear
                                                   >
                                                      <Select.Option
                                                         value={"construction"}
                                                      >
                                                         {t(
                                                            "staticDropdown.upcomingActivities.construction"
                                                         )}
                                                      </Select.Option>
                                                      <Select.Option
                                                         value={
                                                            "deconstruction"
                                                         }
                                                      >
                                                         {t(
                                                            "staticDropdown.upcomingActivities.deconstruction"
                                                         )}
                                                      </Select.Option>
                                                      <Select.Option
                                                         value={"none"}
                                                      >
                                                         {t(
                                                            "staticDropdown.upcomingActivities.none"
                                                         )}
                                                      </Select.Option>
                                                   </Select> */}
                                                </Form.Item>
                                             </Col>

                                             <>
                                                {(operationType ===
                                                   "renovation" ||
                                                   operationType ===
                                                      "extension") && (
                                                   <>
                                                      <Row gutter={12}>
                                                         <Col>
                                                            <Input.Group
                                                               compact
                                                            >
                                                               <Form.Item
                                                                  rules={[
                                                                     () => ({
                                                                        validator:
                                                                           (
                                                                              _rule,
                                                                              value
                                                                           ) => {
                                                                              if (
                                                                                 value
                                                                              )
                                                                                 return Promise.resolve();
                                                                              return Promise.reject(
                                                                                 t(
                                                                                    "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                                                 )
                                                                              );
                                                                           }
                                                                     })
                                                                  ]}
                                                                  label={t(
                                                                     "operations.form.expectedStartOfDeconstruction"
                                                                  )}
                                                                  name="start_date_deconstruction"
                                                               >
                                                                  <DatePicker
                                                                     picker="month"
                                                                     onChange={(
                                                                        value: any
                                                                     ) => {
                                                                        setStartDateDeconstruction(
                                                                           value
                                                                        );
                                                                     }}
                                                                  />
                                                               </Form.Item>

                                                               <Form.Item
                                                                  rules={[
                                                                     () => ({
                                                                        validator:
                                                                           (
                                                                              _rule,
                                                                              value
                                                                           ) => {
                                                                              if (
                                                                                 value
                                                                              ) {
                                                                                 if (
                                                                                    startDateDeconstruction &&
                                                                                    value <
                                                                                       startDateDeconstruction
                                                                                 ) {
                                                                                    return Promise.reject(
                                                                                       t(
                                                                                          "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                                                       )
                                                                                    );
                                                                                 }

                                                                                 return Promise.resolve();
                                                                              }
                                                                              return Promise.reject(
                                                                                 t(
                                                                                    "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                                                 )
                                                                              );
                                                                           }
                                                                     })
                                                                  ]}
                                                                  label={t(
                                                                     "operations.form.expectedEnd"
                                                                  )}
                                                                  name="end_date_deconstruction"
                                                               >
                                                                  <DatePicker
                                                                     picker="month"
                                                                     onChange={(
                                                                        value: any
                                                                     ) => {
                                                                        setEndDateDeconstruction(
                                                                           value
                                                                        );
                                                                     }}
                                                                  />
                                                               </Form.Item>
                                                            </Input.Group>
                                                         </Col>
                                                      </Row>
                                                      <Row gutter={12}>
                                                         <Col>
                                                            <Input.Group
                                                               compact
                                                            >
                                                               <Form.Item
                                                                  rules={[
                                                                     () => ({
                                                                        validator:
                                                                           (
                                                                              _rule,
                                                                              value
                                                                           ) => {
                                                                              if (
                                                                                 value
                                                                              )
                                                                                 return Promise.resolve();
                                                                              return Promise.reject(
                                                                                 t(
                                                                                    "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                                                 )
                                                                              );
                                                                           }
                                                                     })
                                                                  ]}
                                                                  label={t(
                                                                     "operations.form.expectedStartOfConstruction"
                                                                  )}
                                                                  name="start_date_construction"
                                                               >
                                                                  <DatePicker
                                                                     picker="month"
                                                                     onChange={(
                                                                        value: any
                                                                     ) => {
                                                                        setStartDateConstruction(
                                                                           value
                                                                        );
                                                                     }}
                                                                  />
                                                               </Form.Item>

                                                               <Form.Item
                                                                  rules={[
                                                                     () => ({
                                                                        validator:
                                                                           (
                                                                              _rule,
                                                                              value
                                                                           ) => {
                                                                              if (
                                                                                 value
                                                                              ) {
                                                                                 if (
                                                                                    startDateConstruction &&
                                                                                    value <
                                                                                       startDateConstruction
                                                                                 ) {
                                                                                    return Promise.reject(
                                                                                       t(
                                                                                          "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                                                       )
                                                                                    );
                                                                                 }
                                                                                 return Promise.resolve();
                                                                              }
                                                                              return Promise.reject(
                                                                                 t(
                                                                                    "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                                                 )
                                                                              );
                                                                           }
                                                                     })
                                                                  ]}
                                                                  label={t(
                                                                     "operations.form.expectedEnd"
                                                                  )}
                                                                  name="end_date_construction"
                                                               >
                                                                  <DatePicker
                                                                     picker="month"
                                                                     onChange={(
                                                                        value: any
                                                                     ) => {
                                                                        setEndDateConstruction(
                                                                           value
                                                                        );
                                                                     }}
                                                                  />
                                                               </Form.Item>
                                                            </Input.Group>
                                                         </Col>
                                                      </Row>
                                                   </>
                                                )}
                                                {operationType ===
                                                   "deconstruction" && (
                                                   <Row gutter={12}>
                                                      <Col xs={24}>
                                                         <Input.Group compact>
                                                            <Form.Item
                                                               label={t(
                                                                  "buildingAddEditForm.item.estimatedStart"
                                                               )}
                                                               name="start_date_deconstruction"
                                                               rules={[
                                                                  ({
                                                                     getFieldValue
                                                                  }) => ({
                                                                     validator(
                                                                        _rule,
                                                                        value
                                                                     ) {
                                                                        const isExistingBuilding =
                                                                           getFieldValue(
                                                                              "is_existing_building"
                                                                           );

                                                                        const upcomingActivities =
                                                                           getFieldValue(
                                                                              "upcoming_activities"
                                                                           );

                                                                        if (
                                                                           value ||
                                                                           !isExistingBuilding ||
                                                                           upcomingActivities ===
                                                                              t(
                                                                                 "staticDropdown.upcomingActivities.none"
                                                                              )
                                                                        )
                                                                           return Promise.resolve();
                                                                        return Promise.reject(
                                                                           t(
                                                                              "buildingAddEditForm.item.upcomingActivitiesErrMessage"
                                                                           )
                                                                        );
                                                                     }
                                                                  })
                                                               ]}
                                                            >
                                                               <DatePicker
                                                                  picker="month"
                                                                  onChange={(
                                                                     value: any
                                                                  ) => {
                                                                     setStartDateDeconstruction(
                                                                        value
                                                                     );
                                                                  }}
                                                                  style={{
                                                                     width: "100%"
                                                                  }}
                                                               />
                                                            </Form.Item>
                                                            <Form.Item
                                                               rules={[
                                                                  () => ({
                                                                     validator:
                                                                        (
                                                                           _rule,
                                                                           value
                                                                        ) => {
                                                                           if (
                                                                              value
                                                                           ) {
                                                                              if (
                                                                                 startDateDeconstruction &&
                                                                                 value <
                                                                                    startDateDeconstruction
                                                                              ) {
                                                                                 return Promise.reject(
                                                                                    t(
                                                                                       "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                                                    )
                                                                                 );
                                                                              }

                                                                              return Promise.resolve();
                                                                           }
                                                                           return Promise.reject(
                                                                              t(
                                                                                 "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                                              )
                                                                           );
                                                                        }
                                                                  })
                                                               ]}
                                                               label={t(
                                                                  "buildingAddEditForm.item.estimatedEnd"
                                                               )}
                                                               name="end_date_deconstruction"
                                                            >
                                                               <DatePicker
                                                                  picker="month"
                                                                  onChange={(
                                                                     value: any
                                                                  ) => {
                                                                     setEndDateDeconstruction(
                                                                        value
                                                                     );
                                                                  }}
                                                                  style={{
                                                                     width: "100%"
                                                                  }}
                                                               />
                                                            </Form.Item>
                                                         </Input.Group>
                                                      </Col>
                                                   </Row>
                                                )}
                                                {operationType ===
                                                   "construction" && (
                                                   <Row gutter={12}>
                                                      <Col>
                                                         <Form.Item
                                                            label={t(
                                                               "buildingAddEditForm.item.estimatedStart"
                                                            )}
                                                            name="start_date_construction"
                                                            rules={[
                                                               ({
                                                                  getFieldValue
                                                               }) => ({
                                                                  validator(
                                                                     _rule,
                                                                     value
                                                                  ) {
                                                                     const isExistingBuilding =
                                                                        getFieldValue(
                                                                           "is_existing_building"
                                                                        );

                                                                     const upcomingActivities =
                                                                        getFieldValue(
                                                                           "upcoming_activities"
                                                                        );

                                                                     if (
                                                                        value ||
                                                                        !isExistingBuilding ||
                                                                        upcomingActivities ===
                                                                           t(
                                                                              "staticDropdown.upcomingActivities.none"
                                                                           )
                                                                     )
                                                                        return Promise.resolve();
                                                                     return Promise.reject(
                                                                        t(
                                                                           "buildingAddEditForm.item.upcomingActivitiesErrMessage"
                                                                        )
                                                                     );
                                                                  }
                                                               })
                                                            ]}
                                                         >
                                                            <DatePicker
                                                               picker="month"
                                                               onChange={(
                                                                  value: any
                                                               ) => {
                                                                  setStartDateConstruction(
                                                                     value
                                                                  );
                                                               }}
                                                            />
                                                         </Form.Item>
                                                      </Col>
                                                   </Row>
                                                )}
                                             </>
                                          </Row>
                                       </Col>
                                    )}
                                 </Row>
                              )}

                              {!!id &&
                                 buildingDetails &&
                                 buildingDetails?.is_existing_building && (
                                    <Row gutter={12}>
                                       <Col
                                          xs={24}
                                          sm={12}
                                          className={styles.label}
                                       >
                                          {t(
                                             "buildingAddEditForm.item.yearOfConstruction"
                                          )}
                                       </Col>
                                       <Col xs={24} sm={12}>
                                          <Form.Item
                                             name="construction_year"
                                             rules={[
                                                ({}) => ({
                                                   validator(_rule, value) {
                                                      if (value)
                                                         return Promise.resolve();
                                                      return Promise.reject(
                                                         t(
                                                            "buildingAddEditForm.item.upcomingActivitiesErrMessage"
                                                         )
                                                      );
                                                   }
                                                })
                                             ]}
                                          >
                                             <DatePicker picker="year" />
                                          </Form.Item>
                                       </Col>
                                    </Row>
                                 )}
                           </div>
                           {/* <Row gutter={12}>
                              <Col xs={24} sm={12}>
                                 <Form.Item
                                    label={t(
                                       "buildingAddEditForm.item.floorArea"
                                    )}
                                    name="floor_area"
                                 >
                                    <Input addonAfter={t("common.sqm")} />
                                 </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                 <Form.Item
                                    label={t(
                                       "buildingAddEditForm.item.numberOfFloors"
                                    )}
                                    name="number_of_floor"
                                 >
                                    <Input addonAfter={t("common.floors")} />
                                 </Form.Item>
                              </Col>
                           </Row>

                           <Row gutter={12}>
                              <Col xs={24} sm={12}>
                                 <Form.Item
                                    label={t(
                                       "buildingAddEditForm.item.totalArea"
                                    )}
                                    name="total_area"
                                 >
                                    <Input addonAfter={t("common.sqm")} />
                                 </Form.Item>
                              </Col>
                           </Row> */}

                           <div className={styles.section}>
                              <Col className={styles.sectionTitle} xs={24}>
                                 {t("buildingAddEditForm.item.address")}
                              </Col>
                              <Row className={styles.row}>
                                 <Col xs={24} className={styles.topLabel}>
                                    {t(
                                       "buildingAddEditForm.item.searchAndSelectAddress"
                                    )}
                                 </Col>
                                 <Col span={24} xs={24}>
                                    <Form.Item
                                       name="location"
                                       rules={[
                                          { required: true },
                                          ({}) => ({
                                             validator(_, value) {
                                                if (
                                                   selectedLocation ===
                                                      undefined ||
                                                   selectedLocation === null
                                                ) {
                                                   return Promise.reject(
                                                      new Error(
                                                         "Please select valid address"
                                                      )
                                                   );
                                                }
                                                if (value)
                                                   return Promise.resolve();
                                             }
                                          })
                                       ]}
                                    >
                                       <AutoComplete
                                          open={locationDropDownOpen}
                                          notFoundContent={
                                             getPlaces ? <Spin /> : null
                                          }
                                          value={valueLocation}
                                          options={locations}
                                          onSelect={(value) => {
                                             cancelRequestLocation();
                                             setLocationDropDownOpen(false);
                                             const selected = locations.filter(
                                                (rec: any) => {
                                                   return rec.value === value;
                                                }
                                             );

                                             const labelValue =
                                                selected.pop().label;
                                             setValueLocation(labelValue);
                                             const temp = value.split("_");
                                             setIsNewLocation(
                                                temp[0] === "new"
                                             );
                                             if (temp[0] === "new") {
                                                setSelectedLocation(
                                                   locationAPI[temp[1]]
                                                );
                                             } else {
                                                setSelectedLocation(temp[1]);
                                             }

                                             form.setFieldsValue({
                                                location: labelValue
                                             });
                                          }}
                                          onChange={(value) => {
                                             setValueLocation(value);
                                             console.log("value", value);
                                             setLocationDropDownOpen(true);
                                          }}
                                          onSearch={async (
                                             searchText: string
                                          ) => {
                                             setSelectedLocation(null);
                                             cancelRequestLocation();
                                             setLocationDropDownOpen(true);
                                             if (searchText.length === 0) {
                                                setLocations(locationsOrg);
                                             } else if (searchText.length > 0) {
                                                let temp = locationsOrg.filter(
                                                   (rec: any) => {
                                                      return (
                                                         rec?.value &&
                                                         rec?.label
                                                            .toLowerCase()
                                                            .includes(
                                                               searchText.toLowerCase()
                                                            )
                                                      );
                                                   }
                                                );
                                                console.log(temp, locationsOrg);
                                                if (
                                                   temp.length === 0 &&
                                                   searchText.length > 10
                                                ) {
                                                   await findPlace({
                                                      data: {
                                                         address: `${searchText}`
                                                      }
                                                   });
                                                }

                                                if (
                                                   temp.length === 0 &&
                                                   dataPlaces?.data &&
                                                   dataPlaces?.data
                                                      ?.locations &&
                                                   dataPlaces?.data?.locations
                                                      .length > 0
                                                ) {
                                                   const tempLocations =
                                                      dataPlaces?.data?.locations.map(
                                                         (
                                                            rec: any,
                                                            key: any
                                                         ) => {
                                                            if (
                                                               rec &&
                                                               rec?.Label
                                                            ) {
                                                               return {
                                                                  value: `new_${key}`,
                                                                  label: rec?.Label
                                                               };
                                                            }
                                                         }
                                                      );
                                                   console.log(
                                                      "tempLocations",
                                                      tempLocations
                                                   );
                                                   setLocationAPI(
                                                      dataPlaces?.data
                                                         ?.locations
                                                   );
                                                   temp = tempLocations;
                                                }
                                                setLocations(temp);
                                                setLocationDropDownOpen(true);
                                             }
                                          }}
                                          placeholder="Enter location"
                                          onFocus={() => {
                                             setLocationDropDownOpen(true);
                                          }}
                                       ></AutoComplete>
                                    </Form.Item>
                                 </Col>
                              </Row>
                           </div>

                           {/*                 <h3>{"Adresse"}</h3>

                <Row gutter={12}>
                  <Col span={16}>
                    <Form.Item
                      label={t("buildingAddEditForm.item.street")}
                      name="street"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t("buildingAddEditForm.item.houseNumber")}
                      name="house_number"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={8}>
                    <Form.Item
                      label={t("buildingAddEditForm.item.postalCode")}
                      name="postal_code"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={16} sm={8}>
                    <Form.Item
                      label={t("buildingAddEditForm.item.city")}
                      name="city"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8}>
                    <Form.Item
                      label={t("buildingAddEditForm.item.countryName")}
                      name="country"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row> */}

                           <div className={styles.section}>
                              <Col className={styles.sectionTitle} xs={24}>
                                 {t(
                                    "buildingAddEditForm.item.additionalInformation"
                                 )}
                              </Col>

                              <div className={`upload-wrapper ${styles.row}`}>
                                 <Text className={styles.label}>
                                    {t("buildingAddEditForm.item.pictures")}
                                 </Text>

                                 <Form.Item
                                    name="buildingImages"
                                    style={{ marginTop: "10px" }}
                                 >
                                    <ImageUploader
                                       onFileChange={handleFileChange}
                                    ></ImageUploader>
                                 </Form.Item>
                              </div>

                              <Row
                                 className={`${styles.row} ${styles.description}`}
                              >
                                 <Col>
                                    <Text className={styles.topLabel}>
                                       {t(
                                          "buildingAddEditForm.item.description"
                                       )}
                                    </Text>
                                 </Col>
                                 <Col span={24}>
                                    <Form.Item name="description">
                                       <TextArea
                                          placeholder={t(
                                             "buildingAddEditForm.item.placeholder.buildingDescription"
                                          )}
                                          autoSize={{ minRows: 2, maxRows: 6 }}
                                       />
                                    </Form.Item>
                                 </Col>
                              </Row>
                              <div className="d-flex-between">
                                 <ButtonField
                                    type="negative"
                                    size="middle"
                                    onClick={() => window.history.back()}
                                 >
                                    {t("buildingAddEditForm.item.cancel")}
                                 </ButtonField>
                                 <ButtonField
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    size="middle"
                                 >
                                    {t(
                                       "buildingAddEditForm.item.saveBuildingBtn"
                                    )}
                                 </ButtonField>
                              </div>
                           </div>
                        </Col>
                     </Row>
                  </Form>
               </div>
            </Col>
         </Row>
      </div>
   );
};

export const AddEditBuildingForm = () => {
   const { loadingUserInfo, onBoarded, user: currentUser } = useAuth();
   const [t] = useTranslation();
   const history = useHistory();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";
   const [isLoading, setIsLoading] = React.useState<boolean>(true);

   useEffect(() => {
      if (currentUser) {
         setIsLoading(false);
      }
      if (!onBoarded && !loadingUserInfo && !isPrivateOwner) {
         history.push("/onboarding");
      }
   }, [onBoarded, loadingUserInfo, isPrivateOwner, currentUser]);

   if (isLoading)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <BuildingProvider>
         <AddEditBuilding />
      </BuildingProvider>
   );
};
