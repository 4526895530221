import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { AccountPassword } from "./AccountPassword";
import { UserSettings } from "./UserSettings";
import { useTranslation } from "react-i18next";
import { BuildingProvider } from "../../contexts/building";
import OrganizationAccount from "./OrganizationAccount";
import OrganizationUserManagement from "./OrganizationUserManagement";
import { useAuth } from "../../contexts/auth/index";
import { useHistory } from "react-router-dom";
import OrganizationPlansAndBilling from "./OrganizationPlansAndBilling";
import { OrganizationUserSettings } from "./OrganizationUserSettings";
import { Spin, Result, Button } from "antd";
const menuKeys = ["profile", "password"];

export const Settings = () => {
   const location = useLocation();
   const [t] = useTranslation();
   const { joinedOrganizationsList } = useAuth();
   const history = useHistory();
   const shouldSelectKeys =
      menuKeys.find((item) => {
         return location && location.pathname.includes(item) && item;
      }) || "profile";

   const acl = (organisation_id: number, route: string) => {
      if (organisation_id && joinedOrganizationsList) {
         const response = joinedOrganizationsList.filter((rec: any) => {
            return rec.organizations_id == organisation_id;
         });
         // console.log(response)
         if (response.length) {
            if (
               response[0].role === "ADMIN" &&
               response[0].status === "ACCEPTED"
            ) {
               return true;
            } else if (
               response[0].role === "USER" &&
               response[0].status === "ACCEPTED" &&
               route === "user_settings"
            ) {
               return true;
            } else {
               return false;
            }
         } else {
            return false;
         }
      } else {
         return false;
      }
   };

   const UnAuthorized = () => {
      return (
         <Result
            status="403"
            title="403"
            subTitle={t("403.subTitle")}
            extra={
               <Button
                  type="primary"
                  onClick={() => {
                     history.push("/");
                  }}
               >
                  {t("403.homeButton")}
               </Button>
            }
         />
      );
   };

   return (
      <>
         <Switch>
            <Route
               path="/settings/:id/account"
               render={(props) => {
                  return acl(Number(props.match.params.id), "account") ? (
                     <OrganizationAccount />
                  ) : (
                     <UnAuthorized />
                  );
               }}
            ></Route>
            <Route
               path="/settings/:id/user_management"
               render={(props) => {
                  return acl(
                     Number(props.match.params.id),
                     "user_management"
                  ) ? (
                     <OrganizationUserManagement />
                  ) : (
                     <UnAuthorized />
                  );
               }}
            ></Route>
            <Route
               path="/settings/:id/plans_billing"
               render={(props) => {
                  return acl(Number(props.match.params.id), "plans_billing") ? (
                     <OrganizationPlansAndBilling />
                  ) : (
                     <UnAuthorized />
                  );
               }}
            ></Route>
            <Route
               path="/settings/:id/user_settings"
               render={(props) => {
                  console.log(
                     "acl",
                     acl(Number(props.match.params.id), "user_settings")
                  );
                  return acl(Number(props.match.params.id), "user_settings") ? (
                     <OrganizationUserSettings />
                  ) : (
                     <UnAuthorized />
                  );
               }}
            ></Route>
            <Route path="/settings/profile" component={UserSettings}></Route>
            <Route
               path="/settings/password"
               component={AccountPassword}
            ></Route>
         </Switch>
      </>
   );
};

export const SettingsView = () => {
   const { loadingUserInfo, onBoarded, user: currentUser } = useAuth();
   const [t] = useTranslation();
   const history = useHistory();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";
   const [isLoading, setIsLoading] = React.useState<boolean>(true);

   useEffect(() => {
      if (currentUser) {
         setIsLoading(false);
      }
      if (!onBoarded && !loadingUserInfo && !isPrivateOwner) {
         history.push("/onboarding");
      }
   }, [onBoarded, loadingUserInfo, isPrivateOwner, currentUser]);

   if (isLoading)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <BuildingProvider>
         <Settings />
      </BuildingProvider>
   );
};
