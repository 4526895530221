import React, { useEffect } from "react";
import "./style.scss";
import { MaterialWizardProvider } from "../../contexts/materialWizard/index";
import { CatalogueDashboard } from "./Dashboard";
import { Switch, Route } from "react-router-dom";
import { MaterialPassportDetails } from "./Dashboard/MaterialPassportDetails";
//import { MaterialDetails } from "./Dashboard/MaterialDetails";
import { BuildingProvider } from "../../contexts/building";
import { useAuth } from "../../contexts/auth/index";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
export const Catalogue = () => {
   return (
      <MaterialWizardProvider>
         <Switch>
            <Route
               exact
               path={`/catalogue`}
               component={CatalogueDashboard}
            ></Route>
            {/* <Route
          exact
          path={`/catalogue/:id`}
          component={MaterialDetails}
        ></Route> */}
            <Route
               path={`/catalogue/:id/passports/:passport_id`}
               component={MaterialPassportDetails}
            ></Route>
         </Switch>
      </MaterialWizardProvider>
   );
};

export const CatalogueView = () => {
   const { loadingUserInfo, onBoarded, user: currentUser } = useAuth();
   const [t] = useTranslation();
   const history = useHistory();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";
   const [isLoading, setIsLoading] = React.useState<boolean>(true);

   useEffect(() => {
      if (currentUser) {
         setIsLoading(false);
      }
      if (!onBoarded && !loadingUserInfo && !isPrivateOwner) {
         history.push("/onboarding");
      }
   }, [onBoarded, loadingUserInfo, isPrivateOwner, currentUser]);

   if (isLoading)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <BuildingProvider>
         <Catalogue />
      </BuildingProvider>
   );
};
