import React from "react";
import { useTranslation } from "react-i18next";
import ButtonField from "../../../components/FormFields/ButtonField";
import InputField from "../../../components/FormFields/InputField";
import styles from "./CreateOrganization.module.scss";
import { Organization as OrganizationType } from "./../../../types";
import Organization from "./Organization";

interface IProps {
   item: OrganizationType;
   current?: boolean;
   showApplyToAll?: boolean;
   edit?: () => any;
   applyToAll?: (e: string) => any;
   changeTitle: (id?: any, value?: any) => any;
   index?: number;
}

const OrganizationRow = ({
   item,
   current,
   showApplyToAll,
   edit,
   applyToAll,
   changeTitle
}: IProps) => {
   const [t] = useTranslation();
   const handleChangeTitle = (value: any) => {
      changeTitle(item?.id, value);
   };
   return (
      <div className={styles.organization}>
         <div className={styles.organizationRow}>
            <Organization
               logo={(item?.image && item?.image[0]?.object_url) || ""}
               name={item.name}
               type={item.type}
            />
            <div>
               {current && (
                  <ButtonField
                     onClick={edit}
                     type={"link"}
                     className={styles.linkButton}
                  >
                     {t("default.edit")}
                  </ButtonField>
               )}
            </div>
         </div>
         <div className={styles.yourTitle}>
            <InputField
               className={styles.roleTitle}
               value={item.title || ""}
               defaultValue={item.title || ""}
               onChange={handleChangeTitle}
               label={t("onboarding.yourTitle")}
               size="large"
               placeholder={t("onboarding.exampleProductManager")}
               formItem={false}
            />
            {showApplyToAll && (
               <ButtonField
                  onClick={() => applyToAll && applyToAll(item.title || "")}
                  type={"link"}
                  className={styles.applyAll}
               >
                  {t("onboarding.applyToAll")}
               </ButtonField>
            )}
         </div>
      </div>
   );
};

export default OrganizationRow;
