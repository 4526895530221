import React from "react";
import { Form, message, Modal } from "antd";
import styles from "./Settings.module.scss";
import DropdownField from "../../components/FormFields/DropdownField";
import ButtonField from "../../components/FormFields/ButtonField";
import { useTranslation } from "react-i18next";
import { rolesList } from "./consts.d";
import InputField from "../../components/FormFields/InputField";
import { useForm } from "antd/es/form/Form";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { useParams } from "react-router";

interface IProps {
   showModal: boolean;
   handleCancelModal?: (e?: any) => any;
   getUsersAPI?: (e?: any) => any;
   type: "organization" | "building";
   buildingDetails?: any;
}

export const InviteUserModal = ({
   showModal,
   handleCancelModal,
   getUsersAPI,
   type,
   buildingDetails
}: IProps) => {
   const [t] = useTranslation();
   const [form] = useForm();
   const params: { id: string } = useParams();

   const [{ loading: inviteLoading }, inviteUserAPI] = useAxios(
      {
         url: Resource.path.organization.inviteUser,
         method: "POST"
      },
      {
         manual: true
      }
   );

   const [{ loading: loadingInvite }, inviteMember] = useAxios(
      {
         url: `${Resource.path.inviteBuildingMember}`,
         method: "post"
      },
      { manual: true }
   );

   const organizationUserInvite = () => {
      form.submit();
      const data = form.getFieldsValue();
      form.validateFields().then(async (resp) => {
         await inviteUserAPI({
            data: {
               organization_id: +params.id,
               user_role: rolesList
                  .find((item) => item.id == data.role)
                  ?.value.toUpperCase(),
               email: data.email
            }
         })
            .then(async (resp) => {
               getUsersAPI &&
                  (await getUsersAPI().then(() => {
                     message.success(
                        t("settings.organization.invitationSuccessMsg")
                     );
                     form.setFieldsValue({ role: "", email: "" });
                  }));
               form.resetFields();
            })
            .catch((err) => {
               if (err.response.data.message.includes("ER_DUP_ENTRY")) {
                  message.info(t("settings.organization.alreadyExist"));
               } else if (
                  err.response.data.message.includes("User not found!")
               ) {
                  message.error(t("settings.organization.userNotFound"));
               } else {
                  message.error(t("settings.errorMessage"));
               }
            });
      });
   };

   const buildingUserInvite = () => {
      form.submit();
      const data = form.getFieldsValue();
      form.validateFields().then(async () => {
         inviteMember({
            data: {
               email: data.email,
               building_id: buildingDetails.id,
               user_role: rolesList
                  .find((item) => item.id == data.role)
                  ?.value.toUpperCase(),
               original_owner_id: buildingDetails.user_id,
               organisation_id: buildingDetails.organisation_id
            }
         })
            .then(async () => {
               form.resetFields();
               getUsersAPI &&
                  (await getUsersAPI().then(() => {
                     message.success(
                        t("settings.organization.invitationSuccessMsg")
                     );
                     form.setFieldsValue({ role: "", email: "" });
                  }));
            })
            .catch(() => {});
      });
   };

   return (
      <Modal
         visible={showModal}
         onCancel={handleCancelModal}
         width={600}
         title={""}
         footer={null}
         centered
      >
         <Form
            form={form}
            className={styles.inviteUser}
            onFinish={
               type === "organization"
                  ? organizationUserInvite
                  : buildingUserInvite
            }
         >
            <h3 className={styles.title}>
               {t("settings.organization.inviteUser")}
            </h3>
            <div className="d-flex flex-start">
               <InputField
                  label={t("settings.organization.addUserByEmail")}
                  placeholder={"mail@example.com"}
                  type={"email"}
                  size={"large"}
                  required
                  fieldErrorMessage={t(
                     "settings.organization.emailRequiredMessage"
                  )}
                  fieldName="email"
                  className={styles.email}
               />
               <DropdownField
                  label={t("settings.organization.role")}
                  items={rolesList}
                  className={styles.userTypeSelect}
                  size="large"
                  noMargin
                  required
                  fieldErrorMessage={t(
                     "settings.organization.roleRequiredMessage"
                  )}
                  fieldName="role"
               />
            </div>
            <ButtonField
               type="default"
               className={styles.sendButton}
               htmlType={"submit"}
               loading={inviteLoading || loadingInvite}
            >
               {t("settings.organization.sendInvite")}
            </ButtonField>
         </Form>
      </Modal>
   );
};
