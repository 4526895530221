export const Events = {
   Material_Request: {
      name: "Send Material Request",
      meta: {
         description: "Send Material Request",
         component: "Material Catalogue",
         properties: {}
      }
   },
   Material_Catalogue_Filter: {
      name: "Material Category Filter - Catalogue",
      meta: {
         description: "Track Category filter interaction",
         component: "Material Catalogue",
         properties: {
            "Material Name": "Material Name"
         }
      }
   },
   Add_Building: {
      name: "Add Building",
      meta: {
         description: "Track Add Building",
         component: "Building",
         properties: {}
      }
   },
   Add_Material: {
      name: "Add Materials",
      meta: {
         description: "Track Add Materials",
         component: "Material Passport",
         properties: {}
      }
   },
   Add_Passports: {
      name: "Add Passports",
      meta: {
         description: "Track Add Passports",
         component: "Material Passport",
         properties: {}
      }
   },
   Add_Passports_With_Same_Conditions: {
      name: "Add Passports With Same Condition",
      meta: {
         description: "Track Add Passports",
         component: "Material Passport",
         properties: {}
      }
   },
   Visit_Building_Module: {
      name: "Visit Building Module"
   },
   Visit_MarketPlace_Module: {
      name: "Visit MarketPlace Module"
   }
};
