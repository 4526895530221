import React, { useReducer } from "react";
import { INITIAL_STATE } from "./index";
import { reducer } from "./reducer";

export const useBuildingAction = (initialState = INITIAL_STATE) => {
   const [state, dispatch] = useReducer(reducer, initialState);
   const [canEdit, setCanEdit] = React.useState<boolean>();
   const [canDelete, setCanDelete] = React.useState<boolean>();
   const getBuildingDetail = (buildingId: string) => {
      const data = state.buildings.find((item: any) => {
         return item.id === buildingId;
      });
      console.log(data);
      if (data?.role && data?.role === "ADMIN") {
         setCanDelete(true);
         setCanEdit(true);
      } else if (data?.role && data?.role === "USER") {
         setCanDelete(false);
         setCanEdit(false);
      } else {
         setCanDelete(false);
         setCanEdit(false);
      }
      return data;
   };
   const addBuilding = (building: any) => {
      dispatch({ type: "ADD_BUILDING", payload: { building } });
   };
   const resetBuildings = (buildings: any[]) => {
      dispatch({ type: "RESET_BUILDING", payload: { buildings } });
   };

   return {
      state,
      getBuildingDetail,
      addBuilding,
      resetBuildings,
      canDelete,
      canEdit
   };
};
