import React, { useState, useEffect } from "react";
import {
   Button,
   message,
   Spin,
   Input,
   Checkbox,
   Select,
   Form,
   DatePicker,
   Collapse,
   AutoComplete,
   Divider,
   Radio
} from "antd";
import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";
import { Resource } from "../../../../../api/common";
import CategoriesTreeSearchField from "../../../../MaterialInventory/AddMaterialWizard/ProductDetails/ProductCategory/CategoriesTreeSearchField";
import ProductGroupField from "../../../../MaterialInventory/AddMaterialWizard/ProductDetails/ProductCategory/ProductGroupSelectField";
import Drawer from "../../../../../components/ui@concular/Drawer";
import { useAuth } from "../../../../../contexts/auth/index";
import BrandMultiSelect from "../../../../../components/BrandMultiSelect";
import { MaterialDropdown } from "../../../../../components/MaterialDropdown";

const CheckboxGroup = Checkbox.Group;

const moment = require("moment");

export const AddDemand = ({ visible, onClose, getAllDemands }: any) => {
   const { Panel } = Collapse;
   const [t] = useTranslation();
   const { Option } = Select;
   const { user: currentUser } = useAuth();

   const plainOptions = [
      { label: "Aufbereitung", value: "Refurbishment" },
      { label: "Prüfung", value: "Testing" },
      { label: "Upcycling", value: "Upcycling" },
      { label: "(Re)zertifizierung", value: "(Re)certification" },
      { label: "Lieferung", value: "Delivery" }
   ];

   const defaultCheckedList = ["Delivery"];

   const [selectedCategoryId, setSelectedCategoryId] = useState<any>(undefined);

   const [form] = Form.useForm();

   const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
   const [indeterminate, setIndeterminate] = React.useState(true);
   const [checkAll, setCheckAll] = React.useState(false);
   const [resetProduct, setResetProduct] = useState<any>(false);
   const [productUnit, setProductUnit] = useState<any>();
   const [showForm, setShowForm] = useState<any>(false);
   const [showProductGroup, setShowProductGroup] = useState<boolean>(false);
   const [locations, setLocations] = useState<any>();
   const [locationsOrg, setLocationsOrg] = useState<any>();
   const [valueLocation, setValueLocation] = useState<any>();
   const [selectedLocation, setSelectedLocation] = useState<any>();
   const [locationAPI, setLocationAPI] = useState<any>([]);
   const [locationDropDownOpen, setLocationDropDownOpen] = useState<any>(false);
   const [isNewLocation, setIsNewLocation] = useState<any>(false);
   const [isReseller, setIsReseller] = useState<boolean>(false);
   const [productGroup, setProductGroup] = useState<any>();
   const [lcaMetaMaterials, setLcaMetaMaterials] = useState<any>();
   const [deconstructionType, setDeconstructionType] = useState<any>();
   const [deliveryType, setDeliveryType] = useState<any>();
   const [inActiveProductGroup, setInActiveProductGroup] = useState<any>();
   const [
      { data, loading: getProductDetailsLoading, error },
      fetchProductGroupDetails
   ] = useAxios({}, { useCache: false, manual: true });

   const [{ loading: confirmLoading }, add] = useAxios(
      {
         url: `${Resource.path.demandApiUrlHub()}`,
         method: "POST"
      },
      { manual: true }
   );

   const [{ data: dataLocations, loading: dataLocationsLoading }] = useAxios(
      {
         url: `${Resource.path.getLocationsDemandHub}`,
         method: "GET"
      },
      { useCache: false, manual: false }
   );

   const [
      { data: dataPlaces, loading: getPlaces },
      findPlace,
      cancelRequestLocation
   ] = useAxios(
      {
         url: `${Resource.path.getLocationsHub}`,
         method: "POST"
      },
      { useCache: false, manual: true }
   );

   const [
      { data: materialsData, loading: ingredientsLoading },
      fetchIngredients
   ] = useAxios(
      {
         url: `${Resource.path.getMaterialIngredients}`,
         method: "GET"
      },
      { manual: true }
   );
   // console.log("form.getFieldsError()", form.getFieldsError());
   useEffect(() => {
      if (data?.data) {
         setProductGroup(data.data);
         setProductUnit(data.data.unit);
         setShowForm(true);
      }
      if (dataLocations?.data && dataLocations?.data) {
         const temp = dataLocations?.data.map((value: any) => {
            const { id, name } = value;
            return {
               value: `existing_${id}`,
               label: name
            };
         });
         setLocations(temp);
         setLocationsOrg(temp);
      }
      if (dataPlaces?.data && dataPlaces?.data?.locations) {
         setLocationDropDownOpen(true);
      }
      if (currentUser) {
         if (
            currentUser?.attributes &&
            currentUser?.attributes["custom:user_type"]
         ) {
            setIsReseller(
               currentUser?.attributes["custom:user_type"] === "reseller"
            );
         }
      }
      if (materialsData?.data) {
         const materials: any = materialsData?.data.data;
         const temp = materials
            ? productGroup?.lca_meta
                 ?.filter((material: any) => material.sub_material_id)
                 .map((material: any) => {
                    return materials?.find(
                       (m: any) => m.id === material.sub_material_id
                    );
                 })
            : [];
         setLcaMetaMaterials(temp);
      }
   }, [data, dataLocations, dataPlaces, currentUser, materialsData]);

   const onChangeCheckboxGroup = (list: any) => {
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length < plainOptions.length);
      setCheckAll(list.length === plainOptions.length);
   };

   /* const onCheckAllChange = (e: any) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }; */

   const dateFormat = "DD.MM.YYYY";

   const children = [];
   for (let i = 10; i < 36; i++) {
      children.push(
         <Option value={i.toString(36) + i} key={i.toString(36) + i}>
            {i.toString(36) + i}
         </Option>
      );
   }

   const onCategorySelect = (value: {
      id: string;
      name: string;
      dropDownId: string;
      reset: boolean;
   }) => {
      setShowProductGroup(false);
      const { id: categoryId, reset } = value;
      setSelectedCategoryId(categoryId);
      setResetProduct(reset);
      setShowForm(false);
      setTimeout(() => {
         setShowProductGroup(true);
      }, 1500);
   };

   const onProductGroupSelect = async (value: any) => {
      setShowForm(false);
      if (value && value.key) {
         await fetchProductGroupDetails({
            url: `${Resource.path.getProductGroupDetails}/${value.key}`
         });
         form.setFieldsValue({ product_group: value });
         await fetchIngredients();
      }
   };

   if (getProductDetailsLoading || dataLocationsLoading || ingredientsLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   const onFinish = async () => {
      form
         .validateFields()
         .then(async () => {
            const data = form.getFieldsValue();
            let delivery = 0;
            if (isReseller) {
               delivery = deliveryType && deliveryType === "delivery" ? 1 : 0;
            } else {
               delivery =
                  data?.services && data?.services.indexOf("Delivery") !== -1
                     ? 1
                     : 0;
            }
            await add({
               data: {
                  product_group: data.product_group.label,
                  categories: JSON.stringify(data.categories),
                  product_group_id: data.product_group.value,
                  amount: data?.minimum_amount ? data?.minimum_amount : 0,
                  target_price: data.target_price,
                  delivery,
                  refurbishment:
                     data?.services &&
                     data?.services.indexOf("Refurbishment") !== -1
                        ? 1
                        : 0,
                  testing:
                     data?.services && data?.services.indexOf("Testing") !== -1
                        ? 1
                        : 0,
                  upcycling:
                     data?.services &&
                     data?.services.indexOf("Upcycling") !== -1
                        ? 1
                        : 0,
                  recertification:
                     data?.services &&
                     data?.services.indexOf("(Re)certification") !== -1
                        ? 1
                        : 0,
                  delivery_date: data?.delivery_date
                     ? data?.delivery_date
                     : null,
                  unit: productUnit,
                  material_location_places_api: isNewLocation
                     ? selectedLocation
                     : {},
                  isNewLocation,
                  locations_id: !isNewLocation ? selectedLocation : null,
                  measurements: data?.measurements ? data.measurements : null,
                  manufacturers: data?.manufacturers
                     ? data?.manufacturers
                     : null,
                  selected_material: data?.label_material
                     ? data?.label_material
                     : null,

                  deconstruction_service:
                     deconstructionType && deconstructionType === "managed"
                        ? 1
                        : 0,
                  self_deconstruction:
                     deconstructionType && deconstructionType === "self"
                        ? 1
                        : 0,
                  pickup: deliveryType && deliveryType === "pickup" ? 1 : 0
               }
            })
               .then((response) => {
                  getAllDemands();
                  console.log(response);
                  form.resetFields();
                  closeMe();
                  message.success("Demand added successfully");
               })
               .catch((error) => {
                  console.log(error);
                  message.error(t("buildingOperations.errorMessage"));
               });
         })
         .catch((error) => {
            console.log(error);
            console.log(form.getFieldsError());
         });
   };

   const closeMe = () => {
      form.resetFields();
      setShowForm(false);
      setShowProductGroup(false);
      onClose();
   };

   const setInActive = (data: any) => {
      //console.log('inavtice', data)
      setInActiveProductGroup(data);
   };

   return (
      <Drawer
         placement="right"
         closable={true}
         width={450}
         visible={visible}
         onClose={closeMe}
         title={t("demands.drawer.title")}
      >
         <Form
            layout="vertical"
            form={form}
            initialValues={{
               minimum_amount: "1",
               services: checkedList
            }}
         >
            <CategoriesTreeSearchField
               form={form}
               onChange={onCategorySelect}
               operationType={"ADD"}
               setInActive={setInActive}
            ></CategoriesTreeSearchField>

            {showProductGroup && (
               <Form.Item
                  label={t("productDetails.productCategory.productGroup")}
                  name="product_group"
                  rules={[
                     {
                        required: true,
                        message: t(
                           "productDetails.productCategory.validations.productGroup"
                        )
                     }
                  ]}
               >
                  <ProductGroupField
                     onChange={onProductGroupSelect}
                     categoryId={selectedCategoryId}
                     reset={resetProduct}
                     operationType={"ADD"}
                     inActive={inActiveProductGroup}
                  ></ProductGroupField>
               </Form.Item>
            )}
            {showForm && (
               <>
                  {!isReseller && (
                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: t(
                                 "demands.addDemand.minimum_amount_error"
                              )
                           }
                        ]}
                        className="inline"
                        label={t("demands.addDemand.minimum_amount_label")}
                        name="minimum_amount"
                     >
                        <Input placeholder="1" suffix={<b>{productUnit}</b>} />
                     </Form.Item>
                  )}

                  <Form.Item
                     rules={[
                        {
                           required: true,
                           message: t("demands.addDemand.target_price_error")
                        }
                     ]}
                     className="inline"
                     label={t("demands.addDemand.target_price_label")}
                     name="target_price"
                  >
                     <Input
                        min="0"
                        type="number"
                        suffix={<b>€/{productUnit}</b>}
                     />
                  </Form.Item>

                  <Collapse defaultActiveKey={[3]} ghost>
                     <Panel
                        header={t(
                           "productDetails.productCategory.specification.manufacturerName"
                        )}
                        key="1"
                     >
                        <Form.Item name="manufacturers">
                           <BrandMultiSelect></BrandMultiSelect>
                        </Form.Item>
                     </Panel>
                     <Panel
                        header={t(
                           "productDetails.productCategory.specification.measurements"
                        )}
                        key="2"
                     >
                        <Form.Item
                           label={t(
                              "productDetails.productCategory.specification.width"
                           )}
                        >
                           <Input.Group compact>
                              <Form.Item
                                 noStyle
                                 rules={[
                                    {
                                       required: false
                                    },
                                    {
                                       validator(_, value) {
                                          if (
                                             value &&
                                             form.getFieldValue("measurements")
                                                ?.width?.maximum &&
                                             Number(value) >
                                                Number(
                                                   form.getFieldValue(
                                                      "measurements"
                                                   )["width"]["maximum"]
                                                )
                                          ) {
                                             return Promise.reject(
                                                "Please enter valid minimum value"
                                             );
                                          }
                                          return Promise.resolve();
                                       }
                                    }
                                 ]}
                                 className="inline"
                                 name={["measurements", "width", "minimum"]}
                              >
                                 <Input
                                    placeholder={t(
                                       "productDetails.productCategory.specification.from"
                                    )}
                                    style={{
                                       width: "50%",
                                       textAlign: "left"
                                    }}
                                    min="0"
                                    type="number"
                                    suffix={<b>mm</b>}
                                 />
                              </Form.Item>
                              <Form.Item
                                 noStyle
                                 rules={[
                                    {
                                       required: false
                                    },
                                    {
                                       validator(_, value) {
                                          if (
                                             value &&
                                             form.getFieldValue("measurements")
                                                ?.width?.minimum &&
                                             Number(value) <
                                                Number(
                                                   form.getFieldValue(
                                                      "measurements"
                                                   )["width"]["minimum"]
                                                )
                                          ) {
                                             return Promise.reject(
                                                "Please enter valid maximum value"
                                             );
                                          }
                                          return Promise.resolve();
                                       }
                                    }
                                 ]}
                                 className="inline"
                                 name={["measurements", "width", "maximum"]}
                              >
                                 <Input
                                    placeholder={t(
                                       "productDetails.productCategory.specification.to"
                                    )}
                                    style={{
                                       width: "50%",
                                       textAlign: "left"
                                    }}
                                    min={
                                       form.getFieldValue("measurements")?.width
                                          ?.minimum
                                          ? form.getFieldValue("measurements")[
                                               "width"
                                            ]["minimum"]
                                          : 0
                                    }
                                    type="number"
                                    suffix={<b>mm</b>}
                                 />
                              </Form.Item>
                           </Input.Group>
                        </Form.Item>

                        <Form.Item
                           label={t(
                              "productDetails.productCategory.specification.height"
                           )}
                        >
                           <Input.Group compact>
                              <Form.Item
                                 noStyle
                                 rules={[
                                    {
                                       required: false
                                    },
                                    {
                                       validator(_, value) {
                                          if (
                                             value &&
                                             form.getFieldValue("measurements")
                                                ?.height?.maximum &&
                                             Number(value) >
                                                Number(
                                                   form.getFieldValue(
                                                      "measurements"
                                                   )["height"]["maximum"]
                                                )
                                          ) {
                                             return Promise.reject(
                                                "Please enter valid minimum value"
                                             );
                                          }
                                          return Promise.resolve();
                                       }
                                    }
                                 ]}
                                 className="inline"
                                 name={["measurements", "height", "minimum"]}
                              >
                                 <Input
                                    placeholder={t(
                                       "productDetails.productCategory.specification.from"
                                    )}
                                    style={{
                                       width: "50%",
                                       textAlign: "left"
                                    }}
                                    min="0"
                                    type="number"
                                    suffix={<b>mm</b>}
                                 />
                              </Form.Item>
                              <Form.Item
                                 noStyle
                                 rules={[
                                    {
                                       required: false
                                    },
                                    {
                                       validator(_, value) {
                                          if (
                                             value &&
                                             form.getFieldValue("measurements")
                                                ?.height?.minimum &&
                                             Number(value) <
                                                Number(
                                                   form.getFieldValue(
                                                      "measurements"
                                                   )["height"]["minimum"]
                                                )
                                          ) {
                                             return Promise.reject(
                                                "Please enter valid maximum value"
                                             );
                                          }
                                          return Promise.resolve();
                                       }
                                    }
                                 ]}
                                 className="inline"
                                 name={["measurements", "height", "maximum"]}
                              >
                                 <Input
                                    placeholder={t(
                                       "productDetails.productCategory.specification.to"
                                    )}
                                    style={{
                                       width: "50%",
                                       textAlign: "left"
                                    }}
                                    min={
                                       form.getFieldValue("measurements")
                                          ?.height?.minimum
                                          ? form.getFieldValue("measurements")[
                                               "height"
                                            ]["minimum"]
                                          : 0
                                    }
                                    type="number"
                                    suffix={<b>mm</b>}
                                 />
                              </Form.Item>
                           </Input.Group>
                        </Form.Item>

                        <Form.Item
                           label={t(
                              "productDetails.productCategory.specification.depth"
                           )}
                        >
                           <Input.Group compact>
                              <Form.Item
                                 noStyle
                                 rules={[
                                    {
                                       required: false
                                    },
                                    {
                                       validator(_, value) {
                                          if (
                                             value &&
                                             form.getFieldValue("measurements")
                                                ?.depth?.maximum &&
                                             Number(value) >
                                                Number(
                                                   form.getFieldValue(
                                                      "measurements"
                                                   )["depth"]["maximum"]
                                                )
                                          ) {
                                             return Promise.reject(
                                                "Please enter valid minimum value"
                                             );
                                          }
                                          return Promise.resolve();
                                       }
                                    }
                                 ]}
                                 className="inline"
                                 name={["measurements", "depth", "minimum"]}
                              >
                                 <Input
                                    placeholder={t(
                                       "productDetails.productCategory.specification.from"
                                    )}
                                    style={{
                                       width: "50%",
                                       textAlign: "left"
                                    }}
                                    min="0"
                                    type="number"
                                    suffix={<b>mm</b>}
                                 />
                              </Form.Item>
                              <Form.Item
                                 noStyle
                                 rules={[
                                    {
                                       required: false
                                    },
                                    {
                                       validator(_, value) {
                                          if (
                                             value &&
                                             form.getFieldValue("measurements")
                                                ?.depth?.minimum &&
                                             Number(value) <
                                                Number(
                                                   form.getFieldValue(
                                                      "measurements"
                                                   )["depth"]["minimum"]
                                                )
                                          ) {
                                             return Promise.reject(
                                                "Please enter valid maximum value"
                                             );
                                          }
                                          return Promise.resolve();
                                       }
                                    }
                                 ]}
                                 className="inline"
                                 name={["measurements", "depth", "maximum"]}
                              >
                                 <Input
                                    placeholder={t(
                                       "productDetails.productCategory.specification.to"
                                    )}
                                    style={{
                                       width: "50%",
                                       textAlign: "left"
                                    }}
                                    min={
                                       form.getFieldValue("measurements")?.depth
                                          ?.minimum
                                          ? form.getFieldValue("measurements")[
                                               "depth"
                                            ]["minimum"]
                                          : 0
                                    }
                                    type="number"
                                    suffix={<b>mm</b>}
                                 />
                              </Form.Item>
                           </Input.Group>
                        </Form.Item>
                     </Panel>

                     {productGroup &&
                        lcaMetaMaterials &&
                        lcaMetaMaterials.length > 0 && (
                           <Panel header="Material" key="3">
                              <Form.Item label={productGroup?.lca_meta_label}>
                                 <MaterialDropdown
                                    name={["label_material"]}
                                    materials={lcaMetaMaterials}
                                    setMaterialGroup={() => {
                                       const fields = form.getFieldsValue();
                                       if (
                                          fields["label_material"]
                                             ?.sub_material_id
                                       ) {
                                          fields[
                                             "label_material"
                                          ].sub_material_id = undefined;
                                          form.setFieldsValue(fields);
                                       }
                                    }}
                                 />
                              </Form.Item>
                           </Panel>
                        )}
                  </Collapse>

                  {!isReseller && (
                     <Form.Item>
                        <>
                           <strong>
                              {t("demands.addDemand.services.title")}
                           </strong>
                           <p>{t("demands.addDemand.services.message")}</p>
                        </>
                     </Form.Item>
                  )}

                  {!isReseller && (
                     <Form.Item className="checkboxgroup" name="services">
                        <CheckboxGroup
                           options={plainOptions}
                           onChange={onChangeCheckboxGroup}
                        />
                     </Form.Item>
                  )}

                  {!isReseller && (
                     <Form.Item
                        label={t("demands.addDemand.needed_until_label")}
                        name="delivery_date"
                        rules={[
                           {
                              required: true,
                              message: "Bitte Datum eingeben"
                           }
                        ]}
                     >
                        <DatePicker
                           format={dateFormat}
                           placeholder={t("demands.addDemand.enterDate")}
                        />
                     </Form.Item>
                  )}

                  <Form.Item
                     label={"Location"}
                     name={"location"}
                     rules={[
                        {
                           required: true,
                           message: "Bitte eingeben"
                        },
                        ({}) => ({
                           validator(_, value) {
                              if (
                                 selectedLocation === undefined ||
                                 selectedLocation === null
                              ) {
                                 return Promise.reject(
                                    new Error("Bitte gültige Adresse eingeben")
                                 );
                              }
                              if (value) return Promise.resolve();
                           }
                        })
                     ]}
                  >
                     <AutoComplete
                        open={locationDropDownOpen}
                        notFoundContent={getPlaces ? <Spin /> : null}
                        value={valueLocation}
                        options={locations}
                        onSelect={(value) => {
                           cancelRequestLocation();
                           setLocationDropDownOpen(false);
                           const selected = locations.filter((rec: any) => {
                              return rec.value === value;
                           });

                           const labelValue = selected.pop().label;
                           setValueLocation(labelValue);
                           const temp = value.split("_");
                           setIsNewLocation(temp[0] === "new");
                           if (temp[0] === "new") {
                              setSelectedLocation(locationAPI[temp[1]]);
                           } else {
                              setSelectedLocation(temp[1]);
                           }

                           form.setFieldsValue({
                              location: labelValue
                           });
                        }}
                        onChange={(value) => {
                           setValueLocation(value);
                           console.log("value", value);
                           setLocationDropDownOpen(true);
                        }}
                        onSearch={async (searchText: string) => {
                           setSelectedLocation(null);
                           cancelRequestLocation();
                           setLocationDropDownOpen(true);
                           if (searchText.length === 0) {
                              setLocations(locationsOrg);
                           } else if (searchText.length > 0) {
                              let temp = locationsOrg.filter((rec: any) => {
                                 return (
                                    rec?.value &&
                                    rec?.label
                                       .toLowerCase()
                                       .includes(searchText.toLowerCase())
                                 );
                              });
                              console.log(temp, locationsOrg);
                              if (temp.length === 0 && searchText.length > 10) {
                                 await findPlace({
                                    data: {
                                       address: `${searchText}`
                                    }
                                 });
                              }

                              if (
                                 temp.length === 0 &&
                                 dataPlaces?.data &&
                                 dataPlaces?.data?.locations &&
                                 dataPlaces?.data?.locations.length > 0
                              ) {
                                 const tempLocations =
                                    dataPlaces?.data?.locations.map(
                                       (rec: any, key: any) => {
                                          if (rec && rec?.Label) {
                                             return {
                                                value: `new_${key}`,
                                                label: rec?.Label
                                             };
                                          }
                                       }
                                    );
                                 console.log("tempLocations", tempLocations);
                                 setLocationAPI(dataPlaces?.data?.locations);
                                 temp = tempLocations;
                              }
                              setLocations(temp);
                              setLocationDropDownOpen(true);
                           }
                        }}
                        placeholder="Enter location"
                        onFocus={() => {
                           setLocationDropDownOpen(true);
                        }}
                     ></AutoComplete>
                  </Form.Item>
               </>
            )}

            {showForm && (
               <>
                  {!!isReseller && (
                     <Form.Item label={"Rückbau"}>
                        <Radio.Group
                           options={[
                              { label: "Inklusive", value: "managed" },
                              { label: "Selbstrückbau", value: "self" }
                           ]}
                           onChange={(event: any) => {
                              setDeconstructionType(event.target.value);
                           }}
                           optionType="button"
                           buttonStyle="solid"
                        />
                     </Form.Item>
                  )}
                  {!!isReseller && (
                     <Form.Item label={"Lieferung"}>
                        <Radio.Group
                           options={[
                              { label: "Abholung", value: "pickup" },
                              { label: "Lieferung", value: "delivery" }
                           ]}
                           onChange={(event: any) => {
                              setDeliveryType(event.target.value);
                           }}
                           optionType="button"
                           buttonStyle="solid"
                        />
                     </Form.Item>
                  )}

                  <Form.Item>
                     <Divider />
                     <Button
                        type="primary"
                        shape="round"
                        size="large"
                        htmlType="submit"
                        loading={confirmLoading}
                        onClick={onFinish}
                     >
                        {t("demands.addDemand.save_demand")}
                     </Button>
                  </Form.Item>
               </>
            )}
         </Form>
      </Drawer>
   );
};
