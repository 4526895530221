import React, { useState } from "react";
import { WelcomePage } from "../../components/WelcomePage";
import Amplify, { I18n, Auth } from "aws-amplify";
import { Form, Input, Button, Card, message, Statistic, Row, Col } from "antd";
import { authLocale } from "../../components/WelcomePage/locale";
import { useForm } from "antd/lib/form/Form";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useHistory } from "react-router-dom";

Amplify.configure({
   aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
   aws_cognito_identity_pool_id:
      process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
   aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
   aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
   aws_user_pools_web_client_id:
      process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
   oauth: process.env.REACT_APP_OAUTH || {},
   authenticationFlowType: "CUSTOM_AUTH"
});

export const AdminAuth = () => {
   const [user, setUser] = useState<CognitoUser>();
   const [showOTP, setShowOTP] = useState<boolean>(false);
   const [loadingSignIn, setLoadingSignIn] = useState<boolean>(false);
   const [loadingOTP, setLoadingOTP] = useState<boolean>(false);

   I18n.setLanguage(navigator.language === "en-GB" ? "en" : "de");
   I18n.putVocabularies(authLocale);
   const [form] = useForm();
   const history = useHistory();

   const handleSubmit = async () => {
      setLoadingSignIn(true);
      form.submit();
      const data = form.getFieldsValue();
      Auth.configure({
         authenticationFlowType: "CUSTOM_AUTH"
      });
      await Auth.signIn(data?.user_email)
         .then(async (response: CognitoUser) => {
            console.log(response);
            setUser(response);
            setShowOTP(true);
            setLoadingSignIn(false);
            message.info("check admin email for code");
         })
         .catch((error) => {
            console.log(error);
            setShowOTP(false);
            setLoadingSignIn(false);
            message.error(error.message);
         });
   };

   const verifyOTP = async () => {
      setLoadingOTP(true);
      form.submit();
      const data = form.getFieldsValue();
      await Auth.sendCustomChallengeAnswer(user, data?.code)
         .then(async () => {
            await Auth.currentSession()
               .then(() => {
                  //console.log(resp)
                  message.success("logged-in successfully");
                  setLoadingOTP(false);
                  history.push("/");
               })
               .catch((e) => {
                  console.log(e);
                  setLoadingOTP(false);
                  message.error(e.message);
               });
         })
         .catch((err) => {
            console.log(err);
            setLoadingOTP(false);
            message.error("please enter the right code");
         });
   };

   return (
      <WelcomePage>
         <Card>
            <Form form={form} autoComplete="off">
               <Form.Item
                  label={I18n.get("User Email")}
                  name="user_email"
                  rules={[
                     {
                        required: true,
                        message: I18n.get("Please input your user email!")
                     }
                  ]}
               >
                  <Input readOnly={showOTP} />
               </Form.Item>
               {showOTP && (
                  <Form.Item
                     label={I18n.get("Enter Code")}
                     name="code"
                     rules={[
                        {
                           required: true,
                           message: I18n.get("Please input code!")
                        }
                     ]}
                  >
                     <Input maxLength={6} />
                  </Form.Item>
               )}
               {!showOTP && (
                  <Form.Item wrapperCol={{ offset: 10 }}>
                     <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={loadingSignIn}
                     >
                        {I18n.get("Sign In")}
                     </Button>
                  </Form.Item>
               )}
               {showOTP && (
                  <Row>
                     <Col span={6} offset={1}>
                        <Form.Item>
                           <Statistic.Countdown
                              title="Expires in"
                              value={Date.now() + 600000}
                              format="mm:ss"
                              valueStyle={{
                                 fontSize: "15px",
                                 display: "inline"
                              }}
                           />
                        </Form.Item>
                     </Col>
                     <Col span={6} offset={4}>
                        <Form.Item>
                           <Button
                              type="primary"
                              onClick={verifyOTP}
                              loading={loadingOTP}
                           >
                              {I18n.get("Submit")}
                           </Button>
                        </Form.Item>
                     </Col>
                  </Row>
               )}
            </Form>
         </Card>
      </WelcomePage>
   );
};
