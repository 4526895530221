import { Auth } from "aws-amplify";
import useAxios from "axios-hooks";
import React, { useContext, useEffect, useState } from "react";
import { Resource } from "../../api/common";
import { useCookies } from "react-cookie";

const AuthContext = React.createContext({} as any);

export const AuthProvider = ({ children }: any) => {
   const [cookies, setCookie, removeCookie] = useCookies(["user", "modules"]);
   const [user, setUser] = useState<any>();
   const [organizationList, setOrganizationList] = useState<any>();
   const [organizationTypes, setOrganizationTypes] = useState<any>();
   const [joinedOrganizationsList, setJoinedOrganizationsList] =
      useState<any>();
   const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(true);
   const [onBoarded, setOnBoarded] = useState<boolean>(false);

   React.useEffect(() => {
      if (cookies.user) {
         setUser(cookies.user);
         setLoadingUserInfo(false);
         setOnBoarded(
            cookies.user?.attributes &&
               cookies.user?.attributes["custom:on_boarding"]
         );
      }
   }, [cookies]);

   React.useEffect(() => {
      if (!user) {
         Auth.currentUserInfo()
            .then((user) => {
               setOnBoarded(
                  user?.attributes && user?.attributes["custom:on_boarding"]
               );
               setUser(user);
               setCookie("user", user, { secure: true, maxAge: 14400 });
               setLoadingUserInfo(false);
            })
            .catch((error) => {
               console.log(error);
               if (cookies.user) {
                  removeCookie("user");
               }
               if (cookies.modules) {
                  removeCookie("modules");
               }
            });
      }
   }, [user]);

   const [
      { data: organizationsListData, loading: organizationListLoading },
      getOrganizationsListAPI
   ] = useAxios(
      {
         url: `${Resource.path.getOrganizationsList}`,
         method: "GET"
      },
      { useCache: false, manual: false }
   );

   const [{ data: organizationTypesData, loading }] = useAxios(
      {
         url: `${Resource.path.getOrganizationTypes}`,
         method: "GET"
      },
      { useCache: true, manual: false }
   );

   const [
      { data: userOrganizationsListData, loading: userOrganizationLoading },
      getJoinedOrganizationsAPI
   ] = useAxios(
      {
         url: `${Resource.path.userOrganizations}`,
         method: "GET"
      },
      { useCache: false, manual: false }
   );

   useEffect(() => {
      organizationsListData &&
         organizationsListData?.data &&
         setOrganizationList([...organizationsListData.data]);
   }, [organizationsListData]);

   useEffect(() => {
      userOrganizationsListData &&
         userOrganizationsListData?.data &&
         setJoinedOrganizationsList([...userOrganizationsListData.data]);
   }, [userOrganizationsListData]);

   useEffect(() => {
      user &&
         organizationTypesData &&
         organizationTypesData?.data &&
         setOrganizationTypes(
            organizationTypesData?.data.map(
               (item: { [x: string]: any; id: any }) => {
                  return {
                     id: item.id,
                     label:
                        user?.attributes?.locale == "en"
                           ? item["en_name"]
                           : item["de_name"]
                  };
               }
            )
         );
   }, [user, organizationTypesData]);

   if (loadingUserInfo || organizationListLoading || userOrganizationLoading) {
      (window as any).NProgress?.start();
   } else {
      (window as any).NProgress?.done();
   }

   return (
      <AuthContext.Provider
         value={{
            user,
            organizationList,
            organizationTypes,
            organizationListLoading,
            joinedOrganizationsList,
            loadingUserInfo,
            setUser,
            onBoarded,
            setOnBoarded,
            getOrganizationsListAPI,
            getJoinedOrganizationsAPI,
            userOrganizationLoading
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};

export const useAuth = () => useContext(AuthContext);
