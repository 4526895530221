import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import * as AntIconComponents from "@ant-design/icons";
import Joi from "joi";

export const UnitFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("text")
   }).unknown()
});

export interface UnitField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      type: "number";
      [key: string]: any;
   };
}

export type AnyIconType = keyof typeof AntIconComponents;

const getIconComponent = (iconName: AnyIconType) => {
   const IconComponent: any = AntIconComponents[iconName];
   return IconComponent && <IconComponent />;
};

const getOptionsElement = (options: any) => {
   return (
      options &&
      options.map((option: any) => {
         return (
            <Select.Option key={option} value={option}>
               {option}
            </Select.Option>
         );
      })
   );
};

export const UnitField = (props: UnitField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   const formattedProps = {
      ...fieldProps,
      ...(fieldProps.prefix && getIconComponent(fieldProps.prefix)
         ? { prefix: getIconComponent(fieldProps.prefix) }
         : {}),
      ...(fieldProps.suffix && getIconComponent(fieldProps.suffix)
         ? { suffix: getIconComponent(fieldProps.suffix) }
         : {})
   };

   const optionsElm = getOptionsElement(fieldProps.options);

   return (
      <Form.Item {...formItemProps}>
         <UnitCustomField
            formattedProps={formattedProps}
            optionsElm={optionsElm}
         ></UnitCustomField>
      </Form.Item>
   );
};

const UnitCustomField = ({
   formattedProps,
   optionsElm,
   value = {},
   onChange
}: any) => {
   const [unitValue, setNumberFieldValue] = useState<any>();
   const [unit, setUnitFieldValue] = useState<any>();

   const triggerChange = (changedValue: any) => {
      if (onChange) {
         onChange({ unitValue, unit, ...value, ...changedValue });
      }
   };

   const onNumberChange = (e: any) => {
      if (!("unitValue" in value)) {
         setNumberFieldValue(e.target.value);
      }

      triggerChange({ unitValue: e.target.value });
   };
   const onSelectChange = (unit: string) => {
      if (!("unit" in value)) {
         setUnitFieldValue(unit);
      }
      triggerChange({ unit: unit });
   };
   if (
      optionsElm &&
      optionsElm.length === 1 &&
      optionsElm[0] &&
      optionsElm[0].key &&
      value.unit === undefined
   ) {
      value = { unit: optionsElm[0].key };
   }
   return (
      <>
         <Input
            {...formattedProps}
            value={value?.unitValue}
            type="number"
            style={{ width: 100 }}
            onChange={onNumberChange}
         />
         <Select
            value={value?.unit}
            style={{ width: 80, margin: "0 8px" }}
            onChange={onSelectChange}
         >
            {optionsElm}
         </Select>
      </>
   );
};
