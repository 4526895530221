import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { SelectProps } from "antd/es/select";
import useAxios from "axios-hooks";
import { Resource } from "../../../../../../../api/common";
import { useTranslation } from "react-i18next";

const buildOptionsElm = (results: []) => {
   return results.map((result: any) => {
      return (
         <Select.Option key={result.value} value={result.value}>
            {result.label}
         </Select.Option>
      );
   });
};

const ProductGroupField: React.FC<any> = ({
   value,
   onChange,
   categoryId,
   reset,
   operationType,
   inActive
}) => {
   const [t] = useTranslation();
   const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
   const [selectedItems, setSelectedItems] = useState<any>(value);

   const [{ data, loading, error }, refetch] = useAxios(
      { url: `${Resource.path.getCategories}/${categoryId}` },
      { useCache: false, manual: true }
   );

   useEffect(() => {
      if (categoryId) {
         if (reset) {
            setSelectedItems({ label: "", value: "" });
            setOptions([]);
            onChange({ label: "", value: "" });
         }
         refetch();
      }
   }, [categoryId]);

   useEffect(() => {
      if (data?.data) {
         const { product_groups } = data?.data;
         const inActiveItems: any = [];
         if (inActive && inActive.length) {
            inActive.map((rec: any) => {
               inActiveItems.push(rec.id);
            });
         }
         if (product_groups) {
            let options: any = buildOptionsElm(
               product_groups.filter((rec: any) => {
                  return inActiveItems.indexOf(rec.value) === -1;
               })
            );
            if (operationType === "EDIT") {
               options = buildOptionsElm(product_groups);
            }
            setOptions(options);
         } else {
            setOptions([]);
         }
      }
   }, [data]);

   const onValueChange = (value: any) => {
      setSelectedItems(value);
      onChange(value);
   };

   if (loading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <Select
         disabled={operationType === "EDIT"}
         labelInValue={true}
         size="large"
         placeholder={t(
            "productDetails.productCategory.productGroupPlaceholder"
         )}
         value={selectedItems}
         onChange={onValueChange}
         style={{ width: "100%" }}
      >
         {options}
      </Select>
   );
};

export default ProductGroupField;
