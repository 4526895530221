import React from "react";
import { Switch, Route } from "react-router-dom";
import { AddMaterialWizard } from "./AddMaterialWizard";
import { MaterialWizardProvider } from "../../../contexts/materialWizard/index";
import { MaterialInventoryDashboard } from "./Dashboard";
import { MaterialDetails } from "./Dashboard/MaterialDetails";
import { MaterialPassportDetails } from "./Dashboard/MaterialPassportDetails";
import { EditMaterialWizard } from "./EditMaterialWizard";
import { PassportDetailsEdit } from "./Dashboard/PassportDetailsEdit";

export const InventoryViews = () => {
   return (
      <MaterialWizardProvider>
         <Switch>
            <Route
               exact
               path={`/hub/material_inventory/`}
               component={MaterialInventoryDashboard}
            ></Route>
            <Route
               path={`/hub/material_inventory/add`}
               component={AddMaterialWizard}
            ></Route>
            <Route
               exact
               path={`/hub/material_inventory/:material_id`}
               component={MaterialDetails}
            ></Route>
            <Route
               exact
               path={`/hub/material_inventory/:id/passports/:passport_id`}
               component={MaterialPassportDetails}
            ></Route>
            <Route
               path={`/hub/material_inventory/edit/:passport_specification_id`}
               component={EditMaterialWizard}
            ></Route>
            <Route
               path={`/hub/material_inventory/:id/edit/passport_cluster`}
               component={PassportDetailsEdit}
            ></Route>
         </Switch>
      </MaterialWizardProvider>
   );
};
