import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import blackLogo from "./../../../statics/images/blackLogo.svg";
import icon_passportmanager from "./../../../statics/images/icon_passportmanager.svg";
import icon_catalogue from "./../../../statics/images/icon_catalogue.svg";
import icon_app from "./../../../statics/images/icon_app.svg";
import ButtonField from "../../../components/FormFields/ButtonField";
import ProgressBar from "../../../components/ProgressBar";
import CheckBoxSingleField from "../../../components/FormFields/CheckBoxSingleField";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import Organization from "../CreateOrganization/Organization";
import { Organization as OrganizationType } from "../../../types/index";
import { getOrganizationsDetails } from "../getOrganizationsDetails";
import { useHistory } from "react-router";
import { PlusOutlined, ArrowRightOutlined } from "@ant-design/icons";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";

const Dashboard = () => {
   const [t] = useTranslation();
   const {
      joinedOrganizationsList,
      userOrganizationLoading,
      organizationTypes,
      user: currentUser
   } = useAuth();

   const [userOwnedOrganizations, setUserOwnedOrganizations] = useState<
      Array<OrganizationType>
   >([]);
   const [loading, setLoading] = useState(true);
   const [organizationStatus, setOrganizationStatus] = useState<any>([]);
   const history = useHistory();

   const [{ data: paymentData }, getPaymentData] = useAxios(
      {
         method: "get",
         timeout: 900000
      },
      { useCache: false, manual: true, autoCancel: false }
   );

   useEffect(() => {
      joinedOrganizationsList &&
         setUserOwnedOrganizations(
            joinedOrganizationsList.filter(
               (item: OrganizationType) =>
                  item.created_by == currentUser.username
            )
         );
   }, [joinedOrganizationsList]);

   const getDetails = async () => {
      setLoading(true);
      const details = await getOrganizationsDetails(
         userOwnedOrganizations.map((item) => item.organizations_id)
      );
      let paymentInfo: any = [];
      const result = userOwnedOrganizations.map(async (item) => {
         await getPaymentData({
            url: `${Resource.path.plansURL}/customer/${item.organizations_id}`
         }).then((res) => {
            paymentInfo.push(res.data?.data);
         });
      });
      await Promise.all(result);
      const _options = details.map((item: any, index: any) => ({
         id: item.id,
         options: [
            { label: "Upload logo", value: "logo", checked: !!item.image },
            {
               label: "Add contact details",
               value: "contact",
               checked:
                  (item?.address && !!item?.address?.zip) ||
                  !!item?.address?.city ||
                  !!item?.address?.streetNumber
            },
            {
               label: "Add payment details",
               value: "payment",
               checked: paymentInfo?.find(
                  (rec: any) => rec?.id == item?.strip_customer_id
               )?.payment_method
            },
            {
               label: "Add your colleagues",
               value: "colleagues",
               checked: item?.users.length > 1
            }
         ]
      }));
      _options.map((rec: any) => calculateProgress(rec));
      setOrganizationStatus(_options);
      setLoading(false);
   };

   useEffect(() => {
      getDetails();
   }, [userOwnedOrganizations]);

   const goToOrganizationSettings = (organizations_id: number) => {
      history.push(`/settings/${organizations_id}/account`);
   };

   const calculateProgress = (rec: any) => {
      if (rec.options && rec.options.length) {
         rec["progress"] =
            (rec.options.filter((item: { checked: any }) => item.checked)
               .length *
               100) /
            rec.options.length;
      } else {
         rec["progress"] = 0;
      }
   };

   const goToBuildings = () => history.push("/buildings");
   const goToShowroom = () => history.push("/catalogue");

   return (
      <div className={styles.dashboard}>
         <h3 className={"responsivePageTitle"}>{`${t(
            "onboarding.welcome"
         )}`}</h3>
         <Spin
            spinning={
               loading ||
               userOrganizationLoading ||
               userOwnedOrganizations?.length !== organizationStatus.length
            }
         >
            {!loading &&
            !userOrganizationLoading &&
            userOwnedOrganizations?.length === organizationStatus.length ? (
               <Row gutter={24} className={styles.content}>
                  {userOwnedOrganizations?.map(
                     (item: OrganizationType, index: number) =>
                        organizationStatus?.find(
                           (option: any) => option.id === item.organizations_id
                        )?.progress !== 100 ? (
                           <Col
                              xs={24}
                              sm={20}
                              lg={10}
                              xl={8}
                              className={styles.col}
                              key={index}
                           >
                              <div
                                 className={`${styles.block} ${styles.profile}`}
                              >
                                 <div className="d-flex-between">
                                    <div className="d-flex-center flex-align-center">
                                       <img
                                          className={styles.logo}
                                          src={blackLogo}
                                       />
                                       <span className={styles.title}>
                                          {t("onboarding.completeProfile")}
                                       </span>
                                    </div>
                                 </div>
                                 <Organization
                                    name={item?.name}
                                    logo={
                                       item?.image
                                          ? item?.image[0]?.object_url
                                          : ""
                                    }
                                    type={
                                       organizationTypes?.find(
                                          (type: { id: any }) =>
                                             type.id ==
                                             item?.organization_type_id
                                       )?.label
                                    }
                                    className={styles.organizationItem}
                                 />
                                 <div className={styles.progressBar}>
                                    <ProgressBar
                                       bgcolor1="#27B47C"
                                       segment1={
                                          organizationStatus?.find(
                                             (option: any) =>
                                                option.id ===
                                                item?.organizations_id
                                          )?.progress
                                       }
                                    />
                                 </div>
                                 <div className={styles.stages}>
                                    {organizationStatus.length
                                       ? organizationStatus
                                            .find(
                                               (option: any) =>
                                                  option.id ===
                                                  item.organizations_id
                                            )
                                            .options.map(
                                               (item: {
                                                  checked: boolean;
                                                  label: string | undefined;
                                                  value: string | undefined;
                                               }) => (
                                                  <CheckBoxSingleField
                                                     checked={item.checked}
                                                     label={item.label}
                                                     value={item.value}
                                                     noMargin
                                                     round
                                                  />
                                               )
                                            )
                                       : null}
                                 </div>
                                 <div>
                                    <ButtonField
                                       type="default"
                                       size="middle"
                                       onClick={() =>
                                          item?.organizations_id &&
                                          goToOrganizationSettings(
                                             item?.organizations_id
                                          )
                                       }
                                    >
                                       {t("default.continue")}
                                    </ButtonField>
                                 </div>
                              </div>
                           </Col>
                        ) : null
                  )}

                  <Col
                     xs={24}
                     sm={20}
                     lg={15}
                     xl={8}
                     className={styles.col}
                     key={"1"}
                  >
                     <div className={`${styles.block} ${styles.profile}`}>
                        <div className="d-flex-between">
                           <div className="d-flex-center flex-align-center">
                              <img className={styles.logo} src={blackLogo} />
                              <span className={styles.title}>
                                 {t("onboarding.intro")}
                              </span>
                           </div>
                        </div>
                        <div className={styles.description}>
                           {t("onboarding.introText")}
                        </div>
                        <div className={styles.options}>
                           <div className="d-flex">
                              <img
                                 className={styles.icon}
                                 src={icon_passportmanager}
                              />
                              <div>{t("onboarding.addBuildingText")}</div>
                           </div>
                           <ButtonField
                              type="alternative"
                              onClick={goToBuildings}
                           >
                              <PlusOutlined />
                              {t("onboarding.building")}
                           </ButtonField>
                        </div>
                        <div className={styles.options}>
                           <div className="d-flex">
                              <img
                                 className={styles.icon}
                                 src={icon_catalogue}
                              />
                              <div>{t("onboarding.showroomText")}</div>
                           </div>
                           <ButtonField type="negative" onClick={goToShowroom}>
                              <ArrowRightOutlined />
                              {t("onboarding.showroom")}
                           </ButtonField>
                        </div>
                        <div className={styles.options}>
                           <div className="d-flex">
                              <img className={styles.icon} src={icon_app} />
                              <div>{t("onboarding.measureText")}</div>
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
            ) : null}
         </Spin>
      </div>
   );
};

export default Dashboard;
