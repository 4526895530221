import { Checkbox, Form } from "antd";
import React, { useState } from "react";
import styles from "./style.module.scss";

interface IProps {
   checked: boolean;
   disabled?: boolean;
   onChange?: (e: any) => any;
   label?: string;
   value?: string;
   required?: boolean;
   fieldName?: string;
   className?: string;
   formClassName?: string;
   noMargin?: boolean;
   round?: boolean;
}

const CheckBoxSingleField = ({
   checked,
   disabled,
   onChange,
   label,
   value,
   required,
   fieldName = "",
   className,
   formClassName,
   noMargin,
   round
}: IProps) => {
   const [isChecked, setChecked] = useState(checked);

   const handleChange = (e: any) => {
      setChecked(e.target.checked);
      onChange && onChange(e.target.checked);
   };

   return (
      <div className={`${styles.checkBoxField} ${className}`}>
         <Form.Item
            name={[fieldName]}
            rules={[{ required: required }]}
            className={formClassName}
            style={noMargin ? { marginBottom: 0 } : {}}
         >
            <Checkbox
               checked={isChecked}
               value={value}
               disabled={disabled}
               onChange={handleChange}
               className={round ? "round" : ""}
            />
         </Form.Item>
         {label && <div className={styles.label}>{label}</div>}
      </div>
   );
};

export default CheckBoxSingleField;
