import * as uuid from "uuid";
import axios from "axios";
import { Auth } from "aws-amplify";
import { Resource } from "../../../../../api/common";

interface DocumentFiles {
   document_type: string;
   documents_files: {
      file_name: string;
      file_path: string;
      object_url: string;
      bucket_name: string;
   };
}

export async function getUploadedMaterialDocumentsLinks(values: any) {
   const assetId = `${uuid.v4()}_${Date.now()}`;
   const assetType = "material_documents";
   const documentFiles: DocumentFiles[] = [];

   const accessToken = (await Auth.currentSession()).getIdToken();
   const signedUrlPromiseList = values.documents
      .map(async (item: any) => {
         if (!item?.documents_files?.url) {
            // Get Upload Signed URL
            const response = await axios.post(
               Resource.path.getSignedUrlForMaterialAssetsHub,
               {
                  assetType,
                  assetId,
                  fileName: item.documents_files.file.name
               },
               { headers: { Authorization: accessToken.getJwtToken() } }
            );
            const result = response?.data?.data;
            if (result) {
               const documentFileObject =
                  item.documents_files.fileList[0].originFileObj;
               return axios
                  .put(result.signedUrl, documentFileObject)
                  .then(() => {
                     documentFiles.push({
                        document_type: item.document_type,
                        documents_files: {
                           file_name: result.fileName,
                           file_path: result.filePath,
                           object_url: result.objectUrl,
                           bucket_name: result.bucketName
                        }
                     });
                  });
            }
         } else {
            documentFiles.push({
               document_type: item.document_type,
               documents_files: item?.documents_files
            });
         }
      })
      .filter(Boolean);

   await Promise.all(signedUrlPromiseList);
   return documentFiles;
}
