import { useReducer } from "react";
import { INITIAL_STATE } from "./index";
import { reducer } from "./reducer";

export const useMaterialWizardAction = (initialState = INITIAL_STATE) => {
   const [state, dispatch] = useReducer(reducer, initialState);

   const updateProductDetailsWizardDetails = (productDetails: any) => {
      dispatch({
         type: "UPDATE_PRODUCT_DETAILS_WIZARD",
         payload: {
            ...productDetails
         }
      });
   };

   const updatePassportWizardDetails = (passportDetails: any) => {
      dispatch({
         type: "UPDATE_PASSPORT_DETAILS_WIZARD",
         payload: {
            ...passportDetails
         }
      });
   };
   const updateReuseWizardDetails = (reuseDetails: any) => {
      dispatch({
         type: "UPDATE_REUSE_DETAILS_WIZARD",
         payload: {
            ...reuseDetails
         }
      });
   };

   const updatePassportTypeDetails = (type: any) => {
      dispatch({
         type: "UPDATE_PASSPORT_TYPE",
         payload: {
            type
         }
      });
   };

   const resetMaterialPassportWizard = () => {
      dispatch({
         type: "RESET_MATERIAL_WIZARD_FORM"
      });
   };

   const getProductDetailsWizardDetails = () => {
      return state.wizard_steps_product_details;
   };
   const getPassportWizardDetails = () => {};
   const getReuseWizardDetails = () => {};

   return {
      state,
      getProductDetailsWizardDetails,
      getPassportWizardDetails,
      getReuseWizardDetails,

      updateProductDetailsWizardDetails,
      updatePassportWizardDetails,
      updateReuseWizardDetails,
      updatePassportTypeDetails,
      resetMaterialPassportWizard
   };
};
