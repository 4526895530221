import React from "react";
import { MainLayout } from "../../components/Layouts/index";
import { Switch, Route, Redirect } from "react-router-dom";
import { BuildingViews } from "../Building";
import { SettingsView } from "../Settings";
import { LanguageProvider } from "../../contexts/language/index";
import { AddEditBuildingForm } from "../Building/AddEditForm/AddEditBuildingForm";
import { AuthProvider } from "../../contexts/auth";
import { BuildingListView } from "../Building/List/BuildingListView";
import { CatalogueView } from "../Catalogue";
import { MaterialHubViews } from "../MaterialHub";
import { DemandViews } from "../Demand";
import Onboarding from "../Onboarding";
import Subscription from "../Subscription";

export const AuthorizedAppViews = ({ locale }: { locale: string }) => {
   return (
      <AuthProvider>
         <LanguageProvider initLocale={locale}>
            <MainLayout>
               <Switch>
                  <Route
                     exact
                     path="/"
                     render={() => {
                        return <Redirect to="/buildings"></Redirect>;
                     }}
                  ></Route>
                  <Route
                     exact
                     path="/buildings"
                     component={BuildingListView}
                  ></Route>
                  <Route
                     exact
                     path="/buildings/edit/:id"
                     component={AddEditBuildingForm}
                  ></Route>
                  <Route
                     exact
                     path="/buildings/add"
                     component={AddEditBuildingForm}
                  ></Route>
                  <Route
                     path="/buildings/:id"
                     component={BuildingViews}
                  ></Route>
                  <Route path="/settings" component={SettingsView}></Route>
                  <Route path="/catalogue" component={CatalogueView}></Route>
                  <Route path="/hub" component={MaterialHubViews}></Route>
                  <Route path="/demand" component={DemandViews}></Route>
                  <Route path="/onboarding" component={Onboarding}></Route>
                  <Route
                     path="/subscription/:organisation_id/:building_id"
                     component={Subscription}
                  ></Route>
               </Switch>
            </MainLayout>
         </LanguageProvider>
      </AuthProvider>
   );
};
