import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useGetLca } from "./LcaPotentialCalculate";
import { Skeleton } from "antd";

interface Props {
   data: {
      name: string;
      id: string;
      children?: { parentInfo: any; productGroups: [] }[] | undefined;
   }[];
}

interface barDataType {
   data: any[];
   keys: string[];
}

export default function StackedBar({ data }: Props) {
   const getLca = useGetLca();
   const [barData, setBarData] = useState<barDataType>({ data: [], keys: [] });
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      const barData: any[] = [];
      const keys: any[] = [];
      //console.log(data);
      data.map((cat, index) => {
         barData.push({ category: cat.name });
         cat.children?.map((child) => {
            barData[index][child.parentInfo.category_name] = getLca.getLCA(
               child.productGroups.map((pg: { data: any }) => pg?.data),
               child.productGroups.map((pg: { amount: any }) => pg?.amount),
               child.productGroups.map(
                  (pg: { selectedMaterial: any }) => pg?.selectedMaterial
               ),
               child.productGroups.map(
                  (pg: { passports: any }) => pg?.passports
               )
            ).total;
            keys.push(child.parentInfo?.category_name);
         });
      });
      setBarData({ data: barData, keys: keys });
      // let values_list: any[] | any = barData.length ? barData.map(item => Object.values(item)) : [];
      // values_list.forEach((item: any[]) => item.shift())
      // values_list = [].concat.apply([], values_list)
      // values_list = [...new Set(values_list)].sort()
      // setTickValues(values_list);
   }, [data.length]);

   useEffect(() => {
      const timer = setTimeout(() => {
         setLoading(false);
      }, 3000);

      return () => {
         clearTimeout(timer);
      };
   }, []);

   return (
      <>
         <div style={{ width: "95%", height: "400px" }}>
            <Skeleton
               loading={loading}
               active
               className="bar-skeleton"
               paragraph={{ rows: 1 }}
               title={false}
            >
               <ResponsiveBar
                  data={barData.data}
                  keys={barData.keys}
                  indexBy="category"
                  margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  valueScale={{ type: "symlog" }}
                  indexScale={{ type: "band", round: true }}
                  colors={{ scheme: "nivo" }}
                  defs={[
                     {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "#38bcb2",
                        size: 4,
                        padding: 1,
                        stagger: true
                     },
                     {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "#eed312",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                     }
                  ]}
                  fill={[
                     {
                        match: {
                           id: "fries"
                        },
                        id: "dots"
                     },
                     {
                        match: {
                           id: "sandwich"
                        },
                        id: "lines"
                     }
                  ]}
                  borderColor={{
                     from: "color",
                     modifiers: [["darker", 1.6]]
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                     tickSize: 5,
                     tickPadding: 5,
                     tickRotation: 0,
                     legend: "category",
                     legendPosition: "middle",
                     legendOffset: 32
                  }}
                  axisLeft={{
                     tickSize: 5,
                     tickPadding: 5,
                     tickRotation: 0,
                     legend: "LCA Potential",
                     legendPosition: "middle",
                     legendOffset: -90,
                     tickValues: 2
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                     from: "color",
                     modifiers: [["darker", 1.6]]
                  }}
                  legends={[
                     {
                        dataFrom: "keys",
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                           {
                              on: "hover",
                              style: {
                                 itemOpacity: 1
                              }
                           }
                        ]
                     }
                  ]}
                  role="application"
                  ariaLabel="Nivo bar"
                  barAriaLabel={function (e) {
                     return (
                        e.id +
                        ": " +
                        e.formattedValue +
                        " in category: " +
                        e.indexValue
                     );
                  }}
               />
            </Skeleton>
         </div>
      </>
   );
}
