import { Col, Row, Form, Input } from "antd";
import * as React from "react";
import { FormView } from "./FormView";
import BrandSearchField from "../BrandSearchField";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { Response, Material, ProductGroup } from "../../../../../../types";
import { Resource } from "../../../../../../api/common";
import { MaterialDropdown } from "../../../../../../components/MaterialDropdown";
import { FormInstance } from "antd";

type Props = {
   schema: any;
   productGroup?: ProductGroup;
   form: FormInstance;
};

const ProductSpecification = ({ schema, productGroup, form }: Props) => {
   const [t] = useTranslation();
   const [{ data: materialsData }] = useAxios<Response<{ data: Material[] }>>(
      Resource.path.getMaterialIngredients
   );
   const selected_material = form.getFieldValue("selected_material");
   const materials = materialsData?.data.data;
   const lcaMetaMaterials = materials
      ? productGroup?.lca_meta
           ?.filter((material) => material.sub_material_id)
           .map((material) => {
              return materials?.find(
                 (m) => m.id === material.sub_material_id
              ) as Material;
           })
      : undefined;

   return (
      <>
         <Row gutter={12}>
            <Col xs={24} sm={12}>
               <Form.Item
                  label={t(
                     "productDetails.productCategory.specification.manufacturerName"
                  )}
                  name="manufacturer"
               >
                  <BrandSearchField></BrandSearchField>
               </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
               <Form.Item
                  label={t(
                     "productDetails.productCategory.specification.productName"
                  )}
                  name="name"
                  rules={[
                     {
                        required: true,
                        message: t(
                           "productDetails.productCategory.specification.productNameReqMessage"
                        )
                     }
                  ]}
               >
                  <Input type="text" />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={12}>
            <Col xs={24} sm={12}>
               <Form.Item
                  label={t("productDetails.productCategory.specification.ena")}
                  name="ean"
               >
                  <Input type="text" />
               </Form.Item>
            </Col>
         </Row>
         {lcaMetaMaterials && lcaMetaMaterials.length > 0 && (
            <Row gutter={12}>
               <Col xs={24} sm={12}>
                  <>
                     <label>
                        {productGroup?.lca_meta_label || "Material auswählen"}
                     </label>
                     <MaterialDropdown
                        required
                        initial={
                           selected_material && {
                              parent_material_id:
                                 selected_material.parent_material_id,
                              sub_material_id: selected_material.sub_material_id
                           }
                        }
                        name={["selected_material"]}
                        materials={lcaMetaMaterials}
                        setMaterialGroup={() => {
                           const fields = form.getFieldsValue();
                           if (fields["selected_material"]?.sub_material_id) {
                              fields["selected_material"].sub_material_id =
                                 undefined;
                              form.setFieldsValue(fields);
                           }
                        }}
                     />
                  </>
               </Col>
            </Row>
         )}
         <Row justify="start">
            <FormView schema={schema}></FormView>
         </Row>
      </>
   );
};

export default ProductSpecification;
