import React from "react";
import { Route, Switch } from "react-router-dom";
import "./style.scss";

import { useTranslation } from "react-i18next";
import { BuildingOperationDetailsView } from "./OperationDetails";

export const BuildingOperationsView = () => {
   const [t] = useTranslation();

   return (
      <>
         <Switch>
            <Route
               exact
               path="/hub/material_pilot"
               component={BuildingOperationDetailsView}
            ></Route>
         </Switch>
      </>
   );
};
