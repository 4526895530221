export const reducer = (state: any, action: any) => {
   switch (action.type) {
      case "ADD_BUILDING":
         return {
            ...state,
            buildings: [...state.buildings, action.payload.building]
         };
      case "RESET_BUILDING":
         return {
            ...state,
            buildings: [...action.payload.buildings]
         };
      default:
         throw new Error(`Unknown action: ${action.type}`);
   }
};
