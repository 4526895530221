import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "./style.scss";
import { BuildingProvider } from "../../contexts/building/index";
import { useAuth } from "../../contexts/auth/index";
import { Building } from "./Building";

export const BuildingViews = () => {
   const { loadingUserInfo, onBoarded, user: currentUser } = useAuth();
   const history = useHistory();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [t] = useTranslation();
   useEffect(() => {
      if (currentUser) {
         setIsLoading(false);
      }
      if (!onBoarded && !loadingUserInfo && !isPrivateOwner) {
         history.push("/onboarding");
      }
   }, [onBoarded, loadingUserInfo, isPrivateOwner, currentUser, history]);

   if (isLoading) return <div className="loading">{t("common.loading")}</div>;

   return (
      <BuildingProvider>
         <Building />
      </BuildingProvider>
   );
};
