import { ProductGroup, Response, MaterialPassport } from "../types";
import React from "react";
import axios from "axios";
import { Resource } from "../api/common";
import { Auth } from "aws-amplify";

type Props = {
   materialPassports: MaterialPassport[];
};

export const useGetBatchProductGroups = ({ materialPassports }: Props) => {
   const [productGroups, setProductGroups] =
      React.useState<{
         [key: string]: {
            data: ProductGroup;
            amount: number;
            selectedMaterial?: string;
            passport?: any;
         };
      }>();

   const fetchProductGroups = async () => {
      if (productGroups) {
         return;
      }
      const accessToken = (await Auth.currentSession()).getIdToken();
      const pgTmp: {
         [key: string]: {
            data: ProductGroup;
            amount: number;
            selectedMaterial?: string;
            passports?: any;
         };
      } = {};
      if (materialPassports?.length > 0) {
         for (let i = 0; i < materialPassports?.length; i++) {
            const materialPassport = materialPassports[i];
            if (!pgTmp[materialPassport.product_group_id]) {
               const response = await axios.get<Response<ProductGroup>>(
                  `${Resource.path.getProductGroupDetails}/${materialPassport.product_group_id}`,
                  { headers: { Authorization: accessToken.getJwtToken() } }
               );
               pgTmp[materialPassport.product_group_id] = {
                  data: response.data.data,
                  amount: materialPassport.total_amount,
                  selectedMaterial:
                     materialPassport?.selected_material?.sub_material_id,
                  passports: materialPassport
               };
            }
         }
         setProductGroups(pgTmp);
      }
   };
   fetchProductGroups();

   return productGroups
      ? Object.keys(productGroups).map((item) => {
           return productGroups?.[item];
        })
      : [];
};
