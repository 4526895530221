import { Upload, Tooltip } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { PlusOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

const uploadButton = (
   <div>
      <PlusOutlined />
   </div>
);

function getBase64(file: any) {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });
}

export const ImageUploaderSortable = ({ value, onChange, form }: any) => {
   const [previewVisible, setPreviewVisible] = useState(false);
   const [previewImage, setPreviewImage] = useState();
   const [previewTitle, setPreviewTitle] = useState();
   const [fileList, setFileList] = useState(value || []);
   const type = "picture-card";

   const handleOnChange = ({ fileList }: any) => {
      onChange(fileList);
      setFileList(fileList);
   };

   useEffect(() => {
      if (value && value.length) {
         const fileList = value.map((item: any, index: number) => {
            if (item?.object_url) {
               return {
                  ...item,
                  id: index,
                  url: item.object_url
               };
            } else {
               return item;
            }
         });

         setFileList([...fileList]);
      }
   }, [value]);

   const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
         file.preview = await getBase64(file.originFileObj);
      }

      setPreviewVisible(true);
      setPreviewImage(file.url || file.preview);
      setPreviewTitle(
         file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
   };
   const handleCancel = () => {
      setPreviewVisible(false);
   };

   const handleBeforeUpload = () => {
      return false;
   };

   const moveRow = useCallback(
      (dragIndex, hoverIndex) => {
         const dragRow = fileList[dragIndex];
         setFileList(
            update(fileList, {
               $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, dragRow]
               ]
            })
         );
         const images = form.getFieldValue("images");
         const tmp = images[hoverIndex];
         images[hoverIndex] = images[dragIndex];
         images[dragIndex] = tmp;
      },
      [fileList]
   );

   const DraggableUploadImage = ({
      originNode,
      moveRow,
      file,
      fileList
   }: any) => {
      const ref = React.useRef<any>();
      const index = fileList.indexOf(file);
      const [{ isOver }, drop] = useDrop({
         accept: type,
         collect: (monitor) => {
            const { index: dragIndex }: any = monitor.getItem() || {};
            if (dragIndex === index) {
               return {};
            }
            return {
               isOver: monitor.isOver()
            };
         },
         drop: (item: any) => {
            moveRow(item.index, index);
         }
      });
      const [, drag] = useDrag({
         type,
         item: { index },
         collect: (monitor) => ({
            isDragging: monitor.isDragging()
         })
      });
      drop(drag(ref));
      const errorNode = (
         <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
      );
      return (
         <div ref={ref} style={{ cursor: "move" }}>
            {file.status === "error" ? errorNode : originNode}
         </div>
      );
   };

   return (
      <>
         <DndProvider backend={HTML5Backend}>
            <Upload
               fileList={fileList}
               listType="picture-card"
               accept=".png, .jpeg, .jpg"
               multiple={true}
               beforeUpload={handleBeforeUpload}
               onPreview={handlePreview}
               onChange={handleOnChange}
               itemRender={(originNode, file, currFileList) => (
                  <DraggableUploadImage
                     originNode={originNode}
                     file={file}
                     fileList={currFileList}
                     moveRow={moveRow}
                  />
               )}
            >
               {uploadButton}
            </Upload>
         </DndProvider>
         <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
         >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
         </Modal>
      </>
   );
};
