import React, { useEffect, useState } from "react";
import { Menu, Layout, Button, Avatar } from "antd";
import {
   LogoutOutlined,
   UserOutlined,
   MenuOutlined,
   ArrowLeftOutlined
} from "@ant-design/icons";

import { useCookies } from "react-cookie";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/react";

import icon_app from "../../../statics/images/icon_app.svg";
import icon_passportmanager from "../../../statics/images/icon_passportmanager.svg";
import icon_materialhub from "../../../statics/images/icon_materialhub.svg";
import icon_catalogue from "../../../statics/images/icon_catalogue.svg";
import { Mixpanel } from "../../../utils/tracking/mixpanel";
import { Events } from "../../../utils/tracking/tracking.events";
import { useAuth } from "../../../contexts/auth/index";
import ButtonField from "../../FormFields/ButtonField";
import { ScreenSize } from "../../../utils/screenSizes";

const { Header } = Layout;

export const SideBarMenu = () => {
   const location = useLocation();
   const [t] = useTranslation();
   const history = useHistory();
   const { user: currentUser, onBoarded } = useAuth();
   const [jwtToken, setJwtToke] = useState<any>();
   const [cookies, , removeCookie] = useCookies(["user", "modules"]);

   useEffect(() => {
      Auth.currentSession().then((session) => {
         const token = session.getIdToken().getJwtToken();
         setJwtToke(token);
      });
   }, [jwtToken]);

   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";

   const handleLogout = async () => {
      try {
         Sentry.configureScope((scope) => scope.setUser(null));
         if (cookies?.modules) {
            removeCookie("modules");
         }
         if (cookies?.user) {
            removeCookie("user");
         }
         (window as any).localStorage.clear();
         (window as any).sessionStorage.clear();
         await Auth.signOut({ global: true });
         if (isPrivateOwner) {
            history.push("/marketplace-auth");
         } else {
            history.push("/login");
         }
      } catch (error) {
         (window as any).localStorage.clear();
         (window as any).sessionStorage.clear();
         history.push("/login");
         console.log("error signing out: ", error);
      }
   };

   const handleProfile = () => {
      history.push("/settings/profile");
      ScreenSize.mobile && setCollapsed(true);
   };

   const [currentMainRoutePath] = location?.pathname
      ?.split("/")
      .filter(Boolean);

   const navigateTo = (type: string) => {
      if (type === "passportManager") {
         Mixpanel.track(Events.Visit_Building_Module.name, {});
         history.push("/buildings");
      }

      if (type === "market_catalogue") {
         Mixpanel.track(Events.Visit_MarketPlace_Module.name, {});
         history.push("/catalogue");
      }

      if (type === "material_hub") {
         Mixpanel.track(Events.Visit_MarketPlace_Module.name, {});
         history.push("/hub");
      }

      if (type === "dashboard") {
         Mixpanel.track(Events.Visit_MarketPlace_Module.name, {});
         history.push("/onboarding/dashboard");
      }
   };

   function profileSettings() {
      return (
         <div className="user-avatar-wrapper">
            <div className="footer-action-item">
               <div className="d-flex-between">
                  <Avatar icon={<UserOutlined />} />
                  {onBoarded && (
                     <Button
                        type="text"
                        onClick={handleProfile}
                        className={"settings-btn"}
                     >
                        {t("profileSettings.profile")}
                     </Button>
                  )}
               </div>
               <Button
                  type="text"
                  onClick={handleLogout}
                  className="logout-btn"
               >
                  <LogoutOutlined />
               </Button>
            </div>
         </div>
      );
   }
   const [collapsed, setCollapsed] = useState(
      ScreenSize.desktop ? false : true
   );

   const toggleCollapsed = () => {
      setCollapsed(!collapsed);
   };

   return (
      <>
         <div className="mobile-header">
            <ButtonField
               // size="large"
               type="link"
               onClick={toggleCollapsed}
               className={"menu-button"}
            >
               {collapsed ? <MenuOutlined /> : <ArrowLeftOutlined />}
            </ButtonField>
         </div>

         <Header
            className={`header site-layout-background ${
               collapsed ? "close" : "open"
            }`}
         >
            <ButtonField
               type="link"
               onClick={toggleCollapsed}
               className={"menu-button"}
            >
               {collapsed ? <MenuOutlined /> : <ArrowLeftOutlined />}
            </ButtonField>
            {onBoarded || isPrivateOwner ? (
               <>
                  <Menu
                     theme="light"
                     mode="inline"
                     className="main-menu-wrapper"
                     selectedKeys={[`/${currentMainRoutePath}`]}
                     inlineCollapsed={collapsed}
                  >
                     <Menu.Item
                        key="/onboarding"
                        onClick={() => {
                           navigateTo("dashboard");
                           ScreenSize.mobile && setCollapsed(true);
                        }}
                     >
                        {!isPrivateOwner && (
                           <img
                              src={icon_app}
                              alt="Concular"
                              width="20px"
                           ></img>
                        )}
                        {!!isPrivateOwner && (
                           <img
                              src="https://restado.de/dadi-assets/btk_dadi_logo.png"
                              alt="Concular"
                              width="100px"
                           ></img>
                        )}
                        {`${t("default.welcome")} ${
                           currentUser?.attributes?.given_name
                        }`}
                     </Menu.Item>
                     <Menu.Item
                        key="/buildings"
                        onClick={() => {
                           navigateTo("passportManager");
                           ScreenSize.mobile && setCollapsed(true);
                        }}
                     >
                        <img
                           src={icon_passportmanager}
                           alt={t("sidebarMenus.building")}
                           width="20px"
                        ></img>
                        {t("sidebarMenus.passportManager")}
                     </Menu.Item>

                     <Menu.Item
                        key="/hub"
                        onClick={() => {
                           navigateTo("material_hub");
                           ScreenSize.mobile && setCollapsed(true);
                        }}
                     >
                        <img
                           src={icon_materialhub}
                           alt={t("sidebarMenus.materialHub")}
                           width="20px"
                        ></img>
                        {t("sidebarMenus.materialHub")}
                     </Menu.Item>

                     {!isPrivateOwner && (
                        <Menu.Item
                           key="/catalogue"
                           onClick={() => {
                              navigateTo("market_catalogue");
                              ScreenSize.mobile && setCollapsed(true);
                           }}
                        >
                           <img
                              src={icon_catalogue}
                              alt={t("sidebarMenus.showroom")}
                              width="20px"
                           ></img>
                           {t("sidebarMenus.showroom")}
                        </Menu.Item>
                     )}
                     {isPrivateOwner && (
                        <Menu.Item key="/marketplace">
                           <a
                              href={
                                 process.env.REACT_APP_MARKETPLACE_URL
                                    ? `${process.env.REACT_APP_MARKETPLACE_URL}?session=${jwtToken}`
                                    : `https://www.bauteilkreisel-dadi.net?session=${jwtToken}`
                              }
                              target="_self"
                              rel="noopener"
                              onClick={() =>
                                 ScreenSize.mobile && setCollapsed(true)
                              }
                           >
                              Marktplatz
                           </a>
                        </Menu.Item>
                     )}
                  </Menu>
                  {profileSettings()}
               </>
            ) : (
               profileSettings()
            )}
         </Header>
      </>
   );
};
