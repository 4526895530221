import React from "react";
import { FileTextFilled } from "@ant-design/icons";
import { Spin } from "antd";

export const ShowUploadedFile = ({ fileName }: { fileName: string }) => {
   return (
      <Spin>
         <div className="file-upload-view-container">
            <div className="file-upload-button">
               <FileTextFilled
                  twoToneColor="#118060"
                  style={{
                     fontSize: "40px",
                     marginBottom: "10px",
                     color: "#118060"
                  }}
               />
               <p className="ant-upload-hint">{fileName}</p>
            </div>
         </div>
      </Spin>
   );
};
