import React, { useEffect, useState } from "react";
import { Resource } from "../../../../api/common";
import useAxios from "axios-hooks";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useFlagsmith } from "flagsmith-react";
import moment from "moment";
import { CheckOutlined } from "@ant-design/icons";
import { PassportCluster } from "./PassportCluster";

export const PassportList = ({
   material,
   showRequest,
   addAmount,
   refresh,
   setRefresh,
   tempSelected
}: any) => {
   const [passports, setPassports] = useState<any>([]);
   const [t] = useTranslation();
   const { isLoading: isLoadingFlag } = useFlagsmith();

   const { building_id } = material;
   const [{ data, loading: getLoading, error: getError }, refetch] = useAxios(
      {
         url: building_id
            ? `${Resource.path.getClusteredPassports}/list/${material.id}`
            : `${Resource.path.getClusteredPassportsHub}/list/${material.id}`,
         method: "GET"
      },
      { useCache: false }
   );

   useEffect(() => {
      if (data?.data) {
         const passports = data?.data;
         if (passports) {
            setPassports(passports);
         }
      }
      if (refresh) {
         refetch();
         setRefresh(false);
      }
   }, [data, refresh]);

   useEffect(() => {
      if (data?.data) {
         const passportList = data?.data;
         if (passportList) {
            setPassports(passportList);
         }
      }
   }, [data]);

   if (getLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   if (getLoading || isLoadingFlag)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <>
         <div className="passport-list-header">
            {material.amount_op && (
               <h2>{t("materialDashboard.availableItems")}</h2>
            )}
         </div>
         <div className="passport-list">
            {passports.map((passport: any) => {
               return (
                  <>
                     <PassportCluster
                        passportCluster={passport}
                        passport={passport}
                        key={passport.id}
                        material={material}
                        showRequest={showRequest}
                        selectAmount={addAmount}
                        tempSelected={tempSelected}
                        currentItem={
                           tempSelected
                              ? tempSelected.filter(
                                   (item: { selling_id: any }) =>
                                      item.selling_id == passport.id
                                )[0]
                              : undefined
                        }
                     />
                  </>
               );
            })}
         </div>
         <div className="self-deconstruction-date">
            {passports.length && passports[0].self_start_date_deconstruction && (
               <div>
                  <CheckOutlined />
                  {t("passportDetails.optional")}:{" "}
                  {t("passportDetails.selfDeconstructionPeriod")}
                  {` ${moment(
                     passports[0].self_start_date_deconstruction
                  ).format("DD/MM/YYYY")} - ${moment(
                     passports[0].self_end_date_deconstruction
                  ).format("DD/MM/YYYY")}`}
               </div>
            )}
         </div>
      </>
   );
};
