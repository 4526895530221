import {
   Col,
   message,
   Popconfirm,
   Row,
   Spin,
   Collapse,
   Form,
   Table
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import ButtonField from "../../components/FormFields/ButtonField";
import SettingsSideBar from "./SettingsSideBar";
import styles from "./Settings.module.scss";
import {
   PlusOutlined,
   CheckOutlined,
   CaretRightOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import DropdownField from "../../components/FormFields/DropdownField";
import MentionField from "../../components/FormFields/MentionField";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { useParams } from "react-router";
import {
   User as UserType,
   Organization as OrganizationType
} from "./../../types";
import { InviteUserModal } from "./InviteUserModal";
import { rolesList } from "./consts.d";
import { useAuth } from "../../contexts/auth";
import { truncate } from "../../utils/text/textUtils";
import InputField from "../../components/FormFields/InputField";
import { useForm } from "antd/lib/form/Form";

const OrganizationUserManagement = () => {
   const [t] = useTranslation();
   const params: { id: string } = useParams();
   const [users, setUsers] = useState<Array<UserType>>([]);
   const [showAddModal, setShowAddModal] = useState(false);
   const [organizationDetails, setOrganizationDetails] = useState<
      Array<OrganizationType>
   >([]);
   const [namespaces, setNamespaces] = useState<any>([]);
   const { user: currentUser } = useAuth();
   const [form] = useForm();
   const [notificationEmail, setNotificationEmail] = useState<string>();

   const updateNamespaces = async () => {
      if (namespaces.length && form.getFieldValue("email")) {
         const data = namespaces.join(", ");
         await putNamespacesAPI({
            data: {
               mail_namespaces: data || "",
               notification_email: form.getFieldValue("email")
            }
         })
            .then(
               async (resp) =>
                  await getOrganizationDetailsAPI().then((resp) =>
                     message.success(t("settings.successMessage"))
                  )
            )
            .catch((err) => message.error(t("settings.errorMessage")));
      } else {
         getOrganizationDetailsAPI();
         message.error(t("settings.emptyNamespacesMessage"));
      }
   };

   const [{ data: usersData, loading: usersLoading }, getUsersAPI] = useAxios(
      {
         url: Resource.path.organization.getUsers(params?.id),
         method: "GET"
      },
      { manual: false, useCache: true }
   );

   const [{ loading: namepacesLoading }, putNamespacesAPI] = useAxios(
      {
         url: Resource.path.organization.putNamespaces(params?.id),
         method: "PUT"
      },
      { manual: true }
   );

   const [{ data: organizationData }, getOrganizationDetailsAPI] = useAxios(
      {
         url: Resource.path.organization.getOrganizationDetails(params.id),
         method: "GET"
      },
      {
         manual: false
      }
   );

   const [{ loading: userSettingsLoading }, updateUserSettingsAPI] = useAxios(
      {
         method: "PUT"
      },
      {
         manual: true
      }
   );

   const [{}, resendInviteAPI] = useAxios(
      {
         url: "",
         method: "GET"
      },
      {
         manual: true
      }
   );

   useEffect(() => {
      // usersData?.data && setUsers(usersData?.data);
      usersData?.data &&
         setUsers(
            usersData?.data.filter(
               (item: { status: string }) => item.status != "DECLINED"
            )
         );
   }, [usersData]);

   useEffect(() => {
      setOrganizationDetails(organizationData?.data);
      setNamespaces(
         organizationData?.data?.mail_namespaces
            ? organizationData?.data?.mail_namespaces.split(",")
            : []
      );
      setNotificationEmail(
         organizationData?.data?.notification_email || undefined
      );
      form.setFieldsValue({
         email: organizationData?.data?.notification_email || undefined
      });
   }, [organizationData, form]);

   const removeUser = async (user_organizations_id: number) => {
      await updateUserSettingsAPI({
         url: `${Resource.path.organization.updateUserSettings}/${user_organizations_id}`,
         method: "DELETE"
      })
         .then(
            async (res) =>
               await getUsersAPI().then(() => {
                  message.success(
                     t("settings.organization.userRemovedSuccessfully")
                  );
               })
         )
         .catch((err) => message.error(t("settings.errorMessage")));
   };

   const resendInvite = async (user_organizations_id: number) => {
      await resendInviteAPI({
         url: Resource.path.organization.resendInvite(user_organizations_id)
      })
         .then((res) =>
            message.success(t("settings.organization.resendSuccessMessage"))
         )
         .catch((err) => message.error(t("settings.errorMessage")));
   };

   const respondUserInvitation = async (
      user_organizations_id: number,
      user_id: string,
      state: "ACCEPTED" | "PENDING" | "DECLINED"
   ) => {
      await updateUserSettingsAPI({
         url: `${Resource.path.organization.updateUserSettings}/${user_organizations_id}`,
         method: "PUT",
         data: { user_id: user_id, status: state }
      })
         .then(
            async (res) =>
               await getUsersAPI()
                  .then(() => {
                     message.success(
                        state == "ACCEPTED"
                           ? t("settings.organization.userAcceptMessage")
                           : t("settings.organization.userDeclinedMessage")
                     );
                  })
                  .catch((err) => message.error(t("settings.errorMessage")))
         )
         .catch((err) => message.error(t("settings.errorMessage")));
   };

   const changeUserRole = async (
      user_organizations_id: number,
      user_id: string,
      role: string | any
   ) => {
      await updateUserSettingsAPI({
         url: `${Resource.path.organization.updateUserSettings}/${user_organizations_id}`,
         method: "PUT",
         data: { user_id: user_id, user_role: role.toUpperCase() }
      })
         .then(
            async (res) =>
               await getUsersAPI()
                  .then(async () => {
                     await getUsersAPI().then((resp) =>
                        message.success(
                           t("settings.organization.userRoleMessage")
                        )
                     );
                  })
                  .catch((err) => message.error(t("settings.errorMessage")))
         )
         .catch((err) => message.error(t("settings.errorMessage")));
   };

   const openCloseAddModal = () => {
      setShowAddModal(!showAddModal);
   };

   const userStatus = (status: string | undefined) => {
      switch (status) {
         case "ACCEPTED":
            return t("settings.organization.active");
         case "INVITED":
            return t("settings.organization.invited");
         case "PENDING":
            return t("settings.organization.accessRequested");
         default:
            return "";
      }
   };

   const saveChanges = () => {
      form.submit();
      // console.log(data, "DATA");
      form.validateFields().then(async () => {
         await updateNamespaces();
      });
   };

   const { Panel } = Collapse;

   const columns = [
      {
         title: t("settings.organization.user"),
         dataIndex: "given_name",
         key: "given_name",
         render: (text: any, record: any) => (
            <span
               style={{
                  color: record.status == "ACCEPTED" ? "#000" : "#818587"
               }}
            >
               {truncate(`${record.given_name} ${record.family_name}`, 25)}
            </span>
         )
      },
      {
         title: t("settings.organization.title"),
         dataIndex: "title",
         key: "title",
         render: (text: any, record: any) => (
            <span
               style={{
                  color: record.status == "ACCEPTED" ? "#000" : "#818587"
               }}
            >
               {truncate(record.title, 25)}
            </span>
         )
      },
      {
         title: t("settings.organization.mail"),
         dataIndex: "mail",
         key: "mail",
         width: 220,
         render: (text: any, record: any) => (
            <span
               style={{
                  color: record.status == "ACCEPTED" ? "#000" : "#818587"
               }}
            >
               {record.email}
            </span>
         )
      },
      {
         title: t("settings.organization.userManagement"),
         dataIndex: "role",
         key: "role",
         width: 170,
         render: (text: any, record: any) => (
            <>
               {currentUser.username == record.user_id ? (
                  `${record.role
                     .toLowerCase()
                     .charAt(0)
                     .toUpperCase()}${record.role.slice(1).toLowerCase()}`
               ) : (
                  <DropdownField
                     items={rolesList}
                     selected={rolesList.find(
                        (role) =>
                           role.value.toLowerCase() == record.role.toLowerCase()
                     )}
                     formItem={false}
                     onChange={(_, value) =>
                        record.user_organizations_id &&
                        changeUserRole(
                           record.user_organizations_id,
                           record.user_id,
                           value
                        )
                     }
                     className={styles.userTypeSelect}
                     style={{ maxWidth: 100 }}
                  />
               )}
            </>
         )
      },
      {
         title: t("settings.organization.status"),
         dataIndex: "invoice_created",
         key: "invoice_created",
         width: 260,
         render: (text: any, record: any) => {
            const statusStyle = {
               color: record.status == "ACCEPTED" ? "#27b47c" : "#ffa200",
               backgroundColor:
                  record.status == "ACCEPTED" ? "#d6f6ea" : "#fff0d6",
               padding: "0 10px",
               borderRadius: "15px",
               minWidth: "66px",
               display: "flex",
               justifyContent: "center",
               width: "auto",
               maxWidth: "fit-content",
               height: "30px",
               alignItems: "center"
            };
            return (
               <div
                  style={{
                     display: "flex",
                     height: "fit-content",
                     alignItems: "center"
                  }}
               >
                  <span style={statusStyle}>
                     {record.invited && record?.status !== "ACCEPTED"
                        ? userStatus("INVITED")
                        : userStatus(record?.status)}
                  </span>
                  {record.joined_at && (
                     <span
                        style={{
                           paddingLeft: "8px",
                           color: "#818587",
                           fontSize: "11px",
                           fontWeight: "normal"
                        }}
                     >{`${t("settings.organization.since")} ${moment(
                        record.joined_at
                     ).format("DD/MM/YYYY")}`}</span>
                  )}
                  {record.invited &&
                  record?.status !== "ACCEPTED" &&
                  record.invited_by_user_id == currentUser.username ? (
                     <ButtonField
                        type="link"
                        style={{ color: "#000" }}
                        onClick={() =>
                           record?.user_organizations_id &&
                           resendInvite(record.user_organizations_id)
                        }
                     >
                        {t("settings.organization.resend")}
                     </ButtonField>
                  ) : null}
               </div>
            );
         }
      },
      {
         title: "",
         dataIndex: "user_organizations_id",
         key: "user_organizations_id",
         width: 165,
         render: (text: any, record: any) => (
            <>
               {record.status == "PENDING" &&
               !record.invited &&
               record.user_id != currentUser.username ? (
                  <span>
                     <ButtonField
                        type="primary"
                        size="small"
                        style={{ marginLeft: 4 }}
                        onClick={() =>
                           record.user_organizations_id &&
                           respondUserInvitation(
                              record.user_organizations_id,
                              record.user_id,
                              "ACCEPTED"
                           )
                        }
                     >
                        {t("default.accept")}
                     </ButtonField>
                     <ButtonField
                        type="alternative"
                        size="small"
                        style={{ marginLeft: 4 }}
                        onClick={() =>
                           record.user_organizations_id &&
                           respondUserInvitation(
                              record.user_organizations_id,
                              record.user_id,
                              "DECLINED"
                           )
                        }
                     >
                        {t("default.decline")}
                     </ButtonField>
                  </span>
               ) : (
                  currentUser.username != record.user_id && (
                     <div
                        className={"d-flex"}
                        style={{ justifyContent: "flex-end" }}
                     >
                        <Popconfirm
                           title={t("settings.organization.areYouSure")}
                           onConfirm={() =>
                              record?.user_organizations_id &&
                              removeUser(record.user_organizations_id)
                           }
                           icon={null}
                           okText={t("default.yes")}
                           cancelText={t("default.no")}
                           overlayClassName={"confirm-no-icon"}
                           okButtonProps={{
                              loading: userSettingsLoading
                           }}
                        >
                           <ButtonField type="link" style={{ padding: 5 }}>
                              {t("default.remove").toLowerCase()}
                           </ButtonField>
                        </Popconfirm>
                     </div>
                  )
               )}
            </>
         )
      }
   ];

   return (
      <SettingsSideBar title={t("settings.organization.userManagement")}>
         <div className={`${styles.section} ${styles.invitationSettings}`}>
            <h3 className={styles.fieldsTitle}>
               {t("settings.organization.invitationSettings")}
            </h3>
            <Form
               form={form}
               onFinish={saveChanges}
               className={styles.inviteUser}
               initialValues={{ email: notificationEmail }}
            >
               <Row gutter={5}>
                  <Col sm={8} xs={24}>
                     <p className={styles.description}>
                        {t("settings.organization.invitationSettingsText")}
                     </p>
                  </Col>
                  <Col sm={10} xs={24} className={"d-flex-between flex-top"}>
                     <MentionField
                        items={namespaces}
                        setItems={setNamespaces}
                        errorText="namespace"
                        placeholder="@example.com"
                        regEx={/@[a-z0-9.-]+\.[a-z]{2,4}$/}
                     />
                  </Col>
               </Row>
               <Row gutter={5} style={{ marginTop: 7 }}>
                  <Col sm={8} xs={24}>
                     <p className={styles.description}>
                        {t("settings.organization.creatorEmailText")}
                     </p>
                  </Col>
                  <Col sm={10} xs={24} className={""}>
                     <InputField
                        placeholder={"mail@example.com"}
                        type={"email"}
                        size={"large"}
                        required
                        fieldErrorMessage={t(
                           "settings.organization.emailRequiredMessage"
                        )}
                        fieldName="email"
                     />
                     <div className={styles.saveChanges}>
                        <ButtonField
                           type="default"
                           size="middle"
                           htmlType="submit"
                           loading={namepacesLoading}
                        >
                           {t("settings.organization.saveChanges")}
                        </ButtonField>
                     </div>
                  </Col>
               </Row>
            </Form>
         </div>
         <div className={`${styles.section} ${styles.memberSettings}`}>
            <div className="d-flex-between">
               <h3 className={styles.fieldsTitle}>
                  {t("settings.organization.member")}
               </h3>
               <ButtonField
                  type="default"
                  size="middle"
                  icon={<PlusOutlined />}
                  onClick={openCloseAddModal}
               >
                  {t("settings.organization.inviteUser")}
               </ButtonField>
            </div>
            <div className={styles.members}>
               <Spin spinning={usersLoading}>
                  {/* <Row className={styles.titleRow}>
                     <Col span={4}>{t("settings.organization.user")}</Col>
                     <Col span={3}>{t("settings.organization.title")}</Col>
                     <Col span={5}>{t("settings.organization.mail")}</Col>
                     <Col span={4}>
                        {t("settings.organization.userManagement")}
                     </Col>
                     <Col span={5}>{t("settings.organization.status")}</Col>
                     <Col span={3}></Col>
                  </Row>
                  {users.map((item: UserType) => (
                     <Row
                        className={`${styles.userRow} ${
                           item.status == "ACCEPTED" && styles.active
                        }`}
                     >
                        <Col span={4}>
                           {truncate(
                              `${item.given_name} ${item.family_name}`,
                              25
                           )}
                        </Col>
                        <Col span={3}>{item.title}</Col>
                        <Col span={5}>{item.email}</Col>
                        <Col span={4}>
                           {currentUser.username == item.user_id ? (
                              `${item.role
                                 .toLowerCase()
                                 .charAt(0)
                                 .toUpperCase()}${item.role
                                 .slice(1)
                                 .toLowerCase()}`
                           ) : (
                              <DropdownField
                                 items={rolesList}
                                 selected={rolesList.find(
                                    (role) =>
                                       role.value.toLowerCase() ==
                                       item.role.toLowerCase()
                                 )}
                                 onChange={(_, value) =>
                                    item.user_organizations_id &&
                                    changeUserRole(
                                       item.user_organizations_id,
                                       item.user_id,
                                       value
                                    )
                                 }
                                 className={styles.userTypeSelect}
                              />
                           )}
                        </Col>
                        <Col span={5} className={styles.statusCol}>
                           <span className={styles.status}>
                              {item.invited && item?.status !== "ACCEPTED"
                                 ? userStatus("INVITED")
                                 : userStatus(item?.status)}
                           </span>
                           {item.joined_at && (
                              <span className={styles.joinedDate}>{`${t(
                                 "settings.organization.since"
                              )} ${moment(item.joined_at).format(
                                 "DD/MM/YYYY"
                              )}`}</span>
                           )}
                           {item.invited &&
                           item?.status !== "ACCEPTED" &&
                           item.invited_by_user_id == currentUser.username ? (
                              <ButtonField
                                 type="link"
                                 className={styles.resend}
                                 onClick={() =>
                                    item?.user_organizations_id &&
                                    resendInvite(item.user_organizations_id)
                                 }
                              >
                                 {t("settings.organization.resend")}
                              </ButtonField>
                           ) : null}
                        </Col>
                        <Col span={3}>
                           {item.status == "PENDING" &&
                           !item.invited &&
                           item.user_id != currentUser.username ? (
                              <div className={styles.buttons}>
                                 <ButtonField
                                    type="primary"
                                    size="small"
                                    className={styles.normalButton}
                                    onClick={() =>
                                       item.user_organizations_id &&
                                       respondUserInvitation(
                                          item.user_organizations_id,
                                          item.user_id,
                                          "ACCEPTED"
                                       )
                                    }
                                 >
                                    {t("default.accept")}
                                 </ButtonField>
                                 <ButtonField
                                    type="alternative"
                                    size="small"
                                    className={styles.normalButton}
                                    onClick={() =>
                                       item.user_organizations_id &&
                                       respondUserInvitation(
                                          item.user_organizations_id,
                                          item.user_id,
                                          "DECLINED"
                                       )
                                    }
                                 >
                                    {t("default.decline")}
                                 </ButtonField>
                              </div>
                           ) : (
                              currentUser.username != item.user_id && (
                                 <div className={styles.buttons}>
                                    <Popconfirm
                                       title={t(
                                          "settings.organization.areYouSure"
                                       )}
                                       onConfirm={() =>
                                          item?.user_organizations_id &&
                                          removeUser(item.user_organizations_id)
                                       }
                                       icon={null}
                                       okText={t("default.yes")}
                                       cancelText={t("default.no")}
                                       overlayClassName={"confirm-no-icon"}
                                       okButtonProps={{
                                          loading: userSettingsLoading
                                       }}
                                    >
                                       <ButtonField
                                          type="link"
                                          className={styles.linkButton}
                                       >
                                          {t("default.remove").toLowerCase()}
                                       </ButtonField>
                                    </Popconfirm>
                                 </div>
                              )
                           )}
                        </Col>
                     </Row>
                  ))} */}

                  <Table
                     columns={columns}
                     dataSource={users}
                     pagination={false}
                     className={`new-style font-13 bold header-font-14 ${styles}`}
                  />
                  <Collapse
                     bordered={false}
                     defaultActiveKey={["0"]}
                     expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                     )}
                     className={styles.informationRoles}
                  >
                     <Panel
                        header={t("settings.organization.infoRolesRights")}
                        key="1"
                        className="site-collapse-custom-panel"
                     >
                        <Row>
                           <Col md={12} xs={24}>
                              <strong>Admin</strong>
                              <ul>
                                 <li>
                                    <CheckOutlined />{" "}
                                    {t(
                                       "settings.organization.hasAccessToAllBuildings"
                                    )}
                                 </li>
                                 <li>
                                    <CheckOutlined />{" "}
                                    {t(
                                       "settings.organization.canEditOrganization"
                                    )}
                                 </li>
                                 <li>
                                    <CheckOutlined />{" "}
                                    {t(
                                       "settings.organization.canInviteManageUsers"
                                    )}
                                 </li>
                                 <li>
                                    <CheckOutlined />{" "}
                                    {t(
                                       "settings.organization.canCreateNewBuildings"
                                    )}
                                 </li>
                              </ul>
                           </Col>
                           <Col md={12} xs={24}>
                              <strong>User</strong>
                              <ul>
                                 <li>
                                    <CheckOutlined />{" "}
                                    {t(
                                       "settings.organization.hasAccessToInvitedBuildings"
                                    )}
                                 </li>
                              </ul>
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <p>{t("settings.organization.feedback")} </p>
                           </Col>
                        </Row>
                     </Panel>
                  </Collapse>
               </Spin>
            </div>
         </div>

         {showAddModal && (
            <InviteUserModal
               showModal={showAddModal}
               handleCancelModal={openCloseAddModal}
               getUsersAPI={getUsersAPI}
               type="organization"
            />
         )}
      </SettingsSideBar>
   );
};

export default OrganizationUserManagement;
