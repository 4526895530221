import React, { useEffect, useState } from "react";
import { Button, InputNumber } from "antd";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";

export const PassportCluster = ({
   passportCluster,
   material,
   showRequest,
   selectAmount,
   tempSelected,
   currentItem
}: any) => {
   const history = useHistory();
   const { pathname }: any = useLocation();
   const [t] = useTranslation();
   const [amount, setAmount] = useState(passportCluster.amount);
   const [proposalPrice, setProposalPrice] = useState<any>();
   const showPassportDetails = (passport: any) => {
      history.push({
         pathname: `${pathname}/passports/${passport.id}`,
         state: { passport, material }
      });
   };

   const [requestClicked, setRequestClicked] = useState(false);

   const checkDisable = () => {
      if (currentItem) {
         return currentItem.amount == passportCluster.amount;
      } else {
         return false;
      }
   };

   useEffect(() => {
      const timer = setTimeout(() => {
         setRequestClicked(false);
      }, 2000);

      return () => {
         clearTimeout(timer);
      };
   }, [requestClicked]);

   useEffect(() => {
      !tempSelected.length && setAmount(passportCluster.amount);
   }, [tempSelected]);

   return (
      <>
         <div className="available-passport">
            <div className="availability">
               <span className="green-circle"></span>
               <div>{t("passportDetails.available")}</div>
            </div>
            <div className="passport-details">
               <div className="passport-specs">
                  <div>
                     <div className="pieces">
                        {`${passportCluster?.amount} ${
                           passportCluster?.amount_unit == "Stück"
                              ? t("passportDetails.pieces")
                              : passportCluster.amount_unit
                        }`}
                     </div>
                     <dl>
                        <dt>{t("passportDetails.condition")}:</dt>
                        <dd>{passportCluster.condition}</dd>
                     </dl>
                     <dl>
                        <dt>
                           {passportCluster.deconstructability},{" "}
                           {passportCluster.custom_pollutance}
                        </dt>
                     </dl>
                  </div>
               </div>
               <div className="actions">
                  {!!showRequest && passportCluster.request_status === 0 && (
                     <div className="form-field">
                        <div style={{ width: "100%" }}>
                           <div className="d-flex-between">
                              <InputNumber
                                 value={amount}
                                 onChange={(value) => {
                                    setAmount(value);
                                 }}
                                 max={passportCluster.amount}
                                 min={0}
                                 style={{ width: 200 }}
                              ></InputNumber>
                              <Button
                                 className={
                                    requestClicked
                                       ? "requested"
                                       : checkDisable()
                                       ? "disabled"
                                       : ""
                                 }
                                 type="primary"
                                 shape="round"
                                 disabled={checkDisable()}
                                 onClick={() => {
                                    //console.log(passportCluster)
                                    selectAmount({
                                       amount,
                                       selling_id: passportCluster.id,
                                       proposalPrice,
                                       data: passportCluster
                                    });
                                    setAmount(passportCluster.amount - amount);
                                    setRequestClicked(true);
                                 }}
                                 icon={
                                    requestClicked ? (
                                       <CheckOutlined />
                                    ) : (
                                       <PlusOutlined />
                                    )
                                 }
                              >
                                 {requestClicked ? "" : t("default.request")}
                              </Button>
                           </div>
                           <div>
                              <strong className="text-span">
                                 {passportCluster?.amount_unit == "Stück"
                                    ? t("passportDetails.pieces")
                                    : passportCluster.amount_unit}
                                 {` ${t("passportDetails.for")} ${
                                    passportCluster?.selling_price
                                 } €/${t("passportDetails.piece")}`}
                              </strong>
                           </div>
                        </div>
                     </div>
                  )}

                  {/* {!showRequest && passportCluster.amount > 0 && (
                     <div className="form-field">
                        {passportCluster.amount}&nbsp;
                        <strong>
                           {material.product_unit == "Stück"
                              ? t("passportDetails.pieces")
                              : material.product_unit}
                        </strong>
                     </div>
                  )} */}
               </div>
            </div>
         </div>
         {!!passportCluster?.request_status && (
            <div className="passport-availability-details">
               {passportCluster.request_status === 1 && (
                  <span style={{ width: "100px", display: "inline-block" }}>
                     {`${t(
                        "materialRequestForm.materialAlreadySentText"
                     )} ${moment(passportCluster.request_send_date).format(
                        "YYYY-MM-DD"
                     )}`}
                  </span>
               )}
            </div>
         )}
      </>
   );
};
