import { Auth } from "aws-amplify";
import axios from "axios";
import * as uuid from "uuid";
import { Resource } from "../../../api/common";

export async function uploadFile(file: any): Promise<any> {
   const assetId = `${uuid.v4()}_${Date.now()}`;
   const assetType = "building_files";

   const accessToken = (await Auth.currentSession()).getIdToken();

   const result = await axios.post(
      Resource.path.getSignedUrlForBuildingAssets,
      {
         assetType,
         assetId,
         fileName: file.name
      },
      { headers: { Authorization: accessToken.getJwtToken() } }
   );

   if (result?.data?.data) {
      const {
         data: { signedUrl, fileName, filePath, objectUrl, bucketName }
      } = result.data;
      await axios.put(signedUrl, file.originFileObj);
      return {
         file_name: fileName,
         file_path: filePath,
         object_url: objectUrl,
         bucket_name: bucketName
      };
   }
}
