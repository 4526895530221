import { Col, Form, Input, Row } from "antd";
import * as React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";

export const ProductPrice = ({ unit }: any) => {
   const [t] = useTranslation();
   return (
      <>
         <Row gutter={24}>
            {/* <Col>
          <Space
            style={{ display: "flex", marginBottom: "-12px" }}
            align="center"
          >
            <Form.Item
              name="amount"
              label={t("productDetails.productPrice.form.amount")}
              rules={[
                {
                  required: true,
                  message: t("productDetails.productPrice.form.amountReqMessage"),
                },
              ]}
            >
              <Input type="number" min="1" addonAfter={unit} />
            </Form.Item>
            
          </Space>
        </Col> */}
         </Row>
         <Row gutter={24}>
            <Col>
               <Form.Item
                  label={t("productDetails.productPrice.form.weightPerUnit")}
                  name="weight_per_unit"
               >
                  <Input type="text" addonAfter={"Kg"} />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={24}>
            <Col>
               <Form.Item
                  label={t("productDetails.productPrice.form.originalPrice")}
                  name="original_price"
               >
                  <Input type="number" min="0" addonAfter={"€/" + unit} />
               </Form.Item>
            </Col>
         </Row>
      </>
   );
};
