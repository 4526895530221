import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { CloudDownloadOutlined } from "@ant-design/icons";
import useAxios from "axios-hooks";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Resource } from "../../../../api/common";
//import { LoadingOutlined } from "@ant-design/icons";

export const MaterialListGrid = ({ materials }: any) => {
   const [gridApi, setGridApi] = useState<any>(null);
   const [gridColumnApi, setGridColumnApi] = useState(null);
   const [t] = useTranslation();
   const [productGroups, setProductGroups] = useState<any>();
   const onGridReady = (params: any) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   const [{ data: pgList, loading }] = useAxios(
      {
         url: `${Resource.path.listProductGroups}`,
         method: "GET"
      },
      { manual: false, useCache: true }
   );

   useEffect(() => {
      if (pgList?.data) {
         const temp: any = [];
         pgList?.data?.data.map((pg: any) => {
            temp[pg.id] = pg;
         });
         console.log(temp);
         setProductGroups(temp);
      }
   }, [pgList]);

   const getMaterialSpecification = (material: any) => {
      let materialGroupSpecification;

      if (material?.specification_group) {
         materialGroupSpecification = material.specification_group
            .map((item: any, index: number) => {
               if (!item) return null;
               const keys = Object.keys(item);
               return keys.map((key: any, index: number) => {
                  const keyValue = item[key];

                  if (typeof keyValue === "object" && keyValue !== null) {
                     if ("unit" in keyValue && "unitValue" in keyValue) {
                        return `${key}: ${keyValue["unitValue"]}${keyValue["unit"]}`;
                     }
                  } else {
                     // test if color
                     var ddClass;
                     if (/^#([0-9A-F]{3}){1,2}$/i.test(item[key])) {
                        ddClass = "color";
                        return `${key}: ${item[key]}`;
                     } else if (key !== "isRalColorMatched") {
                        return `${key}: ${item[key]}`;
                     }
                  }
               });
            })
            .filter(Boolean);
      }

      return materialGroupSpecification;
   };

   const formattedMaterial = materials?.map((material: any) => {
      const materialDetails = getMaterialSpecification(material);

      return {
         ...material,
         specification_details:
            materialDetails &&
            Array.isArray(materialDetails) &&
            materialDetails.flat().join("\n")
      };
   });

   //console.log({ formattedMaterial });

   const colDefsAthleteIncluded = [
      {
         headerName: t("materialDashboard.name"),
         field: "product_name",
         filter: "agTextColumnFilter"
      },
      {
         headerName: t("materialDashboard.categories"),
         field: "category_name",
         filter: "agTextColumnFilter"
      },
      {
         headerName: t("materialDashboard.productGroup"),
         field: "product_group_name",
         filter: "agTextColumnFilter"
      },
      {
         headerName: t("materialDashboard.amount"),
         field: "total_amount",
         filter: "agNumberColumnFilter"
      },
      {
         headerName: t("materialDashboard.productUnit"),
         field: "product_unit",
         filter: "agTextColumnFilter"
      },
      {
         headerName: t("materialDashboard.manufacturer"),
         field: "manufacturer",
         filter: "agTextColumnFilter"
      },
      {
         headerName: t("materialDashboard.location"),
         field: "material_current_location",
         filter: "agTextColumnFilter"
      },
      {
         headerName: "Specification Details",
         field: "specification_details"
      },
      {
         headerName: "LCA",
         field: "product_group_id",
         cellRenderer: "lcaCellRenderer"
      },
      {
         headerName: "In contact with polluted materials",
         field: "contact_polluted_material_count",
         cellRenderer: "pollutedMaterialCellRenderer"
      },
      {
         wrapText: true,
         autoHeight: true,
         headerName: "Locations",
         field: "roomsAndLocationNote",
         cellRenderer: "roomsAndLocationNote",
         cellStyle: {
            whiteSpace: "normal",
            wordBreak: "normal"
         }
      }
   ];

   const onBtExport = () => {
      gridApi.exportDataAsExcel({
         author: "Concular",
         sheetName: "Materials",
         fileName: `${+new Date()}_Materialien`,
         processCellCallback: (params: any) => {
            const column = params?.column?.userProvidedColDef?.headerName;
            if (column === "LCA") {
               return productGroups[params.value] &&
                  productGroups[params.value]?.obdProductUuid
                  ? `${
                       +!isNaN(
                          productGroups[params.value]?.gwp_a1a3 +
                             productGroups[params.value]?.gwp_c4
                       ) &&
                       (
                          productGroups[params.value]?.gwp_a1a3 ||
                          0 + productGroups[params.value]?.gwp_c4 ||
                          0
                       )?.toFixed(2)
                    } ${productGroups[params.value]?.lca_unit}`
                  : ``;
            } else if (column === "In contact with polluted materials") {
               return params.value &&
                  params.value > 0 &&
                  productGroups[params?.node?.data?.product_group_id] &&
                  productGroups[params?.node?.data?.product_group_id]?.unit
                  ? `Yes  (${params.value} ${
                       productGroups[params?.node?.data?.product_group_id].unit
                    })`
                  : ``;
            } else {
               return params.value;
            }
         }
      });
   };

   const LCA = ({ value }: any) => {
      const pgData = productGroups[value];
      //console.log(productGroups[value], productGroups);
      return (
         <span>
            {pgData && pgData?.obdProductUuid
               ? `${
                    +!isNaN(pgData?.gwp_a1a3 + pgData?.gwp_c4) &&
                    (pgData?.gwp_a1a3 || 0 + pgData?.gwp_c4 || 0)?.toFixed(2)
                 } ${pgData?.lca_unit}`
               : ""}
         </span>
      );
   };

   const GetPollutedMaterial = (rec: any) => {
      const { value, data }: any = rec;
      const pgData = productGroups[data.product_group_id];

      return (
         <span>
            {value && value > 0 && pgData && pgData?.unit
               ? `Yes  (${value} ${pgData.unit})`
               : ``}
         </span>
      );
   };

   return (
      <div className="content-wrap material-overview-wrapper">
         <Spin spinning={loading}>
            <div style={{ textAlign: "right", paddingRight: "17px" }}>
               <Button
                  onClick={() => onBtExport()}
                  style={{ marginBottom: "5px", fontWeight: "bold" }}
               >
                  <CloudDownloadOutlined />
                  Export to Excel
               </Button>
            </div>

            {productGroups && (
               <div
                  className="ag-theme-alpine"
                  style={{
                     height: 600,
                     paddingLeft: "20px",
                     paddingRight: "20px"
                  }}
               >
                  <AgGridReact
                     onGridReady={onGridReady}
                     rowData={formattedMaterial}
                     columnDefs={colDefsAthleteIncluded}
                     defaultColDef={{
                        flex: 1,
                        minWidth: 200,
                        sortable: true,
                        resizable: true,
                        filter: true
                     }}
                     frameworkComponents={{
                        lcaCellRenderer: LCA,
                        pollutedMaterialCellRenderer: GetPollutedMaterial
                     }}
                  />
               </div>
            )}
         </Spin>
      </div>
   );
};
