export const NavigationKeyMap = {
   building_data: "building_data",
   material_inventory: "material_inventory",
   operations: "operations",
   material_passport: "material_passport",
   financial_report: "financial_report",
   files: "files",
   users: "users",
   access: "access"
};
