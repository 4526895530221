import { Empty } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MaterialItemListItem } from "./MaterialItemListItem";
import Drawer from "../../../components/ui@concular/Drawer";
import { MaterialDetails } from "./Drawer/MaterialDetails";

export const MaterialItemList = ({ materials, refetch }: any) => {
   const [t] = useTranslation();
   const [showDrawer, setShowDrawer] = useState<boolean>(false);
   const [selectedMaterial, setSelectedMaterial] = useState<any>(null);

   const onClose = () => {
      setSelectedMaterial(null);
      setShowDrawer(false);
   };

   return (
      <>
         {materials && materials.length ? (
            <div className="material-tiles">
               {materials.map((item: any, index: number) => {
                  return (
                     <MaterialItemListItem
                        item={item}
                        key={`${item.id}_${index}`}
                        refetch={refetch}
                        setSelectedMaterial={setSelectedMaterial}
                        setShowDrawer={setShowDrawer}
                     ></MaterialItemListItem>
                  );
               })}
            </div>
         ) : (
            <Empty />
         )}
         {selectedMaterial && (
            <Drawer
               closable={true}
               onClose={onClose}
               visible={showDrawer}
               width={600}
            >
               <MaterialDetails material={selectedMaterial} onClose={onClose} />
            </Drawer>
         )}
      </>
   );
};
