import { Col, Row, Form, Input } from "antd";
import * as React from "react";
import { FormView } from "./FormView";
import BrandSearchField from "../BrandSearchField";
import { useTranslation } from "react-i18next";

const ProductSpecification = ({ schema }: any) => {
   const [t] = useTranslation();

   return (
      <>
         <Row gutter={12}>
            <Col xs={24} sm={12}>
               <Form.Item
                  label={t(
                     "productDetails.productCategory.specification.manufacturerName"
                  )}
                  name="manufacturer"
               >
                  <BrandSearchField></BrandSearchField>
               </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
               <Form.Item
                  label={t(
                     "productDetails.productCategory.specification.productName"
                  )}
                  name="name"
                  rules={[
                     {
                        required: true,
                        message: t(
                           "productDetails.productCategory.specification.productNameReqMessage"
                        )
                     }
                  ]}
               >
                  <Input type="text" />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={12}>
            <Col xs={24} sm={12}>
               <Form.Item
                  label={t("productDetails.productCategory.specification.ena")}
                  name="ean"
               >
                  <Input type="text" />
               </Form.Item>
            </Col>
         </Row>
         <Row justify="start">
            <FormView schema={schema}></FormView>
         </Row>
      </>
   );
};

export default ProductSpecification;
