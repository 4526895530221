import { message } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { Mixpanel } from "../../../utils/tracking/mixpanel";
import { Events } from "../../../utils/tracking/tracking.events";
import { getUploadedBuildingImagesLinks } from "./getUploadedBuildingImagesLinks";
const moment = require("moment");

export function submitBuildingForm(
   _form: FormInstance<any>,
   updateLoading: React.Dispatch<React.SetStateAction<boolean>>,
   executePost: any,
   executePut: any,
   refetchBuildings: any,
   buildingId: string,
   history: any,
   t: any,
   buildingUserId: any,
   location_places_api: any = {},
   isNewLocation: boolean,
   locations_id: any
) {
   return async (values: any) => {
      updateLoading(true);
      try {
         const key = "addBuildingLoadingKey";
         message.loading({ content: "Saving...", key });

         if (buildingId) {
            const buildingImages: any[] =
               values.buildingImages &&
               (await getUploadedBuildingImagesLinks(values));

            const buildingInputData = Object.assign(
               {},
               {
                  ...values
               }
            );

            if (buildingInputData?.expected_start_of_construction) {
               Reflect.deleteProperty(
                  buildingInputData,
                  "expected_start_of_construction"
               );
            }
            //if (buildingInputData?.construction_year) {
            //Reflect.deleteProperty(buildingInputData, "construction_year");
            //}
            if (buildingInputData?.expected_date_of_construction) {
               Reflect.deleteProperty(
                  buildingInputData,
                  "expected_date_of_construction"
               );
            }
            if (buildingInputData?.start_date_construction) {
               Reflect.deleteProperty(
                  buildingInputData,
                  "start_date_construction"
               );
            }
            if (buildingInputData?.start_date_deconstruction) {
               Reflect.deleteProperty(
                  buildingInputData,
                  "start_date_deconstruction"
               );
            }
            if (buildingInputData?.end_date_deconstruction) {
               Reflect.deleteProperty(
                  buildingInputData,
                  "end_date_deconstruction"
               );
            }
            if (buildingInputData?.end_date_construction) {
               Reflect.deleteProperty(
                  buildingInputData,
                  "end_date_construction"
               );
            }

            Reflect.deleteProperty(buildingInputData, "buildingImages");

            executePut({
               data: {
                  ...buildingInputData,
                  id: buildingId,
                  user_id: buildingUserId,
                  ...(buildingImages && buildingImages.length
                     ? {
                          building_images: buildingImages
                       }
                     : {}),
                  location_places_api,
                  isNewLocation,
                  locations_id
               }
            })
               .then(() => {
                  refetchBuildings();

                  message.success(
                     {
                        content: t(
                           "buildingAddEditForm.action.addEditSuccessMessage"
                        ),
                        key
                     },
                     0
                  );
                  setTimeout(() => {
                     message.destroy(key);
                  }, 100);
                  updateLoading(false);
                  history.push("/buildings");
               })
               .catch((error: any) => {
                  message.error(t("buildingAddEditForm.action.errorMessage"));
                  console.error(error);
                  updateLoading(false);
               });
         } else {
            const buildingImages: any[] =
               values.buildingImages &&
               (await getUploadedBuildingImagesLinks(values));

            if (values.start_date_construction) {
               values.start_date_construction = moment(
                  values.start_date_construction?.toString()
               ).format("YYYY-MM-DD HH:mm:ss");
            }
            if (values.start_date_deconstruction) {
               values.start_date_deconstruction = moment(
                  values.start_date_deconstruction?.toString()
               ).format("YYYY-MM-DD HH:mm:ss");
            }
            if (values.end_date_construction) {
               values.end_date_construction = moment(
                  values.end_date_construction?.toString()
               ).format("YYYY-MM-DD HH:mm:ss");
            }
            if (values.end_date_deconstruction) {
               values.end_date_deconstruction = moment(
                  values.end_date_deconstruction?.toString()
               ).format("YYYY-MM-DD HH:mm:ss");
            }
            if (values.expected_start_of_construction) {
               values.expected_start_of_construction = moment(
                  values.expected_start_of_construction?.toString()
               ).format("YYYY-MM-DD HH:mm:ss");
            }
            if (values.expected_date_of_construction) {
               values.expected_date_of_construction = moment(
                  values.expected_date_of_construction?.toString()
               ).format("YYYY-MM-DD HH:mm:ss");
            }
            if (values.construction_year) {
               values.construction_year = moment(
                  values.construction_year?.toString()
               ).format("YYYY-MM-DD HH:mm:ss");
            }

            const buildingInputData = Object.assign(
               {},
               {
                  ...values
               }
            );
            Reflect.deleteProperty(buildingInputData, "buildingImages");

            executePost({
               data: {
                  ...buildingInputData,
                  ...(buildingImages && buildingImages.length
                     ? {
                          building_images: buildingImages
                       }
                     : {}),
                  location_places_api,
                  isNewLocation,
                  locations_id
               }
            })
               .then(() => {
                  refetchBuildings();
                  message.success(
                     {
                        content: t(
                           "buildingAddEditForm.action.buildingSavedSuccessfully"
                        ),
                        key
                     },
                     0
                  );

                  Mixpanel.track(Events.Add_Building.name, {
                     "Building Name": buildingInputData?.name,
                     "Is Planned Building": !!buildingInputData?.is_planned,
                     "Is Existing Building":
                        !!buildingInputData?.is_existing_building,
                     "Building Type": buildingInputData?.type,
                     "Building Total Area": buildingInputData?.total_area,
                     "Building City": buildingInputData?.city,
                     "Building Country": buildingInputData.country,
                     "Number of Floor": +buildingInputData?.number_of_floor
                  });

                  setTimeout(() => {
                     message.destroy(key);
                  }, 100);
                  updateLoading(false);
                  history.push("/buildings");
               })
               .catch((error: any) => {
                  console.error(error);
                  updateLoading(false);
               });
         }

         // console.log(values);
      } catch (error) {
         console.error(error);
         updateLoading(false);
      }
   };
}
