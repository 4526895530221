import { Col, Collapse, Menu, Row, Spin } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import Organization from "./../Onboarding/CreateOrganization/Organization";
import styles from "./Settings.module.scss";
import icon_user from "./../../statics/images/icon_user.svg";
import ButtonField from "../../components/FormFields/ButtonField";
import JoinOrganization from "../Onboarding/JoinOrganization";
import CreateOrganization from "../Onboarding/CreateOrganization";
import DropdownField from "../../components/FormFields/DropdownField";
import { ScreenSize } from "../../utils/screenSizes";

const { Panel } = Collapse;

interface IProps {
   children: ReactNode;
   title?: string;
   refreshSideBar?: boolean;
}
const SettingsSideBar = ({ children, title, refreshSideBar }: IProps) => {
   const location = useLocation();
   const [t] = useTranslation();
   const {
      joinedOrganizationsList,
      organizationTypes,
      getJoinedOrganizationsAPI,
      getOrganizationsListAPI,
      userOrganizationLoading
   } = useAuth();
   const [showJoinOrganization, setShowJoinOrganization] = useState(false);
   const [showCreateOrganization, setCreateOrganization] = useState(false);
   const params: { id: string } = useParams();
   const history = useHistory();

   useEffect(() => {
      getJoinedOrganizationsAPI();
      getOrganizationsListAPI();
   }, [refreshSideBar, showJoinOrganization, showCreateOrganization]);

   useEffect(() => {
      getJoinedOrganizationsAPI();
   }, []);

   const closeModal = () => {
      setShowJoinOrganization(false);
      setCreateOrganization(false);
   };

   const openJoinModal = () => {
      setShowJoinOrganization(true);
      setCreateOrganization(false);
   };

   const openCreateModal = () => {
      setShowJoinOrganization(false);
      setCreateOrganization(true);
   };

   // if (organizationListLoading) {
   //    (window as any).NProgress.start();
   // } else {
   //    (window as any).NProgress.done();
   // }

   let dropdownList = [
      {
         label: (
            <Organization
               name={t("settings.account.myAccount")}
               type={""}
               className={styles.organization}
               logo={icon_user}
            />
         ),
         id: 0,
         value: 0,
         disabled: false
      }
   ];

   function createOrgList() {
      const orgList = joinedOrganizationsList
         ? joinedOrganizationsList.map((item: any, index: number) => ({
              label: (
                 <Organization
                    name={item.name}
                    type={
                       organizationTypes?.find(
                          (type: { id: any }) =>
                             type.id == item?.organization_type_id
                       )?.label
                    }
                    logo={item?.image ? item?.image[0]?.object_url : ""}
                    tag={item?.status == "PENDING" ? "pending" : ""}
                    className={styles.organization}
                 />
              ),
              id: item.organizations_id,
              value: index + 1,
              disabled: item?.status == "PENDING"
           }))
         : [];

      dropdownList.push(...orgList);
   }

   createOrgList();

   const [selectedOrg, setSelectedOrg] = useState(
      params.id
         ? dropdownList.find((item: any) => item.id == params.id)
         : dropdownList[0]
   );

   return (
      <Spin spinning={ScreenSize.mobile && userOrganizationLoading}>
         {ScreenSize.mobile && (
            <div className="responsivePageTitle">
               {t("settings.organization.settings")}
            </div>
         )}
         <Row className={styles.settings}>
            <Col
               xs={{ span: 24 }}
               sm={{ span: 8 }}
               xl={{ span: 4 }}
               className={styles.sideBar}
            >
               <div style={{ height: "85%" }}>
                  <h3 className={styles.pageTitle}>
                     {t("settings.organization.settings")}
                  </h3>
                  <div className={styles.organizationSettings}>
                     {ScreenSize.desktop ? (
                        <Collapse
                           accordion
                           defaultActiveKey={[params?.id]}
                           ghost
                           expandIconPosition={"right"}
                           className={`${styles.collapse} no-padding`}
                           expandIcon={(state) =>
                              state.isActive ? <UpOutlined /> : <DownOutlined />
                           }
                        >
                           {joinedOrganizationsList &&
                              joinedOrganizationsList.map((item: any) => (
                                 <Panel
                                    header={
                                       <Organization
                                          name={item.name}
                                          type={
                                             organizationTypes?.find(
                                                (type: { id: any }) =>
                                                   type.id ==
                                                   item?.organization_type_id
                                             )?.label
                                          }
                                          logo={
                                             item?.image
                                                ? item?.image[0]?.object_url
                                                : ""
                                          }
                                          tag={
                                             item?.status == "PENDING"
                                                ? "pending"
                                                : ""
                                          }
                                          className={styles.organization}
                                       />
                                    }
                                    key={item?.organizations_id}
                                    style={{
                                       justifyContent: "space-between",
                                       borderBottom: "1px solid #eee"
                                    }}
                                    collapsible={
                                       item.status == "PENDING"
                                          ? "disabled"
                                          : "header"
                                    }
                                 >
                                    <div className={styles.links}>
                                       {(item.owner == "ADMIN" ||
                                          item.owner == "SUPER_ADMIN") && (
                                          <>
                                             <Link
                                                to={`/settings/${item.organizations_id}/account`}
                                                className={
                                                   location.pathname ==
                                                   `/settings/${item.organizations_id}/account`
                                                      ? styles.selected
                                                      : ""
                                                }
                                             >
                                                {t(
                                                   "settings.organization.account"
                                                )}
                                             </Link>
                                             <Link
                                                to={`/settings/${item.organizations_id}/user_management`}
                                                className={
                                                   location.pathname ==
                                                   `/settings/${item.organizations_id}/user_management`
                                                      ? styles.selected
                                                      : ""
                                                }
                                             >
                                                {t(
                                                   "settings.organization.userManagement"
                                                )}
                                             </Link>
                                             <Link
                                                to={`/settings/${item.organizations_id}/plans_billing`}
                                                className={
                                                   location.pathname ==
                                                   `/settings/${item.organizations_id}/plans_billing`
                                                      ? styles.selected
                                                      : ""
                                                }
                                             >
                                                {t(
                                                   "settings.organization.plansAndBillings"
                                                )}
                                             </Link>
                                          </>
                                       )}
                                       <Link
                                          to={`/settings/${item.organizations_id}/user_settings`}
                                          className={
                                             location.pathname ==
                                             `/settings/${item.organizations_id}/user_settings`
                                                ? styles.selected
                                                : ""
                                          }
                                       >
                                          {t(
                                             "settings.organization.userSettings"
                                          )}
                                       </Link>
                                    </div>
                                 </Panel>
                              ))}
                        </Collapse>
                     ) : (
                        <>
                           <DropdownField
                              items={dropdownList}
                              className={styles.organizationsDropdown}
                              selected={selectedOrg || dropdownList[0]}
                              formItem={false}
                              showSearch={false}
                              onChange={(id) => {
                                 setSelectedOrg(
                                    dropdownList.find(
                                       (item: any) => item.id == id
                                    )
                                 );
                                 id === 0
                                    ? history.push(`/settings/profile`)
                                    : history.push(`/settings/${id}/account`);
                              }}
                           />
                           {selectedOrg?.id !== 0 && (
                              <Menu
                                 className={styles.menu}
                                 mode={
                                    ScreenSize.desktop
                                       ? "vertical"
                                       : "horizontal"
                                 }
                                 // selectedKeys={[selectedKeys()]}
                              >
                                 <Menu.Item
                                    key="account"
                                    className={styles.menuItem}
                                 >
                                    <Link
                                       to={`/settings/${selectedOrg?.id}/account`}
                                       className={
                                          location.pathname ==
                                          `/settings/${selectedOrg?.id}/account`
                                             ? styles.selected
                                             : ""
                                       }
                                    >
                                       {t("settings.organization.account")}
                                    </Link>
                                 </Menu.Item>
                                 <Menu.Item
                                    key="user_management"
                                    className={styles.menuItem}
                                 >
                                    <Link
                                       to={`/settings/${selectedOrg?.id}/user_management`}
                                       className={
                                          location.pathname ==
                                          `/settings/${selectedOrg?.id}/user_management`
                                             ? styles.selected
                                             : ""
                                       }
                                    >
                                       {t(
                                          "settings.organization.userManagement"
                                       )}
                                    </Link>
                                 </Menu.Item>
                                 <Menu.Item
                                    key="plans_billing"
                                    className={styles.menuItem}
                                 >
                                    <Link
                                       to={`/settings/${selectedOrg?.id}/plans_billing`}
                                       className={
                                          location.pathname ==
                                          `/settings/${selectedOrg?.id}/plans_billing`
                                             ? styles.selected
                                             : ""
                                       }
                                    >
                                       {t(
                                          "settings.organization.plansAndBillings"
                                       )}
                                    </Link>
                                 </Menu.Item>
                                 <Menu.Item
                                    key="user_settings"
                                    className={styles.menuItem}
                                 >
                                    <Link
                                       to={`/settings/${selectedOrg?.id}/user_settings`}
                                       className={
                                          location.pathname ==
                                          `/settings/${selectedOrg?.id}/user_settings`
                                             ? styles.selected
                                             : ""
                                       }
                                    >
                                       {t("settings.organization.userSettings")}
                                    </Link>
                                 </Menu.Item>
                              </Menu>
                           )}
                        </>
                     )}
                  </div>

                  {ScreenSize.desktop && (
                     <div className={styles.organizationSettings}>
                        <Collapse
                           defaultActiveKey={1000}
                           ghost
                           expandIconPosition={"right"}
                           className={`${styles.collapse} no-padding`}
                           expandIcon={(state) =>
                              state.isActive ? <UpOutlined /> : <DownOutlined />
                           }
                        >
                           <Panel
                              header={
                                 <Organization
                                    name={t("settings.account.myAccount")}
                                    type={""}
                                    className={styles.organization}
                                    logo={icon_user}
                                 />
                              }
                              key={1000}
                              style={{
                                 justifyContent: "space-between",
                                 alignItems: "center"
                              }}
                           >
                              <Link
                                 to="/settings/profile"
                                 className={
                                    location.pathname == "/settings/profile"
                                       ? styles.selected
                                       : ""
                                 }
                              >
                                 {t("settings.organization.userSettings")}
                              </Link>
                           </Panel>
                        </Collapse>
                     </div>
                  )}
               </div>
               <ButtonField
                  type="alternative"
                  icon={<PlusOutlined />}
                  className={styles.createOrJoin}
                  onClick={openJoinModal}
               >
                  {t("settings.organization.createOrJoin")}
               </ButtonField>
            </Col>
            <Col
               xs={{ span: 24 }}
               sm={{ span: 16 }}
               xl={{ span: 20 }}
               className={styles.content}
            >
               {title && <h2 className={styles.contentTitle}>{title}</h2>}
               {children}
               {showJoinOrganization && (
                  <JoinOrganization
                     modal
                     showModal={showJoinOrganization}
                     handleCancelModal={closeModal}
                     openCreateNew={openCreateModal}
                  />
               )}
               {showCreateOrganization && (
                  <CreateOrganization
                     modal
                     showModal={showCreateOrganization}
                     handleCancelModal={closeModal}
                     openJoin={openJoinModal}
                  />
               )}
            </Col>
         </Row>
      </Spin>
   );
};

export default SettingsSideBar;
