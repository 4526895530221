import React, { useEffect, useState } from "react";
import styles from "./JoinOrganization.module.scss";
import { useTranslation } from "react-i18next";
import {
   PlusOutlined,
   CheckOutlined,
   ArrowLeftOutlined
} from "@ant-design/icons";
import ButtonField from "../../../components/FormFields/ButtonField";
import Welcome from "../Welcome";
import { useHistory } from "react-router";
import { Modal, Spin } from "antd";
import UserOrganizationTitle from "../UserOrganizationTitle";
import { useAuth } from "../../../contexts/auth";
import Organization from "../CreateOrganization/Organization";

interface IProps {
   modal?: boolean;
   showModal?: boolean;
   handleCancelModal?: (e?: any) => any;
   openCreateNew?: (e?: any) => any;
}
const JoinOrganization = ({
   modal,
   showModal,
   handleCancelModal,
   openCreateNew
}: IProps) => {
   const [t] = useTranslation();
   const history = useHistory();
   const {
      organizationList: organizationListData,
      organizationListLoading,
      getOrganizationsListAPI
   } = useAuth();
   const [organizationList, setOrganizationList] = useState<any>([]);
   const [showAddingTitlePage, setShowAddingTitlePage] = useState(false);

   const CreateNewOrganization = () => {
      if (modal) {
         openCreateNew && openCreateNew(true);
      } else {
         history.push("/onboarding/create_organization");
      }
   };

   const join = (id: number) => {
      organizationList.map((item: any, index: number) => {
         if (index == id) {
            item["joinRequest"] = !item["joinRequest"];
         }
      });
      setOrganizationList([...organizationList]);
   };

   useEffect(() => {
      getOrganizationsListAPI();
   }, []);

   useEffect(() => {
      organizationListData && setOrganizationList([...organizationListData]);
   }, [organizationListData, showModal]);

   const goBack = () => {
      organizationList.map((item: any) => (item.joinRequest = false));
      setOrganizationList([...organizationList]);
   };

   const continueToJoin = () => {
      setShowAddingTitlePage(true);
   };

   function joinComponent() {
      return (
         <div className={styles.joinComponent}>
            {!showAddingTitlePage ? (
               <>
                  <h3 className={styles.title}>
                     {t("onboarding.joinOrganization")}
                  </h3>
                  {organizationList.map(
                     (
                        item: {
                           id: number;
                           name: string;
                           status: string;
                           joinRequest: boolean;
                           invited: string | boolean;
                           image?: any[];
                        },
                        index: number
                     ) => {
                        return (
                           <div className={styles.organizationRow} key={index}>
                              <Organization
                                 name={item.name}
                                 logo={
                                    (item?.image &&
                                       item?.image[0]?.object_url) ||
                                    ""
                                 }
                              />
                              <div className="d-flex-center flex-align-center">
                                 {!item.joinRequest && (
                                    <span className={styles.status}>
                                       {item.invited == "0"
                                          ? t(
                                               "onboarding.recommended"
                                            ).toUpperCase()
                                          : t(
                                               "onboarding.invited"
                                            ).toUpperCase()}
                                    </span>
                                 )}
                                 <ButtonField
                                    icon={
                                       item?.joinRequest ? (
                                          <CheckOutlined />
                                       ) : (
                                          <PlusOutlined />
                                       )
                                    }
                                    onClick={() => join(index)}
                                    type={item?.joinRequest ? "state" : "light"}
                                 >
                                    {item?.joinRequest
                                       ? item.invited == "0"
                                          ? t("onboarding.accessRequested")
                                          : t("onboarding.joined")
                                       : t("onboarding.join")}
                                 </ButtonField>
                              </div>
                           </div>
                        );
                     }
                  )}
                  {organizationList.find((item: any) => item.joinRequest) ? (
                     <div className={styles.bottomButtons}>
                        <ButtonField
                           className={styles.goBack}
                           type="link"
                           icon={<ArrowLeftOutlined />}
                           onClick={goBack}
                        >
                           <span>{t("onboarding.goBack")}</span>
                        </ButtonField>
                        <ButtonField type="default" onClick={continueToJoin}>
                           <span>{t("default.continue")}</span>
                        </ButtonField>
                     </div>
                  ) : (
                     <>
                        <div className={styles.description}>
                           {t("onboarding.joininigDescription")}
                        </div>
                        <div className={styles.createNew}>
                           <div className={styles.title}>
                              {t("onboarding.createOrganization")}
                           </div>
                           <ButtonField
                              type="primary"
                              onClick={CreateNewOrganization}
                           >
                              {t("onboarding.getStarted")}
                           </ButtonField>
                        </div>
                     </>
                  )}
               </>
            ) : (
               <UserOrganizationTitle
                  showAddingTitlePage={setShowAddingTitlePage}
                  requestedOrganizations={organizationList.filter(
                     (item: any) => item.joinRequest
                  )}
                  className={styles.userTitlePage}
                  modal={modal}
                  closeModal={handleCancelModal}
               />
            )}
         </div>
      );
   }

   return (
      <>
         {modal ? (
            <Modal
               visible={showModal}
               title={""}
               footer={null}
               className={`${styles.joinOranization} ${styles.modalView}`}
               onCancel={handleCancelModal}
               centered
               width={"auto"}
               style={{ minHeight: "auto" }}
            >
               <div style={{ padding: "20px" }}>{joinComponent()}</div>
            </Modal>
         ) : (
            <Spin spinning={organizationListLoading}>
               <Welcome className={styles.joinOranization}>
                  {joinComponent()}
               </Welcome>
            </Spin>
         )}
      </>
   );
};

export default JoinOrganization;
