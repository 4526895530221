import React from "react";
import { IfcPropertySingleValue } from "./types";
import { Collapse } from "antd";
import { getProperties } from "./utils";
import { useIfcViewer } from "./IfcViewerContext";
// import "./style.scss";
import { Card } from "antd";
import styled from "@emotion/styled";
import { IFCObjectIN, IFCObjectOUT } from "./../api-client";
import { IfcSearch } from "./IfcSearch";

type Props = {
   title: string;
   ifcObject: IFCObjectIN | IFCObjectOUT;
   count: number;
};

const { Panel } = Collapse;

const findPropertyValueByName = (
   properties: IfcPropertySingleValue[],
   name: string
) => {
   const value = properties?.find((a) => {
      return a?.Name?.value?.toLowerCase() === name?.toLowerCase();
   })?.NominalValue.value;
   if (value) {
      return parseFloat(value).toFixed(3);
   }

   return null;
};

export const IfcMapping = ({ title, ifcObject, count }: Props) => {
   const { viewer } = useIfcViewer();
   const [properties, setProperties] =
      React.useState<IfcPropertySingleValue[]>();

   const [materials, setMaterials] =
      React.useState<{ Materials: any; expressID: number; type: number }[]>();
   const [loaded, setLoaded] = React.useState<boolean>(false);

   React.useEffect(() => {
      (async () => {
         if (ifcObject.ifc_express_id) {
            const materials =
               await viewer?.IFC.loader.ifcManager.getMaterialsProperties(
                  0,
                  parseInt(ifcObject.ifc_express_id),
                  true
               );
            setMaterials(materials?.[0]?.Materials);
         }
      })();
   }, [viewer?.IFC.loader.ifcManager, ifcObject.ifc_express_id]);

   React.useEffect(() => {
      (async () => {
         if (!viewer || loaded || !ifcObject.ifc_express_id) {
            return;
         }
         const singlePropObjects = await getProperties({
            viewer,
            id: ifcObject.ifc_express_id
         });

         setProperties(singlePropObjects);
         setLoaded(true);
      })();
   }, [viewer, loaded, ifcObject.ifc_express_id]);

   return (
      <Collapse ghost>
         <Panel
            header={
               <>
                  {title}
                  <span style={{ color: "gray" }}>{` (${count}) `}</span>
               </>
            }
            key={ifcObject.ifc_express_id || "1"}
         >
            <Wrapper>
               <ObjectProperties>
                  <Card title="Descriptive" bordered={false}>
                     <p>
                        <Label>Name: </Label>
                        {title}
                     </p>
                  </Card>
                  <Card title="Materials" bordered={false}>
                     <p>
                        {materials
                           ? materials.map((material: any) => {
                                return (
                                   <p key={material.Name.value}>
                                      {material.Name.value}
                                   </p>
                                );
                             })
                           : "No materials found."}
                     </p>
                  </Card>

                  <Card title="Base quantities" bordered={false}>
                     <p>
                        <LabelAligned>OverallHeight: </LabelAligned>
                        {ifcObject?.overall_height?.toFixed(3)}
                     </p>
                     <p>
                        <LabelAligned>OverallWidth: </LabelAligned>
                        {ifcObject.overall_width?.toFixed(3)}
                     </p>
                     <p>
                        <LabelAligned>Volume: </LabelAligned>
                        {properties &&
                           findPropertyValueByName(properties, "volume")}
                     </p>
                     <p>
                        <LabelAligned>Surface: </LabelAligned>
                        {properties &&
                           findPropertyValueByName(properties, "surface")}
                     </p>
                  </Card>
               </ObjectProperties>
               <ProductDataSetMapping>
                  <IfcSearch />
               </ProductDataSetMapping>
            </Wrapper>
         </Panel>
      </Collapse>
   );
};

const Wrapper = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-between;

   > div:nth-of-type(1) {
      width: 45%;
   }
   > div:nth-of-type(2) {
      width: 55%;
   }

   .ant-card-body {
      padding: 0;
      padding-top: 16px;
   }

   .ant-card-head {
      padding: 0;
   }
`;

const ObjectProperties = styled.div``;
const ProductDataSetMapping = styled.div`
   padding-left: 16px;
`;

const Label = styled.label`
   font-weight: bold;
   display: inline-block;
   margin-right: 8px;
`;

const LabelAligned = styled(Label)`
   min-width: 100px;
`;
