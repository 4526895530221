import React, { ReactNode } from "react";

import CO_LOGO from "../../../statics/images/o_logo.svg";
import styles from "./Welcome.module.scss";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import { Spin } from "antd";

interface IProps {
   children?: ReactNode;
   className?: string;
}

const Welcome = ({ children, className }: IProps) => {
   const [t] = useTranslation();
   const { user: currentUser } = useAuth();

   return (
      <div
         className={`${styles.onboarding} ${
            children ? className : styles.welcome
         }`}
      >
         <div className={styles.wrapper}>
            <div className={styles.welcomeImage}>
               <img className={styles.logo} alt="logo" src={CO_LOGO} />
            </div>
            {children ? (
               children
            ) : (
               <Spin spinning={!currentUser}>
                  <div className={styles.message}>
                     {currentUser?.attributes?.given_name &&
                        `${t("onboarding.welcome")} ${
                           currentUser?.attributes?.given_name
                        }`}
                  </div>
               </Spin>
            )}
         </div>
      </div>
   );
};

export default Welcome;
