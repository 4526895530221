import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import {
   Form,
   Checkbox,
   DatePicker,
   AutoComplete,
   Spin,
   Input,
   Button,
   message,
   PageHeader,
   Select,
   Radio,
   Collapse
} from "antd";
import { Resource } from "../../api/common";
import CategoriesTreeSearchField from "../MaterialInventory/AddMaterialWizard/ProductDetails/ProductCategory/CategoriesTreeSearchField";
import ProductGroupField from "../MaterialInventory/AddMaterialWizard/ProductDetails/ProductCategory/ProductGroupSelectField";
import { useAuth } from "../../contexts/auth/index";
import { getAxiosInstance } from "../../utils/axios";
import { MaterialDropdown } from "../../components/MaterialDropdown";
import BrandMultiSelect from "../../components/BrandMultiSelect";

const { Option } = Select;
const defaultCheckedList = ["Delivery"];
const plainOptions = [
   { label: "Aufbereitung", value: "Refurbishment" },
   { label: "Prüfung", value: "Testing" },
   { label: "Upcycling", value: "Upcycling" },
   { label: "(Re)zertifizierung", value: "(Re)certification" },
   { label: "Lieferung", value: "Delivery" }
];
const dateFormat = "DD.MM.YYYY";

const DemandForm: React.FC<any> = ({}) => {
   const [buildings, setBuildings] = useState<any>();
   const { Panel } = Collapse;
   const [{ data: dataBuildings, loading: loadingBuilding }] = useAxios(
      Resource.path.listBuilding,
      {
         useCache: false
      }
   );
   const [t] = useTranslation();
   const [form] = useForm();
   const [checkedList, setCheckedList] = useState(defaultCheckedList);
   const [showProductGroup, setShowProductGroup] =
      React.useState<boolean>(false);
   const [selectedCategoryId, setSelectedCategoryId] =
      React.useState<any>(undefined);
   const [resetProduct, setResetProduct] = React.useState<any>(false);
   const [showForm, setShowForm] = React.useState<any>(false);
   const [indeterminate, setIndeterminate] = React.useState(true);
   const [checkAll, setCheckAll] = React.useState(false);
   const [locations, setLocations] = React.useState<any>();
   const [locationsOrg, setLocationsOrg] = React.useState<any>();
   const [valueLocation, setValueLocation] = React.useState<any>();
   const [selectedLocation, setSelectedLocation] = React.useState<any>();
   const [locationAPI, setLocationAPI] = React.useState<any>([]);
   const [fetchOperationsLoading, setFetchOperationsLoading] =
      React.useState<any>(false);
   const [locationDropDownOpen, setLocationDropDownOpen] =
      React.useState<any>(false);
   const [selectedOpId, setSelectedOpId] = React.useState<any>();
   const [isNewLocation, setIsNewLocation] = React.useState<any>(false);
   const [productUnit, setProductUnit] = useState<any>();
   const history = useHistory();
   const [deliveryType, setDeliveryType] = React.useState<any>(1);
   const { user: currentUser } = useAuth();
   const [productGroup, setProductGroup] = useState<any>();
   const [lcaMetaMaterials, setLcaMetaMaterials] = useState<any>();
   const [isReseller, setIsReseller] = useState<boolean>(false);
   const [deconstructionType, setDeconstructionType] = useState<any>();
   const [deliveryOption, setDeliveryOption] = useState<any>();
   const [inActiveProductGroup, setInActiveProductGroup] = useState<any>();
   const [
      { data, loading: getProductDetailsLoading, error },
      fetchProductGroupDetails
   ] = useAxios({}, { useCache: false, manual: true });
   const [userScopes, setUserScope] = React.useState<any>([]);
   const [operations, setOperations] = React.useState<any>([]);
   const [selectedBuildingId, setSelectedBuildingId] = React.useState<any>();
   const [
      { data: dataPlaces, loading: getPlaces },
      findPlace,
      cancelRequestLocation
   ] = useAxios(
      {
         url: `${Resource.path.getLocationsHub}`,
         method: "POST"
      },
      { useCache: false, manual: true }
   );

   const [
      { data: materialsData, loading: ingredientsLoading },
      fetchIngredients
   ] = useAxios(
      {
         url: `${Resource.path.getMaterialIngredients}`,
         method: "GET"
      },
      { manual: true }
   );

   const onChangeCheckboxGroup = (list: any) => {
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length < plainOptions.length);
      setCheckAll(list.length === plainOptions.length);
   };

   const onCategorySelect = (value: {
      id: string;
      name: string;
      dropDownId: string;
      reset: boolean;
   }) => {
      setShowProductGroup(false);
      const { id: categoryId, reset } = value;
      setSelectedCategoryId(categoryId);
      setResetProduct(reset);
      setTimeout(() => {
         setShowProductGroup(true);
      }, 1500);
   };

   const onBuildingSelect = async (value: any) => {
      setOperations([]);
      setSelectedOpId(null);
      const axios = await getAxiosInstance();
      setSelectedBuildingId(value);
      setFetchOperationsLoading(true);
      await axios
         .get(`${Resource.path.operations}/${value}`)
         .then((response) => {
            if (response?.data?.data?.result) {
               const records = response?.data?.data?.result.filter(
                  (resp: any) => {
                     return (
                        resp.operation_status === "IN_PROGRESS" &&
                        resp.operation_type !== "deconstruction"
                     );
                  }
               );
               console.log(records);
               setOperations(records);
               setFetchOperationsLoading(false);
            }
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const onProductGroupSelect = async (value: any) => {
      if (value && value.key) {
         await fetchProductGroupDetails({
            url: `${Resource.path.getProductGroupDetails}/${value.key}`
         });
         form.setFieldsValue({ product_group: value });
         await fetchIngredients();
         setShowForm(true);
      }
   };
   const [{ data: dataLocations, loading: dataLocationsLoading }] = useAxios(
      {
         url: `${Resource.path.getUsersLocationsHub}`,
         method: "get"
      },
      { useCache: true, manual: false }
   );

   const [{ loading: confirmLoading }, add] = useAxios(
      {
         url: `${Resource.path.addDemandUniversal}`,
         method: "POST"
      },
      { manual: true }
   );

   const onFinish = async () => {
      setLocationDropDownOpen(false);
      form
         .validateFields()
         .then(async () => {
            const data = form.getFieldsValue();
            console.log("data", data);
            const buildingData = buildings.filter((rec: any) => {
               return rec.id === selectedBuildingId;
            });
            let delivery = 0;
            if (isReseller) {
               delivery =
                  deliveryOption && deliveryOption === "delivery" ? 1 : 0;
            } else {
               delivery =
                  data?.services && data?.services.indexOf("Delivery") !== -1
                     ? 1
                     : 0;
            }
            await add({
               data: {
                  product_group: data.product_group.label,
                  categories: JSON.stringify(data.categories),
                  product_group_id: data.product_group.value,
                  amount: data.minimum_amount ? data.minimum_amount : 0,
                  target_price: data.target_price,
                  delivery,
                  refurbishment:
                     data?.services &&
                     data?.services.indexOf("Refurbishment") !== -1
                        ? 1
                        : 0,
                  testing:
                     data?.services && data?.services.indexOf("Testing") !== -1
                        ? 1
                        : 0,
                  upcycling:
                     data?.services &&
                     data?.services.indexOf("Upcycling") !== -1
                        ? 1
                        : 0,
                  recertification:
                     data?.services &&
                     data?.services.indexOf("(Re)certification") !== -1
                        ? 1
                        : 0,
                  delivery_date: data?.delivery_date
                     ? data?.delivery_date
                     : null,
                  unit: productUnit,
                  material_location_places_api: isNewLocation
                     ? selectedLocation
                     : {},
                  isNewLocation: isNewLocation ? isNewLocation : null,
                  locations_id: !isNewLocation ? selectedLocation : null,
                  building_name: selectedBuildingId
                     ? buildingData.pop().name
                     : null,
                  building_id: selectedBuildingId ? selectedBuildingId : null,
                  operations_id: selectedOpId ? selectedOpId : null,
                  measurements: data?.measurements ? data.measurements : null,
                  manufacturers: data?.manufacturers
                     ? data?.manufacturers
                     : null,
                  selected_material: data?.label_material
                     ? data?.label_material
                     : null,
                  deconstruction_service:
                     deconstructionType && deconstructionType === "managed"
                        ? 1
                        : 0,
                  self_deconstruction:
                     deconstructionType && deconstructionType === "self"
                        ? 1
                        : 0,
                  pickup: deliveryOption && deliveryOption === "pickup" ? 1 : 0
               }
            })
               .then((response) => {
                  console.log(response);
                  form.resetFields();
                  message.success("Demand added successfully");
                  if (selectedBuildingId) {
                     history.push(
                        `/buildings/${selectedBuildingId}/operations/${selectedOpId}`
                     );
                  } else {
                     history.push("/hub/material_pilot");
                  }
               })
               .catch((error) => {
                  console.log(error);
                  message.error(t("buildingOperations.errorMessage"));
               });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   useEffect(() => {
      if (dataPlaces?.data && dataPlaces?.data?.locations) {
         setLocationDropDownOpen(true);
      }
      if (data?.data) {
         setProductGroup(data.data);
         setProductUnit(data.data.unit);
      }
      if (dataLocations?.data && dataLocations?.data) {
         const temp = dataLocations?.data.map((value: any) => {
            const { id, name } = value;
            return {
               value: `existing_${id}`,
               label: name
            };
         });
         setLocations(temp);
         setLocationsOrg(temp);
      }
      if (currentUser?.attributes) {
         const temp = currentUser?.attributes["custom:scopes"].split(",");
         setUserScope(temp);
         if (
            temp.indexOf("material-hub") !== -1 &&
            temp.indexOf("material-passport") !== -1
         ) {
            setDeliveryType(1);
         } else if (
            temp.indexOf("material-hub") === -1 &&
            temp.indexOf("material-passport") !== -1
         ) {
            setDeliveryType(2);
         } else if (
            temp.indexOf("material-hub") !== -1 &&
            temp.indexOf("material-passport") === -1
         ) {
            setDeliveryType(1);
         }
      }
      if (currentUser) {
         if (
            currentUser?.attributes &&
            currentUser?.attributes["custom:user_type"]
         ) {
            setIsReseller(
               currentUser?.attributes["custom:user_type"] === "reseller"
            );
         }
      }
      //console.log("buildings", buildings);
      if (dataBuildings?.data) {
         setBuildings(dataBuildings?.data);
      }
      if (materialsData?.data) {
         const materials: any = materialsData?.data.data;
         const temp = materials
            ? productGroup?.lca_meta
                 ?.filter((material: any) => material.sub_material_id)
                 .map((material: any) => {
                    return materials?.find(
                       (m: any) => m.id === material.sub_material_id
                    );
                 })
            : [];
         setLcaMetaMaterials(temp);
      }
   }, [
      dataPlaces,
      data,
      dataLocations,
      currentUser,
      dataBuildings,
      materialsData
   ]);

   if (
      getPlaces ||
      getProductDetailsLoading ||
      dataLocationsLoading ||
      loadingBuilding ||
      ingredientsLoading
   ) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   const setInActive = (data: any) => {
      //console.log('inavtice', data)
      setInActiveProductGroup(data);
   };

   return (
      <div className="content-wrap building-overview-wrapper">
         <PageHeader title="">
            <div className="page-title">
               <h1>Add Demand</h1>
            </div>
         </PageHeader>
         <div className="page-content">
            <Spin spinning={getProductDetailsLoading || ingredientsLoading}>
               <Form
                  name="demand_form"
                  initialValues={{
                     minimum_amount: "1",
                     services: checkedList
                  }}
                  layout="vertical"
                  form={form}
                  style={{ minWidth: "100% !important" }}
               >
                  <CategoriesTreeSearchField
                     form={form}
                     onChange={onCategorySelect}
                     operationType={"ADD"}
                     user={currentUser}
                     setInActive={setInActive}
                  ></CategoriesTreeSearchField>
                  {!!showProductGroup && (
                     <Form.Item
                        label={t("productDetails.productCategory.productGroup")}
                        name="product_group"
                        rules={[
                           {
                              required: true,
                              message: t(
                                 "productDetails.productCategory.validations.productGroup"
                              )
                           }
                        ]}
                     >
                        <ProductGroupField
                           onChange={onProductGroupSelect}
                           categoryId={selectedCategoryId}
                           reset={resetProduct}
                           operationType={"ADD"}
                           inActive={inActiveProductGroup}
                        ></ProductGroupField>
                     </Form.Item>
                  )}

                  {!!showForm && (
                     <>
                        {userScopes.indexOf("material-hub") === -1 &&
                           userScopes.indexOf("material-passport") !== -1 && (
                              <Form.Item
                                 name="building_id"
                                 label={t("materialRequestForm.building")}
                                 rules={[{ required: true }]}
                              >
                                 <Select onSelect={onBuildingSelect}>
                                    {buildings &&
                                       buildings.map((building: any) => (
                                          <Option value={building.id}>
                                             {building.name}
                                          </Option>
                                       ))}
                                 </Select>
                              </Form.Item>
                           )}
                        {userScopes.indexOf("material-hub") === -1 &&
                           operations &&
                           userScopes.indexOf("material-passport") !== -1 && (
                              <Form.Item
                                 name="operation_id"
                                 label={t("materialRequestForm.operation")}
                                 rules={[{ required: true }]}
                              >
                                 <Select
                                    loading={fetchOperationsLoading}
                                    onSelect={(value: any) => {
                                       setSelectedOpId(value);
                                    }}
                                 >
                                    {operations.map((operation: any) => (
                                       <Option value={operation.id}>
                                          {operation.name
                                             ? operation.name
                                             : operation.operation_type}
                                       </Option>
                                    ))}
                                 </Select>
                              </Form.Item>
                           )}
                        {!isReseller && (
                           <Form.Item
                              rules={[
                                 {
                                    required: true,
                                    message: t(
                                       "demands.addDemand.minimum_amount_error"
                                    )
                                 }
                              ]}
                              className="inline"
                              label={t(
                                 "demands.addDemand.minimum_amount_label"
                              )}
                              name="minimum_amount"
                           >
                              <Input
                                 placeholder="1"
                                 suffix={<b>{productUnit}</b>}
                              />
                           </Form.Item>
                        )}
                        <Form.Item
                           rules={[
                              {
                                 required: true,
                                 message: t(
                                    "demands.addDemand.target_price_error"
                                 )
                              }
                           ]}
                           className="inline"
                           label={t("demands.addDemand.target_price_label")}
                           name="target_price"
                        >
                           <Input
                              min="0"
                              type="number"
                              suffix={<b>€/{productUnit}</b>}
                           />
                        </Form.Item>

                        <Collapse defaultActiveKey={[3]} ghost>
                           <Panel
                              header={t(
                                 "productDetails.productCategory.specification.manufacturerName"
                              )}
                              key="1"
                           >
                              <Form.Item name="manufacturers">
                                 <BrandMultiSelect></BrandMultiSelect>
                              </Form.Item>
                           </Panel>
                           <Panel header="measurements" key="2">
                              <Form.Item label="Width">
                                 <Input.Group compact>
                                    <Form.Item
                                       noStyle
                                       rules={[
                                          {
                                             required: false
                                          },
                                          {
                                             validator(_, value) {
                                                if (
                                                   value &&
                                                   form.getFieldValue(
                                                      "measurements"
                                                   )?.width?.maximum &&
                                                   Number(value) >
                                                      Number(
                                                         form.getFieldValue(
                                                            "measurements"
                                                         )["width"]["maximum"]
                                                      )
                                                ) {
                                                   return Promise.reject(
                                                      "Please enter valid minimum value"
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                          }
                                       ]}
                                       className="inline"
                                       name={[
                                          "measurements",
                                          "width",
                                          "minimum"
                                       ]}
                                    >
                                       <Input
                                          placeholder="Minimum"
                                          style={{
                                             width: "50%",
                                             textAlign: "center"
                                          }}
                                          min="0"
                                          type="number"
                                          suffix={<b>mm</b>}
                                       />
                                    </Form.Item>
                                    <Form.Item
                                       noStyle
                                       rules={[
                                          {
                                             required: false
                                          },
                                          {
                                             validator(_, value) {
                                                if (
                                                   value &&
                                                   Number(value) <
                                                      Number(
                                                         form.getFieldValue(
                                                            "measurements"
                                                         )["width"]["minimum"]
                                                      )
                                                ) {
                                                   return Promise.reject(
                                                      "Please enter valid maximum value"
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                          }
                                       ]}
                                       className="inline"
                                       name={[
                                          "measurements",
                                          "width",
                                          "maximum"
                                       ]}
                                    >
                                       <Input
                                          placeholder="Maximum"
                                          style={{
                                             width: "50%",
                                             textAlign: "center"
                                          }}
                                          min={
                                             form.getFieldValue("measurements")
                                                ?.width?.minimum
                                                ? form.getFieldValue(
                                                     "measurements"
                                                  )["width"]["minimum"]
                                                : 0
                                          }
                                          type="number"
                                          suffix={<b>mm</b>}
                                       />
                                    </Form.Item>
                                 </Input.Group>
                              </Form.Item>

                              <Form.Item label="Height">
                                 <Input.Group compact>
                                    <Form.Item
                                       noStyle
                                       rules={[
                                          {
                                             required: false
                                          },
                                          {
                                             validator(_, value) {
                                                if (
                                                   value &&
                                                   form.getFieldValue(
                                                      "measurements"
                                                   )?.height?.maximum &&
                                                   Number(value) >
                                                      Number(
                                                         form.getFieldValue(
                                                            "measurements"
                                                         )["height"]["maximum"]
                                                      )
                                                ) {
                                                   return Promise.reject(
                                                      "Please enter valid minimum value"
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                          }
                                       ]}
                                       className="inline"
                                       name={[
                                          "measurements",
                                          "height",
                                          "minimum"
                                       ]}
                                    >
                                       <Input
                                          placeholder="Minimum"
                                          style={{
                                             width: "50%",
                                             textAlign: "center"
                                          }}
                                          min="0"
                                          type="number"
                                          suffix={<b>mm</b>}
                                       />
                                    </Form.Item>
                                    <Form.Item
                                       noStyle
                                       rules={[
                                          {
                                             required: false
                                          },
                                          {
                                             validator(_, value) {
                                                if (
                                                   value &&
                                                   Number(value) <
                                                      Number(
                                                         form.getFieldValue(
                                                            "measurements"
                                                         )["height"]["minimum"]
                                                      )
                                                ) {
                                                   return Promise.reject(
                                                      "Please enter valid maximum value"
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                          }
                                       ]}
                                       className="inline"
                                       name={[
                                          "measurements",
                                          "height",
                                          "maximum"
                                       ]}
                                    >
                                       <Input
                                          placeholder="Maximum"
                                          style={{
                                             width: "50%",
                                             textAlign: "center"
                                          }}
                                          min={
                                             form.getFieldValue("measurements")
                                                ?.height?.minimum
                                                ? form.getFieldValue(
                                                     "measurements"
                                                  )["height"]["minimum"]
                                                : 0
                                          }
                                          type="number"
                                          suffix={<b>mm</b>}
                                       />
                                    </Form.Item>
                                 </Input.Group>
                              </Form.Item>

                              <Form.Item label="Depth">
                                 <Input.Group compact>
                                    <Form.Item
                                       noStyle
                                       rules={[
                                          {
                                             required: false
                                          },
                                          {
                                             validator(_, value) {
                                                if (
                                                   value &&
                                                   form.getFieldValue(
                                                      "measurements"
                                                   )?.depth?.maximum &&
                                                   Number(value) >
                                                      Number(
                                                         form.getFieldValue(
                                                            "measurements"
                                                         )["depth"]["maximum"]
                                                      )
                                                ) {
                                                   return Promise.reject(
                                                      "Please enter valid minimum value"
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                          }
                                       ]}
                                       className="inline"
                                       name={[
                                          "measurements",
                                          "depth",
                                          "minimum"
                                       ]}
                                    >
                                       <Input
                                          placeholder="Minimum"
                                          style={{
                                             width: "50%",
                                             textAlign: "center"
                                          }}
                                          min="0"
                                          type="number"
                                          suffix={<b>mm</b>}
                                       />
                                    </Form.Item>
                                    <Form.Item
                                       noStyle
                                       rules={[
                                          {
                                             required: false
                                          },
                                          {
                                             validator(_, value) {
                                                if (
                                                   value &&
                                                   Number(value) <
                                                      Number(
                                                         form.getFieldValue(
                                                            "measurements"
                                                         )["depth"]["minimum"]
                                                      )
                                                ) {
                                                   return Promise.reject(
                                                      "Please enter valid maximum value"
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                          }
                                       ]}
                                       className="inline"
                                       name={[
                                          "measurements",
                                          "depth",
                                          "maximum"
                                       ]}
                                    >
                                       <Input
                                          placeholder="Maximum"
                                          style={{
                                             width: "50%",
                                             textAlign: "center"
                                          }}
                                          min={
                                             form.getFieldValue("measurements")
                                                ?.depth?.minimum
                                                ? form.getFieldValue(
                                                     "measurements"
                                                  )["depth"]["minimum"]
                                                : 0
                                          }
                                          type="number"
                                          suffix={<b>mm</b>}
                                       />
                                    </Form.Item>
                                 </Input.Group>
                              </Form.Item>
                           </Panel>

                           {productGroup &&
                              lcaMetaMaterials &&
                              lcaMetaMaterials.length > 0 && (
                                 <Panel header="material" key="3">
                                    <Form.Item
                                       label={productGroup?.lca_meta_label}
                                    >
                                       <MaterialDropdown
                                          name={["label_material"]}
                                          materials={lcaMetaMaterials}
                                          setMaterialGroup={() => {
                                             const fields =
                                                form.getFieldsValue();
                                             if (
                                                fields["label_material"]
                                                   ?.sub_material_id
                                             ) {
                                                fields[
                                                   "label_material"
                                                ].sub_material_id = undefined;
                                                form.setFieldsValue(fields);
                                             }
                                          }}
                                       />
                                    </Form.Item>
                                 </Panel>
                              )}
                        </Collapse>

                        {!isReseller && (
                           <Form.Item>
                              <strong>
                                 {t("demands.addDemand.services.title")}
                              </strong>
                              <p>{t("demands.addDemand.services.message")}</p>
                           </Form.Item>
                        )}
                        {!isReseller && (
                           <Form.Item className="checkboxgroup" name="services">
                              <Checkbox.Group
                                 options={plainOptions}
                                 onChange={onChangeCheckboxGroup}
                              />
                           </Form.Item>
                        )}
                        {!isReseller && (
                           <Form.Item
                              label={t("demands.addDemand.delivery_date_label")}
                              name="delivery_date"
                              rules={[
                                 {
                                    required: true,
                                    message: "Please enter date"
                                 }
                              ]}
                           >
                              <DatePicker format={dateFormat} />
                           </Form.Item>
                        )}
                        {userScopes.indexOf("material-hub") !== -1 &&
                           userScopes.indexOf("material-passport") !== -1 && (
                              <Form.Item
                                 label={t("materialRequestForm.deliverTo")}
                              >
                                 <Radio.Group
                                    onChange={({ target: { value } }) => {
                                       setDeliveryType(value);
                                    }}
                                    value={deliveryType}
                                 >
                                    <Radio.Button value={1}>
                                       {t("materialRequestForm.address")}
                                    </Radio.Button>
                                    <Radio.Button value={2}>
                                       {t("materialRequestForm.building")}
                                    </Radio.Button>
                                 </Radio.Group>
                              </Form.Item>
                           )}
                        {deliveryType === 2 &&
                           userScopes.indexOf("material-hub") !== -1 &&
                           userScopes.indexOf("material-passport") !== -1 && (
                              <Form.Item
                                 name="building_id"
                                 label={t("materialRequestForm.building")}
                                 rules={[{ required: true }]}
                              >
                                 <Select onSelect={onBuildingSelect}>
                                    {buildings &&
                                       buildings.map((building: any) => (
                                          <Option value={building.id}>
                                             {building.name}
                                          </Option>
                                       ))}
                                 </Select>
                              </Form.Item>
                           )}
                        {deliveryType === 2 &&
                           userScopes.indexOf("material-hub") !== -1 &&
                           operations &&
                           userScopes.indexOf("material-passport") !== -1 && (
                              <Form.Item
                                 name="operation_id"
                                 label={t("materialRequestForm.operation")}
                                 rules={[{ required: true }]}
                              >
                                 <Select
                                    loading={fetchOperationsLoading}
                                    onSelect={(value: any) => {
                                       setSelectedOpId(value);
                                    }}
                                 >
                                    {operations.map((operation: any) => (
                                       <Option value={operation.id}>
                                          {operation.name
                                             ? operation.name
                                             : operation.operation_type}
                                       </Option>
                                    ))}
                                 </Select>
                              </Form.Item>
                           )}
                        {deliveryType === 1 && (
                           <Form.Item
                              label={"Location"}
                              name={"location"}
                              rules={[
                                 {
                                    required: true,
                                    message: "Please enter"
                                 },
                                 ({}) => ({
                                    validator(_, value) {
                                       if (
                                          selectedLocation === undefined ||
                                          selectedLocation === null
                                       ) {
                                          return Promise.reject(
                                             new Error(
                                                "Please select valid location"
                                             )
                                          );
                                       }
                                       if (value) return Promise.resolve();
                                    }
                                 })
                              ]}
                           >
                              <AutoComplete
                                 open={locationDropDownOpen}
                                 notFoundContent={getPlaces ? <Spin /> : null}
                                 value={valueLocation}
                                 options={locations}
                                 onSelect={(value) => {
                                    cancelRequestLocation();
                                    setLocationDropDownOpen(false);
                                    const selected = locations.filter(
                                       (rec: any) => {
                                          return rec.value === value;
                                       }
                                    );

                                    const labelValue = selected.pop().label;
                                    setValueLocation(labelValue);
                                    const temp = value.split("_");
                                    setIsNewLocation(temp[0] === "new");
                                    if (temp[0] === "new") {
                                       setSelectedLocation(
                                          locationAPI[temp[1]]
                                       );
                                    } else {
                                       setSelectedLocation(temp[1]);
                                    }

                                    form.setFieldsValue({
                                       location: labelValue
                                    });
                                 }}
                                 onChange={(value) => {
                                    setValueLocation(value);
                                    console.log("value", value);
                                    setLocationDropDownOpen(true);
                                 }}
                                 onSearch={async (searchText: string) => {
                                    setSelectedLocation(null);
                                    cancelRequestLocation();
                                    setLocationDropDownOpen(true);
                                    if (searchText.length === 0) {
                                       setLocations(locationsOrg);
                                    } else if (searchText.length > 0) {
                                       let temp = locationsOrg.filter(
                                          (rec: any) => {
                                             return (
                                                rec?.value &&
                                                rec?.label
                                                   .toLowerCase()
                                                   .includes(
                                                      searchText.toLowerCase()
                                                   )
                                             );
                                          }
                                       );
                                       console.log(temp, locationsOrg);
                                       if (
                                          temp.length === 0 &&
                                          searchText.length > 10
                                       ) {
                                          await findPlace({
                                             data: {
                                                address: `${searchText}`
                                             }
                                          });
                                       }

                                       if (
                                          temp.length === 0 &&
                                          dataPlaces?.data &&
                                          dataPlaces?.data?.locations &&
                                          dataPlaces?.data?.locations.length > 0
                                       ) {
                                          const tempLocations =
                                             dataPlaces?.data?.locations.map(
                                                (rec: any, key: any) => {
                                                   if (rec && rec?.Label) {
                                                      return {
                                                         value: `new_${key}`,
                                                         label: rec?.Label
                                                      };
                                                   }
                                                }
                                             );
                                          console.log(
                                             "tempLocations",
                                             tempLocations
                                          );
                                          setLocationAPI(
                                             dataPlaces?.data?.locations
                                          );
                                          temp = tempLocations;
                                       }
                                       setLocations(temp);
                                       setLocationDropDownOpen(true);
                                    }
                                 }}
                                 placeholder="Enter location"
                                 onFocus={() => {
                                    setLocationDropDownOpen(true);
                                 }}
                              ></AutoComplete>
                           </Form.Item>
                        )}
                        {!!isReseller && (
                           <Form.Item label={"Deconstruction"}>
                              <Radio.Group
                                 options={[
                                    { label: "managed", value: "managed" },
                                    {
                                       label: "self deconstruction",
                                       value: "self"
                                    }
                                 ]}
                                 onChange={(event: any) => {
                                    setDeconstructionType(event.target.value);
                                 }}
                                 optionType="button"
                                 buttonStyle="solid"
                              />
                           </Form.Item>
                        )}
                        {!!isReseller && (
                           <Form.Item label={"Delivery"}>
                              <Radio.Group
                                 options={[
                                    { label: "self pickup", value: "pickup" },
                                    { label: "delivery", value: "delivery" }
                                 ]}
                                 onChange={(event: any) => {
                                    setDeliveryOption(event.target.value);
                                 }}
                                 optionType="button"
                                 buttonStyle="solid"
                              />
                           </Form.Item>
                        )}
                        <Form.Item>
                           <Button
                              type="primary"
                              shape="round"
                              size="large"
                              htmlType="submit"
                              loading={confirmLoading}
                              onClick={onFinish}
                           >
                              {t("demands.addDemand.save_demand")}
                           </Button>
                        </Form.Item>
                     </>
                  )}
               </Form>
            </Spin>
         </div>
      </div>
   );
};

export default DemandForm;
