import React, { useState, useEffect } from "react";
import { PageHeader, Tabs, Select, Form, Spin } from "antd";
import useAxios from "axios-hooks";
import { useHistory } from "react-router";
import "./style.scss";

import { useTranslation } from "react-i18next";
import { Resource } from "../../../api/common";
import { Materials } from "./Details/Materials";
import { AddMaterials } from "./Details/Drawer/AddMaterials";
const moment = require("moment");

export const BuildingOperationDetailsView = () => {
   const [t] = useTranslation();
   const [isModalVisible, setIsModalVisible] = useState(false);
   const { Option } = Select;
   const [selectionType, setSelectionType] = useState("checkbox");
   const [activeTab, setActiveTab] = useState<any>(1);
   const [activeTabInner, setActiveTabInner] = useState<any>(1);
   const [state, setState] = useState({ showAddSelectAll: true });

   const [form] = Form.useForm();
   const history = useHistory();
   // tabs
   const { TabPane } = Tabs;
   const callback = (key: any) => {
      setActiveTab(key);
   };
   const callbackInnerTab = (key: any) => {
      setActiveTabInner(key);
      refetchSelectedMaterials();
   };
   // drawer
   const [visible, setVisible] = useState(false);

   const [
      { data: dataSelectedMaterials, loading: loadingSelectedMaterials },
      refetchSelectedMaterials
   ] = useAxios(`${Resource.path.getMaterialPassportPilot()}`);

   const [
      { data: dataMaterials, loading: getLoadingMaterials },
      fetchMaterials
   ] = useAxios(
      {
         url: Resource.path.getMaterialInventoryHub(),
         method: "GET"
      },
      { useCache: false }
   );

   const showDrawer = async () => {
      await fetchMaterials();
      setVisible(true);
   };

   const onClose = () => {
      setVisible(false);
   };

   useEffect(() => {
      if (dataMaterials?.data) {
         dataMaterials.data.map((dataMaterial: any) => {
            const balance = Number(dataMaterial.amount);
            if (balance > 0) {
               setState({ showAddSelectAll: false });
            }
         });
      }
   }, [dataMaterials]);

   if (loadingSelectedMaterials || getLoadingMaterials) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   if (getLoadingMaterials)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <div className="page-wrap building-operations-overview-wrapper">
         <PageHeader
            title={t("common.back")}
            onBack={() => window.history.back()}
         >
            <div className="page-title">
               <h1>Material Hub</h1>
            </div>
            <div className="manage-menu"></div>
         </PageHeader>
         <Materials
            showDrawerLoading={getLoadingMaterials}
            callbackInnerTab={callbackInnerTab}
            tabKey={activeTabInner}
            showDrawer={showDrawer}
            dataSelectedMaterials={dataSelectedMaterials}
            fetchMaterials={fetchMaterials}
            refetchSelectedMaterials={refetchSelectedMaterials}
         />
         <AddMaterials
            dataMaterials={dataMaterials}
            fetchMaterials={fetchMaterials}
            onClose={onClose}
            refetchSelectedMaterials={refetchSelectedMaterials}
            visible={visible}
            setVisible={onClose}
            showSelectAll={state.showAddSelectAll}
            loadingSelectedMaterials={loadingSelectedMaterials}
            getLoadingMaterials={getLoadingMaterials}
            showDrawer={showDrawer}
         />
      </div>
   );
};
