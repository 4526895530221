import React, { useEffect, useState } from "react";
import {
   InboxOutlined,
   DeleteOutlined,
   CloudDownloadOutlined
} from "@ant-design/icons";
import "./buildingFiles.scss";
import Dragger from "antd/lib/upload/Dragger";
import {
   Button,
   Card,
   message,
   Popconfirm,
   Row,
   Skeleton,
   PageHeader,
   Image
} from "antd";
import { Resource } from "../../../api/common";
import useAxios from "axios-hooks";
import { useParams } from "react-router";
import RandomFileImage from "./randomFile.png";
import { uploadFile } from "../AddEditForm/uploadFile";
import { useTranslation } from "react-i18next";
import { ShowUploadedFile } from "./ShowUploadedFile";
import { createBuildingFileList } from "./createBuildingFileList";

const CardSkelton = () => {
   return (
      <Card
         hoverable
         style={{
            width: 200,
            height: 220,
            marginRight: "15px",
            marginBottom: "25px"
         }}
      >
         <Skeleton loading={true} active></Skeleton>
      </Card>
   );
};

export const BuildingFileListItem = ({
   file,
   setReloadList,
   refetchBuildingList
}: any) => {
   const { id }: any = useParams();
   const [t] = useTranslation();
   const [showActionsButton, setShowDeleteButton] = useState(false);
   const handleOnMouseEnter = () => {
      setShowDeleteButton(true);
   };

   const handleOnMouseLeave = () => {
      setShowDeleteButton(false);
   };

   const [{ data, loading, error }, executeDelete] = useAxios(
      {
         url: `${Resource.path.getBuildingFiles}/${id}/files/${file.id}`,
         method: "delete"
      },
      { manual: true }
   );

   const onConfirm = () => {
      executeDelete().then(() => {
         setReloadList(true);
         refetchBuildingList();
         message.success(t("buildingFiles.deleteActionsMessageSuccess"));
      });
   };

   return (
      <Card
         hoverable
         cover={
            <div>
               <Image
                  height="170px"
                  width="200px"
                  alt={file.name}
                  src={RandomFileImage}
                  preview={false}
               />
               {!!showActionsButton && (
                  <Button
                     type="link"
                     shape="circle"
                     href={file.object_url}
                     className="download-btn"
                     target="_blank"
                  >
                     <CloudDownloadOutlined />
                  </Button>
               )}
               <Popconfirm
                  title={t("buildingFiles.deletionConfirmation")}
                  onConfirm={onConfirm}
                  okText={t("buildingFiles.yes")}
                  cancelText={t("buildingFiles.No")}
               >
                  {showActionsButton && (
                     <Button
                        danger
                        shape="circle"
                        className="delete-file-button"
                     >
                        <DeleteOutlined />
                     </Button>
                  )}
               </Popconfirm>
            </div>
         }
         style={{
            width: 200,
            height: 220,
            marginRight: "15px",
            marginBottom: "25px"
         }}
         onMouseEnter={handleOnMouseEnter}
         onMouseLeave={handleOnMouseLeave}
      >
         <div>
            <Card.Meta title={file.name} />
         </div>
      </Card>
   );
};

const BuildingFiles = ({ reload, setReloadList }: any) => {
   const { id }: any = useParams();
   const [{ data, loading, error }, refetch] = useAxios(
      {
         url: Resource.path.getSaveBuildingFilePath(id),
         method: "GET"
      },
      {
         useCache: false
      }
   );

   useEffect(() => {
      reload && refetch();
   }, [reload]);

   const refetchBuildingList = () => refetch();

   if (loading || !data?.data) return <CardSkelton />;
   const buildingFilesList = createBuildingFileList(
      data?.data,
      setReloadList,
      refetchBuildingList
   );

   return <>{buildingFilesList}</>;
};

export const BuildingFilesViews = () => {
   const { id }: any = useParams();
   const [t] = useTranslation();
   const [selectedFile, setSelectedFile] = useState<any>();
   const [reloadList, setReloadList] = useState(false);

   const [{ data, loading, error }, executePost] = useAxios(
      {
         url: Resource.path.getSaveBuildingFilePath(id),
         method: "POST"
      },
      {
         manual: true,
         useCache: false
      }
   );

   const handleOnChange = async (values: any) => {
      setReloadList(false);

      const [file] = values.fileList;
      setSelectedFile(file);

      const uploadedObjectDetails = await uploadFile(file);

      executePost({
         data: {
            name: file.name,
            ...uploadedObjectDetails
         }
      })
         .then(() => {
            setSelectedFile(null);
            message.success(t("buildingFiles.fileUploadSuccess"));
            setReloadList(true);
         })
         .catch((error) => {
            console.log(error);
            setSelectedFile(null);
            message.error(t("buildingFiles.errorMessage"));
         });
   };

   const handleBeforeUpload = () => {
      return false;
   };

   return (
      <div className="building-files-view">
         <PageHeader title="">
            <div className="page-title">
               <h1>{t("buildingFiles.files")}</h1>
            </div>

            <div className="manage-menu">
               <Button type="primary" shape="round">
                  {t("buildingFiles.uploadFile")}
               </Button>
            </div>
         </PageHeader>
         <div className="inner-wrap">
            <Row gutter={24}>
               <div className="file-upload-container">
                  {selectedFile ? (
                     <ShowUploadedFile fileName={selectedFile.name} />
                  ) : (
                     <Dragger
                        listType="text"
                        showUploadList={false}
                        onChange={handleOnChange}
                        beforeUpload={handleBeforeUpload}
                        multiple={false}
                     >
                        <div className="file-upload-button">
                           <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                           </p>
                           <p className="ant-upload-text">
                              {t("buildingFiles.uploadButtonTitle")}
                           </p>
                           <p className="ant-upload-hint">
                              {t("buildingFiles.uploadButtonHint")}
                           </p>
                        </div>
                     </Dragger>
                  )}
               </div>
               <BuildingFiles
                  reload={reloadList}
                  setReloadList={setReloadList}
               />
            </Row>
         </div>
      </div>
   );
};
