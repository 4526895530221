import { Tree, Radio, PageHeader, Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./style.scss";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { MaterialItemList } from "./MaterialItemList";
import { usePermissionStatus } from "../../../hooks/usePermissionStatus";
import { UnAuthorizedMessage } from "../../../components/UnAuthorizedMessage/index";
import InfiniteScroll from "react-infinite-scroll-component";
import { Mixpanel } from "../../../utils/tracking/mixpanel";

interface DataNode {
   title: string;
   key: string;
   isLeaf?: boolean;
   children?: DataNode[];
}

export enum FilterType {
   "ProductGroup",
   "Category"
}

const DEFAULT_CATALOGUE_PAGINATION_LIMIT = 12;

const CategoriesTree = ({ onProductGroupSelected: onFilterSelected }: any) => {
   const [categories, setCategoriesTreeData] = useState([]);
   const [t] = useTranslation();
   const [showLoader, setShowLoader] = useState<boolean>(true);
   const [expKeys, setExpKeys] = useState<any>([]);
   const [productGroupsHavingItems, setProductGroupsHavingItems] = useState([]);
   const [lastSelectedCatId, setLastSelectedCatId] = useState("");

   const [{ data, loading: getLoading, error: getError }, executeGet] =
      useAxios(
         {
            url: `${Resource.path.getCategoriesWithProductGroups}`,
            method: "GET"
         },
         { useCache: true }
      );

   function updateTreeData(
      list: DataNode[],
      key: React.Key,
      children: DataNode[]
   ): DataNode[] {
      return list.map((node) => {
         if (node.key === key) {
            return {
               ...node,
               isLeaf: false,
               children
            };
         } else if (node.children) {
            return {
               ...node,
               isLeaf: false,
               children: updateTreeData(node.children, key, children)
            };
         }
         return node;
      });
   }

   useEffect(() => {
      if (data?.data?.categories?.treeData) {
         setShowLoader(false);
         // console.log(data?.data?.treeData, "treeData");
         const temp: any = [];
         data.data.categories.treeData.forEach((category: any) => {
            if (category.has_items && category.has_items === 1) {
               temp.push(category);
            }
         });
         setCategoriesTreeData(temp);
         if (data?.data?.productGroups?.data) {
            const productGroupIds: any = [];
            data?.data?.productGroups?.data.forEach((productGroup: any) => {
               if (productGroup.has_items && productGroup.has_items === 1) {
                  productGroupIds.push(productGroup.id);
                  // console.log(productGroup.name);
               }
            });
            setProductGroupsHavingItems(productGroupIds);
         }
         setLastSelectedCatId("");
      }
   }, [data]);

   const handleOnSelect = (selectedItem: any, info: any) => {
      const {
         node: { isProductGroup, key }
      } = info;

      const {
         node: { isLeaf, product_groups, children }
      } = info;

      if (isProductGroup) {
         onFilterSelected({
            key: key,
            type: FilterType.ProductGroup,
            catId: lastSelectedCatId
         });
      } else {
         const [selectedCategoryId] = selectedItem;
         onFilterSelected({
            key: selectedCategoryId,
            type: FilterType.Category,
            catId: selectedCategoryId
         });
      }

      if (children) {
         const updatedChildren = children.map((item: any) => {
            if (item.isLeaf && item.product_groups) {
               let { product_groups_has_items } = item;
               if (product_groups_has_items === undefined) {
                  product_groups_has_items = [];
               }
               const temp = item.product_groups.filter((product_group: any) => {
                  const { key } = product_group;
                  return (
                     Object.values(product_groups_has_items).indexOf(
                        key as never
                     ) !== -1 &&
                     productGroupsHavingItems.indexOf(key as never) !== -1
                  );
               });
               const productGroupsNodes = temp.map((item: any) => {
                  return {
                     title: item.label,
                     key: item.key,
                     isLeaf: true,
                     isProductGroup: true
                  };
               });
               return {
                  ...item,
                  children: [...productGroupsNodes],
                  isLeaf: false
               };
            } else {
               return item;
            }
         });

         const updatedTree: any = updateTreeData(
            categories,
            key,
            updatedChildren
         );
         setCategoriesTreeData(updatedTree);
      }
   };

   if (getLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   if (getLoading && showLoader)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   const onLoadData = async (treeSelectedInfo: any): Promise<any> => {
      return new Promise((resolve, reject) => {
         // console.log(treeSelectedInfo);
         if (treeSelectedInfo) {
            //setTimeout(() => {
            const { key, children, pos } = treeSelectedInfo;

            if (children) {
               const updatedChildren = children.filter((item: any) => {
                  if (item.isLeaf && item.product_groups) {
                     let { product_groups_has_items } = item;
                     if (product_groups_has_items === undefined) {
                        product_groups_has_items = [];
                     }
                     const temp = item.product_groups.filter(
                        (product_group: any) => {
                           const { key } = product_group;
                           return (
                              Object.values(product_groups_has_items).indexOf(
                                 key as never
                              ) !== -1 &&
                              productGroupsHavingItems.indexOf(key as never) !==
                                 -1
                           );
                        }
                     );
                     const productGroupsNodes = temp.map(
                        (item: any, index: number) => {
                           return {
                              title: item.label,
                              key: item.key + "#" + index + "#" + pos,
                              isLeaf: true,
                              isProductGroup: true
                           };
                        }
                     );
                     return {
                        ...item,
                        children: [...productGroupsNodes],
                        isLeaf: false
                     };
                  } else if (item && item.has_items && item.has_items === 1) {
                     return item;
                  }
               });

               const updatedTree: any = updateTreeData(
                  categories,
                  key,
                  updatedChildren
               );
               setCategoriesTreeData(updatedTree);

               resolve(true);
               return;
            } else if (
               !treeSelectedInfo.isLeaf &&
               treeSelectedInfo.product_groups
            ) {
               let { product_groups_has_items } = treeSelectedInfo;
               if (product_groups_has_items === undefined) {
                  product_groups_has_items = [];
               }
               const temp = treeSelectedInfo.product_groups.filter(
                  (product_group: any) => {
                     const { key } = product_group;
                     return (
                        productGroupsHavingItems.indexOf(key as never) !== -1 &&
                        Object.values(product_groups_has_items).indexOf(
                           key as never
                        ) !== -1
                     );
                  }
               );
               const updatedChildren = temp.map((item: any, index: number) => {
                  return {
                     title: item.label,
                     key: item.key + "#" + index + "#" + pos,
                     isLeaf: true,
                     isProductGroup: true
                  };
               });

               const updatedTree: any = updateTreeData(
                  categories,
                  key,
                  updatedChildren
               );
               setCategoriesTreeData(updatedTree);

               resolve(true);
               return;
            }
            //});
         }
      });
   };

   const handleOnExpand = (expandedKeys: any, info: any) => {
      if (info && info.node && info.node.id) {
         setLastSelectedCatId(info.node.id);
      }
      setTimeout(() => {
         setExpKeys([]);
         const { node, expanded } = info;
         const { pos } = node;
         const temp: any = [];
         const path = pos.split("-");
         path.splice(0, 1);
         path.reverse();
         let indexFind = path.pop();
         const walk = (treeNode: any, index: any) => {
            if (treeNode[index]) {
               temp.push(treeNode[index].id);
               if (treeNode[index].children) {
                  const nextNode = treeNode[index].children;
                  if (path.length > 0) {
                     const nextIndex = path.pop();
                     walk(nextNode, nextIndex);
                  }
               }
            }
         };
         walk(categories, indexFind);
         // console.log(temp);
         if (expanded) {
            setExpKeys(temp);
         } else {
            setExpKeys(expandedKeys);
         }
      });
   };

   return (
      <Tree.DirectoryTree
         showIcon={false}
         treeData={categories}
         loadData={onLoadData}
         switcherIcon={<DownOutlined />}
         onSelect={handleOnSelect}
         onExpand={handleOnExpand}
         expandedKeys={expKeys}
      />
   );
};

export const CatalogueDashboard = () => {
   const hasPermission = usePermissionStatus();

   const [materials, setMaterials] = useState<any>([]);
   const [allMaterials, setAllMaterials] = useState(true);
   const [t] = useTranslation();
   const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
   const [paginationConfig, setParamsPagination] = useState<{
      cursor?: number;
      limit?: number;
      filter?: any;
   }>({ cursor: 0, limit: DEFAULT_CATALOGUE_PAGINATION_LIMIT });
   const [showLoader, setShowLoader] = useState<boolean>(true);
   const [autoScroll, setAutoScroll] = useState<boolean>(false);

   const [{ data, loading: getLoading, error: getError }, refetch] = useAxios(
      {
         url: `${Resource.path.getAllAvailableMaterialPassportInvent}`,
         method: "post",
         data: {
            ...paginationConfig
         }
      },
      { useCache: true, manual: false }
   );

   useEffect(() => {
      if (data?.data?.data) {
         setMaterials([...materials, ...data?.data?.data]);
         if (data?.data?.meta) {
            const { hasMoreData } = data?.data?.meta;
            setShowLoadMore(hasMoreData);
            setAutoScroll(true);
         }
         setShowLoader(false);
      }
   }, [data]);

   if (hasPermission !== undefined && !hasPermission) {
      const pageName = t("sidebarMenus.catalogue");
      return <UnAuthorizedMessage currentPageTitle={pageName} />;
   }

   if (getLoading && showLoader)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   if (getLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   const onFilterSelected = async ({
      key,
      type,
      catId
   }: {
      key: string;
      type: FilterType;
      catId: string;
   }) => {
      try {
         setAutoScroll(false);
         setShowLoader(false);
         if (type === FilterType.Category) {
            setParamsPagination({
               limit: DEFAULT_CATALOGUE_PAGINATION_LIMIT,
               filter: { categories: key }
            });
         } else {
            const [productGroupKey] = key.split("#");
            setParamsPagination({
               limit: DEFAULT_CATALOGUE_PAGINATION_LIMIT,
               filter: { product_group_id: productGroupKey, categories: catId }
            });
         }
         setMaterials([]);
         setAllMaterials(false);
         await refetch();
      } catch (error) {
         console.log(error);
      }
   };

   const onAllSelected = async (_value: any) => {
      try {
         setAutoScroll(false);
         setShowLoader(true);
         setParamsPagination({ limit: DEFAULT_CATALOGUE_PAGINATION_LIMIT });
         setAllMaterials(true);
         setMaterials([]);
         await refetch();
      } catch (error) {
         console.log(error);
      }
   };

   const loadMore = async () => {
      try {
         if (!autoScroll) {
            return;
         }
         Mixpanel.track("Scroll Catalogues", {});

         if (data?.data?.meta) {
            setShowLoader(false);
            setAutoScroll(false);
            const { cursor: cursorKey } = data?.data?.meta;
            paginationConfig["cursor"] = cursorKey;
            setParamsPagination({ ...paginationConfig });
            await refetch();
         }
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <>
         <div className="responsivePageTitle"></div>
         <div className="page-wrap material-catalogue-wrapper wrapper-style black">
            <PageHeader title="">
               <div className="page-title">
                  <h1 className="big-title">
                     {t("sidebarMenus.catalogueNiceTitle01")}
                     <br />
                     {t("sidebarMenus.catalogueNiceTitle02")}
                  </h1>
               </div>
               <div className="manage-menu">
                  <Button type="primary" shape="round">
                     <a
                        href="https://concular.de/de/material-beschaffen/"
                        target="_blank"
                        rel="noreferrer"
                     >
                        {t("sidebarMenus.addDemands")}
                     </a>
                  </Button>
               </div>
            </PageHeader>
            <div className="inventory-content">
               <div className="category-sidebar">
                  <span className="category-sidebar-title">
                     {t("materialDashboard.categories")}
                  </span>
                  <div className="category-list">
                     <div className="reset">
                        <Radio checked={allMaterials} onChange={onAllSelected}>
                           {t("materialDashboard.allMaterials")}
                        </Radio>
                     </div>
                     <CategoriesTree
                        onProductGroupSelected={onFilterSelected}
                     ></CategoriesTree>
                  </div>
               </div>
               <div className="material-list">
                  <InfiniteScroll
                     dataLength={materials.length}
                     next={loadMore}
                     hasMore={showLoadMore}
                     loader={<h4>Loading...</h4>}
                     endMessage={
                        <p style={{ textAlign: "center" }}>
                           <b>Yay! You have seen it all</b>
                        </p>
                     }
                  >
                     <MaterialItemList
                        materials={materials}
                        refetch={refetch}
                     ></MaterialItemList>
                  </InfiniteScroll>
               </div>
            </div>
         </div>
      </>
   );
};
