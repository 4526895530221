import React, { useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { Settings } from "../Settings";
import CreateOrganization from "./CreateOrganization";
import Dashboard from "./Dashboard";
import JoinOrganization from "./JoinOrganization";
import Welcome from "./Welcome";
import { useAuth } from "../../contexts/auth/index";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
const Onboarding = () => {
   const history = useHistory();
   const location = useLocation();
   const { loadingUserInfo, onBoarded } = useAuth();
   const [t] = useTranslation();

   useEffect(() => {
      if (onBoarded && !loadingUserInfo) {
         history.push("/onboarding/dashboard");
      } else {
         const timer = setTimeout(() => {
            history.push("/onboarding/join_organization");
         }, 3000);
         return () => clearTimeout(timer);
      }
   }, [onBoarded, loadingUserInfo]);

   if (loadingUserInfo)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   if (location.pathname == "/onboarding") {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <>
         <Switch>
            <Route exact path={`/onboarding`} component={Welcome}></Route>
            <Route
               path={`/onboarding/join_organization`}
               component={JoinOrganization}
            ></Route>
            <Route
               path={`/onboarding/create_organization`}
               component={CreateOrganization}
            ></Route>
            <Route path={`/onboarding/settings`} component={Settings}></Route>
            <Route path={`/onboarding/dashboard`} component={Dashboard}></Route>
         </Switch>
      </>
   );
};

export default Onboarding;
