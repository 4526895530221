import React, { useState } from "react";
import { PageHeader, Tag, Image, Button, message, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { PassportList } from "./PassportList";
import { useTranslation } from "react-i18next";
import { getImagePath } from "../../../../utils/getImagePath";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import useAxios from "axios-hooks";
import { Resource } from "../../../../api/common";
import { useParams } from "react-router-dom";
import { useBuilding } from "../../../../contexts/building/index";
import { RequestModal } from "./RequestModal";

export const getDocumentsList = (documents: any[]) => {
   return documents.map((item: any, index) => {
      return (
         <div key="index">
            <a
               href={item.documents_files?.object_url}
               target="_blank"
               rel="noreferrer"
            >
               {item.document_type} ({index + 1})
            </a>
         </div>
      );
   });
};

export const getMaterialReuseForTag = (tags: any[]) => {
   return tags.map((tag: any) => {
      return <Tag>{tag}</Tag>;
   });
};

export const MaterialDetails = ({
   material,
   showRequestBtn,
   demand_id,
   closeMe,
   dataDemand
}: any) => {
   const [t] = useTranslation();
   const [materialImages, setMaterialImages] = useState<any>();
   const [showSendRequest, setShowSendRequest] = useState<boolean>(false);
   const [amountTotal, setAmountTotal] = useState<any>();
   const [amountTotalSellingData, setAmountTotalSellingData] = useState<any>(
      []
   );
   const [form] = useForm();
   const { id: buildingId, operation_id }: any = useParams<string>();
   const [refreshPassport, setRefreshPassport] = useState<boolean>(false);
   const { getBuildingDetail } = useBuilding();
   const buildingDetails = buildingId && getBuildingDetail(buildingId);
   const [{ loading: confirmLoading }, addRequest] = useAxios(
      {
         url: `${Resource.path.getRequestUrl(
            demand_id,
            buildingId,
            operation_id
         )}`,
         method: "POST"
      },
      { manual: true }
   );
   const [
      { data: demandLocationData, loading: loadingLocationData },
      getDemandLocationData
   ] = useAxios(
      {
         method: "GET"
      },
      { manual: true }
   );
   let materialGroupSpecification;
   if (material?.specification_group) {
      materialGroupSpecification = material.specification_group
         .map((item: any, index: number) => {
            if (!item) return null;
            const keys = Object.keys(item);

            return (
               <>
                  {keys.map((key: any, index: number) => {
                     const keyValue = item[key];

                     if (typeof keyValue === "object" && keyValue !== null) {
                        if ("unit" in keyValue && "unitValue" in keyValue) {
                           return (
                              <dl>
                                 <dt>{key}</dt>
                                 <dd>
                                    {keyValue["unitValue"]} {keyValue["unit"]}
                                 </dd>
                              </dl>
                           );
                        }
                     } else {
                        // test if color
                        var ddClass;
                        if (/^#([0-9A-F]{3}){1,2}$/i.test(item[key])) {
                           ddClass = "color";
                           return (
                              <dl>
                                 <dt>{t(`${key}`)}</dt>
                                 <dd>
                                    <div
                                       className={ddClass}
                                       style={{
                                          ["background" as any]: item[key],
                                          display: "inline",
                                          padding: "0px 30px 0px",
                                          border: "1px solid #d9d9d9"
                                       }}
                                    ></div>{" "}
                                    <span style={{ display: "inline" }}>
                                       &nbsp;
                                       {item.isRalColorMatched
                                          ? t(
                                               `colors:${item[
                                                  key
                                               ].toUpperCase()}`
                                            )
                                          : t(
                                               `colors:${item[
                                                  key
                                               ].toUpperCase()}`
                                            ).substr(
                                               0,
                                               t(
                                                  `colors:${item[
                                                     key
                                                  ].toUpperCase()}`
                                               ).indexOf("(")
                                            )}
                                    </span>
                                 </dd>
                              </dl>
                           );
                        } else if (key !== "isRalColorMatched") {
                           return (
                              <dl>
                                 <dt>{key}</dt>
                                 <dd>{item[key]}</dd>
                              </dl>
                           );
                        }
                     }
                  })}
               </>
            );
         })
         .filter(Boolean);
   }
   const [tempSelected, setTempSelected] = useState<any>([]);

   useEffect(() => {
      if (material?.images) {
         setPassportImageList(material.images);
      }
      if (dataDemand) {
         getDemandLocationData({
            url: `${Resource.path.organization.getLocationDataURL(
               dataDemand?.building_id
                  ? getBuildingDetail(dataDemand?.building_id).locations_id
                  : dataDemand.locations_id
            )}`,
            method: "GET"
         });
      }
   }, [material, amountTotalSellingData, tempSelected, dataDemand]);

   const setPassportImageList = (images: any) => {
      const materialImages = images
         ?.map((item: any, index: number) => {
            return (
               item?.object_url && (
                  <Image src={getImagePath(item)} key={index} />
               )
            );
         })
         .filter(Boolean);

      materialImages && setMaterialImages(materialImages);
   };

   const addAmount = (value: any) => {
      const temp: any = amountTotalSellingData;
      const ifExist: any = temp.findIndex((rec: any) => {
         return rec.selling_id === value.selling_id;
      });
      if (temp[ifExist]) {
         delete temp[ifExist];
      }
      temp.push(value);
      setAmountTotalSellingData(
         temp.filter((rec: any) => {
            return rec != undefined;
         })
      );
      let total = 0;
      let totalPrice = 0;
      temp
         .filter((rec: any) => {
            return rec != undefined;
         })
         .map((data: any) => {
            total = total + parseFloat(data.amount);
            totalPrice = totalPrice + parseFloat(data.proposalPrice);
         });
      setAmountTotal(total);
      form.setFieldsValue({ quantity: total, price_proposal: totalPrice });
      setTempSelected(temp);
   };

   const getPrice = (item: any) => {
      /*       console.log('rec', item);
      let deconstructionCost = 0;
      if (
         item?.data?.deconstructability &&
         item?.data?.deconstructability === "loose (easy)"
      ) {
         console.log("CASE-1");
         deconstructionCost = 1;
      } else if (
         item?.data?.deconstructability &&
         [
            "built-in (easy)",
            "built-in (moderate)",
            "built-in (elaborate)"
         ].indexOf(item?.data?.deconstructability) !== -1 &&
         item?.data?.third_party_deconstruction
      ) {
         deconstructionCost = 1;
      } else {
         deconstructionCost = 0;
      }
      return (
         item?.data?.selling_price -
         ((material?.demands_target_price
            ? material?.demands_target_price
            : 0) -
            material?.deconstruction_costs * deconstructionCost)
      ); */
      let deconstructionCost = 0;
      if (item.deconstruction === "provided") {
         deconstructionCost = 1;
      }
      //console.log('selectedItems', selectedItems);
      return (
         item?.data?.selling_price * 1 +
         material?.deconstruction_costs * deconstructionCost * 1
      );
   };

   const handleOk = async (requestedItemsInfo: any = {}) => {
      const data = form.getFieldsValue();
      await addRequest({
         data: {
            requestData: requestedItemsInfo.items
               .filter((rec: any) => {
                  return rec !== undefined;
               })
               .map((rec: any) => {
                  return {
                     ...rec,
                     cal_price_per_unit: getPrice(rec)
                  };
               }),
            proposal_price: data.price_proposal,
            comments: data.comments,
            building_name: buildingDetails.name,
            product_name: material.product_name,
            ...requestedItemsInfo,
            deconstruction_costs: material?.deconstruction_costs,
            demands_target_price: material?.demands_target_price
         }
      })
         .then(() => {
            form.resetFields();
            setShowSendRequest(false);
            setRefreshPassport(true);
            setAmountTotalSellingData([]);
            setAmountTotal(null);
            console.log("Close-me: MaterialDet");
            closeMe();
            message.success("Request sent successfully");
         })
         .catch((error) => {
            console.log(error);
            message.error(t("buildingOperations.errorMessage"));
         });
   };

   const handleCancel = () => {
      setShowSendRequest(false);
   };

   return (
      <Spin spinning={loadingLocationData}>
         <div className="content-wrap material-overview-wrapper">
            <PageHeader>
               <div className="page-title">
                  <h1>
                     {material.manufacturer} {material.product_name}
                  </h1>
                  {/* <span>{material?.product_group_name}</span> */}
               </div>
               <div className="manage-menu"></div>
            </PageHeader>
            {materialImages && (
               <div
                  className={
                     materialImages.length > 1
                        ? "image-scroll"
                        : "images-scroll single"
                  }
               >
                  {materialImages}
               </div>
            )}
            <PassportList
               material={material}
               showRequest={showRequestBtn}
               //setPassportImageList={setPassportImageList}
               addAmount={addAmount}
               refresh={refreshPassport}
               setRefresh={(val: any) => {
                  setRefreshPassport(val);
               }}
               tempSelected={tempSelected}
            ></PassportList>
            <div className="separated"></div>
            <div className="page-content">
               <div className="grid col-4">
                  {material.manufacturer && (
                     <div className="grid-item widget data">
                        <span>{t("materialDashboard.manufacturer")}</span>
                        <strong>{material.manufacturer}</strong>
                     </div>
                  )}
                  {material.ean && (
                     <div className="grid-item widget data">
                        <span>{t("materialDashboard.ean")}</span>
                        <strong>{material.ean}</strong>
                     </div>
                  )}
                  {material.yearOfManufacturing && (
                     <div className="grid-item widget data">
                        <span>
                           {t("materialDashboard.yearOfManufacturing")}
                        </span>
                        <strong>{material.yearOfManufacturing}</strong>n
                     </div>
                  )}
               </div>
               <div className="grid material-specifications">
                  <div className="specs widget">
                     <h4>{t("materialDashboard.specifications")}</h4>
                     {material.weight_per_unit && (
                        <dl>
                           <dt>{t("materialDashboard.weight")}</dt>
                           <dd>{material.weight_per_unit} kg</dd>
                        </dl>
                     )}
                     {materialGroupSpecification}
                     {material.materials && (
                        <h4>{t("productDetails.material.form.materials")}</h4>
                     )}
                     {material.materials && (
                        <dl className="material">
                           {material.materials.map((item: any) => {
                              return (
                                 <>
                                    <dt>{item.material_type}</dt>
                                    {item?.percentage && (
                                       <dd>
                                          {item?.material_sub_type && (
                                             <span>
                                                {item.material_sub_type}:{" "}
                                             </span>
                                          )}
                                          <span>{item?.percentage}%</span>
                                       </dd>
                                    )}
                                 </>
                              );
                           })}
                        </dl>
                     )}
                  </div>
                  <div className="description">
                     {material?.need_for_reuse && (
                        <div className="widget">
                           <h4>
                              {t("reusePotentialSteps.form.neededForReuse")}
                           </h4>
                           {getMaterialReuseForTag(material?.need_for_reuse)}
                        </div>
                     )}

                     {material?.used_as && (
                        <div className="widget">
                           <h4>
                              {t("reusePotentialSteps.form.couldBeUsedAs")}
                           </h4>
                           {material?.used_as.map((item: any) => {
                              return <Tag>{item}</Tag>;
                           })}
                        </div>
                     )}

                     {material.documents && (
                        <div className="widget">
                           <h4>{t("materialDashboard.documents")}</h4>
                           {getDocumentsList(material.documents)}
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
         {showRequestBtn && !!amountTotal && (
            <div className="cart-bar show">
               {!!amountTotal && (
                  <div className="selected">
                     <Button
                        type="link"
                        shape="circle"
                        icon={<CloseOutlined />}
                        onClick={() => {
                           setAmountTotal(null);
                           setAmountTotalSellingData([]);
                           setTempSelected([]);
                        }}
                     ></Button>
                     <strong>
                        {amountTotal} {t("passportDetails.pieces")}{" "}
                        {t("default.selected")}
                     </strong>
                  </div>
               )}

               <div className="actions">
                  <Button
                     type="primary"
                     className="send-request"
                     shape="round"
                     onClick={() => {
                        setShowSendRequest(true);
                     }}
                  >
                     {t("default.sendRequest")}
                  </Button>
               </div>
            </div>
         )}

         {showSendRequest && !!amountTotal && (
            <RequestModal
               visible={showSendRequest}
               onOk={handleOk}
               onCancel={handleCancel}
               confirmLoading={confirmLoading}
               items={amountTotalSellingData}
               material={material}
               demandData={{
                  ...dataDemand,
                  location: demandLocationData?.data
               }}
            />
         )}
      </Spin>
   );
};
