import React from "react";
import { useCalculateLca } from "../../hooks/useCalculateLca";
import { ProductGroup, Lca, CalcResult } from "../../types";
import "./style.scss";
import { Spin } from "antd";
import * as Sentry from "@sentry/react";
import { useFlagsmith } from "flagsmith-react";

type Props = {
   productGroup?: ProductGroup | ProductGroup[];
   amount?: number | number[];
   selectedMaterial?: string | (string | undefined)[];
   passport?: any;
   hideHtml?: boolean;
};
export const missingWidth = "Please select a width larger than 0.";
export const missingHeight = "Please select a height larger than 0.";
export const missingDepth = "Please select a depth larger than 0.";

export function LcaPotential({
   productGroup,
   amount,
   selectedMaterial,
   passport,
   hideHtml
}: Props) {
   const { calculateTotalLca } = useCalculateLca();
   const [error, setError] = React.useState<string>();
   const { hasFeature } = useFlagsmith();
   const hasLcaErrorHighlightEnabled = hasFeature("lca_error_highlight");

   const [total, setTotal] = React.useState<number>(0);
   const [lcaResult, setLcaResult] = React.useState<CalcResult>();

   React.useEffect(() => {
      try {
         if (productGroup && amount) {
            if (
               Array.isArray(productGroup) &&
               Array.isArray(amount) &&
               Array.isArray(selectedMaterial) &&
               Array.isArray(passport)
            ) {
               // for summing up LCA for whole building
               setTotal(0);
               productGroup.forEach((pg, i) => {
                  setSentryContext(pg);
                  try {
                     const material = getLcaMeta(pg, selectedMaterial[i]);
                     if (material) {
                        const conversion: any = {
                           width: null,
                           height: null,
                           depth: null,
                           multiplier: null
                        };
                        if (
                           passport[i]?.specification &&
                           pg?.templateData &&
                           pg?.conversion
                        ) {
                           const heightField = pg.templateData.template
                              .filter((rec: any) => {
                                 return (
                                    pg?.conversion?.height?.field_id === rec.id
                                 );
                              })
                              .pop();
                           if (
                              heightField !== undefined &&
                              passport[i].specification[
                                 heightField.formItem.name
                              ] !== undefined
                           ) {
                              conversion.height =
                                 passport[i].specification[
                                    heightField.formItem.name
                                 ].unitValue;
                           }

                           const widthField = pg.templateData.template
                              .filter((rec: any) => {
                                 return (
                                    pg?.conversion?.width?.field_id === rec.id
                                 );
                              })
                              .pop();
                           if (
                              widthField !== undefined &&
                              passport[i].specification[
                                 widthField.formItem.name
                              ] !== undefined
                           ) {
                              conversion.width =
                                 passport[i].specification[
                                    widthField.formItem.name
                                 ].unitValue;
                           }

                           const depthField = pg.templateData.template
                              .filter((rec: any) => {
                                 return (
                                    pg?.conversion?.depth?.field_id === rec.id
                                 );
                              })
                              .pop();
                           if (
                              depthField !== undefined &&
                              passport[i].specification[
                                 depthField.formItem.name
                              ] !== undefined
                           ) {
                              conversion.depth =
                                 passport[i].specification[
                                    depthField.formItem.name
                                 ].unitValue;
                           }

                           console.log("conversion", conversion);
                        }
                        conversion.multiplier = pg.conversion?.multiplier;
                        const lcaValue = calculateTotalLca(
                           pg.unit,
                           material.lca_unit || pg.lca_unit,
                           pg?.lca_meta && pg?.lca_meta.length > 0
                              ? material
                              : pg,
                           amount[i],
                           conversion
                        );
                        if (lcaValue?.status === "ok" && lcaValue?.result) {
                           setLcaResult(lcaValue);
                           setTotal((current) => {
                              if (lcaValue.result)
                                 return current + lcaValue?.result;
                              return 0;
                           });
                        }
                     } else {
                        setLcaResult({
                           status: "error",
                           message:
                              "Selected material is not available any more."
                        });
                     }
                  } catch (e) {
                     console.log(e);
                  }
               });
            } else if (
               !Array.isArray(productGroup) &&
               !Array.isArray(amount) &&
               !Array.isArray(selectedMaterial)
            ) {
               setSentryContext(productGroup);
               const conversion: any = {
                  width: null,
                  height: null,
                  depth: null,
                  multiplier: null
               };

               if (
                  passport?.specification &&
                  productGroup?.templateData &&
                  productGroup?.conversion
               ) {
                  const heightField = productGroup.templateData.template
                     .filter((rec: any) => {
                        return (
                           productGroup?.conversion?.height?.field_id === rec.id
                        );
                     })
                     .pop();
                  if (
                     heightField !== undefined &&
                     passport.specification[heightField.formItem.name] !==
                        undefined
                  ) {
                     conversion.height =
                        passport.specification[
                           heightField.formItem.name
                        ].unitValue;
                  }

                  const widthField = productGroup.templateData.template
                     .filter((rec: any) => {
                        return (
                           productGroup?.conversion?.width?.field_id === rec.id
                        );
                     })
                     .pop();
                  if (
                     widthField !== undefined &&
                     passport.specification[widthField.formItem.name] !==
                        undefined
                  ) {
                     conversion.width =
                        passport.specification[
                           widthField.formItem.name
                        ].unitValue;
                  }

                  const depthField = productGroup.templateData.template
                     .filter((rec: any) => {
                        return (
                           productGroup?.conversion?.depth?.field_id === rec.id
                        );
                     })
                     .pop();
                  if (
                     depthField !== undefined &&
                     passport.specification[depthField.formItem.name] !==
                        undefined
                  ) {
                     conversion.depth =
                        passport.specification[
                           depthField.formItem.name
                        ].unitValue;
                  }
                  console.log("conversion", conversion);
               }
               //console.log(productGroup);
               conversion.multiplier = productGroup.conversion?.multiplier;
               const material = getLcaMeta(productGroup, selectedMaterial);
               if (material) {
                  const lcaValue = calculateTotalLca(
                     productGroup.unit,
                     material.lca_unit || productGroup.lca_unit,
                     productGroup?.lca_meta && productGroup?.lca_meta.length > 0
                        ? material
                        : productGroup,
                     amount,
                     conversion
                  );

                  setLcaResult(lcaValue);
                  if (lcaValue.status === "ok" && lcaValue.result) {
                     setTotal(lcaValue.result);
                  } else {
                     Sentry.captureException(new Error(lcaValue.message));
                  }
               } else {
                  setLcaResult({
                     status: "error",
                     message: "Selected material is not available any more."
                  });
               }
            }
         }
      } catch (e: any) {
         setError(e.message);
         Sentry.captureException(e);
      }
   }, [amount, productGroup, calculateTotalLca, selectedMaterial]);

   if (!productGroup || !amount) {
      return null;
   }
   if (hideHtml) {
      return !error && lcaResult?.status === "ok" ? (
         <>
            {total.toFixed(2)} kgCO
            <sub>2</sub>-eq
         </>
      ) : (
         <>{error}</>
      );
   }
   /*    if (!selectedMaterial) {
      return (
         <span className={`lca-potential hint`}>
            <small>LCA Potential</small>
            <span>Please select a material</span>
         </span>
      );
   } */

   return !error ? (
      <span className={`lca-potential ${lcaResult?.status !== "ok" && "hint"}`}>
         {lcaResult?.status === "ok" ? (
            <>
               <small>LCA Potential</small>
               <Spin spinning={total === undefined}>
                  {total.toFixed(2)} kgCO
                  <sub>2</sub>-eq
               </Spin>
            </>
         ) : (
            <>
               <small>LCA Potential</small>
               <span>{lcaResult?.message}</span>
            </>
         )}
      </span>
   ) : hasLcaErrorHighlightEnabled ? (
      <span className="lca-potential error">
         <small>Error</small>
         {error}
      </span>
   ) : null;
}

// lca meta can be found on 2 places in product group, this merges it into one, to simplify the code for calculation
export const getLcaMeta = (
   productGroup: ProductGroup,
   selectedMaterial?: string
) => {
   if (
      productGroup?.lca_meta &&
      productGroup?.lca_meta?.length > 0 &&
      selectedMaterial
   ) {
      for (let i = 0; i < productGroup.lca_meta.length; i++) {
         if (productGroup.lca_meta[i].sub_material_id === selectedMaterial) {
            return productGroup.lca_meta[i];
         }
      }
   } else {
      const a: Lca = {
         gwp_a1a3: productGroup.gwp_a1a3,
         gwp_c1: productGroup.gwp_c1,
         gwp_c3: productGroup.gwp_c3,
         gwp_c4: productGroup.gwp_c4,
         lca_unit: productGroup.lca_unit,
         min: productGroup.min,
         max: productGroup.max
      };
      return a;
   }
};

const setSentryContext = (productGroup: ProductGroup) => {
   Sentry.setContext("productGroup", productGroup);
   Sentry.setContext("productGroupJsonEncoded", {
      productGroupJsonEncoded: JSON.stringify(productGroup)
   });
};
