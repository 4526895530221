import React, { useState } from "react";
import { Form, Select, Checkbox } from "antd";
import * as AntIconComponents from "@ant-design/icons";
import Joi from "joi";
import { useTranslation } from "react-i18next";
import engColors from "../../../locales/en/colors.json";

export const ColorSwatchFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("colorswatch")
   }).unknown()
});

export interface ColorSwatchField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      type: "colorswatch";
      [key: string]: any;
   };
   form: any;
}

export type AnyIconType = keyof typeof AntIconComponents;

const getIconComponent = (iconName: AnyIconType) => {
   const IconComponent: any = AntIconComponents[iconName];
   return IconComponent && <IconComponent />;
};

export const ColorSwatchField = (props: ColorSwatchField) => {
   const { formItem: formItemProps, field: fieldProps, form } = props;
   const [t] = useTranslation();
   const formattedProps = {
      ...fieldProps,
      ...(fieldProps.prefix && getIconComponent(fieldProps.prefix)
         ? { prefix: getIconComponent(fieldProps.prefix) }
         : {}),
      ...(fieldProps.suffix && getIconComponent(fieldProps.suffix)
         ? { suffix: getIconComponent(fieldProps.suffix) }
         : {})
   };
   const { name } = formItemProps;
   const key = name.split("_::specification-field::_");

   return (
      <>
         <Form.Item {...formItemProps} label={t(`${formItemProps.label}`)}>
            <CustomSwatchColorField></CustomSwatchColorField>
         </Form.Item>
         <Form.Item
            name={`isRalColorMatched_::specification-field::_${key[1]}`}
            valuePropName="checked"
         >
            <Checkbox>{t("isRalColorMatched")}</Checkbox>
         </Form.Item>
      </>
   );
};

const CustomOptions = (props: any) => {
   const { hex } = props;
   const [t] = useTranslation(["colors"]);
   return (
      <div>
         <div
            style={{
               background: hex,
               padding: "0px 10px 0px",
               display: "inline",
               marginLeft: "2px",
               border: "1px solid #d9d9d9"
            }}
         >
            &nbsp;
         </div>
         <span style={{ display: "inline", marginLeft: "5px" }}>
            {t(`${hex.toUpperCase()}`)}
         </span>
      </div>
   );
};

const CustomSwatchColorField = ({ value = " ", onChange }: any) => {
   const [colors] = useState(Object.keys(engColors));
   const { Option } = Select;
   const [t] = useTranslation(["colors"]);

   const filterOption = (input: any, option: any) => {
      const dataText = t(`${option.value.toUpperCase()}`);
      return dataText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
   };

   return (
      <Select
         style={{ width: 250, marginRight: 10 }}
         defaultValue={value.toUpperCase()}
         onSelect={(selected) => {
            onChange && onChange(selected);
         }}
         showSearch
         filterOption={filterOption}
         allowClear
      >
         {colors.map((color) => (
            <Option value={color} key={color}>
               <CustomOptions hex={color} />
            </Option>
         ))}
      </Select>
   );
};
