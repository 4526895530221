import { Button, Result } from "antd";
import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MaterialItemListItem } from "./MaterialItemListItem";

export const MaterialItemList = ({ materials, refetch }: any) => {
   const location = useLocation();
   const [t] = useTranslation();

   return (
      <>
         {materials && materials.length ? (
            <div>
               <div className="inventory-operation-intro">
                  <h4>
                     {t("materialDashboard.intro")}{" "}
                     <b>{t("navigation.materialPilot")}</b>
                  </h4>
               </div>
               <div className="material-item-header">
                  <span className="item">{t("materialDashboard.item")}</span>
                  <span className="sell">
                     {t("materialDashboard.holdSell")}
                  </span>
                  <span className="menu"></span>
               </div>

               {materials.map((item: any, index: number) => {
                  return (
                     <MaterialItemListItem
                        item={item}
                        key={`${item.id}_${index}`}
                        refetch={refetch}
                     ></MaterialItemListItem>
                  );
               })}
            </div>
         ) : (
            <Result
               title={t("materialDashboard.emptyMaterialTitle")}
               extra={
                  <Link
                     to={`${location.pathname}/add/product_details?state=new`}
                  >
                     <Button type="primary" shape="round">
                        {t("materialDashboard.addMaterialBtn")}
                     </Button>
                  </Link>
               }
            />
         )}
      </>
   );
};
