import { Col, message, Popconfirm, Row, Spin, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { useParams } from "react-router";
import { Resource } from "../../api/common";
import ButtonField from "../../components/FormFields/ButtonField";
import SettingsSideBar from "./SettingsSideBar";
import styles from "./Settings.module.scss";
import {
   // ArrowUpOutlined,
   ArrowDownOutlined,
   PlusOutlined,
   // RedoOutlined,
   ArrowRightOutlined
} from "@ant-design/icons";
import icon_passportmanager from "./../../statics/images/icon_passportmanager.svg";
// import icon_materialhub from "./../../statics/images/icon_materialhub.svg";
import mc_symbol from "./../../statics/images/mc_symbol.svg";
import Visa_Brandmark from "./../../statics/images/Visa_Brandmark.png";
import moment from "moment";
import TagField from "../../components/FormFields/TagField";
import { useBuilding } from "../../contexts/building";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

const OrganizationPlansAndBillings = ({}) => {
   const [t] = useTranslation();
   const { id }: any = useParams();
   const [customerId, setCustomerId] = useState<string>("");
   const { buildings } = useBuilding();
   const [currentTab, setCurrentTab] = useState("1");

   const [{ data, loading }] = useAxios(
      {
         url: `${Resource.path.plansURL}/customer/${id}`,
         method: "get",
         timeout: 900000
      },
      { useCache: false, manual: false, autoCancel: false }
   );

   const columns = [
      {
         title: t("settings.organization.date"),
         dataIndex: "invoice_created",
         key: "invoice_created",
         width: 110,
         render: (text: any, record: any) => (
            <span>{moment(record.invoice_created).format("YYYY/MM/DD")}</span>
         )
      },
      {
         title: t("settings.organization.project"),
         dataIndex: "building_id",
         key: "building_id",
         // width: 200,
         render: (text: any, record: any) => (
            <Link
               to={`/buildings/${record.building_id}`}
               style={{ color: "#000" }}
            >
               {
                  buildings.find(
                     (item: { id: any }) => item.id === record.building_id
                  )?.name
               }
            </Link>
         )
      },
      {
         title: t("settings.organization.plan"),
         dataIndex: "product_name",
         key: "product_name",
         width: 110,
         render: (text: any, record: any) => <span>{record.product_name}</span>
      },
      {
         title: t("settings.organization.interval"),
         dataIndex: "interval",
         key: "interval",
         width: 120,
         render: (text: any, record: any) => <span>{record.interval}</span>
      },
      {
         title: t("settings.organization.method"),
         dataIndex: "payment_funding",
         key: "payment_funding",
         width: 180,
         render: (text: any, record: any) => (
            <span>{`${record.payment_funding || ""} ${
               record.payment_mode
            } ****${record.payment_last4 || ""}`}</span>
         )
      },
      {
         title: t("settings.organization.amount"),
         dataIndex: "amount",
         key: "amount",
         width: 110,
         render: (text: any, record: any) => <span>{record.amount}€</span>
      },
      {
         title: t("settings.organization.status"),
         dataIndex: "invoice_status",
         key: "invoice_status",
         width: 100,
         render: (text: any, record: any) => (
            <TagField
               size="small"
               status={
                  record.invoice_status === "Paid" ? "success" : "attention"
               }
            >
               {record?.invoice_status?.toLowerCase()}
            </TagField>
         )
      },
      {
         title: t("settings.organization.invoice"),
         dataIndex: "invoice_pdf",
         key: "download",
         width: 200,
         render: (text: any, record: any) => (
            <a
               style={{
                  color: "#000",
                  fontSize: "12px",
                  fontWeight: "bold"
               }}
               href={record.invoice_pdf}
            >
               <ArrowDownOutlined style={{ color: "#818587" }} />{" "}
               {`${t("settings.organization.invoice")} ${
                  record.invoice_number
               }`}
            </a>
         )
      }
   ];
   // console.log(invoiceList);
   function TransactionsTable() {
      return (
         <div>
            <Table
               columns={columns}
               dataSource={invoiceList}
               // loading={loadingTable}
               pagination={false}
               className={"new-style font-12"}
            />
         </div>
      );
   }

   function TransactionsTable(transactions: any[]) {
      return (
         <div>
            <Table
               columns={columns}
               dataSource={transactions}
               // loading={loadingTable}
               pagination={false}
               className={"new-style font-12 bold"}
            />
         </div>
      );
   }

   const activeTabChanged = (index: any) => {
      setCurrentTab(index);
   };

   const OverviewTab = () => {
      const [subList, setSubList] = useState<any>();
      const [cardsList, setCardsList] = useState<any>(null);
      const [latestTransactions, setLatestTransactions] = useState<any>();

      const [{ data: dataSubList, loading: loadingSubList }] = useAxios(
         {
            url: `${Resource.path.plansURL}/customer/sub/${id}/list`,
            method: "get",
            timeout: 900000
         },
         { useCache: false, manual: false }
      );

      const [{ data: dataCards, loading: loadingCards, error }, fetchCards] =
         useAxios(
            {
               url: `${Resource.path.plansURL}/cards/`,
               method: "get",
               timeout: 900000
            },
            { useCache: false, manual: true }
         );

      const [
         { data: latestTransactionData, loading: loadingLatestTransactions }
      ] = useAxios(
         {
            url: `${Resource.path.plansURL}/customer/invoice/${id}/list?limit=3`,
            method: "get"
         },
         { useCache: false, manual: false }
      );

      const [{ loading: loadingAddCard }, addNewCardSession] = useAxios(
         {
            url: `${Resource.path.plansURL}/add/card`,
            method: "post",
            timeout: 900000
         },
         { useCache: false, manual: true }
      );

      useEffect(() => {
         if (data?.data) {
            setCustomerId(data?.data?.id);
            fetchCards({
               url: `${Resource.path.plansURL}/cards/${data?.data?.id}`,
               method: "get",
               timeout: 900000
            });
         }
         if (dataSubList?.data) {
            setSubList(dataSubList?.data);
         }
      }, [data, dataSubList]);

      useEffect(() => {
         if (dataCards?.data) {
            setCardsList(dataCards?.data);
         }
      }, [dataCards]);

      useEffect(() => {
         if (latestTransactionData?.data) {
            setLatestTransactions(latestTransactionData?.data);
         }
      }, [latestTransactionData]);

      const CardsItems = ({ data }: any) => {
         const [{ loading: loadingDelete }, deleteCard] = useAxios(
            {
               url: `${Resource.path.plansURL}/card/${data?.id}`,
               method: "delete",
               timeout: 900000
            },
            { useCache: false, manual: true }
         );
         const deActivateCard = async () => {
            //console.log(data);
            await deleteCard();
            await fetchCards({
               url: `${Resource.path.plansURL}/cards/${customerId}`,
               method: "get",
               timeout: 900000
            });
         };
         return (
            <Col lg={8} sm={12} xs={24}>
               <div className={`${styles.box} ${styles.paymentMethod}`}>
                  <div className="d-flex-between">
                     <span>{data.name}</span>
                     <div className={styles.tags}>
                        {/* {paymentMethod === data.id && (
                        <TagField status="success" size="small">
                           {t("settings.organization.default")}
                        </TagField>
                     )} */}
                        {moment().year() >= data.exp_year &&
                           moment().month() + 1 > data.exp_month && (
                              <TagField status="warning" size="small">
                                 {t("settings.organization.expired")}
                              </TagField>
                           )}
                        {moment().year() === data.exp_year &&
                           data.exp_month - (moment().month() + 1) < 2 &&
                           moment().month() + 1 <= data.exp_month && (
                              <TagField status="attention" size="small">
                                 {`${t("settings.organization.expiringOn")} ${
                                    data.exp_month
                                 }/${data.exp_year}`}
                              </TagField>
                           )}
                     </div>
                  </div>
                  <div className={styles.card}>
                     <img
                        className={styles.cardLogo}
                        src={
                           data.brand === "visa"
                              ? Visa_Brandmark
                              : data.brand === "mastercard"
                              ? mc_symbol
                              : ""
                        }
                     />
                     <div>**** **** **** {data.last4}</div>
                     <ButtonField
                        loading={loadingDelete}
                        type="negative"
                        size="small"
                        onClick={() => deActivateCard()}
                     >
                        {t("settings.organization.remove")}
                     </ButtonField>
                  </div>
               </div>
            </Col>
         );
      };

      const addNewCard = async () => {
         await addNewCardSession({
            data: {
               organisation_id: id,
               customer_id: customerId,
               redirectCancel: `settings/${id}/plans_billing`,
               redirectSuccess: `settings/${id}/plans_billing`
            }
         })
            .then((response: any) => {
               window.location.replace(response?.data?.data?.url);
            })
            .catch(() => {
               message.error("Something went wrong");
            });
      };

      return (
         <Spin
            spinning={
               loadingLatestTransactions || loadingCards || loadingSubList
            }
         >
            <Row gutter={24} className={styles.section}>
               <Col span={24}>
                  <h3 className={styles.headerTitle}>
                     {t("settings.organization.subscriptions")}
                  </h3>
               </Col>
               {/* <Col span={8}>
                     <div className={styles.section}>
                        <div className={styles.header}>
                           <div className="d-flex-center flex-align-center">
                              <img
                                 className={styles.icon}
                                 src={icon_materialhub}
                                 alt="logo"
                              />
                              <h3 className={styles.title}>MaterialHub</h3>
                           </div>
                        </div>
                        <div className={styles.box}>
                           <p className={styles.subTitle}>
                              {t("settings.organization.currentPlan")}
                           </p>
                           <div className={styles.row}>
                              <span>Starter</span>
                              <span>Free</span>
                           </div>
                        </div>
                     </div>
                  </Col> */}
               <Col sm={8} xs={24}>
                  <div className={styles.section}>
                     <div className={styles.box}>
                        <div className={styles.boxTitle}>
                           <img
                              className={styles.icon}
                              src={icon_passportmanager}
                              alt="logo"
                           />
                           <h3 className={styles.title}>
                              {t("settings.organization.passportManager")}
                           </h3>
                        </div>
                        <Row className={`${styles.subTitle} ${styles.row}`}>
                           {subList?.length
                              ? t(
                                   "settings.organization.currentPlans"
                                ).toUpperCase()
                              : t(
                                   "settings.organization.noCurrentPlans"
                                ).toUpperCase()}
                        </Row>
                        {subList &&
                           subList?.map((rec: any) => (
                              <Row className={styles.row}>
                                 <Col span={7}>
                                    <span>
                                       {rec.count}{" "}
                                       {rec.count > 1
                                          ? t("settings.organization.buildings")
                                          : t("settings.organization.building")}
                                    </span>
                                 </Col>
                                 <Col span={15} className="d-flex-between">
                                    <span>{rec.product_name}</span>
                                    <span>
                                       {t("settings.organization.total")}
                                       {"  "}
                                       {rec.price * rec.count} €/
                                       {t("settings.organization.year")}
                                    </span>
                                 </Col>
                              </Row>
                           ))}
                     </div>
                  </div>
               </Col>
               <Col span={8}></Col>
            </Row>
            <Row gutter={24}>
               <Col span={24}>
                  <div className={styles.section}>
                     <h3 className={styles.headerTitle}>
                        {t("settings.organization.defaultPaymentMethod")}
                     </h3>
                     {/* {!!(cardsList && cardsList.length) && ( */}
                     <Row gutter={24}>
                        {cardsList &&
                           cardsList.map((rec: any) => (
                              <CardsItems data={rec} />
                           ))}
                        <Col lg={8} sm={12} xs={24}>
                           {!cardsList?.length && (
                              <Popconfirm
                                 title={
                                    <div
                                       style={{
                                          width: "330px",
                                          padding: "0 10px"
                                       }}
                                    >
                                       <h3 style={{ fontWeight: "bold" }}>
                                          {t(
                                             "settings.organization.readBefore"
                                          )}
                                       </h3>
                                       <p>
                                          {t(
                                             "settings.organization.readBeforeText"
                                          )}
                                       </p>
                                    </div>
                                 }
                                 onConfirm={addNewCard}
                                 icon={null}
                                 okText={
                                    <>
                                       <ArrowRightOutlined />{" "}
                                       {t(
                                          "settings.organization.confirmContinue"
                                       )}
                                    </>
                                 }
                                 cancelText={t("default.cancel")}
                                 overlayClassName={"confirm-no-icon"}
                                 okButtonProps={{
                                    loading: loadingAddCard
                                 }}
                              >
                                 <div className={styles.addNewCard}>
                                    <PlusOutlined className={styles.addIcon} />{" "}
                                    {t("settings.organization.addNew")}
                                 </div>
                              </Popconfirm>
                           )}
                        </Col>
                     </Row>
                     {/* )} */}
                  </div>
               </Col>
            </Row>
            <div className={`${styles.invoices} ${styles.section}`}>
               <h3 className={styles.headerTitle}>
                  {t("settings.organization.latestTransactions")}
               </h3>
               {TransactionsTable(latestTransactions)}
            </div>
         </Spin>
      );
   };

   const TransactionsTab = () => {
      const [transactions, setTransactions] = useState<any>();

      const [{ data: transactionData, loading: loadingTransactions }] =
         useAxios(
            {
               url: `${Resource.path.plansURL}/customer/invoice/${id}/list`,
               method: "get"
            },
            { useCache: false, manual: false }
         );
      useEffect(() => {
         if (transactionData?.data) {
            setTransactions(transactionData?.data);
         }
      }, [transactionData]);

      return (
         <Spin spinning={loadingTransactions}>
            {TransactionsTable(transactions)}
         </Spin>
      );
   };

   const SubscriptionTab = () => {
      const [subscriptions, setSubscriptions] = useState<any>();

      const [
         { data: subscriptionsData, loading: loadingSubscriptions },
         getSubscriptions
      ] = useAxios(
         {
            url: `${Resource.path.plansURL}/customer/trans/${customerId}/list`,
            method: "get"
         },
         { useCache: false, manual: true }
      );

      const [{ loading: loadingCancel }, cancelSubscription] = useAxios(
         {
            method: "DELETE"
         },
         { useCache: false, manual: true }
      );

      useEffect(() => {
         if (subscriptionsData?.data) {
            setSubscriptions(subscriptionsData?.data);
         }
      }, [loadingSubscriptions]);

      const subscriptionColumns = [
         {
            title: t("settings.organization.subscription"),
            dataIndex: "name",
            key: "name",
            // width: 110,
            render: (text: any, record: any) => (
               <Link
                  to={`/buildings/${record.building_id}`}
                  style={{ color: "#000" }}
               >
                  {
                     buildings.find(
                        (item: { id: any }) => item.id === record.building_id
                     ).name
                  }
               </Link>
            )
         },
         {
            title: t("settings.organization.plan"),
            dataIndex: "product_name",
            key: "product_name",
            // width: 130,
            render: (text: any, record: any) => (
               <span>{record.product_name}</span>
            )
         },
         {
            title: t("settings.organization.started"),
            dataIndex: "created_date",
            key: "created_date",
            width: 150,
            render: (text: any, record: any) => (
               <span>{moment(record.created_date).format("YYYY/MM/DD")}</span>
            )
         },
         {
            title: t("settings.organization.monthly"),
            dataIndex: "amount",
            key: "amount",
            width: 120,
            render: (text: any, record: any) => <span>{record.amount}€</span>
         },
         {
            title: t("settings.organization.status"),
            dataIndex: "status",
            key: "status",
            width: 250,
            render: (text: any, record: any) => (
               <div className="d-flex flex-align-center">
                  <TagField
                     size="small"
                     status={
                        record.status === "active" ? "success" : "attention"
                     }
                  >
                     {record.status === "active"
                        ? t("settings.organization.ongoing")
                        : t("settings.organization.cancelled")}
                  </TagField>{" "}
                  {record.status === "active" ? (
                     <div style={{ paddingLeft: 10 }}>{` ${t(
                        "settings.organization.until"
                     )} ${moment(record.current_period_end).format(
                        "YYYY/MM/DD"
                     )}`}</div>
                  ) : (
                     ""
                  )}
               </div>
            )
         },
         {
            title: "",
            dataIndex: "status",
            key: "action",
            width: 120,
            render: (text: any, record: any) => (
               <div className="d-flex-end">
                  {
                     record.status === "active" ? (
                        <ButtonField
                           type={"link"}
                           onClick={async () =>
                              await cancelSubscription({
                                 url: `${Resource.path.plansURL}/customer/sub/${record.subscription_id}`
                              }).then(async (resp) => await getSubscriptions())
                           }
                           style={{ color: "#000" }}
                        >
                           {t("default.cancel").toLowerCase()}
                        </ButtonField>
                     ) : null
                     // <ButtonField
                     //    type={"alternative"}
                     //    onClick={async () =>
                     //       history.push(
                     //          `/subscription/${id}/${record.building_id}`
                     //       )
                     //    }
                     // >
                     //    <RedoOutlined style={{ color: "#000" }} />
                     //    {t("settings.organization.activate")}
                     // </ButtonField>
                  }
               </div>
            )
         }
      ];

      useEffect(() => {
         customerId && getSubscriptions();
      }, [customerId]);

      return (
         <Spin spinning={loadingSubscriptions || loadingCancel}>
            <div className={styles.subscriptions}>
               {/* <div className={styles.section}>
            <div className={styles.header}>
               <img
                  className={styles.icon}
                  src={icon_materialhub}
                  alt="logo"
               />
               <h3 className={styles.title}>MaterialHub</h3>
            </div>
            <div>
               <Table
                  columns={subscriptionColumns}
                  dataSource={invoiceList}
                  // loading={loadingTable}
                  pagination={false}
                  className={"new-style font-12"}
               />
            </div>
         </div> */}
               <div className={styles.section}>
                  <div className={styles.header}>
                     <img
                        className={styles.icon}
                        src={icon_passportmanager}
                        alt="logo"
                     />
                     <h3 className={styles.title}>
                        {t("settings.organization.passportManager")}
                     </h3>
                  </div>
                  <div>
                     <Table
                        columns={subscriptionColumns}
                        dataSource={subscriptions}
                        // loading={loadingSubscriptions}
                        pagination={false}
                        className={"new-style font-12 bold"}
                     />
                  </div>
               </div>
            </div>
         </Spin>
      );
   };

   return (
      <SettingsSideBar title={t("settings.organization.plansAndBillings")}>
         <div className={styles.plansAndBilling}>
            <Tabs defaultActiveKey="1" onChange={activeTabChanged}>
               <TabPane
                  tab={t("settings.organization.overview")}
                  key="1"
                  className={styles.tabContent}
               >
                  <OverviewTab />
               </TabPane>
               <TabPane tab={t("settings.organization.transactions")} key="2">
                  <TransactionsTab />
               </TabPane>
               <TabPane tab={t("settings.organization.subscriptions")} key="3">
                  <SubscriptionTab />
               </TabPane>
            </Tabs>
         </div>
      </SettingsSideBar>
   );
};

export default OrganizationPlansAndBillings;
