import { Col, Row, Form, FormInstance } from "antd";
import React, { useState, useEffect } from "react";
import CategoriesTreeSearchField from "./CategoriesTreeSearchField";
import ProductGroupField from "./ProductGroupSelectField";
import useAxios from "axios-hooks";
import { Resource } from "../../../../../api/common";
import ProductSpecification from "./ProductSpecification";
import { useTranslation } from "react-i18next";
import { ProductGroup } from "../../../../../types";

type Props = {
   form: FormInstance<any>;
   onProductGroupSelected: ({
      label,
      value
   }: {
      label: string;
      value: string;
   }) => void;
   operationType: "EDIT";
   showFormFields: boolean;
   setShowFormFields: (val: boolean) => void;
   productGroup: ProductGroup;
};

export const ProductCategory = ({
   form,
   onProductGroupSelected,
   operationType,
   showFormFields,
   setShowFormFields,
   productGroup
}: Props) => {
   const [t] = useTranslation();
   const [selectedCategoryId, setSelectedCategoryId] = useState<any>(undefined);
   const [formSchema, setFormSchema] = useState<any>([]);
   const [resetProduct, setResetProduct] = useState<any>(false);
   const [showProductGroup, setShowProductGroup] = useState<boolean>(false);
   const [inActiveProductGroup, setInActiveProductGroup] = useState<any>();

   useEffect(() => {
      const productGroupKey = form.getFieldValue("product_group")?.key;

      if (productGroupKey)
         executeGet({
            url: `${Resource.path.getFormTemplateById}/${productGroupKey}`
         });
   }, [form.getFieldValue("product_group")?.key]);

   const [{ data, loading: postLoading, error: postError }, executeGet] =
      useAxios(
         {
            method: "GET"
         },
         {
            manual: true,
            useCache: true
         }
      );

   useEffect(() => {
      if (data) {
         const schema = data.data;
         schema && setFormSchema(schema.template);
      }
      if (operationType === "EDIT") {
         setShowProductGroup(true);
      }
   }, [data, operationType]);

   const onCategorySelect = (value: {
      id: string;
      name: string;
      dropDownId: string;
      reset: boolean;
   }) => {
      if (operationType !== "EDIT") {
         setShowProductGroup(false);
      }
      const { id: categoryId, reset } = value;
      setSelectedCategoryId(categoryId);
      setResetProduct(reset);
      if (operationType !== "EDIT") {
         setShowFormFields(false);
         setTimeout(() => {
            setShowProductGroup(true);
         }, 1500);
      }
   };

   const onProductGroupSelect = (value: any) => {
      if (value) {
         executeGet({
            url: `${Resource.path.getFormTemplateById}/${value.key}`
         });
      } else {
         setFormSchema(null);
      }

      form.setFieldsValue({ product_group: value });
      onProductGroupSelected(value);
   };

   const setInActive = (data: any) => {
      //console.log('inavtice', data)
      setInActiveProductGroup(data);
   };

   if (postLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }
   return (
      <>
         <Row gutter={12}>
            <Col xs={24} sm={12}>
               <CategoriesTreeSearchField
                  form={form}
                  onChange={onCategorySelect}
                  operationType={operationType}
                  setInActive={setInActive}
               ></CategoriesTreeSearchField>
            </Col>
         </Row>
         {showProductGroup && (
            <Row gutter={12}>
               <Col xs={24} sm={12}>
                  <Form.Item
                     label={t("productDetails.productCategory.productGroup")}
                     name="product_group"
                     rules={[
                        {
                           required: true,
                           message: t(
                              "productDetails.productCategory.validations.productGroup"
                           )
                        }
                     ]}
                  >
                     <ProductGroupField
                        onChange={onProductGroupSelect}
                        categoryId={selectedCategoryId}
                        reset={resetProduct}
                        operationType={operationType}
                        inActive={inActiveProductGroup}
                     ></ProductGroupField>
                  </Form.Item>
               </Col>
            </Row>
         )}
         {showFormFields && (
            <ProductSpecification
               productGroup={productGroup}
               schema={formSchema}
               form={form}
            ></ProductSpecification>
         )}
      </>
   );
};
