import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as BaseRouter } from "react-router-dom";
import { FlagsmithProvider } from "flagsmith-react";
import { CookiesProvider } from "react-cookie";

import "./main.scss";

const FlagSmithEnv = process.env.FEATURE_FLAG_ENV || process.env.NODE_ENV;

ReactDOM.render(
   <React.StrictMode>
      <BaseRouter>
         <FlagsmithProvider
            environmentId={
               FlagSmithEnv === "production"
                  ? "LMbYsJadPnBDYcwo3ZSKAm"
                  : "J4VamU3eAPifcBbxiXNasq"
            }
         >
            <CookiesProvider>
               <App />
            </CookiesProvider>
         </FlagsmithProvider>
      </BaseRouter>
   </React.StrictMode>,
   document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
