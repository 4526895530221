import React from "react";
import "./index.scss";

export interface PropsPassportIdentifier {
   identifier: string;
   locationNote: string;
   room: string;
   onIdentifierClicked: any;
}

// @FixMe: Get rid of passport args
const PassportIdentifier: React.FC<any> = ({
   identifier,
   locationNote,
   room,
   onIdentifierClicked
}: PropsPassportIdentifier) => {
   return (
      <span className="passport-id" onClick={() => onIdentifierClicked()}>
         <span className="light">#</span>
         {identifier}
         {room && <span className="tag">{room}</span>}
         {locationNote && <span className="tag">{locationNote}</span>}
      </span>
   );
};

export default PassportIdentifier;
