import {
   Form,
   Input,
   Row,
   Col,
   Button,
   Select,
   Checkbox,
   message,
   Collapse,
   AutoComplete,
   Spin
} from "antd";
import React, { useEffect, useState } from "react";
import { ProductCategory } from "./ProductCategory";
import { Materials } from "./Materials";
import { Documents } from "./Documents";
import { ProductPrice } from "./ProductPrice";
import { Resource } from "../../../../api/common";
import useAxios from "axios-hooks";
import "./style.scss";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useBuilding } from "../../../../contexts/building/index";
import { ImageUploaderSortable } from "../../../../components/ImageUploaderSortable/index";
import { getFormattedMaterialsDetails } from "./saveMaterialDetails";
import { getAxiosInstance } from "../../../../utils/axios";
import { Mixpanel } from "../../../../utils/tracking/mixpanel";
import { Events } from "../../../../utils/tracking/tracking.events";

const getFormattedBuildingAddress = (building: any) => {
   if (!building) return "";

   return [
      building?.street,
      building?.house_number,
      building?.postal_code,
      "-",
      building?.city
   ]
      .filter(Boolean)
      .join(" ");
};

export const ProductDetails = ({
   initialValues,
   operationType,
   pageTitle
}: any) => {
   const history = useHistory();
   const [form] = Form.useForm();
   const [productUnit, setProductUnit] = useState();
   const [t] = useTranslation();
   const { getBuildingDetail, buildings } = useBuilding();
   const { id, passport_specification_id }: any = useParams();
   const [currentSelectedBuilding, setCurrentSelectedBuilding] = useState();
   const [loading, setLoading] = useState(false);
   const [buildingCurrentLocation, setBuildingCurrentLocation] = useState("");
   const { Panel } = Collapse;
   const [showFormFields, setShowFormFields] = useState(false);
   const [locations, setLocations] = useState<any>();
   const [locationsOrg, setLocationsOrg] = useState<any>();
   const [valueLocation, setValueLocation] = useState<any>();
   const [selectedLocation, setSelectedLocation] = useState<any>();
   const [locationAPI, setLocationAPI] = useState<any>([]);
   const [locationDropDownOpen, setLocationDropDownOpen] = useState<any>(false);
   const [isNewLocation, setIsNewLocation] = useState<any>(false);

   const plainOptions = [
      {
         label: t("staticDropdown.needForReuse.refurbishment"),
         value: t("staticDropdown.needForReuse.refurbishment")
      },
      {
         label: t("staticDropdown.needForReuse.reCertification"),
         value: t("staticDropdown.needForReuse.reCertification")
      },
      {
         label: t("staticDropdown.needForReuse.specialLogistics"),
         value: t("staticDropdown.needForReuse.specialLogistics")
      },
      {
         label: t("staticDropdown.needForReuse.repainting"),
         value: t("staticDropdown.needForReuse.repainting")
      }
   ];

   const [{ data: dataLocations, loading: getLocations }] = useAxios(
      `${Resource.path.getLocationsHub}`,
      {
         useCache: false,
         manual: false
      }
   );

   const [
      { data: dataPlaces, loading: getPlaces },
      findPlace,
      cancelRequestLocation
   ] = useAxios(
      {
         url: `${Resource.path.getLocationsHub}`,
         method: "POST"
      },
      { useCache: false, manual: true }
   );

   useEffect(() => {
      if (initialValues?.product_unit) {
         setProductUnit(initialValues?.product_unit);
      }
      if (initialValues?.building_location_of) {
         setCurrentSelectedBuilding(initialValues?.building_location_of);
      }
      if (initialValues?.locations_id) {
         setSelectedLocation(initialValues?.locations_id);
      }
      if (dataLocations?.data && dataLocations?.data?.result) {
         const temp = dataLocations?.data?.result.map((value: any) => {
            const { id, name } = value;
            return {
               value: `existing_${id}`,
               label: name
            };
         });
         setLocations(temp);
         setLocationsOrg(temp);
         if (initialValues?.locations_id) {
            setSelectedLocation(initialValues?.locations_id);
         }
      }
      if (dataPlaces?.data && dataPlaces?.data?.locations) {
         setLocationDropDownOpen(true);
      }
   }, [initialValues?.product_unit, dataLocations, dataPlaces]);

   useEffect(() => {
      if (id) {
         const building = getBuildingDetail(id);

         // Set Building Current Location details
         if (building) {
            const address = getFormattedBuildingAddress(building);
            setBuildingCurrentLocation(address);
            if (
               !initialValues?.material_current_location &&
               initialValues?.building_location_of !==
                  t("productDetails.otherLocation")
            ) {
               form.setFieldsValue({
                  material_current_location: address
               });
            }

            if (!initialValues?.building_location_of) {
               form.setFieldsValue({
                  building_location_of: building.name
               });
               setCurrentSelectedBuilding(building.name);
            } else {
               setCurrentSelectedBuilding(initialValues?.building_location_of);
            }
         }
      }
      if (operationType === "EDIT") {
         setShowFormFields(true);
      }
   }, [id, initialValues, operationType]);

   const handleOnSelectBuilding = (value: any) => {
      setCurrentSelectedBuilding(value);

      if (value !== t("productDetails.otherLocation")) {
         const selectedBuilding = buildings.find((building: any) => {
            return building?.name == value;
         });

         if (selectedBuilding) {
            const address = getFormattedBuildingAddress(selectedBuilding);

            form.setFieldsValue({
               material_current_location: address
            });
         }
      } else {
         form.setFieldsValue({
            material_current_location: ""
         });
      }
   };

   const [
      { data, loading: getProductDetailsLoading, error },
      fetchProductGroupDetails
   ] = useAxios({}, { useCache: false, manual: true });

   useEffect(() => {
      if (data?.data && data?.data?.unit) {
         setProductUnit(data.data.unit);
         setShowFormFields(true);
      }
   }, [data]);

   const onProductGroupSelected = (value: any) => {
      value &&
         value.key &&
         fetchProductGroupDetails({
            url: `${Resource.path.getProductGroupDetails}/${value.key}`
         });
   };

   const pg = form.getFieldValue("product_group");

   React.useEffect(() => {
      if (pg?.key) {
         fetchProductGroupDetails({
            url: `${Resource.path.getProductGroupDetails}/${pg.key}`
         });
      }
   }, [pg?.key, fetchProductGroupDetails]);

   const handleOnSubmit = async (values: any) => {
      if (loading) return true;
      setLoading(true);

      let _currentSelectedBuilding = currentSelectedBuilding;
      let _buildingCurrentLocation = buildingCurrentLocation;

      if (!currentSelectedBuilding || !buildingCurrentLocation) {
         const building = buildings.find((building: any) => {
            return building?.id == id;
         });

         _currentSelectedBuilding = building?.id;
         _buildingCurrentLocation = getFormattedBuildingAddress(building);
      }

      try {
         if (operationType === "EDIT") {
            const materialPassportDetails = await getFormattedMaterialsDetails({
               building_id: id,
               wizard_steps_product_details: values
            });

            const axios = await getAxiosInstance();
            axios
               .post(
                  Resource.path.updateMaterialPassportBulk +
                     `/${passport_specification_id}`,
                  {
                     ...materialPassportDetails,
                     product_unit: productUnit,
                     building_location_of:
                        values?.building_location_of ||
                        _currentSelectedBuilding,
                     material_current_location:
                        values?.material_current_location ||
                        _buildingCurrentLocation,
                     material_location_places_api: isNewLocation
                        ? selectedLocation
                        : {},
                     isNewLocation,
                     locations_id: !isNewLocation ? selectedLocation : null
                  }
               )
               .then(() => {
                  message.success(t("reusePotentialSteps.successMessage"));
                  history.push(`/buildings/${id}/material_inventory`);
                  setLoading(false);
               })
               .catch((error) => {
                  console.log(error);
                  message.error(
                     "Some error occurred while processing this request"
                  );
                  setLoading(false);
               });
         } else {
            const materialPassportDetails = await getFormattedMaterialsDetails({
               building_id: id,
               wizard_steps_product_details: values
            });

            console.log(materialPassportDetails);

            const axios = await getAxiosInstance();
            axios
               .post(Resource.path.createMaterialPassport, {
                  ...materialPassportDetails,
                  product_unit: productUnit,
                  building_location_of:
                     values?.building_location_of || _currentSelectedBuilding,
                  material_current_location:
                     values?.material_current_location ||
                     _buildingCurrentLocation,
                  material_location_places_api: isNewLocation
                     ? selectedLocation
                     : {},
                  isNewLocation,
                  locations_id: !isNewLocation ? selectedLocation : null
               })
               .then((response: any) => {
                  message.success(t("reusePotentialSteps.successMessage"));

                  Mixpanel.track(Events.Add_Material.name, {
                     "Building Id": materialPassportDetails?.building_id,
                     "Building Location":
                        materialPassportDetails?.building_location_of,
                     "Category Name": materialPassportDetails?.category?.name,
                     "Product Group Name":
                        materialPassportDetails?.product_group?.label,
                     "Material Name": materialPassportDetails?.name
                  });

                  //history.push(`/buildings/${id}/material_inventory`);
                  history.push({
                     pathname: `/buildings/${id}/material_inventory/${response?.data?.data?.passportSpecificationId}`,
                     state: {
                        material: response?.data?.data?.details,
                        showAddPassport: true
                     }
                  });

                  setLoading(false);
               })
               .catch((error) => {
                  console.log(error);
                  message.error(
                     "Some error occurred while processing this request"
                  );
                  setLoading(false);
               });
         }
      } catch (error) {
         console.log(error);
         setLoading(false);
      }
   };

   if (getProductDetailsLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   const onChange = () => {};

   return (
      <>
         <h1 className="wizard-title">{pageTitle}</h1>
         <Row>
            <Col span={24}>
               <Form
                  name="material_form"
                  form={form}
                  size="large"
                  layout="vertical"
                  initialValues={initialValues}
                  onFinish={handleOnSubmit}
               >
                  <ProductCategory
                     form={form}
                     onProductGroupSelected={onProductGroupSelected}
                     operationType={operationType}
                     showFormFields={showFormFields}
                     setShowFormFields={setShowFormFields}
                     productGroup={data?.data}
                  ></ProductCategory>

                  {showFormFields && (
                     <Row gutter={24}>
                        <Col span={24}>
                           <Materials form={form}></Materials>
                        </Col>
                     </Row>
                  )}

                  {showFormFields && (
                     <Form.Item
                        name="images"
                        label={t("passportDetails.uploadImage")}
                     >
                        <ImageUploaderSortable
                           form={form}
                        ></ImageUploaderSortable>
                     </Form.Item>
                  )}

                  {showFormFields && (
                     <Collapse
                        ghost
                        defaultActiveKey={operationType === "EDIT" ? 1 : []}
                     >
                        <Panel header={t("productDetails.moreOptions")} key="1">
                           <ProductPrice unit={productUnit}></ProductPrice>

                           <Row gutter={24}>
                              <Col span={24}>
                                 <Documents></Documents>
                              </Col>
                           </Row>

                           <Form.Item
                              name="used_as"
                              label={t(
                                 "reusePotentialSteps.form.couldBeUsedAs"
                              )}
                           >
                              <Select mode="tags" allowClear>
                                 <Select.Option
                                    value={
                                       t(
                                          "staticDropdown.couldBeUsedAs.roof"
                                       ) as string
                                    }
                                 >
                                    {t("staticDropdown.couldBeUsedAs.roof")}
                                 </Select.Option>
                                 <Select.Option
                                    value={
                                       t(
                                          "staticDropdown.couldBeUsedAs.facade"
                                       ) as string
                                    }
                                 >
                                    {t("staticDropdown.couldBeUsedAs.facade")}
                                 </Select.Option>
                              </Select>
                           </Form.Item>
                           <Form.Item
                              label={t(
                                 "reusePotentialSteps.form.neededForReuse"
                              )}
                              name="need_for_reuse"
                           >
                              <Checkbox.Group
                                 options={plainOptions}
                                 defaultValue={[
                                    t(
                                       "staticDropdown.needForReuse.refurbishment"
                                    ) as string
                                 ]}
                                 onChange={onChange}
                              />
                           </Form.Item>
                           <Form.Item
                              label={t("reusePotentialSteps.form.notes")}
                              name="notes"
                           >
                              <Input.TextArea rows={5} />
                           </Form.Item>

                           <Form.Item
                              name="building_location_of"
                              label={t("productDetails.buildingLocation")}
                           >
                              <Select
                                 onChange={handleOnSelectBuilding}
                                 allowClear
                              >
                                 {buildings.map((building: any) => {
                                    return (
                                       <Select.Option
                                          key={building.id}
                                          value={building.name}
                                       >
                                          {building.name}
                                       </Select.Option>
                                    );
                                 })}
                                 <Select.Option
                                    value={
                                       t(
                                          "productDetails.otherLocation"
                                       ) as string
                                    }
                                 >
                                    {t("productDetails.otherLocation")}
                                 </Select.Option>
                              </Select>
                           </Form.Item>
                           {currentSelectedBuilding ===
                              t("productDetails.otherLocation") && (
                              <Form.Item
                                 name="material_current_location"
                                 label={
                                    currentSelectedBuilding ===
                                       t("productDetails.otherLocation") &&
                                    t("productDetails.currentLocation")
                                 }
                                 rules={[
                                    {
                                       required:
                                          currentSelectedBuilding ===
                                          t("productDetails.otherLocation"),
                                       message: t(
                                          "productDetails.currentLocationRequiredMessage"
                                       )
                                    },
                                    ({}) => ({
                                       validator(_, value) {
                                          if (
                                             selectedLocation === undefined ||
                                             selectedLocation === null
                                          ) {
                                             return Promise.reject(
                                                new Error(
                                                   "Please select valid address"
                                                )
                                             );
                                          }
                                          if (value) return Promise.resolve();
                                       }
                                    })
                                 ]}
                              >
                                 <AutoComplete
                                    open={locationDropDownOpen}
                                    notFoundContent={
                                       getPlaces ? <Spin /> : null
                                    }
                                    value={valueLocation}
                                    options={locations}
                                    onSelect={(value) => {
                                       cancelRequestLocation();
                                       setLocationDropDownOpen(false);
                                       const selected = locations.filter(
                                          (rec: any) => {
                                             return rec.value === value;
                                          }
                                       );

                                       const labelValue = selected.pop().label;
                                       setValueLocation(labelValue);
                                       const temp = value.split("_");
                                       setIsNewLocation(temp[0] === "new");
                                       if (temp[0] === "new") {
                                          setSelectedLocation(
                                             locationAPI[temp[1]]
                                          );
                                       } else {
                                          setSelectedLocation(temp[1]);
                                       }

                                       form.setFieldsValue({
                                          material_current_location: labelValue
                                       });
                                    }}
                                    onChange={(value) => {
                                       setValueLocation(value);
                                       console.log("value", value);
                                       setLocationDropDownOpen(true);
                                    }}
                                    onSearch={async (searchText: string) => {
                                       setSelectedLocation(null);
                                       cancelRequestLocation();
                                       setLocationDropDownOpen(true);
                                       if (searchText.length === 0) {
                                          setLocations(locationsOrg);
                                       } else if (searchText.length > 0) {
                                          let temp =
                                             locationsOrg &&
                                             locationsOrg.filter((rec: any) => {
                                                return (
                                                   rec?.value &&
                                                   rec?.label
                                                      .toLowerCase()
                                                      .includes(
                                                         searchText.toLowerCase()
                                                      )
                                                );
                                             });
                                          console.log(temp, locationsOrg);
                                          if (
                                             temp.length === 0 &&
                                             searchText.length > 10
                                          ) {
                                             await findPlace({
                                                data: {
                                                   address: `${searchText}`
                                                }
                                             });
                                          }

                                          if (
                                             temp.length === 0 &&
                                             dataPlaces?.data &&
                                             dataPlaces?.data?.locations &&
                                             dataPlaces?.data?.locations
                                                .length > 0
                                          ) {
                                             const tempLocations =
                                                dataPlaces?.data?.locations.map(
                                                   (rec: any, key: any) => {
                                                      if (rec && rec?.Label) {
                                                         return {
                                                            value: `new_${key}`,
                                                            label: rec?.Label
                                                         };
                                                      }
                                                   }
                                                );
                                             console.log(
                                                "tempLocations",
                                                tempLocations
                                             );
                                             setLocationAPI(
                                                dataPlaces?.data?.locations
                                             );
                                             temp = tempLocations;
                                          }
                                          setLocations(temp);
                                          setLocationDropDownOpen(true);
                                       }
                                    }}
                                    placeholder="Enter location"
                                    onFocus={() => {
                                       setLocationDropDownOpen(true);
                                    }}
                                 ></AutoComplete>
                              </Form.Item>
                           )}
                        </Panel>
                     </Collapse>
                  )}

                  {showFormFields && (
                     <Form.Item>
                        <div className="action-wrapper">
                           <Button
                              size="large"
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                           >
                              {t("productDetails.saveAndContinue")}
                           </Button>
                        </div>
                     </Form.Item>
                  )}
               </Form>
            </Col>
         </Row>
      </>
   );
};
