import React from "react";
import { AutoComplete, Input, Card } from "antd";
import { lcaApi, LCAEntryOUT } from "../../api-client";

export const IfcSearch = () => {
   const [options, setOptions] = React.useState<
      { value: string; label: React.ReactNode }[]
   >([]);
   const [selectedOption, setSelectedOption] = React.useState<LCAEntryOUT>();
   const [results, setResults] = React.useState<LCAEntryOUT[]>();
   const [currentValue, setCurrentValue] = React.useState<string>();
   return (
      <>
         <AutoComplete
            style={{ width: 350 }}
            options={options}
            value={selectedOption ? selectedOption.name_de : currentValue}
            onSelect={(data: string) => {
               setSelectedOption(results?.find((a) => a.id === data));
               setCurrentValue("");
            }}
            onSearch={(searchText: string) => {
               (async () => {
                  const response =
                     await lcaApi.djIfcBaseApiV1RoutersLcaSearchLcaData(
                        searchText,
                        5
                     );
                  setResults(response.data.items);
                  const newOptions = response.data.items?.map((option) => {
                     return {
                        value: option.id,
                        label: (
                           <span title={option.name_de}>{option.name_de}</span>
                        )
                     };
                  });
                  if (newOptions) setOptions(newOptions);
               })();
            }}
            onChange={(data: string) => {
               setCurrentValue(data);
            }}
         >
            {selectedOption ? (
               <Input.Search
                  value={selectedOption.name_de}
                  onClick={() => {
                     setCurrentValue("");
                     setSelectedOption(undefined);
                  }}
               />
            ) : (
               <Input.Search placeholder="Search by name or material" />
            )}
         </AutoComplete>
         {selectedOption && (
            <Card title="Selected" bordered={false}>
               <p>{selectedOption?.name_de}</p>
            </Card>
         )}
      </>
   );
};
