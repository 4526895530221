import {
   Button,
   message,
   Popconfirm,
   Image,
   Skeleton,
   PageHeader,
   Spin
} from "antd";
import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useBuilding } from "../../contexts/building/index";
import { DeleteOutlined } from "@ant-design/icons";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getImagePath } from "../../utils/getImagePath";
import { useState } from "react";
import { useEffect } from "react";
import { useGetBatchProductGroups } from "../../hooks/useGetBatchProductGroups";
import StackedBar from "../../components/LcaPotential/StackedBar";

export const BuildingOverview = () => {
   const { getBuildingDetail, refetchBuildings, canDelete, canEdit } =
      useBuilding();
   const { id }: any = useParams();
   const history = useHistory();
   const [t] = useTranslation();
   const [operationsList, setOperationsList] = useState<any>([]);
   const [subCategories, setSubCategories] = useState<any>([]);
   const [LcaCategorized, setLcaCategorized] = useState<any>([]);

   const [{ data: materialPassport }] = useAxios(
      {
         url: `${Resource.path.getMaterialPassportAuth}/${id}`,
         method: "GET"
      },
      { useCache: false }
   );

   const [
      { data: parentCategories, loading: getLoading, error: getError },
      executeGet
   ] = useAxios(
      {
         url: `${Resource.path.getCategoriesWithProductGroups}`,
         method: "GET"
      },
      { useCache: true }
   );

   const productGroups = useGetBatchProductGroups({
      materialPassports: materialPassport?.data
   });

   useEffect(() => {
      const categoryList: any = materialPassport?.data.map(
         (item: { category_id: any }) => item.category_id
      );
      const _ = Array.from(new Set(categoryList));
      const subCategories = _.map((catId) =>
         materialPassport?.data.filter(
            (item: { category_id: any }) => item.category_id == catId
         )
      );
      setSubCategories(subCategories);
   }, [materialPassport?.data]);

   useEffect(() => {
      parentCategories?.data &&
         productGroups.length &&
         !LcaCategorized.length &&
         getLCAGroup(subCategories, productGroups);
   }, [productGroups, subCategories, parentCategories]);

   const [, executeDelete] = useAxios(
      {
         url: `${Resource.path.removeBuilding}/${id}`,
         method: "delete",
         headers: {
            "X-Custom-Has-Operation": true
         }
      },
      { manual: true }
   );

   const [{ data: dataOperations, loading: loadingOperations }] = useAxios(
      `${Resource.path.operations}/${id}`
   );
   const buildingDetails = id && getBuildingDetail(id);

   useEffect(() => {
      if (dataOperations && dataOperations?.data?.result) {
         setOperationsList(dataOperations.data.result);
      }
   }, [dataOperations]);

   const handleDeleteBuilding = async () => {
      executeDelete().then(() => {
         refetchBuildings();
         history.push("/buildings");
         message.success(t("buildingList.deleteSuccessMessage"));
      });
   };

   if (!buildingDetails || Object.keys(buildingDetails).length === 0) {
      return (
         <div>
            <Skeleton />
         </div>
      );
   }

   const getBuildingImages = () => {
      return (
         buildingDetails &&
         buildingDetails.building_images.map((item: any, index: number) => {
            return <Image src={getImagePath(item)} key={index} />;
         })
      );
   };

   if (loadingOperations) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   function getLCAGroup(
      subCategories: any,
      productGroups: {
         data: any;
         amount: number;
         selectedMaterial?: string | undefined;
         passport?: any;
      }[]
   ) {
      subCategories.map(
         (category: { product_group_id: any; LCAInfo: any }[]) => {
            category.map(
               (item: { product_group_id: any; LCAInfo: any }) =>
                  (item["LCAInfo"] = productGroups.find(
                     (pg) => pg.data.id == item.product_group_id
                  ))
            );
         }
      );
      const lcaListPerGroup = subCategories.map(
         (
            row: {
               LCAInfo: any;
               category_name: string;
               category_id: string;
            }[]
         ) => ({
            parentInfo: {
               category_name: row[0].category_name,
               category_id: row[0].category_id,
               parent_id: null,
               parent_name: null
            },
            productGroups: row.map((item) =>
               item.LCAInfo ? item.LCAInfo : null
            )
         })
      );
      lcaListPerGroup
         .map((row: { productGroups: [] }) =>
            row.productGroups ? row.productGroups.filter((item) => item) : null
         )
         .filter((item: string | any[]) => item.length);
      const parentList = parentCategories?.data?.categories?.treeData;
      lcaListPerGroup.map(
         (subCat: {
            parentInfo: {
               category_id: any;
               parent_id: string;
               parent_name: string;
            };
         }) => {
            for (let i = 0; i < parentList.length; i++) {
               if (parentList[i].hasOwnProperty("children")) {
                  const child = parentList[i].children.find(
                     (item: { id: any }) =>
                        item.id == subCat.parentInfo.category_id
                  );
                  const categoryList = LcaCategorized;
                  if (child) {
                     !categoryList.find(
                        (item: { id: any }) => item.id == parentList[i].id
                     ) &&
                        categoryList.push({
                           name: parentList[i].name,
                           id: parentList[i].id,
                           children: []
                        });
                     subCat.parentInfo["parent_id"] = parentList[i].id;
                     subCat.parentInfo["parent_name"] = parentList[i].name;
                  }
               }
            }
         }
      );
      const categoryList = LcaCategorized;
      categoryList.map(
         (cat: { id: any; children: { parentInfo: { parent_id: any } }[] }) => {
            lcaListPerGroup.map((item: { parentInfo: { parent_id: any } }) => {
               if (item.parentInfo.parent_id == cat.id)
                  cat.children?.push(item);
            });
         }
      );
      categoryList.length && setLcaCategorized([...categoryList]);
   }

   return (
      <div className="content-wrap building-overview-wrapper">
         <PageHeader title="">
            <div className="page-title">
               <h1>{buildingDetails.name}</h1>
               <span>{buildingDetails.type} </span>
            </div>

            <div className="manage-menu">
               {canEdit && (
                  <Link to={`/buildings/edit/${id}`}>
                     <Button shape="round">{t("default.edit")}</Button>
                  </Link>
               )}
               {canDelete && (
                  <Popconfirm
                     title={t("buildingList.deleteBuildingPopupConfirmation")}
                     onConfirm={handleDeleteBuilding}
                     okText={t("buildingList.yes")}
                     cancelText={t("buildingList.no")}
                  >
                     <Button type="link" shape="round">
                        <DeleteOutlined />
                     </Button>
                  </Popconfirm>
               )}
            </div>
         </PageHeader>

         {buildingDetails?.building_images?.length && (
            <div
               className={
                  buildingDetails?.building_images?.length > 1
                     ? "image-scroll"
                     : "image-scroll single"
               }
            >
               {getBuildingImages()}
            </div>
         )}

         <div className="page-content">
            <div className="grid col-2">
               <div className="grid-item">
                  {!buildingDetails?.locations_id && (
                     <address>
                        {buildingDetails.street} {buildingDetails.house_number}{" "}
                        <br />
                        {buildingDetails.postal_code} {buildingDetails.city}{" "}
                        <br />
                        {buildingDetails.country}
                     </address>
                  )}
                  {buildingDetails?.locations_id && (
                     <address>{buildingDetails.location}</address>
                  )}
               </div>
               <div className="grid-item">
                  {buildingDetails.description && (
                     <div className="description">
                        {buildingDetails.description}
                     </div>
                  )}
               </div>
            </div>
         </div>
         <div className="separated"></div>
         <div className="page-content ">
            <div className="grid col-4">
               {buildingDetails.construction_year && (
                  <div className="grid-item widget number">
                     <span>
                        {t("buildingAddEditForm.item.yearOfConstruction")}
                     </span>
                     <strong>
                        {moment(buildingDetails.construction_year).format(
                           "YYYY"
                        )}
                     </strong>
                  </div>
               )}
               {buildingDetails.floor_area && (
                  <div className="grid-item widget number">
                     <span>{t("buildingDetails.form.floorArea")}</span>
                     <strong>
                        {buildingDetails.floor_area}
                        <small> {t("common.sqm")}</small>
                     </strong>
                  </div>
               )}
               {buildingDetails.total_area && (
                  <div className="grid-item widget number">
                     <span>{t("buildingDetails.form.totalArea")}</span>
                     <strong>
                        {buildingDetails.total_area}
                        <small> {t("common.sqm")}</small>
                     </strong>
                  </div>
               )}
               {buildingDetails.number_of_floor && (
                  <div className="grid-item widget number">
                     <span>{t("buildingDetails.form.numberOfFloors")}</span>
                     <strong>{buildingDetails.number_of_floor}</strong>
                  </div>
               )}
            </div>
         </div>
         <div>
            <Spin spinning={!LcaCategorized.length}>
               <StackedBar data={LcaCategorized} />
            </Spin>
         </div>
         <div className="page-content">
            {!!(operationsList.length > 0) && (
               <div className="activity-log">
                  <h3>{t("buildingDetails.form.ophistory")}</h3>
                  <div className="chain">
                     {operationsList.map((operations: any) => (
                        <div
                           className={`chain-item ${
                              operations.end_date_construction ||
                              operations.end_date_deconstruction
                                 ? ""
                                 : "upcoming"
                           }`}
                        >
                           {operations.end_date_construction && (
                              <strong>
                                 {t(
                                    "staticDropdown.upcomingActivities.construction"
                                 )}
                                 :&nbsp;
                                 {moment(
                                    operations.end_date_construction
                                 ).format("YYYY")}
                              </strong>
                           )}
                           {operations.end_date_deconstruction && (
                              <strong>
                                 {t(
                                    "staticDropdown.upcomingActivities.deconstruction"
                                 )}
                                 :&nbsp;
                                 {moment(
                                    operations.end_date_deconstruction
                                 ).format("YYYY")}
                              </strong>
                           )}
                           <span>
                              {t(
                                 `staticDropdown.upcomingActivities.${operations.operation_type}`
                              )}
                           </span>
                        </div>
                     ))}
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};
