import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Form, message, Col, Row, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useLocale } from "../../contexts/language/index";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import SettingsSideBar from "./SettingsSideBar";
import styles from "./Settings.module.scss";
import InputField from "../../components/FormFields/InputField";
import ButtonField from "../../components/FormFields/ButtonField";
import DropdownField from "../../components/FormFields/DropdownField";
import { Link } from "react-router-dom";

const formItemLayout = {
   labelCol: { span: 8 },
   wrapperCol: { span: 8 }
};

const tailLayout = {
   wrapperCol: { offset: 8, span: 16 }
};

const languages = [
   { id: 1, label: "🇬🇧 English", value: "en" },
   { id: 2, label: "🇩🇪 German", value: "de" }
];

export const UserSettings = () => {
   const [user, updateUser] = useState<any>();
   const [t] = useTranslation();
   const [categoryItems, setCategoryItems] = useState([]);
   const [form] = useForm();
   const { locale, changeLanguage } = useLocale();
   const [loading, setLoading] = useState(false);
   const [selectedLang, setLanguage] = useState(locale);

   const [{ data, error }, refetch] = useAxios(Resource.path.getCategoriesTree);
   const buildOptionsElm = (results: any) => {
      return results.map((result: any) => {
         return { id: result.id, name: result.name };
      });
   };

   useEffect(() => {
      if (data?.data.data && categoryItems?.length === 0) {
         const categoryItems = buildOptionsElm(data?.data.data);
         setCategoryItems(categoryItems);
      }
   }, [data?.data.data]);

   const handleChange = (lang: string) => {
      const value = languages.find((item) => item.id == +lang)?.value;
      setLanguage(value);
      changeLanguage(value);
   };

   useEffect(() => {
      (async () => {
         if (!user) {
            const userInfo = await Auth.currentUserInfo();
            if (userInfo) {
               updateUser(userInfo.attributes);
               form.setFieldsValue({
                  email: userInfo?.attributes?.email,
                  family_name: userInfo?.attributes?.family_name,
                  given_name: userInfo?.attributes?.given_name,
                  locale: languages.find((item) => item.value == selectedLang)
                     ?.id,
                  category_tree_type:
                     userInfo?.attributes["custom:category_tree_types"]
               });
            }
         }
      })();
   }, [user]);

   const handleOnSubmit = async (value: any) => {
      setLoading(true);
      try {
         const currentUser = await Auth.currentAuthenticatedUser();
         await Auth.updateUserAttributes(currentUser, {
            family_name: String(value?.family_name),
            given_name: String(value?.given_name),
            locale: String(
               languages
                  .filter((rec: any) => {
                     return rec.id === value?.locale;
                  })
                  .pop()?.value
            ),
            "custom:category_tree_types": String(value.category_tree_type)
         })
            .then(() => {
               message.success(
                  t("settings.profile.form.saveSuccessfullyMessage")
               );
               setLoading(false);
            })
            .catch((err) => {
               throw new Error(err.message);
            });
      } catch (error) {
         message.error(t("settings.profile.form.errorMessage"));
         setLoading(false);
      }
   };

   return (
      <SettingsSideBar title={t("settings.profile.userSettings")}>
         <Row>
            <Col xl={{ span: 16 }} xs={{ span: 24 }}>
               <div className={styles.userSettings}>
                  <Form
                     form={form}
                     name="profile_form"
                     onFinish={handleOnSubmit}
                  >
                     <div className={styles.section}>
                        <h3 className={styles.fieldsTitle}>
                           {t("settings.profile.profile")}
                        </h3>
                        <Row className={styles.row}>
                           <Col span={12} className={styles.label}>
                              {t("settings.profile.form.firstName")}
                           </Col>
                           <Col span={12} className={styles.field}>
                              <InputField
                                 fieldName="given_name"
                                 noMargin
                                 required
                                 fieldErrorMessage={t(
                                    "settings.profile.form.firstNameRequiredMessage"
                                 )}
                              />
                           </Col>
                        </Row>
                        <Row className={styles.row}>
                           <Col span={12} className={styles.label}>
                              {t("settings.profile.form.lastName")}
                           </Col>
                           <Col span={12} className={styles.field}>
                              <InputField
                                 fieldName="family_name"
                                 noMargin
                                 required
                                 fieldErrorMessage={t(
                                    "settings.profile.form.lastNameRequiredMessage"
                                 )}
                              />
                           </Col>
                        </Row>
                     </div>
                     <div className={styles.section}>
                        <h3 className={styles.fieldsTitle}>
                           {t("settings.profile.account")}
                        </h3>
                        <Row className={styles.row}>
                           <Col span={12} className={styles.label}>
                              {t("settings.profile.form.mailAddress")}
                           </Col>
                           <Col span={12} className={styles.field}>
                              <InputField
                                 fieldName="email"
                                 noMargin
                                 required
                                 disabled
                              />
                           </Col>
                        </Row>
                        <Row className={styles.row}>
                           <Col span={12} className={styles.label}>
                              {t("settings.profile.form.password")}
                           </Col>
                           <Col span={12} className={styles.field}>
                              <div className="d-flex-between">
                                 <Input.Password
                                    visibilityToggle={false}
                                    className={styles.password}
                                    value="111111111"
                                 />
                                 <Link
                                    to={"/settings/password"}
                                    className={styles.changePassword}
                                 >
                                    {t("settings.profile.changePassword")}
                                 </Link>
                              </div>
                           </Col>
                        </Row>
                     </div>
                     <div className={styles.section}>
                        <h3 className={styles.fieldsTitle}>
                           {t("settings.profile.interface")}
                        </h3>
                        <Row className={styles.row}>
                           <Col span={12} className={styles.label}>
                              {t("settings.profile.form.language")}
                           </Col>
                           <Col span={12} className={styles.field}>
                              <DropdownField
                                 items={languages}
                                 noMargin
                                 fieldName="locale"
                                 onChange={handleChange}
                              />
                           </Col>
                        </Row>
                        <Row className={styles.row}>
                           <Col span={12} className={styles.label}>
                              {t("settings.profile.form.categorization")}
                           </Col>
                           <Col span={12} className={styles.field}>
                              <DropdownField
                                 items={categoryItems}
                                 fieldName="category_tree_type"
                                 noMargin
                                 required
                              />
                           </Col>
                        </Row>
                     </div>
                     <Row>
                        <Col span={24} className={styles.submitForm}>
                           <ButtonField
                              type="default"
                              size="large"
                              htmlType="submit"
                              loading={loading}
                           >
                              {t("default.submit")}
                           </ButtonField>
                        </Col>
                     </Row>
                  </Form>
               </div>
            </Col>
         </Row>
      </SettingsSideBar>
   );
};
