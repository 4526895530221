import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

export const PassportDetailsEdit = () => {
   const {
      pathname,
      state: { passportCluster, material }
   }: any = useLocation();

   const [gridApi, setGridApi] = useState<any>(null);
   const [gridColumnApi, setGridColumnApi] = useState(null);

   const onGridReady = (params: any) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
   };

   const onCellValueChanged = (event: any) => {
      console.log(
         "onCellValueChanged: " + event.colDef.field + " = " + event.newValue
      );
   };

   const onRowValueChanged = (event: any) => {
      var data = event.data;
      console.log(
         "onRowValueChanged: (" +
            data.condition +
            ", " +
            data.deconstructability +
            ", " +
            ")"
      );
   };

   const onBtStopEditing = () => {
      gridApi.stopEditing();
   };

   const onBtStartEditing = () => {
      gridApi.setFocusedCell(2, "make");
      gridApi.startEditingCell({
         rowIndex: 2,
         colKey: "make"
      });
   };

   console.log({ passportCluster });

   const rowData = passportCluster.passports;

   const [t] = useTranslation();

   return (
      <div className="content-wrap material-overview-wrapper">
         <PageHeader
            title={t("common.back")}
            onBack={() => window.history.back()}
         >
            <div className="page-title">
               <h1>Passport Edits</h1>
            </div>
         </PageHeader>

         <div
            className="ag-theme-alpine"
            style={{ height: 600, paddingLeft: "20px", paddingRight: "20px" }}
         >
            <AgGridReact
               defaultColDef={{
                  flex: 1,
                  editable: true,
                  singleClickEdit: true
               }}
               rowData={rowData}
               editType={"fullRow"}
               onGridReady={onGridReady}
               onCellValueChanged={onCellValueChanged}
               onRowValueChanged={onRowValueChanged}
            >
               <AgGridColumn
                  field="passport_identifier"
                  filter={true}
                  sortable={true}
                  minWidth={100}
                  headerName="Passport Identifier"
                  editable={false}
               ></AgGridColumn>
               <AgGridColumn
                  field="condition"
                  sortable={true}
                  filter={true}
                  cellEditor="agSelectCellEditor"
                  headerName="Condition"
                  cellEditorParams={{
                     values: [
                        t("staticDropdown.condition.originalPacked"),
                        t("staticDropdown.condition.new"),
                        t("staticDropdown.condition.likeNew"),
                        t("staticDropdown.condition.refurbished"),
                        t("staticDropdown.condition.signOfUsages"),
                        t("staticDropdown.condition.signOfAge"),
                        t("staticDropdown.condition.heavilyUsed"),
                        t("staticDropdown.condition.damaged"),
                        t("staticDropdown.condition.broken")
                     ]
                  }}
               ></AgGridColumn>
               <AgGridColumn
                  field="deconstructability"
                  headerName="Deconstructability"
                  sortable={true}
                  cellEditor="agSelectCellEditor"
                  filter={true}
                  cellEditorParams={{
                     values: [
                        t("staticDropdown.deconstructability.easyLoose"),
                        t("staticDropdown.deconstructability.easyPlugged"),
                        t("staticDropdown.deconstructability.easyScrewed"),
                        t("staticDropdown.deconstructability.easyBuild"),
                        t("staticDropdown.deconstructability.moderateBuild"),
                        t("staticDropdown.deconstructability.elaborateBuild"),
                        t(
                           "staticDropdown.deconstructability.hardSpecialSkillsNeeded"
                        ),
                        t(
                           "staticDropdown.deconstructability.notEconomicallyFeasible"
                        ),
                        t("staticDropdown.deconstructability.notPossible")
                     ]
                  }}
               ></AgGridColumn>
               <AgGridColumn
                  field="custom_pollutance"
                  headerName="Custom Pollutance"
                  sortable={true}
                  cellEditor="agSelectCellEditor"
                  filter={true}
                  cellEditorParams={{
                     values: [
                        t("staticDropdown.customPollutance.unknown"),
                        t("staticDropdown.customPollutance.toBeProved"),
                        t(
                           "staticDropdown.customPollutance.potentiallyHarmless"
                        ),
                        t("staticDropdown.customPollutance.potentiallyHarmful"),
                        t("staticDropdown.customPollutance.harmlessChecked"),
                        t("staticDropdown.customPollutance.harmfulChecked")
                     ]
                  }}
               ></AgGridColumn>

               <AgGridColumn
                  field="amount"
                  sortable={true}
                  filter={true}
                  headerName="Amount"
                  editable={false}
               ></AgGridColumn>
               <AgGridColumn
                  field="amount_unit"
                  headerName="Amount Unit"
                  sortable={true}
                  filter={true}
                  editable={false}
               ></AgGridColumn>
            </AgGridReact>
         </div>
      </div>
   );
};
