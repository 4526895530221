import React from "react";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { message } from "antd";
import { Auth } from "aws-amplify";
import { RouteComponentProps, useHistory } from "react-router";
import { Form, Input, Button } from "antd";
import axios from "axios";
import { WelcomePage } from "../../components/WelcomePage";

const layout = {
   labelCol: { span: 8 },
   wrapperCol: { span: 16 }
};
const tailLayout = {
   wrapperCol: { offset: 8, span: 16 }
};

const passwordRegex = new RegExp(
   "^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})"
);

type Props = { invitation_id: string };

const AcceptInvitations: React.FC<any> = ({
   match
}: RouteComponentProps<Props>) => {
   const [t] = useTranslation();
   const invitationId = match.params.invitation_id;
   const [inviteDetails, setInviteDetails] = React.useState<any>(null);
   const history = useHistory();

   const [{ data, loading, error }] = useAxios(
      {
         url: `${Resource.path.inviteDetails}/${invitationId}`,
         method: "get"
      },
      { manual: false }
   );

   React.useEffect(() => {
      // console.log(data?.data);
      data?.data && setInviteDetails(data?.data);

      if (data?.data.status === "CONFIRMED") {
         history.push("/buildings");
      }
   }, [data]);

   React.useEffect(() => {
      if (
         inviteDetails?.email &&
         inviteDetails?.temp_password &&
         inviteDetails?.user?.user_status === "FORCE_CHANGE_PASSWORD"
      ) {
         const { email, temp_password } = inviteDetails;
         Auth.signIn(email, temp_password).then((user) => {
            //   console.log({ user });

            if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
            } else {
               history.push("/buildings");
            }
         });
      } else if (inviteDetails?.status === "CONFIRMED") {
         history.push("/buildings");
      }
   }, [
      inviteDetails?.email,
      inviteDetails?.temp_password,
      inviteDetails?.status
   ]);

   React.useEffect(() => {
      if (error && !loading) {
         message.error("Invalid Invitation Id");
      }
   }, [error, loading]);

   if (loading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   //  console.log({ invitationId });

   const onFinish = (values: any) => {
      const { email, temp_password } = inviteDetails;

      // console.log(values);
      Auth.signIn(email, temp_password).then((user) => {
         //    console.log({ user });

         if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, values.password, {
               family_name: values?.family_name,
               given_name: values?.given_name
            })
               .then(async (user) => {
                  const jwtToken = (await Auth.currentSession())
                     .getIdToken()
                     .getJwtToken();
                  axios({
                     method: "get",
                     url: `${Resource.path.confirmInvitation(invitationId)}`,
                     headers: { Authorization: jwtToken }
                  })
                     .then(async (response) => {
                        history.push("/buildings");
                        message.success(
                           t("inviteMembers.message.confirmationSuccess")
                        );
                     })
                     .catch((error) => {
                        console.log(error);
                        message.error(
                           t("inviteMembers.message.confirmationFailed")
                        );
                     });
               })
               .catch((e) => {
                  message.error("Some error occurred Please try again!");
               });
         } else {
            history.push("/buildings");
         }
      });
   };

   const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
   };

   return (
      <WelcomePage>
         <div className="welcome-box">
            <div className="welcome-box-inner">
               <div
                  style={{
                     color: "black",
                     fontSize: "18px",
                     backgroundColor: "white",
                     marginBottom: "20px"
                  }}
               >
                  {inviteDetails?.invited_by_email}{" "}
                  {t("loginPage.inviteMessage")}
               </div>
               <Form
                  {...layout}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  size="large"
               >
                  <Form.Item
                     name="given_name"
                     rules={[
                        {
                           required: true,
                           message: "Please input your given name!"
                        }
                     ]}
                  >
                     <Input placeholder={t("loginPage.firstname")} />
                  </Form.Item>

                  <Form.Item
                     name="family_name"
                     rules={[
                        {
                           required: true,
                           message: "Please input your family name!"
                        }
                     ]}
                  >
                     <Input placeholder={t("loginPage.lastname")} />
                  </Form.Item>

                  <Form.Item
                     name="password"
                     hasFeedback
                     rules={[
                        {
                           required: true,
                           message: t(
                              "settings.account.form.newPasswordRequired"
                           )
                        },
                        ({ getFieldValue }) => ({
                           validator(_rule, value) {
                              if (!value) {
                                 return Promise.resolve();
                              }

                              if (value.length < 10) {
                                 return Promise.reject(
                                    t(
                                       "settings.account.form.passwordLengthMessage"
                                    )
                                 );
                              }

                              if (!passwordRegex.test(value)) {
                                 return Promise.reject(
                                    t(
                                       "settings.account.form.passwordLengthContainsMessage"
                                    )
                                 );
                              }

                              return Promise.resolve();
                           }
                        })
                     ]}
                  >
                     <Input.Password placeholder={t("loginPage.password")} />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                     <Button type="primary" htmlType="submit">
                        {t("loginPage.continue")}
                     </Button>
                  </Form.Item>
               </Form>
            </div>
         </div>
      </WelcomePage>
   );
};

export default AcceptInvitations;
