import {
   IFCFileApi,
   IFCObjectApi,
   ProductsApi,
   SetupApi,
   LCAApi
} from "./generated/index";

export * from "./generated/index";

const BASE_PATH = "https://dev.ifc.concular.com";

export const ifcApi = new IFCFileApi({
   basePath: BASE_PATH,
   isJsonMime: () => true
});

export const ifcObjectApi = new IFCObjectApi({
   basePath: BASE_PATH,
   isJsonMime: () => true
});

export const productGroupApi = new ProductsApi({
   basePath: BASE_PATH,
   isJsonMime: () => true
});

export const setupApi = new SetupApi({
   basePath: BASE_PATH,
   isJsonMime: () => true
});

export const lcaApi = new LCAApi({
   basePath: BASE_PATH,
   isJsonMime: () => true
});
