import React, { useEffect, useState } from "react";

import { Button, PageHeader, Tree, Radio, Spin } from "antd";
import { useLocation, Link } from "react-router-dom";
import "./style.scss";
import useAxios from "axios-hooks";
import { Resource } from "../../../../api/common";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { MaterialItemList } from "./MaterialItemList";
import { MaterialListGrid } from "./MaterialListGrid";
import { TableOutlined, GroupOutlined } from "@ant-design/icons";
//import { useFlagsmith } from "flagsmith-react";
import { Auth } from "aws-amplify";

interface DataNode {
   title: string;
   key: string;
   isLeaf?: boolean;
   children?: DataNode[];
}

export enum FilterType {
   "ProductGroup",
   "Category"
}

const CategoriesTree = ({
   onProductGroupSelected: onFilterSelected,
   pgList,
   setTreeRawData
}: any) => {
   const [categories, setCategoriesTreeData] = useState([]);
   const [t] = useTranslation();
   const [expKeys, setExpKeys] = useState<any>([]);
   const [productGroupsHavingItems, setProductGroupsHavingItems] =
      useState<any>([]);
   const [showLoader, setShowLoader] = useState<boolean>(true);
   const [lastSelectedCatId, setLastSelectedCatId] = useState("");
   const [catWithPg, setCatWithPg] = useState<any>();

   useEffect(() => {
      Auth.currentUserInfo().then((currentUser) => {
         const { attributes } = currentUser;
         executeGet({
            url: `${Resource.path.getCategoriesByTreeId}/${
               attributes["custom:category_tree_types"] ||
               "28680da1-41a1-494e-8cd8-ea08b267e463"
            }`
         });
      });
   }, []);

   const [{ data, loading: loadingCategories, error: getError }, executeGet] =
      useAxios(
         {
            method: "GET"
         },
         { manual: true, useCache: false }
      );

   function updateTreeData(
      list: DataNode[],
      key: React.Key,
      children: DataNode[]
   ): DataNode[] {
      return list.map((node) => {
         if (node.key === key) {
            return {
               ...node,
               isLeaf: false,
               children
            };
         } else if (node.children) {
            return {
               ...node,
               isLeaf: false,
               children: updateTreeData(node.children, key, children)
            };
         }
         return node;
      });
   }

   useEffect(() => {
      if (data?.data?.treeData && data?.data?.data && pgList.length > 0) {
         setTreeRawData(data?.data?.data);
         setShowLoader(false);
         const temp: any = [];
         const iterateData = (rec: any) => {
            //console.log(rec)
            rec.map((records: any) => {
               if (temp[records.id] === undefined) {
                  temp[records.id] = [];
               }
               if (
                  records?.parent_id &&
                  temp[records?.parent_id] === undefined
               ) {
                  temp[records?.parent_id] = [];
               }
               if (records?.product_groups) {
                  records?.product_groups.map((pg: any) => {
                     temp[records.id].push(pg.value);
                     if (records?.parent_id) {
                        temp[records.parent_id].push(pg.value);
                     }
                  });
               }
               if (records?.children) {
                  iterateData(records.children);
               }
            });
         };
         iterateData(data.data.data);
         setCatWithPg(temp);
         const treeFiltered = data.data.treeData.filter((rec: any) => {
            let hasData = false;
            Object.values(temp[rec.id]).map((product_group_id) => {
               if (pgList.indexOf(product_group_id) !== -1) {
                  hasData = true;
               }
            });
            return hasData;
         });
         setCategoriesTreeData(treeFiltered);

         if (pgList) {
            setProductGroupsHavingItems(pgList);
         }
         setLastSelectedCatId("");
      }

      if (pgList.length === 0) {
         setCategoriesTreeData([]);
         setProductGroupsHavingItems([]);
         setLastSelectedCatId("");
      }
   }, [data, pgList]);

   const handleOnSelect = (selectedItem: any, info: any) => {
      const {
         node: { isProductGroup, key }
      } = info;

      if (isProductGroup) {
         onFilterSelected({
            key: key,
            type: FilterType.ProductGroup,
            catId: lastSelectedCatId
         });
      } else {
         const [selectedCategoryId] = selectedItem;
         setLastSelectedCatId(selectedCategoryId);
         onFilterSelected({
            key: selectedCategoryId,
            type: FilterType.Category,
            catId: selectedCategoryId
         });
      }
   };

   if (loadingCategories) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   if (loadingCategories)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   const onLoadData = async (treeSelectedInfo: any): Promise<any> => {
      return new Promise((resolve, reject) => {
         if (treeSelectedInfo) {
            const { key, children, pos } = treeSelectedInfo;

            if (children) {
               const updatedChildren = children.filter((item: any) => {
                  let pgHasData = false;
                  if (catWithPg[item.id]) {
                     Object.values(catWithPg[item.id]).map((rec: any) => {
                        if (pgList.indexOf(rec) !== -1) {
                           pgHasData = true;
                        }
                     });
                  }
                  if (item.isLeaf && item.product_groups) {
                     const temp = item.product_groups.filter(
                        (product_group: any) => {
                           const { key } = product_group;
                           return (
                              productGroupsHavingItems.indexOf(key as never) !==
                              -1
                           );
                        }
                     );
                     const productGroupsNodes = temp.map(
                        (item: any, index: number) => {
                           return {
                              title: item.label,
                              key: item.key + "#" + index + "#" + pos,
                              isLeaf: true,
                              isProductGroup: true
                           };
                        }
                     );
                     return {
                        ...item,
                        children: [...productGroupsNodes],
                        isLeaf: false
                     };
                  } else if (pgHasData) {
                     return item;
                  }
               });

               const updatedTree: any = updateTreeData(
                  categories,
                  key,
                  updatedChildren
               );
               setCategoriesTreeData(updatedTree);

               resolve(true);
               return;
            } else if (
               !treeSelectedInfo.isLeaf &&
               treeSelectedInfo.product_groups
            ) {
               const temp = treeSelectedInfo.product_groups.filter(
                  (product_group: any) => {
                     const { key } = product_group;
                     return (
                        productGroupsHavingItems.indexOf(key as never) !== -1
                     );
                  }
               );
               const updatedChildren = temp.map((item: any, index: number) => {
                  return {
                     title: item.label,
                     key: item.key + "#" + index + "#" + pos,
                     isLeaf: true,
                     isProductGroup: true
                  };
               });

               const updatedTree: any = updateTreeData(
                  categories,
                  key,
                  updatedChildren
               );
               setCategoriesTreeData(updatedTree);

               resolve(true);
               return;
            }
         }
      });
   };

   const handleOnExpand = (expandedKeys: any, info: any) => {
      if (info && info.node && info.node.id) {
         setLastSelectedCatId(info.node.id);
      }
      setTimeout(() => {
         setExpKeys([]);
         const { node, expanded } = info;
         const { pos } = node;
         const temp: any = [];
         const path = pos.split("-");
         path.splice(0, 1);
         path.reverse();
         let indexFind = path.pop();
         const walk = (treeNode: any, index: any) => {
            if (treeNode[index]) {
               temp.push(treeNode[index].id);
               if (treeNode[index].children) {
                  const nextNode = treeNode[index].children;
                  if (path.length > 0) {
                     const nextIndex = path.pop();
                     walk(nextNode, nextIndex);
                  }
               }
            }
         };
         walk(categories, indexFind);
         if (expanded) {
            setExpKeys(temp);
         } else {
            setExpKeys(expandedKeys);
         }
      });
   };

   return (
      <Tree.DirectoryTree
         showIcon={false}
         treeData={categories}
         loadData={onLoadData}
         switcherIcon={<DownOutlined />}
         onSelect={handleOnSelect}
         onExpand={handleOnExpand}
         expandedKeys={expKeys}
      />
   );
};

export const MaterialInventoryDashboard = () => {
   const location = useLocation();
   const [initialMaterialList, setInitialMaterialList] = useState([]);
   const [materials, setMaterials] = useState([]);
   const [allMaterials, setAllMaterials] = useState(true);
   const [t] = useTranslation();
   const [treeRawData, setTreeRawData] = useState<any>();
   const [rawCatData, setRawCatData] = useState<any>();
   const [pgList, setPgList] = useState([]);
   const [view, setViews] = useState("list");

   //const { isLoading: isLoadingFlag, hasFeature } = useFlagsmith();
   // const hasMaterialGridEnabled = hasFeature("material_grid_views");
   const hasMaterialGridEnabled = true;

   //console.log({ hasMaterialGridEnabled });

   const [{ data, loading: getLoading, error: getError }, refetch] = useAxios(
      {
         url: `${Resource.path.getHubMaterialPassportAuth}`,
         method: "GET"
      },
      { useCache: false }
   );

   useEffect(() => {
      if (data?.data) {
         if (data?.data && data?.data.length > 0) {
            const temp: any = [];
            const tempPg: any = [];
            data?.data.forEach((material: any) => {
               const { product_group_id } = material;
               tempPg.push(product_group_id);
            });
            //console.log(tempPg)
            setPgList(tempPg);
         } else {
            setPgList([]);
         }
         setMaterials(data?.data);
         setInitialMaterialList(data?.data);
      } else {
         setPgList([]);
      }
      if (treeRawData) {
         const temp: any = [];
         const iterateData = (rec: any) => {
            //console.log(rec)
            rec.map((records: any) => {
               if (temp[records.id] === undefined) {
                  temp[records.id] = [];
               }
               if (
                  records?.parent_id &&
                  temp[records?.parent_id] === undefined
               ) {
                  temp[records?.parent_id] = [];
               }
               if (records?.product_groups) {
                  records?.product_groups.map((pg: any) => {
                     temp[records.id].push(pg.value);
                     if (records?.parent_id) {
                        temp[records.parent_id].push(pg.value);
                     }
                  });
               }
               if (records?.children) {
                  iterateData(records.children);
               }
            });
         };
         iterateData(treeRawData);
         //console.log(temp);
         setRawCatData(temp);
      }
   }, [data, treeRawData]);

   if (getLoading)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   if (getLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   const onFilterSelected = ({
      key,
      type,
      catId
   }: {
      key: string;
      type: FilterType;
      catId: string;
   }) => {
      const filteredMaterial = initialMaterialList.filter((material: any) => {
         if (type === FilterType.Category && rawCatData[key]) {
            return (
               Object.values(rawCatData[key]).indexOf(
                  material?.product_group?.key
               ) !== -1
            );
         } else {
            const [productGroupKey] = key.split("#");

            return material?.product_group?.key === productGroupKey;
         }
      });
      setMaterials([...filteredMaterial]);
      setAllMaterials(false);
   };

   const onAllSelected = (value: any) => {
      setAllMaterials(true);
      setMaterials([...initialMaterialList]);
   };

   const handleMaterialView = (type: string) => {
      setViews(type);
   };

   return (
      <>
         <div className="page-wrap material-inventory-overview-wrapper">
            <PageHeader title="">
               <div className="page-title">
                  <h1>{t("materialDashboard.inventories")}</h1>
               </div>
               <div className="manage-menu">
                  <Link
                     to={`${location.pathname}/add/product_details?state=new`}
                  >
                     <Button type="primary" shape="round">
                        {t("materialDashboard.addMaterial")}
                     </Button>
                  </Link>
               </div>
            </PageHeader>

            {!!hasMaterialGridEnabled && (
               <div style={{ textAlign: "right", paddingRight: "30px" }}>
                  <Button onClick={() => handleMaterialView("list")}>
                     <GroupOutlined />
                  </Button>
                  <Button onClick={() => handleMaterialView("grid")}>
                     <TableOutlined />
                  </Button>
               </div>
            )}

            <div className="inventory-content">
               {view === "list" ? (
                  <>
                     <div className="category-sidebar">
                        <span className="category-sidebar-title">
                           {t("materialDashboard.categories")}
                        </span>
                        <div className="category-list">
                           <div className="reset">
                              <Radio
                                 checked={allMaterials}
                                 onChange={onAllSelected}
                              >
                                 {t("materialDashboard.allMaterials")}
                              </Radio>
                           </div>
                           <CategoriesTree
                              onProductGroupSelected={onFilterSelected}
                              setTreeRawData={setTreeRawData}
                              pgList={pgList}
                           ></CategoriesTree>
                        </div>
                     </div>
                     <div className="material-list">
                        <MaterialItemList
                           materials={materials}
                           refetch={refetch}
                        ></MaterialItemList>
                     </div>
                  </>
               ) : (
                  <div className="material-grids">
                     <MaterialListGrid materials={materials}></MaterialListGrid>
                  </div>
               )}
            </div>
         </div>
      </>
   );
};
