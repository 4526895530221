import { Auth } from "aws-amplify";
import React from "react";
import { useCookies } from "react-cookie";

const ModuleAuthorizationMap = {
   catalogue: "marketplace",
   buildings: "material-passport",
   hub: "material-hub"
} as any;

interface IModulesAcl {
   passport_access: number;
   material_hub_access: number;
   service_access: number;
}

const isUserHasPermissionToView = (
   views: string,
   userScopes: string,
   modules: IModulesAcl,
   isPrivateOwner: boolean
) => {
   const viewScopes = ModuleAuthorizationMap[views];

   if (viewScopes === ModuleAuthorizationMap.buildings) {
      return true;
   } else if (viewScopes === ModuleAuthorizationMap.hub && isPrivateOwner) {
      return true;
   } else if (viewScopes === ModuleAuthorizationMap.catalogue) {
      return true;
   } else {
      return false;
   }

   // ToDo: Enabled after adding materialHub as feature
   /*    if (viewScopes === ModuleAuthorizationMap.buildings) {
      return modules?.passport_access;
   } else if (viewScopes === ModuleAuthorizationMap.hub) {
      return modules?.material_hub_access;
   } else if (viewScopes === ModuleAuthorizationMap.catalogue) {
      return modules?.service_access;
   } else {
      return false;
   } */

   //return !!userScopes && userScopes.indexOf(viewScopes) !== -1;
};

export function usePermissionStatus() {
   const [user, setUser] = React.useState<any>(undefined);
   const [cookies, setCookie, removeCookie] = useCookies(["user", "modules"]);
   const [hasPermission, setPermissionFlag] = React.useState<any>(undefined);
   const [currentMainRoutePath] = window.location?.pathname
      ?.split("/")
      .filter(Boolean);

   React.useEffect(() => {
      if (cookies.user) {
         setUser(cookies.user);
         const userScopes =
            cookies.user &&
            cookies.user["attributes"] &&
            cookies.user["attributes"]["custom:scopes"];
         const isPrivateOwner =
            cookies.user &&
            cookies.user["attributes"] &&
            cookies.user["attributes"]["custom:user_type"] === "private-owner";

         const hasPermission = isUserHasPermissionToView(
            currentMainRoutePath,
            userScopes,
            cookies.modules,
            isPrivateOwner
         );
         setPermissionFlag(hasPermission);
      }
   }, [cookies, hasPermission]);

   React.useEffect(() => {
      if (!user) {
         Auth.currentUserInfo()
            .then((user) => {
               setUser(user);
               setCookie("user", user, { secure: true, maxAge: 14400 });
               const userScopes =
                  user &&
                  user["attributes"] &&
                  user["attributes"]["custom:scopes"];
               const isPrivateOwner =
                  user &&
                  user["attributes"] &&
                  user["attributes"]["custom:user_type"] === "private-owner";

               const hasPermission = isUserHasPermissionToView(
                  currentMainRoutePath,
                  userScopes,
                  cookies.modules,
                  isPrivateOwner
               );
               setPermissionFlag(hasPermission);
            })
            .catch((error) => {
               console.log(error);
               if (cookies.user) {
                  removeCookie("user");
               }
               if (cookies.modules) {
                  removeCookie("modules");
               }
            });
      }
   }, [user, hasPermission]);

   return hasPermission;
}

export function usePermissionOfView(currentView: string) {
   const [user, setUser] = React.useState<any>(undefined);
   const [cookies, setCookie, removeCookie] = useCookies(["user", "modules"]);
   const [hasViewPermission, setPermissionFlag] =
      React.useState<any>(undefined);

   React.useEffect(() => {
      if (cookies.user) {
         setUser(cookies.user);
         const userScopes =
            cookies.user &&
            cookies.user["attributes"] &&
            cookies.user["attributes"]["custom:scopes"];
         const isPrivateOwner =
            cookies.user &&
            cookies.user["attributes"] &&
            cookies.user["attributes"]["custom:user_type"] === "private-owner";

         const hasPermission = isUserHasPermissionToView(
            currentView,
            userScopes,
            cookies.modules,
            isPrivateOwner
         );
         setPermissionFlag(hasPermission);
      }
   }, [cookies]);

   React.useEffect(() => {
      if (!user) {
         Auth.currentUserInfo()
            .then((user) => {
               setUser(user);
               setCookie("user", user, { secure: true, maxAge: 14400 });
               const userScopes =
                  user &&
                  user["attributes"] &&
                  user["attributes"]["custom:scopes"];
               const isPrivateOwner =
                  user &&
                  user["attributes"] &&
                  user["attributes"]["custom:user_type"] === "private-owner";

               const hasPermission = isUserHasPermissionToView(
                  currentView,
                  userScopes,
                  cookies.modules,
                  isPrivateOwner
               );
               setPermissionFlag(hasPermission);
            })
            .catch((err) => {
               console.log(err);
               if (cookies.user) {
                  removeCookie("user");
               }
               if (cookies.modules) {
                  removeCookie("modules");
               }
            });
      }
   }, [user, hasViewPermission]);

   return hasViewPermission;
}
