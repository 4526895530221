import { PageHeader } from "antd";
import React, { useState, useEffect } from "react";
import "../AddMaterialWizard/style.scss";
import { useMaterialWizard } from "../../../../contexts/materialWizard/index";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ProductDetails } from "../AddMaterialWizard/ProductDetails/index";
import { Resource } from "../../../../api/common";
import useAxios from "axios-hooks";
import moment from "moment";

const getPreviewCompatibleDocuments = (documents: any) => {
   if (documents && documents.length) {
      const documentsList = documents.map((document: any, index: number) => {
         if (document?.documents_files) {
            return {
               ...document,
               documents_files: {
                  ...document.documents_files,
                  id: index,
                  url: document.documents_files.object_url
               }
            };
         } else {
            return document;
         }
      });

      return documentsList;
   } else {
      return [];
   }
};

const getFormaCompatibleSpecification = (specification: any) => {
   if (specification && Object.keys(specification)) {
      const normalizedSpecification: any = {};
      Object.keys(specification).forEach((key) => {
         const value = specification[key];
         if (
            typeof value === "string" &&
            value.length > 9 &&
            moment(
               moment(value).format("YYYY-MM-DD"),
               "YYYY-MM-DD",
               true
            ).isValid()
         ) {
            normalizedSpecification[key] = moment(specification[key]);
         } else {
            normalizedSpecification[key] = specification[key];
         }
      });

      return normalizedSpecification;
   }

   return undefined;
};

export const EditMaterialWizard = () => {
   const { search }: any = useLocation();
   const history = useHistory();
   const { id, passport_specification_id }: any = useParams();
   const [forceUpdateFlag, setForceUpdateFlag] = useState(0);
   const [initialValues, setInitialValues] = useState({});

   const [t] = useTranslation();

   const [{ data, loading: getLoading, error: getError }, executeGet] =
      useAxios(
         {
            url: `${Resource.path.getMaterialDetailsHub}/${passport_specification_id}`,
            method: "GET"
         },
         { useCache: true }
      );

   React.useEffect(() => {
      console.log(data);

      if (data?.data) {
         const material = data?.data;
         const materialData = {
            category: {
               id: material?.category_id,
               name: material?.category_name
            },
            categories: material?.categories,
            documents: getPreviewCompatibleDocuments(material?.documents) || [],
            ean: material?.ean,
            manufacturer: material?.manufacturer,
            material_current_location: material?.material_current_location,
            materials: material?.materials,
            name: material?.product_name,
            original_price: material?.original_price,
            product_group: material?.product_group,
            product_unit: material?.product_unit,
            specification: getFormaCompatibleSpecification(
               material?.specification
            ),
            weight_per_unit: material?.weight_per_unit,
            need_for_reuse: material?.need_for_reuse,
            notes: material?.notes,
            used_as: material?.used_as,
            images: material?.images,
            locations_id: material?.locations_id
         };

         setInitialValues(materialData);
         setForceUpdateFlag(Math.random());
      }
   }, [data]);

   let { wizard_steps_product_details, resetMaterialPassportWizard } =
      useMaterialWizard();

   useEffect(() => {
      if (search.indexOf("state=new") !== -1) {
         resetMaterialPassportWizard();
         if (forceUpdateFlag === 0) {
            setForceUpdateFlag(1);
         }
      }
   }, [search]);

   const navigateBack = () => {
      history.push(`/hub/material_inventory`);
   };

   return (
      <div className="form-wrap material-inventory-wizard-wrapper wrapper-style blackborder">
         <div className="form-wrap-inner">
            <PageHeader
               title={t("common.back")}
               onBack={navigateBack}
            ></PageHeader>

            <div className="steps-wrapper-content">
               <Switch>
                  <Route
                     path={`/hub/material_inventory/edit/:passport_specification_id/product_details`}
                  >
                     <ProductDetails
                        key={forceUpdateFlag}
                        initialValues={initialValues}
                        pageTitle={t("productDetails.editMaterialTitle")}
                        operationType={"EDIT"}
                     />
                  </Route>
               </Switch>
            </div>
         </div>
      </div>
   );
};
