import React, { useState, useEffect } from "react";
import {
   Row,
   Col,
   Button,
   Comment,
   Input,
   Form,
   List,
   Empty,
   Avatar,
   message,
   Spin
} from "antd";
import useAxios from "axios-hooks";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import Drawer from "../../../../../components/ui@concular/Drawer";
import { useAuth } from "../../../../../contexts/auth/index";
import { Resource } from "../../../../../api/common";

const { TextArea } = Input;

export const RequestComments = ({
   data,
   visible,
   onClose,
   created_by_type
}: any) => {
   const [comments, setComments] = useState<any>([]);
   const [value, setValue] = useState<any>("");
   const { user: currentUser } = useAuth();
   const [form] = useForm();

   console.log("data", data);
   const [{ loading: addCommentLoading }, addComment] = useAxios(
      {
         url: `${Resource.path.addOrderComment(
            data?.order_id ? data?.order_id : data.id
         )}`,
         method: "post"
      },
      { useCache: false, manual: true }
   );

   const [{ data: commentsData, loading: listCommentLoading }, fetch] =
      useAxios(
         {
            url: `${Resource.path.addOrderComment(
               data?.order_id ? data?.order_id : data.id
            )}`,
            method: "get"
         },
         { useCache: false, manual: false }
      );

   const closeMe = () => {
      onClose();
   };

   const CommentList = ({ comments }: any) => (
      <List
         dataSource={comments}
         header={`${comments.length} ${
            comments.length > 1 ? "Nachrichten" : "Nachricht"
         }`}
         itemLayout="horizontal"
         renderItem={(props: any) => (
            <Comment
               avatar={<Avatar icon={<UserOutlined />} />}
               author={
                  <p>
                     {props?.is_seller ? "(Verkäufer)" : "(Käufer)"}{" "}
                     {currentUser?.attributes.sub == props.created_by &&
                     props?.created_by_name
                        ? "You"
                        : ""}
                  </p>
               }
               datetime={moment(props.created_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
               )}
               content={props.content}
            />
         )}
      />
   );

   const onEditorSubmit = async () => {
      await addComment({
         data: {
            content: value,
            created_by_type
         }
      })
         .then(async (response) => {
            console.log(response);
            await fetch();
            setValue("");
            form.resetFields();
            message.success("Erfolgreich gesendet!");
         })
         .catch((error) => {
            console.log(error);
         });
   };

   useEffect(() => {
      if (commentsData?.data) {
         setComments(commentsData?.data);
      }
   }, [commentsData]);

   return (
      <Drawer
         placement="right"
         closable={true}
         width={600}
         visible={visible}
         onClose={closeMe}
      >
         <Spin tip="Laden..." spinning={listCommentLoading}>
            <Row
               style={{
                  minHeight: "350px",
                  maxHeight: "350px",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column-reverse"
               }}
            >
               <Col span={24}>
                  {comments.length > 0 && <CommentList comments={comments} />}
                  {comments.length == 0 && <Empty />}
               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  <Comment
                     avatar={<Avatar icon={<UserOutlined />} />}
                     author={<p>Du</p>}
                     content={
                        <>
                           <Form
                              form={form}
                              name="requestComments"
                              initialValues={{ comment: value }}
                              autoComplete="off"
                              onFinish={onEditorSubmit}
                           >
                              <Form.Item
                                 name="comment"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Nachricht schreiben"
                                    }
                                 ]}
                              >
                                 <TextArea
                                    rows={4}
                                    showCount
                                    maxLength={500}
                                    onChange={(e: any) => {
                                       setValue(e.target.value);
                                    }}
                                    value={value}
                                 />
                              </Form.Item>
                              <Form.Item>
                                 <Button
                                    htmlType="submit"
                                    loading={addCommentLoading}
                                    type="primary"
                                 >
                                    Senden
                                 </Button>
                              </Form.Item>
                           </Form>
                        </>
                     }
                  />
               </Col>
            </Row>
         </Spin>
      </Drawer>
   );
};
