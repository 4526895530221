import React from "react";
import {
   decodeIfcString,
   rootObjectsNames,
   getAllRootObjects,
   getParsedData
} from "./utils";
import { Collapse } from "antd";
import { IfcMapping } from "./IfcMapping";
import { IfcViewerAPI } from "web-ifc-viewer";
import { useIfcViewer, GrouppedObjects } from "./IfcViewerContext";
import { ifcApi, IFCObjectIN } from "../api-client";
import { getGrouppedByName } from "./IfcFiles";

const { Panel } = Collapse;

export default function IfcList({
   viewer
}: {
   viewer?: IfcViewerAPI;
   model: any;
}) {
   const { parsedData, model, currentIfcFile, setParsedData } = useIfcViewer();
   React.useEffect(() => {
      if (model && viewer && !parsedData) {
         getAllRootObjects({ viewer }).then((data) => {
            getParsedData({ viewer, data }).then((result) => {
               currentIfcFile &&
                  ifcApi.djIfcBaseApiV1RoutersIfcFileBulkCreateIfcObject(
                     currentIfcFile.id,
                     result as IFCObjectIN[]
                  );
               setParsedData?.(getGrouppedByName(result));
            });
         });
      }
   }, [currentIfcFile, model, setParsedData, parsedData, viewer]);

   return (
      <Collapse accordion>
         {parsedData &&
            Object.keys(parsedData).map((ifcClass) => {
               const grouppedByName = parsedData[ifcClass].reduce(
                  (previous, current) => {
                     if (previous[current.name]) {
                        previous[current.name].push(current);
                     } else {
                        previous[current.name] = [current];
                     }
                     return previous;
                  },
                  {} as GrouppedObjects
               );
               return (
                  <Panel header={rootObjectsNames[ifcClass]} key={ifcClass}>
                     {Object.keys(grouppedByName).map((singleObjectName) => {
                        const firstObject = grouppedByName[singleObjectName][0];
                        return (
                           <div key={`${ifcClass}.${singleObjectName}`}>
                              <IfcMapping
                                 title={decodeIfcString(singleObjectName)}
                                 ifcObject={firstObject}
                                 count={grouppedByName[singleObjectName].length}
                              />
                           </div>
                        );
                     })}
                  </Panel>
               );
            })}
      </Collapse>
   );
}
