const BUILDING_SERVICE_BASE_API_URL: any = `${process.env.REACT_APP_API_BASE_URL}/building-service`;
const MATERIAL_SERVICE_BASE_API_URL: any = `${process.env.REACT_APP_API_BASE_URL}/material-service`;
const OPERATION_SERVICE_BASE_API_URL: any = `${process.env.REACT_APP_API_BASE_URL}/operations-service`;
const HUB_INVENTORY_SERVICE_BASE_API_URL: any = `${process.env.REACT_APP_API_BASE_URL}/materialHub-inventory`;
const HUB_PILOT_SERVICE_BASE_API_URL: any = `${process.env.REACT_APP_API_BASE_URL}/materialHub-pilot`;
const PLATFORM_ADMIN_SERVICE_BASE_API_URL: any = `${process.env.REACT_APP_PLATFORM_ADMIN_SERVICE_BASE_API_URL}`;
const ONBOARDING_API_URL: any = `${process.env.REACT_APP_API_BASE_URL}/user-service`;
// "https://platform-admin-api-prod.concular.com"; //`${process.env.REACT_APP_PLATFORM_ADMIN_SERVICE_BASE_API_URL}`;

export const Resource = {
   path: {
      // Platform Admin Resource Path
      getCategories: `${PLATFORM_ADMIN_SERVICE_BASE_API_URL}/categories`,
      getFormTemplateById: `${PLATFORM_ADMIN_SERVICE_BASE_API_URL}/forms`,
      getBrands: `${PLATFORM_ADMIN_SERVICE_BASE_API_URL}/brands`,
      getProductGroupDetails: `${PLATFORM_ADMIN_SERVICE_BASE_API_URL}/productGroups`,
      listProductGroups: `${PLATFORM_ADMIN_SERVICE_BASE_API_URL}/productGroups`,
      getCategoriesWithProductGroups: `${PLATFORM_ADMIN_SERVICE_BASE_API_URL}/categories-product-groups`,

      getCategoriesTree: `${PLATFORM_ADMIN_SERVICE_BASE_API_URL}/categoriesTree`,
      getCategoriesByTreeId: `${PLATFORM_ADMIN_SERVICE_BASE_API_URL}/categories/tree`,

      // Building Resources
      listBuilding: `${BUILDING_SERVICE_BASE_API_URL}/buildings`,
      removeBuilding: `${BUILDING_SERVICE_BASE_API_URL}/buildings`,
      createBuilding: `${BUILDING_SERVICE_BASE_API_URL}/buildings`,
      updateBuilding: `${BUILDING_SERVICE_BASE_API_URL}/buildings`,
      getSignedUrlForBuildingAssets: `${BUILDING_SERVICE_BASE_API_URL}/building/initiateUpload`,
      getSaveBuildingFilePath(id: string): string {
         return `${BUILDING_SERVICE_BASE_API_URL}/buildings/${id}/files`;
      },
      getBuildingFiles: `${BUILDING_SERVICE_BASE_API_URL}/buildings`,
      calDistanceBetBuildings(
         source_building_id: string,
         dest_building_id: string
      ): string {
         return `${BUILDING_SERVICE_BASE_API_URL}/distance/${source_building_id}/${dest_building_id}`;
      },
      // Material Resources
      createMaterial: `${MATERIAL_SERVICE_BASE_API_URL}/materials`,
      createMaterialHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/materials`,
      getMaterials: `${MATERIAL_SERVICE_BASE_API_URL}/materials`,
      getMaterialsHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/materials`,
      getSignedUrlForMaterialAssets: `${MATERIAL_SERVICE_BASE_API_URL}/materials/initiateUpload`,
      getSignedUrlForMaterialAssetsHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/materials/initiateUpload`,
      removeMaterial: `${MATERIAL_SERVICE_BASE_API_URL}/materials`,
      getMaterialDetailsById: (materialId: string) => {
         return `${MATERIAL_SERVICE_BASE_API_URL}/materials/${materialId}`;
      },
      // Material Passport Resources
      createPassport: `${MATERIAL_SERVICE_BASE_API_URL}/passports`,
      getPassports: `${MATERIAL_SERVICE_BASE_API_URL}/passports`,
      getSignedUrlForPassportAssets: (materialId: string) => {
         return `${MATERIAL_SERVICE_BASE_API_URL}/passports/${materialId}/initiateUpload`;
      },
      getSignedUrlForPassportAssetsHub: (materialId: string) => {
         return `${HUB_INVENTORY_SERVICE_BASE_API_URL}/passports/${materialId}/initiateUpload`;
      },

      getClusteredPassports: `${MATERIAL_SERVICE_BASE_API_URL}/mp/passports`,
      getClusteredPassportsHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/mp/passports`,
      deleteClusteredPassports: `${MATERIAL_SERVICE_BASE_API_URL}/mp/passports/deleteCluster`,

      getMaterialDetails: `${MATERIAL_SERVICE_BASE_API_URL}/materials`,
      getMaterialDetailsHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/materials`,

      createPassportItem: `${MATERIAL_SERVICE_BASE_API_URL}/mp/passport`,
      createPassportItemHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/mp/passport`,

      updatePassportBulk: `${MATERIAL_SERVICE_BASE_API_URL}/mp/passport`,

      // Material Passport Resources
      createMaterialPassport: `${MATERIAL_SERVICE_BASE_API_URL}/mp`,
      createMaterialPassportHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/mp`,
      removeMaterialPassport: `${MATERIAL_SERVICE_BASE_API_URL}/mp`,
      removeMaterialPassportHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/mp`,
      deletePassportDetails: `${MATERIAL_SERVICE_BASE_API_URL}/passportDetails`,
      deletePassportDetailsHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/passportDetails`,
      updateMaterialPassportBulk: `${MATERIAL_SERVICE_BASE_API_URL}/mp`,
      updateMaterialPassportBulkHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/mp`,
      editPassportDetails: `${MATERIAL_SERVICE_BASE_API_URL}/passportDetails`,
      editPassportDetailsHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/passportDetails`,
      getMaterialPassportAuth: `${MATERIAL_SERVICE_BASE_API_URL}/materials/list`,
      getHubMaterialPassportAuth: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/materials/list`,
      cloneMaterialSpec: `${MATERIAL_SERVICE_BASE_API_URL}/materials/clone`,
      cloneMaterialSpecHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/materials/clone`,
      getPassportDetailsListAuth: `${MATERIAL_SERVICE_BASE_API_URL}/mp/passportDetails/list`,
      getPassportDetailsListAuthHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/mp/passportDetails/list`,
      getPassportData: (id: any) => {
         return `${MATERIAL_SERVICE_BASE_API_URL}/materials/${id}`;
      },

      // Material ingredients
      getMaterialIngredients: `${PLATFORM_ADMIN_SERVICE_BASE_API_URL}/materialIngredient`,

      // Marketplace Resources
      getAllAvailableMaterials: `${MATERIAL_SERVICE_BASE_API_URL}/marketplace/materials`,

      // Material Request
      createMaterialRequest: `${MATERIAL_SERVICE_BASE_API_URL}/materials/request`,
      createMaterialRequestHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/materials/request`,

      inviteBuildingMember: `${BUILDING_SERVICE_BASE_API_URL}/buildings/members`,
      inviteDetails: `${BUILDING_SERVICE_BASE_API_URL}/invitation`,

      getBuildingMembers: (buildingId: string) => {
         return `${BUILDING_SERVICE_BASE_API_URL}/buildings/members/${buildingId}`;
      },
      removeBuildingMember: (buildingId: string, userId: string) => {
         return `${BUILDING_SERVICE_BASE_API_URL}/buildings/members/${buildingId}/${userId}`;
      },
      patchBuildingMember: (id: string) => {
         return `${BUILDING_SERVICE_BASE_API_URL}/buildings/members/invite/${id}`;
      },
      confirmInvitation: (inviteId: string | null) => {
         return `${BUILDING_SERVICE_BASE_API_URL}/buildings/members/invite/${inviteId}/confirm`;
      },
      resendInvitation: (inviteId: string) => {
         return `${BUILDING_SERVICE_BASE_API_URL}/buildings/members/resendinvite/${inviteId}`;
      },
      //Operations:
      operations: `${OPERATION_SERVICE_BASE_API_URL}/operations`,
      operationsHub: `${HUB_PILOT_SERVICE_BASE_API_URL}`,
      getMaterialPassportOperations: (
         building_id: string,
         operationId: string
      ) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/operations/${building_id}/selling/${operationId}/materials`;
      },
      getMaterialPassportPilot: () => {
         return `${HUB_PILOT_SERVICE_BASE_API_URL}/selling/materials`;
      },
      getMaterialInventoryOp: (building_id: string, operationId: string) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/operations/${building_id}/selling/${operationId}/inventory`;
      },
      getMaterialInventoryHub: () => {
         return `${HUB_PILOT_SERVICE_BASE_API_URL}/selling/inventory`;
      },
      demandApiUrl: (building_id: string, operationId: string) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/operations/${building_id}/demand/${operationId}`;
      },
      getSellingOrders: (
         building_id: string,
         operationId: string,
         specification_id: any
      ) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/selling/${building_id}/operation/${operationId}/spec/${specification_id}/orders`;
      },
      ordersApiUrl: (demand_id: number) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/demand/${demand_id}/orders`;
      },
      updateOrderApi: (order_id: number) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/order/${order_id}`;
      },
      demandApiUrlHub: () => {
         return `${HUB_PILOT_SERVICE_BASE_API_URL}/demand`;
      },
      getAllAvailableMaterialPassportInvent: `${MATERIAL_SERVICE_BASE_API_URL}/marketplace/mp/invent`,
      getMatchUrl: (demand_id: any, buildingId: any, operation_id: any) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/match/${buildingId}/demand/${demand_id}/op/${operation_id}`;
      },
      getRequestUrl: (demand_id: any, buildingId: any, operation_id: any) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/request/${buildingId}/operation/${operation_id}/demand/${demand_id}`;
      },
      getRequestUrlHub: (demand_id: any) => {
         return `${HUB_PILOT_SERVICE_BASE_API_URL}/request/demand/${demand_id}`;
      },
      getSellingRequestData: (
         buildingId: any,
         operation_id: any,
         passport_specification_id: any
      ) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/selling/${buildingId}/operation/${operation_id}/request/${passport_specification_id}`;
      },
      getSellingRequestDataHub: (passport_specification_id: any) => {
         return `${HUB_PILOT_SERVICE_BASE_API_URL}/selling/request/${passport_specification_id}`;
      },
      updateRequest: (
         building_id: any,
         operation_id: any,
         selling_id: any,
         request_id: any
      ) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/selling/${building_id}/operation/${operation_id}/selling/${selling_id}/request/${request_id}`;
      },
      updateRequestHub: (selling_id: any, request_id: any) => {
         return `${HUB_PILOT_SERVICE_BASE_API_URL}/selling/${selling_id}/request/${request_id}`;
      },
      removeRequest: (id: any) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/request/remove/${id}`;
      },
      orderDetails: (id: any) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/order/${id}`;
      },
      removeRequestHub: (id: any) => {
         return `${HUB_PILOT_SERVICE_BASE_API_URL}/request/remove/${id}`;
      },
      getMaterialRequestOfUser: `${MATERIAL_SERVICE_BASE_API_URL}/materials/request/user/specification`,
      createMaterialRequestV2: (buildingId: string, operation_id: any) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/catalogue/request/${buildingId}/operation/${operation_id}`;
      },
      addOrderComment: (id: string) => {
         return `${OPERATION_SERVICE_BASE_API_URL}/comments/order/${id}`;
      },
      syncURL: `${ONBOARDING_API_URL}/sync-user`,
      getLocationsHub: `${HUB_INVENTORY_SERVICE_BASE_API_URL}/mp/locations`,
      getLocationsDemandHub: `${HUB_PILOT_SERVICE_BASE_API_URL}/demand/locations`,
      calDistanceBetHub: `${HUB_PILOT_SERVICE_BASE_API_URL}/distance`,
      createMaterialRequestV2Hub: () => {
         return `${HUB_PILOT_SERVICE_BASE_API_URL}/catalogue/request`;
      },
      getLocationsBuilding: `${BUILDING_SERVICE_BASE_API_URL}/buildings/locations`,
      getLocationsEmailHub: `${HUB_PILOT_SERVICE_BASE_API_URL}/locations/email`,
      demandApiUrlHubPub: () => {
         return `${HUB_PILOT_SERVICE_BASE_API_URL}/demand/public`;
      },
      checkUserExists: `${HUB_PILOT_SERVICE_BASE_API_URL}/user/check`,
      getUsersLocationsHub: `${HUB_PILOT_SERVICE_BASE_API_URL}/locations`,
      addDemandUniversal: `${OPERATION_SERVICE_BASE_API_URL}/demand/universal`,

      getOrganizationTypes: `${ONBOARDING_API_URL}/org/types`,
      joinCreateOrganization: `${ONBOARDING_API_URL}/onboarding`,
      getOrganizationsList: `${ONBOARDING_API_URL}/org/list`,
      userOrganizations: `${ONBOARDING_API_URL}/org/user`,

      organization: {
         getSignedUrl: `${ONBOARDING_API_URL}/org/initiateUpload`,
         update: `${ONBOARDING_API_URL}/org`,
         updateUserSettings: `${ONBOARDING_API_URL}/org/joined`,
         join: `${ONBOARDING_API_URL}/org/join`,
         create: `${ONBOARDING_API_URL}/org`,
         getOrganizationDetails: (id: string) => {
            return `${ONBOARDING_API_URL}/org/${id}`;
         },
         getUsers: (id: string) => {
            return `${ONBOARDING_API_URL}/org/${id}/users`;
         },
         putNamespaces: (id: string) => {
            return `${ONBOARDING_API_URL}/org/${id}/namespace-email`;
         },
         inviteUser: `${ONBOARDING_API_URL}/org/invite`,
         resendInvite: (user_organizations_id: number) => {
            return `${ONBOARDING_API_URL}/user-org/${user_organizations_id}/resend-invite`;
         },
         getLocationDataURL: (location_id: string | number) => {
            return `${BUILDING_SERVICE_BASE_API_URL}/location/${location_id}`;
         }
      },
      getAllPlans: `${ONBOARDING_API_URL}/plans/packages`,
      paymentSessionUrl: `${ONBOARDING_API_URL}/plans/session`,
      plansURL: `${ONBOARDING_API_URL}/plans`
   }
};
