import React from "react";
import { Form, Switch } from "antd";
import * as Joi from "joi";
import { useTranslation } from "react-i18next";

export const SwitchFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      type: Joi.string().allow("switch")
   }).unknown()
});

export interface SwitchField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      [key: string]: any;
   };
   [key: string]: any;
}

const CustomSwitch = ({ onChange, fieldProps }: any) => {
   const [t] = useTranslation();

   const handleOnChange = (value: any) => {
      const result = value ? t("formElement.yes") : t("formElement.no");
      onChange(result);
   };

   return (
      <Switch
         onChange={handleOnChange}
         {...fieldProps}
         checkedChildren={t("formElement.yes")}
         unCheckedChildren={t("formElement.no")}
      ></Switch>
   );
};

export const SwitchField = (props: SwitchField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   return (
      <Form.Item {...formItemProps}>
         <CustomSwitch fieldProps={fieldProps}></CustomSwitch>
      </Form.Item>
   );
};
