import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { MaterialDetails } from "../MaterialDetails";
import Drawer from "../../../../../components/ui@concular/Drawer";
import { Resource } from "../../../../../api/common";

export const SourcingRequestDetails = ({ data, visible, onClose }: any) => {
   console.log("data", data);
   console.log("hubspot_status", data.status);
   console.log("hubspot_paid", data.paid);

   const [material, setMaterial] = useState<any>();
   const [{ data: dataPassport, loading: getLoading }] = useAxios(
      {
         url: `${Resource.path.getPassportData(
            data.passport_specification_id
         )}`,
         method: "GET"
      },
      { useCache: true }
   );
   useEffect(() => {
      if (dataPassport?.data) {
         setMaterial(dataPassport.data);
      }
      if (dataPassport?.data) {
         setMaterial(dataPassport.data);
      }
   }, [dataPassport]);

   const closeMe = () => {
      setMaterial(null);
      onClose();
   };

   console.log(data?.hubspot_data?.properties?.progress_update?.value);

   return (
      <Drawer
         placement="right"
         closable={true}
         width={600}
         visible={visible}
         onClose={closeMe}
      >
         <div className="request-status">
            <div className="sale-details">
               <h4>Order details</h4>
               <div className="order-volume">
                  <div className="amount">
                     {data.amount} {data.amount_unit}
                  </div>
                  {data.proposal_price && (
                     <div>
                        {data.proposal_price} €/{data.amount_unit}
                     </div>
                  )}
               </div>
            </div>
            <div className="material-details">
               {data.hubspot_data.properties.progress_update && (
                  <>
                     <h4>Status</h4>
                     <div>
                        <span className="status tag">{data.status}</span>
                        <span className="progress-update tag">
                           {data.hubspot_data.properties.progress_update.value}
                        </span>
                     </div>
                  </>
               )}
               {data.hubspot_data.properties.service_update && (
                  <>
                     <h4>Zustand</h4>
                     <div>
                        <span className="service-update tag">
                           {data.hubspot_data.properties.service_update.value}
                        </span>
                     </div>
                  </>
               )}
               {data.hubspot_data.properties.current_location && (
                  <>
                     <h4>Adresse</h4>
                     <div>
                        {data.hubspot_data.properties.current_location.value}
                     </div>
                  </>
               )}
            </div>
         </div>
         {material && (
            <MaterialDetails
               material={material}
               closeMe={closeMe}
               showRequestBtn={false}
               demand_id={data.demands_id}
               dataDemand={data}
            />
         )}
      </Drawer>
   );
};
