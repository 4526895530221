import React from "react";
import { DatePicker, Form } from "antd";
import * as Joi from "joi";

export const DateFieldSchema = Joi.object({
   formItem: Joi.object({
      label: Joi.string().required()
   }).unknown(),
   field: Joi.object({
      picker: Joi.string().valid("week", "month", "year", "quarter"),
      type: Joi.string().allow("date")
   }).unknown()
});

export interface DateField {
   formItem: {
      name: string;
      label: string;
      [key: string]: any;
   };
   field: {
      picker: "week" | "month" | "year" | "quarter";
      [key: string]: any;
   };
   form: any;
}

export const DateField = (props: DateField) => {
   const { formItem: formItemProps, field: fieldProps } = props;

   const dateFieldProps = Object.assign({}, { ...fieldProps });

   // Remove unsupported props
   Reflect.deleteProperty(dateFieldProps, "type");

   return (
      <Form.Item {...formItemProps}>
         <DatePicker {...dateFieldProps} />
      </Form.Item>
   );
};
