import React from "react";
import { Form, Input, message, Row, Col } from "antd";
import { Auth } from "aws-amplify";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import SettingsSideBar from "./SettingsSideBar";
import styles from "./Settings.module.scss";
import ButtonField from "../../components/FormFields/ButtonField";
import { useHistory } from "react-router";

const layout = {
   labelCol: { span: 8 },
   wrapperCol: { span: 24 }
};

const passwordRegex = new RegExp(
   "^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})"
);

export const AccountPassword = () => {
   const [form] = useForm();
   const [t] = useTranslation();
   const history = useHistory();

   const handleOnSubmit = async (value: {
      oldPassword: string;
      newPassword: string;
      confirmPassword: string;
   }) => {
      try {
         const currentUser = await Auth.currentAuthenticatedUser();
         await Auth.changePassword(
            currentUser,
            value.oldPassword,
            value.newPassword
         );
         message.success(t("settings.account.message.success"));
         form.resetFields();
         message.warning("Please login again");
         await Auth.signOut({ global: true });
         (window as any).localStorage.clear();
         (window as any).sessionStorage.clear();
         history.push("/login");
      } catch (error) {
         console.log(error);
         message.error(t("settings.account.message.error"));
      }
   };

   return (
      <SettingsSideBar title={t("settings.profile.changePassword")}>
         <Row>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
               <Form
                  name="passwordForm"
                  {...layout}
                  onFinish={handleOnSubmit}
                  form={form}
               >
                  <div className={styles.section}>
                     <Row className={styles.row}>
                        <Col span={12} className={styles.label}>
                           {t("settings.account.form.oldPassword")}
                        </Col>
                        <Col span={12} className={styles.field}>
                           <Form.Item
                              name="oldPassword"
                              rules={[
                                 {
                                    required: true,
                                    message: t(
                                       "settings.account.form.oldPasswordRequired"
                                    )
                                 }
                              ]}
                              hasFeedback
                              style={{ marginBottom: 0 }}
                           >
                              <Input.Password size="large" />
                           </Form.Item>
                        </Col>
                     </Row>
                     <Row className={styles.row}>
                        <Col span={12} className={styles.label}>
                           {t("settings.account.form.newPassword")}
                        </Col>
                        <Col span={12} className={styles.field}>
                           <Form.Item
                              name="newPassword"
                              hasFeedback
                              style={{ marginBottom: 0 }}
                              rules={[
                                 {
                                    required: true,
                                    message: t(
                                       "settings.account.form.newPasswordRequired"
                                    )
                                 },
                                 ({ getFieldValue }) => ({
                                    validator(_rule, value) {
                                       if (!value) {
                                          return Promise.resolve();
                                       }

                                       if (value.length < 10) {
                                          return Promise.reject(
                                             t(
                                                "settings.account.form.passwordLengthMessage"
                                             )
                                          );
                                       }

                                       if (!passwordRegex.test(value)) {
                                          return Promise.reject(
                                             t(
                                                "settings.account.form.passwordLengthContainsMessage"
                                             )
                                          );
                                       }

                                       return Promise.resolve();
                                    }
                                 })
                              ]}
                           >
                              <Input.Password size="large" />
                           </Form.Item>
                        </Col>
                     </Row>
                     <Row className={styles.row}>
                        <Col span={12} className={styles.label}>
                           {t("settings.account.form.confirmPassword")}
                        </Col>
                        <Col span={12} className={styles.field}>
                           <Form.Item
                              name="confirmPassword"
                              style={{ marginBottom: 0 }}
                              rules={[
                                 {
                                    required: true,
                                    message: t(
                                       "settings.account.form.confirmPasswordMessage"
                                    )
                                 },
                                 ({ getFieldValue }) => ({
                                    validator(_rule, value) {
                                       if (
                                          !value ||
                                          getFieldValue("newPassword") === value
                                       ) {
                                          return Promise.resolve();
                                       }

                                       return Promise.reject(
                                          t(
                                             "settings.account.form.passwordNotMatchedMessage"
                                          )
                                       );
                                    }
                                 })
                              ]}
                              dependencies={["newPassword"]}
                              hasFeedback
                           >
                              <Input.Password size="large" />
                           </Form.Item>
                        </Col>
                     </Row>
                  </div>
                  <Row>
                     <Col span={24} className={styles.submitForm}>
                        <ButtonField
                           type="link"
                           size="large"
                           onClick={() => history.push("/settings/profile")}
                        >
                           {t("settings.profile.back")}
                        </ButtonField>
                        <ButtonField
                           type="default"
                           size="large"
                           htmlType="submit"
                        >
                           {t("settings.account.form.btnSubmit")}
                        </ButtonField>
                     </Col>
                  </Row>
               </Form>
            </Col>
         </Row>
      </SettingsSideBar>
   );
};
