import { Form, Input } from "antd";
import React, { ReactNode, useState } from "react";
import styles from "./style.module.scss";

interface IProps {
   id?: string;
   defaultValue?: string;
   value?: string;
   onChange?: (e: any) => any;
   label?: string;
   formLabel?: string;
   required?: boolean;
   size?: "small" | "middle" | "large";
   fieldName?: string;
   placeholder?: string;
   className?: string;
   formClassName?: string;
   noMargin?: boolean;
   onKeyDown?: (e: any, value: string) => any;
   onInput?: (e: any) => any;
   formItem?: boolean;
   fieldErrorMessage?: string;
   disabled?: boolean;
   type?: "string" | "number" | "boolean" | "url" | "email";
   icon?: ReactNode;
}

const InputField = ({
   id,
   defaultValue,
   value = "",
   onChange,
   label,
   formLabel,
   required,
   size = "middle",
   fieldName = "",
   placeholder = "",
   className,
   formClassName,
   noMargin,
   onKeyDown,
   onInput,
   formItem = true,
   fieldErrorMessage,
   disabled,
   type = "string",
   icon
}: IProps) => {
   const [inputValue, setInputValue] = useState(value);

   const setValue = (e: any) => {
      setInputValue(e.target.value);
      onChange && onChange(e.target.value);
   };

   const onKeyDownPressed = (e: any) => {
      onKeyDown && onKeyDown(e, inputValue);
   };

   return (
      <div className={`${styles.inputField} ${className}`}>
         {label && <div className={styles.label}>{label}</div>}
         {formItem ? (
            <Form.Item
               wrapperCol={{ span: 24 }}
               name={[fieldName]}
               label={formLabel}
               rules={[
                  {
                     required: required,
                     message: fieldErrorMessage,
                     type: type
                  }
               ]}
               className={formClassName}
               style={noMargin ? { marginBottom: 0 } : {}}
            >
               {InputObject()}
            </Form.Item>
         ) : (
            <span className={styles.simpleInput}>
               {InputObject(icon ? styles.withIcon : "")}
               {icon && <span className={styles.icon}>{icon}</span>}
            </span>
         )}
      </div>
   );

   function InputObject(classname?: string) {
      return (
         <Input
            defaultValue={defaultValue}
            size={size}
            value={inputValue}
            onChange={setValue}
            placeholder={placeholder}
            onKeyDown={(e: any) => onKeyDownPressed(e)}
            disabled={disabled}
            onInput={onInput}
            id={id}
            className={classname}
         />
      );
   }
};

export default InputField;
