import { Form, InputNumber } from "antd";
import React from "react";
import styles from "./style.module.scss";

interface IProps {
   defaultValue?: string;
   min?: string;
   max?: string;
   value?: string;
   onChange?: (e: any) => any;
   label?: string;
   required?: boolean;
   size?: "small" | "middle" | "large";
   fieldName?: string;
   placeholder?: string;
   className?: string;
   formClassName?: string;
   noMargin?: boolean;
   hideArrow?: boolean;
}

const NumberInputField = ({
   defaultValue,
   min,
   max,
   value,
   onChange,
   label,
   required,
   size = "middle",
   fieldName = "",
   placeholder = "",
   className,
   formClassName,
   noMargin,
   hideArrow
}: IProps) => {
   const setValue = (value: any) => {
      onChange && onChange(value);
   };

   return (
      <div
         className={`${styles.inputField} ${className} ${
            hideArrow && "no-arrow-number-input"
         }`}
      >
         {label && <div className={styles.label}>{label}</div>}
         <Form.Item
            wrapperCol={{ span: 24 }}
            name={[fieldName]}
            rules={[{ required: required }]}
            className={formClassName}
            style={noMargin ? { marginBottom: 0 } : {}}
         >
            <InputNumber
               type={"number"}
               defaultValue={defaultValue}
               min={min}
               max={max}
               size={size}
               value={value}
               onChange={setValue}
               placeholder={placeholder}
            />
         </Form.Item>
      </div>
   );
};

export default NumberInputField;
