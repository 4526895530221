import { IfcViewerAPI } from "web-ifc-viewer";
import { IfcRootObject, IfcPropertySingleValue } from "./types";
import { IFCWINDOW, IFCDOOR } from "web-ifc";
import { IFCObjectIN, IFCObjectOUT } from "../api-client";

type Params = {
   viewer: IfcViewerAPI;
   id: string;
};

export const rootObjectsNames: { [key: string]: string } = {
   // [IFCWALLSTANDARDCASE]: "IfcWallStandardCase",
   // [IFCSLAB]: "IfcSlab",
   // [IFCSTAIR]: "IfcStair",
   // [IFCRAILING]: "IfcRailing",
   // [IFCFLOWSEGMENT]: "IfcFlowSegment",
   // [IFCCOLUMN]: "IfcColumn",
   [IFCWINDOW]: "IfcWindow",
   // [IFCBUILDINGELEMENTPROXY]: "IfcBuildingElementProxy",
   [IFCDOOR]: "IfcDoor"
   // [IFCSTAIRFLIGHT]: "IfcStairFlight",
   // [IFCFOOTING]: "IfcRooting",
   // [IFCSPACE]: "IfcSpace",
   // [IFCFLOWFITTING]: "IfcFlowFitting",
   // [IFCFLOWTERMINAL]: "IfcFlowTerminal",
   // [IFCCOVERING]: "IfcCovering",
   // [IFCFURNISHINGELEMENT]: "IfcFurnishingElement",
   // [IFCWALL]: "IfcWall"
};

export const getProperties = async ({ viewer, id }: Params) => {
   const addedMap: { [key: string]: boolean } = {};
   const result = [];
   const singleProps = await getSinglePropertyObjects({ viewer, id });
   const typeProps = await getTypePropertyObjects({ viewer, id });
   const both = [...singleProps, ...typeProps];
   for (let i = 0; i < both.length; i++) {
      const property = both[i];
      if (!addedMap[property.expressID]) {
         result.push(property);
         addedMap[property.expressID] = true;
      }
   }

   return result;
};

export const getSinglePropertyObjects = async ({ viewer, id }: Params) => {
   const data = await viewer?.IFC.loader.ifcManager.getPropertySets(
      0,
      parseInt(id)
   );
   if (!data) {
      return [];
   }

   const singlePropertyIdsWithDuplicates = data
      .map((item) =>
         item?.HasProperties?.map((i: { value: number }) => i.value)
      )
      .flat();

   const singlePropertyIds = [
      ...Array.from(new Set(singlePropertyIdsWithDuplicates))
   ];

   const singlePropertyObjects: IfcPropertySingleValue[] = [];
   for (let index = 0; index < singlePropertyIds.length; index++) {
      const id = singlePropertyIds[index];
      if (!id) {
         continue;
      }
      const ifcObject = await viewer?.IFC.loader.ifcManager.getItemProperties(
         0,
         id
      );
      singlePropertyObjects.push(ifcObject);
   }
   return singlePropertyObjects;
};

export const getTypePropertyObjects = async ({ viewer, id }: Params) => {
   const data = await viewer?.IFC.loader.ifcManager.getTypeProperties(
      0,
      parseInt(id),
      true
   );
   if (!data) {
      return [];
   }

   const singlePropertyIdsWithDuplicates: IfcPropertySingleValue[] = data
      .map((a) => a.HasPropertySets)
      .flat()
      .map((item) => item?.HasProperties)
      .filter((b) => !!b)
      .flat();

   return singlePropertyIdsWithDuplicates;
};

export const getRootObjects = async ({ viewer, id }: Params) => {
   const data: IfcRootObject[] =
      await viewer?.IFC.loader.ifcManager.getAllItemsOfType(
         0,
         parseInt(id),
         true
      );

   const grouppedRootObjects = data.reduce((previous, current) => {
      const title = current.ObjectType?.value;

      if (!title) {
         return previous;
      }

      if (previous[title]) {
         previous[title].push(current);
      } else {
         previous[title] = [current];
      }
      return previous;
   }, {} as { [key: string]: IfcRootObject[] });

   return grouppedRootObjects;
};

export type AllRootObjects = {
   [key: string]: { [key: string]: IfcRootObject[] };
};

const findPropertyValueByName = (
   properties: IfcPropertySingleValue[],
   name: string
) => {
   return parseFloat(
      properties?.find((a) => {
         return a?.Name?.value?.toLowerCase() === name?.toLowerCase();
      })?.NominalValue.value || "0"
   );
};

export const getParsedData = async ({
   viewer,
   data
}: {
   viewer: IfcViewerAPI;
   data: AllRootObjects;
}) => {
   const result: (IFCObjectIN | IFCObjectOUT)[] = [];
   for (const [ifcClass, ifcObjectsKeyed] of Object.entries(data)) {
      for (const [name, ifcObjects] of Object.entries(ifcObjectsKeyed)) {
         for (let i = 0; i < ifcObjects.length; i++) {
            const ifcObject = ifcObjects[i];
            const singlePropObjects = await getProperties({
               viewer,
               id: ifcObject.expressID.toString()
            });

            const surface = findPropertyValueByName(
               singlePropObjects,
               "surface"
            );

            const volume = findPropertyValueByName(singlePropObjects, "volume");

            // const materials = (
            //    await viewer?.IFC.loader.ifcManager.getMaterialsProperties(
            //       0,
            //       ifcObject.expressID,
            //       true
            //    )
            // ).map((material: any) => {
            //    return material?.Name?.value;
            // });

            const newOne = {
               ifc_class: ifcClass,
               name,
               ifc_express_id: ifcObject.expressID.toString(),
               overall_height: ifcObject?.OverallHeight?.value || 0,
               overall_width: ifcObject?.OverallWidth?.value || 0,
               surface_area: surface,
               volume,
               overall_length: 0,
               ifc_materials: []
            };
            console.log("pushing");
            result.push(newOne as IFCObjectIN);
         }
      }
   }
   return result;
};

export const getAllRootObjects = async ({
   viewer
}: {
   viewer: IfcViewerAPI;
}) => {
   const result: AllRootObjects = {};
   for (const [id] of Object.entries(rootObjectsNames)) {
      result[id] = await getRootObjects({
         viewer,
         id
      });
   }

   return result;
};

export function decodeIfcString(ifcString: string) {
   const ifcUnicodeRegEx = /\\X2\\(.*?)\\X0\\/giu;
   let resultString = ifcString;
   let match = ifcUnicodeRegEx.exec(ifcString);
   while (match) {
      const unicodeChar = String.fromCharCode(parseInt(match[1], 16));
      resultString = resultString.replace(match[0], unicodeChar);
      match = ifcUnicodeRegEx.exec(ifcString);
   }
   return resultString;
}
