import React from "react";
import "./style.scss";
import { IfcFiles } from "../IfcFiles";
import { IfcViewer } from "./IfcViewer";
import { useIfcViewer, GrouppedObjects } from "../IfcViewerContext";
import IfcList from "../IfcList";
import { ifcObjectApi } from "../../api-client";

export function IfcViewerWrapper() {
   const { viewer, model, setParsedData } = useIfcViewer();

   const { currentIfcFile } = useIfcViewer();

   React.useEffect(() => {
      if (currentIfcFile) {
         ifcObjectApi
            .djIfcBaseApiV1RoutersIfcObjectGetIfcObjects(currentIfcFile.id)
            .then((data) => {
               const result = data.data;
               if (result && result?.length > 0) {
                  const resultGrouppedByClass = result.reduce(
                     (previous, current) => {
                        if (previous[current.ifc_class]) {
                           previous[current.ifc_class].push(current);
                        } else {
                           previous[current.ifc_class] = [current];
                        }

                        return previous;
                     },
                     {} as GrouppedObjects
                  );
                  setParsedData?.(resultGrouppedByClass);
               } else if (viewer && result) {
               }
            });
         return;
      }
   }, [viewer, model, currentIfcFile, setParsedData]);

   return (
      <div className="ifc-container">
         <div className="ifc-container-content">
            {!currentIfcFile && <IfcFiles></IfcFiles>}

            {currentIfcFile && (
               <div className="ifc-container-list-wrapper">
                  <IfcViewer />
                  <IfcList model={model} viewer={viewer} />
               </div>
            )}
         </div>
      </div>
   );
}
