export const reducer = (state: any, action: any) => {
   switch (action.type) {
      case "UPDATE_PRODUCT_DETAILS_WIZARD":
         return {
            ...state,
            wizard_steps_product_details: Object.assign(
               {},
               { ...action.payload }
            )
         };
      case "UPDATE_PASSPORT_DETAILS_WIZARD":
         return {
            ...state,
            wizard_steps_passport_details: Object.assign(
               {},
               { ...action.payload }
            )
         };
      case "UPDATE_REUSE_DETAILS_WIZARD":
         return {
            ...state,
            wizard_steps_reuse_details: Object.assign({}, { ...action.payload })
         };
      case "UPDATE_PASSPORT_TYPE":
         return {
            ...state,
            material_passport_type: action.payload.type
         };
      case "RESET_MATERIAL_WIZARD_FORM":
         return {
            ...state,
            wizard_steps_product_details: null,
            wizard_steps_passport_details: null,
            wizard_steps_reuse_details: null
         };
      default:
         throw new Error(`Unknown action: ${action.type}`);
   }
};
