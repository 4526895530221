import React, { useEffect } from "react";
import { ConfigProvider } from "antd";
import de_DE from "antd/lib/locale/de_DE";
import en_GB from "antd/lib/locale/en_GB";
import { updateLanguage } from "../../utils/lang.util";

const LanguageContext = React.createContext({} as any);

const LanguageMap: any = {
   de: de_DE,
   en: en_GB
};

export const LanguageProvider = ({ children, initLocale }: any) => {
   const [locale, setLocale] = React.useState(initLocale);

   useEffect(() => {
      updateLanguage(locale);
   }, [locale]);

   const changeLanguage = (newLocale: string) => {
      updateLanguage(newLocale);
      setLocale(newLocale);
   };

   return (
      <LanguageContext.Provider value={{ locale, changeLanguage }}>
         <ConfigProvider locale={LanguageMap[locale]}>
            {children}
         </ConfigProvider>
      </LanguageContext.Provider>
   );
};

export const useLocale = () => React.useContext(LanguageContext);
