import React, { useState } from "react";
import useAxios from "axios-hooks";
import "antd/dist/antd.less";
import { I18n } from "aws-amplify";
import { Auth } from "aws-amplify";
import { Redirect, useHistory } from "react-router-dom";
import { Resource } from "../../../api/common";
import { WelcomePage } from "./WelcomePage";
import { CognitoUserAmplify } from "@aws-amplify/ui-react/node_modules/@aws-amplify/ui";
import { Switch, Route } from "react-router-dom";
import { authLocale } from "../../../components/WelcomePage/locale";
import "./auth.scss";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { MaterialDropdown } from "../../../components/MaterialDropdown";
import BrandMultiSelect from "../../../components/BrandMultiSelect";
import { useCookies } from "react-cookie";
import {
   message,
   DatePicker,
   Form,
   Spin,
   Input,
   Checkbox,
   AutoComplete,
   Button,
   Col,
   Row,
   Collapse
} from "antd";
import CategoriesTreeSearchField from "../../MaterialInventory/AddMaterialWizard/ProductDetails/ProductCategory/CategoriesTreeSearchField";
import ProductGroupField from "../../MaterialInventory/AddMaterialWizard/ProductDetails/ProductCategory/ProductGroupSelectField";

const defaultCheckedList = ["Delivery"];
const plainOptions = [
   { label: "Aufbereitung", value: "Refurbishment" },
   { label: "Prüfung", value: "Testing" },
   { label: "Upcycling", value: "Upcycling" },
   { label: "(Re)zertifizierung", value: "(Re)certification" },
   { label: "Lieferung", value: "Delivery" }
];
const dateFormat = "DD.MM.YYYY";

export const SignUp = () => {
   const { Panel } = Collapse;
   const history = useHistory();
   I18n.setLanguage(navigator.language === "en-GB" ? "en" : "de");
   I18n.putVocabularies(authLocale);
   const [form] = useForm();
   const [checkUserForm] = useForm();
   const [t] = useTranslation();
   const [checkedList, setCheckedList] = useState(defaultCheckedList);
   const [indeterminate, setIndeterminate] = React.useState(true);
   const [user, setUser] = React.useState<CognitoUserAmplify | null>(null);
   const [selectedCategoryId, setSelectedCategoryId] =
      React.useState<any>(undefined);
   const [resetProduct, setResetProduct] = React.useState<any>(false);
   const [showSpin, setShowSpin] = useState(true);
   const [productUnit, setProductUnit] = useState<any>();
   const [checkAll, setCheckAll] = React.useState(false);
   const [locations, setLocations] = React.useState<any>();
   const [locationsOrg] = React.useState<any>([]);
   const [valueLocation, setValueLocation] = React.useState<any>();
   const [selectedLocation, setSelectedLocation] = React.useState<any>();
   const [locationAPI, setLocationAPI] = React.useState<any>([]);
   const [isValidEmail, setIsValidEmail] = React.useState<any>(true);
   const [showSignUp, setShowSignUp] = React.useState<any>(false);
   const [locationDropDownOpen, setLocationDropDownOpen] =
      React.useState<any>(false);
   const [isNewLocation, setIsNewLocation] = React.useState<any>(false);
   const [validateStatusEmail, setValidateStatusEmail] = useState<any>();
   const [productGroup, setProductGroup] = useState<any>();
   const [lcaMetaMaterials, setLcaMetaMaterials] = useState<any>();
   const [showForm, setShowForm] = useState<any>(false);
   const [showPg, setShowPg] = useState<any>(false);
   const [inActiveProductGroup, setInActiveProductGroup] = useState<any>();
   const [cookies, setCookie, removeCookie] = useCookies(["user", "modules"]);
   const checkUser = async () => {
      await Auth.currentUserInfo()
         .then((user) => {
            setUser(user);
            setCookie("user", user, { secure: true, maxAge: 14400 });
            setShowSpin(false);
         })
         .catch((err) => {
            console.log(err);
            if (cookies.user) {
               removeCookie("user");
            }
            if (cookies.modules) {
               removeCookie("modules");
            }
            history.push("/login");
         });
   };

   const onCategorySelect = (value: {
      id: string;
      name: string;
      dropDownId: string;
      reset: boolean;
   }) => {
      const { id: categoryId, reset } = value;
      setSelectedCategoryId(categoryId);
      setResetProduct(reset);
      setShowPg(true);
   };

   const [
      { data, loading: getProductDetailsLoading },
      fetchProductGroupDetails
   ] = useAxios({}, { useCache: false, manual: true });

   const [
      { data: materialsData, loading: ingredientsLoading },
      fetchIngredients
   ] = useAxios(
      {
         url: `${Resource.path.getMaterialIngredients}`,
         method: "GET"
      },
      { manual: true }
   );

   const onChangeCheckboxGroup = (list: any) => {
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length < plainOptions.length);
      setCheckAll(list.length === plainOptions.length);
   };

   const onProductGroupSelect = async (value: any) => {
      if (value && value.key) {
         await fetchProductGroupDetails({
            url: `${Resource.path.getProductGroupDetails}/${value.key}`
         });
         form.setFieldsValue({ product_group: value });
         await fetchIngredients();
         setShowForm(true);
      }
   };

   const [{ loading: confirmLoading }, add] = useAxios(
      {
         url: `${Resource.path.demandApiUrlHubPub()}`,
         method: "POST"
      },
      { manual: true }
   );

   const [
      { data: dataPlaces, loading: getPlaces },
      findPlace,
      cancelRequestLocation
   ] = useAxios(
      {
         url: `${Resource.path.getLocationsHub}`,
         method: "POST"
      },
      { useCache: false, manual: true }
   );

   const [{ data: dataUser, loading: validateUserLoading }, checkUserExists] =
      useAxios(
         {
            url: `${Resource.path.checkUserExists}`,
            method: "POST"
         },
         { useCache: false, manual: true }
      );

   const onFinish = async () => {
      setLocationDropDownOpen(false);
      form
         .validateFields()
         .then(async () => {
            const data = form.getFieldsValue();
            console.log("data", data);
            await add({
               data: {
                  email: data.email,
                  product_group: data.product_group.label,
                  categories: JSON.stringify(data.categories),
                  product_group_id: data.product_group.value,
                  amount: data.minimum_amount,
                  target_price: data.target_price,
                  delivery: data.services.indexOf("Delivery") !== -1 ? 1 : 0,
                  refurbishment:
                     data.services.indexOf("Refurbishment") !== -1 ? 1 : 0,
                  testing: data.services.indexOf("Testing") !== -1 ? 1 : 0,
                  upcycling: data.services.indexOf("Upcycling") !== -1 ? 1 : 0,
                  recertification:
                     data.services.indexOf("(Re)certification") !== -1 ? 1 : 0,
                  delivery_date: data.delivery_date,
                  unit: productUnit,
                  material_location_places_api: isNewLocation
                     ? selectedLocation
                     : {},
                  isNewLocation,
                  locations_id: !isNewLocation ? selectedLocation : null,
                  given_name: data.given_name,
                  family_name: data.family_name,
                  measurements: data?.measurements ? data.measurements : null,
                  manufacturers: data?.manufacturers
                     ? data?.manufacturers
                     : null,
                  selected_material: data?.label_material
                     ? data?.label_material
                     : null
               }
            })
               .then((response) => {
                  console.log(response);
                  form.resetFields();
                  history.push("/login");
               })
               .catch((error) => {
                  console.log(error);
                  message.error(t("buildingOperations.errorMessage"));
               });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   React.useEffect(() => {
      if (!user) {
         checkUser();
      }
   }, [user]);

   React.useEffect(() => {
      if (data?.data) {
         setProductGroup(data.data);
         setProductUnit(data.data.unit);
      }
      if (dataPlaces?.data && dataPlaces?.data?.locations) {
         setLocationDropDownOpen(true);
      }
      // console.log("dataUser", dataUser);
      if (materialsData?.data) {
         const materials: any = materialsData?.data.data;
         const temp = materials
            ? productGroup?.lca_meta
                 ?.filter((material: any) => material.sub_material_id)
                 .map((material: any) => {
                    return materials?.find(
                       (m: any) => m.id === material.sub_material_id
                    );
                 })
            : [];
         setLcaMetaMaterials(temp);
      }
   }, [data, dataPlaces, dataUser, materialsData]);

   const setInActive = (data: any) => {
      //console.log('inavtice', data)
      setInActiveProductGroup(data);
   };

   if (showSpin)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <div className="App">
         <Switch>
            <Route
               path="/public/demand/sign-up"
               render={() => {
                  return user ? (
                     <Redirect to="/demand/add"></Redirect>
                  ) : (
                     <WelcomePage>
                        <div className="welcome-box">
                           <div className="welcome-box-inner">
                              {showSignUp && (
                                 <Spin
                                    spinning={
                                       getProductDetailsLoading ||
                                       ingredientsLoading
                                    }
                                 >
                                    <Form
                                       initialValues={{
                                          minimum_amount: "1",
                                          services: checkedList
                                       }}
                                       layout="vertical"
                                       form={form}
                                    >
                                       <Form.Item
                                          rules={[
                                             {
                                                type: "email",
                                                required: true,
                                                message:
                                                   I18n.get(
                                                      "Please enter email"
                                                   )
                                             },
                                             ({}) => ({
                                                validator(_, value) {
                                                   if (!isValidEmail) {
                                                      return Promise.reject(
                                                         new Error(
                                                            "Email already exists"
                                                         )
                                                      );
                                                   }
                                                   if (value)
                                                      return Promise.resolve();
                                                }
                                             })
                                          ]}
                                          className="inline"
                                          label={t(
                                             "demands.addDemand.yourMail"
                                          )}
                                          name="email"
                                          validateStatus={validateStatusEmail}
                                          hasFeedback
                                       >
                                          <Input
                                             disabled
                                             placeholder={t(
                                                "demands.addDemand.yourMail"
                                             )}
                                             onChange={() => {
                                                setIsValidEmail(true);
                                                setValidateStatusEmail(
                                                   "validating"
                                                );
                                             }}
                                             onBlur={async (value: any) => {
                                                const errorMessages =
                                                   form.getFieldError("email");
                                                if (
                                                   value.target.value &&
                                                   errorMessages.length === 0
                                                ) {
                                                   setValidateStatusEmail(
                                                      "validating"
                                                   );
                                                   await checkUserExists({
                                                      data: {
                                                         email: value.target
                                                            .value
                                                      }
                                                   })
                                                      .then((response) => {
                                                         console.log(
                                                            "response",
                                                            response
                                                         );
                                                         setValidateStatusEmail(
                                                            "success"
                                                         );
                                                         setIsValidEmail(true);
                                                      })
                                                      .catch((error) => {
                                                         console.log(error);
                                                         setValidateStatusEmail(
                                                            "error"
                                                         );
                                                         setIsValidEmail(false);
                                                      });
                                                }
                                             }}
                                          />
                                       </Form.Item>
                                       <Row gutter={12}>
                                          <Col xs={24} sm={12}>
                                             <Form.Item
                                                rules={[
                                                   {
                                                      required: true
                                                   }
                                                ]}
                                                className="inline"
                                                label={I18n.get("First name")}
                                                name="given_name"
                                             >
                                                <Input
                                                   placeholder={I18n.get(
                                                      "First name"
                                                   )}
                                                />
                                             </Form.Item>
                                          </Col>
                                          <Col xs={24} sm={12}>
                                             <Form.Item
                                                rules={[
                                                   {
                                                      required: true,
                                                      message: t(
                                                         "demands.addDemand.minimum_amount_error"
                                                      )
                                                   }
                                                ]}
                                                className="inline"
                                                label={I18n.get("Last name")}
                                                name="family_name"
                                             >
                                                <Input
                                                   placeholder={I18n.get(
                                                      "Last name"
                                                   )}
                                                />
                                             </Form.Item>
                                          </Col>
                                       </Row>
                                       <h3>Materialbedarf</h3>
                                       <CategoriesTreeSearchField
                                          form={form}
                                          onChange={onCategorySelect}
                                          operationType={"ADD"}
                                          setInActive={setInActive}
                                       ></CategoriesTreeSearchField>
                                       {!!showPg && (
                                          <Form.Item
                                             label={t(
                                                "productDetails.productCategory.productGroup"
                                             )}
                                             name="product_group"
                                             rules={[
                                                {
                                                   required: true,
                                                   message: t(
                                                      "productDetails.productCategory.validations.productGroup"
                                                   )
                                                }
                                             ]}
                                          >
                                             <ProductGroupField
                                                onChange={onProductGroupSelect}
                                                categoryId={selectedCategoryId}
                                                reset={resetProduct}
                                                operationType={"ADD"}
                                                inActive={inActiveProductGroup}
                                             ></ProductGroupField>
                                          </Form.Item>
                                       )}
                                       {!!showForm && (
                                          <>
                                             <Row gutter={12}>
                                                <Col xs={24} sm={12}>
                                                   <Form.Item
                                                      rules={[
                                                         {
                                                            required: true,
                                                            message: t(
                                                               "demands.addDemand.minimum_amount_error"
                                                            )
                                                         }
                                                      ]}
                                                      className="inline"
                                                      label={t(
                                                         "demands.addDemand.minimum_amount_label"
                                                      )}
                                                      name="minimum_amount"
                                                   >
                                                      <Input
                                                         placeholder="1"
                                                         suffix={
                                                            <b>{productUnit}</b>
                                                         }
                                                      />
                                                   </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={12}>
                                                   <Form.Item
                                                      rules={[
                                                         {
                                                            required: true,
                                                            message: t(
                                                               "demands.addDemand.target_price_error"
                                                            )
                                                         }
                                                      ]}
                                                      className="inline"
                                                      label={t(
                                                         "demands.addDemand.target_price_label"
                                                      )}
                                                      name="target_price"
                                                   >
                                                      <Input
                                                         min="0"
                                                         type="number"
                                                         suffix={
                                                            <b>
                                                               €/{productUnit}
                                                            </b>
                                                         }
                                                      />
                                                   </Form.Item>
                                                </Col>
                                             </Row>

                                             <Collapse
                                                defaultActiveKey={[3]}
                                                ghost
                                             >
                                                <Panel
                                                   header={t(
                                                      "productDetails.productCategory.specification.manufacturerName"
                                                   )}
                                                   key="1"
                                                >
                                                   <Form.Item name="manufacturers">
                                                      <BrandMultiSelect></BrandMultiSelect>
                                                   </Form.Item>
                                                </Panel>
                                                <Panel
                                                   header={t(
                                                      "productDetails.productCategory.specification.measurements"
                                                   )}
                                                   key="2"
                                                >
                                                   <Form.Item
                                                      label={t(
                                                         "productDetails.productCategory.specification.width"
                                                      )}
                                                   >
                                                      <Input.Group compact>
                                                         <Form.Item
                                                            noStyle
                                                            rules={[
                                                               {
                                                                  required:
                                                                     false
                                                               },
                                                               {
                                                                  validator(
                                                                     _,
                                                                     value
                                                                  ) {
                                                                     if (
                                                                        value &&
                                                                        form.getFieldValue(
                                                                           "measurements"
                                                                        )?.width
                                                                           ?.maximum &&
                                                                        Number(
                                                                           value
                                                                        ) >
                                                                           Number(
                                                                              form.getFieldValue(
                                                                                 "measurements"
                                                                              )[
                                                                                 "width"
                                                                              ][
                                                                                 "maximum"
                                                                              ]
                                                                           )
                                                                     ) {
                                                                        return Promise.reject(
                                                                           "Please enter valid minimum value"
                                                                        );
                                                                     }
                                                                     return Promise.resolve();
                                                                  }
                                                               }
                                                            ]}
                                                            className="inline"
                                                            name={[
                                                               "measurements",
                                                               "width",
                                                               "minimum"
                                                            ]}
                                                         >
                                                            <Input
                                                               placeholder={t(
                                                                  "productDetails.productCategory.specification.from"
                                                               )}
                                                               style={{
                                                                  width: "50%",
                                                                  textAlign:
                                                                     "left"
                                                               }}
                                                               min="0"
                                                               type="number"
                                                               suffix={
                                                                  <b>mm</b>
                                                               }
                                                            />
                                                         </Form.Item>
                                                         <Form.Item
                                                            noStyle
                                                            rules={[
                                                               {
                                                                  required:
                                                                     false
                                                               },
                                                               {
                                                                  validator(
                                                                     _,
                                                                     value
                                                                  ) {
                                                                     if (
                                                                        value &&
                                                                        form.getFieldValue(
                                                                           "measurements"
                                                                        )?.width
                                                                           ?.minimum &&
                                                                        Number(
                                                                           value
                                                                        ) <
                                                                           Number(
                                                                              form.getFieldValue(
                                                                                 "measurements"
                                                                              )[
                                                                                 "width"
                                                                              ][
                                                                                 "minimum"
                                                                              ]
                                                                           )
                                                                     ) {
                                                                        return Promise.reject(
                                                                           "Please enter valid maximum value"
                                                                        );
                                                                     }
                                                                     return Promise.resolve();
                                                                  }
                                                               }
                                                            ]}
                                                            className="inline"
                                                            name={[
                                                               "measurements",
                                                               "width",
                                                               "maximum"
                                                            ]}
                                                         >
                                                            <Input
                                                               placeholder={t(
                                                                  "productDetails.productCategory.specification.to"
                                                               )}
                                                               style={{
                                                                  width: "50%",
                                                                  textAlign:
                                                                     "left"
                                                               }}
                                                               min={
                                                                  form.getFieldValue(
                                                                     "measurements"
                                                                  )?.width
                                                                     ?.minimum
                                                                     ? form.getFieldValue(
                                                                          "measurements"
                                                                       )[
                                                                          "width"
                                                                       ][
                                                                          "minimum"
                                                                       ]
                                                                     : 0
                                                               }
                                                               type="number"
                                                               suffix={
                                                                  <b>mm</b>
                                                               }
                                                            />
                                                         </Form.Item>
                                                      </Input.Group>
                                                   </Form.Item>

                                                   <Form.Item
                                                      label={t(
                                                         "productDetails.productCategory.specification.height"
                                                      )}
                                                   >
                                                      <Input.Group compact>
                                                         <Form.Item
                                                            noStyle
                                                            rules={[
                                                               {
                                                                  required:
                                                                     false
                                                               },
                                                               {
                                                                  validator(
                                                                     _,
                                                                     value
                                                                  ) {
                                                                     if (
                                                                        value &&
                                                                        form.getFieldValue(
                                                                           "measurements"
                                                                        )
                                                                           ?.height
                                                                           ?.maximum &&
                                                                        Number(
                                                                           value
                                                                        ) >
                                                                           Number(
                                                                              form.getFieldValue(
                                                                                 "measurements"
                                                                              )[
                                                                                 "height"
                                                                              ][
                                                                                 "maximum"
                                                                              ]
                                                                           )
                                                                     ) {
                                                                        return Promise.reject(
                                                                           "Please enter valid minimum value"
                                                                        );
                                                                     }
                                                                     return Promise.resolve();
                                                                  }
                                                               }
                                                            ]}
                                                            className="inline"
                                                            name={[
                                                               "measurements",
                                                               "height",
                                                               "minimum"
                                                            ]}
                                                         >
                                                            <Input
                                                               placeholder={t(
                                                                  "productDetails.productCategory.specification.from"
                                                               )}
                                                               style={{
                                                                  width: "50%",
                                                                  textAlign:
                                                                     "left"
                                                               }}
                                                               min="0"
                                                               type="number"
                                                               suffix={
                                                                  <b>mm</b>
                                                               }
                                                            />
                                                         </Form.Item>
                                                         <Form.Item
                                                            noStyle
                                                            rules={[
                                                               {
                                                                  required:
                                                                     false
                                                               },
                                                               {
                                                                  validator(
                                                                     _,
                                                                     value
                                                                  ) {
                                                                     if (
                                                                        value &&
                                                                        form.getFieldValue(
                                                                           "measurements"
                                                                        )
                                                                           ?.height
                                                                           ?.minimum &&
                                                                        Number(
                                                                           value
                                                                        ) <
                                                                           Number(
                                                                              form.getFieldValue(
                                                                                 "measurements"
                                                                              )[
                                                                                 "height"
                                                                              ][
                                                                                 "minimum"
                                                                              ]
                                                                           )
                                                                     ) {
                                                                        return Promise.reject(
                                                                           "Please enter valid maximum value"
                                                                        );
                                                                     }
                                                                     return Promise.resolve();
                                                                  }
                                                               }
                                                            ]}
                                                            className="inline"
                                                            name={[
                                                               "measurements",
                                                               "height",
                                                               "maximum"
                                                            ]}
                                                         >
                                                            <Input
                                                               placeholder={t(
                                                                  "productDetails.productCategory.specification.to"
                                                               )}
                                                               style={{
                                                                  width: "50%",
                                                                  textAlign:
                                                                     "left"
                                                               }}
                                                               min={
                                                                  form.getFieldValue(
                                                                     "measurements"
                                                                  )?.height
                                                                     ?.minimum
                                                                     ? form.getFieldValue(
                                                                          "measurements"
                                                                       )[
                                                                          "height"
                                                                       ][
                                                                          "minimum"
                                                                       ]
                                                                     : 0
                                                               }
                                                               type="number"
                                                               suffix={
                                                                  <b>mm</b>
                                                               }
                                                            />
                                                         </Form.Item>
                                                      </Input.Group>
                                                   </Form.Item>

                                                   <Form.Item
                                                      label={t(
                                                         "productDetails.productCategory.specification.depth"
                                                      )}
                                                   >
                                                      <Input.Group compact>
                                                         <Form.Item
                                                            noStyle
                                                            rules={[
                                                               {
                                                                  required:
                                                                     false
                                                               },
                                                               {
                                                                  validator(
                                                                     _,
                                                                     value
                                                                  ) {
                                                                     if (
                                                                        value &&
                                                                        form.getFieldValue(
                                                                           "measurements"
                                                                        )?.depth
                                                                           ?.maximum &&
                                                                        Number(
                                                                           value
                                                                        ) >
                                                                           Number(
                                                                              form.getFieldValue(
                                                                                 "measurements"
                                                                              )[
                                                                                 "depth"
                                                                              ][
                                                                                 "maximum"
                                                                              ]
                                                                           )
                                                                     ) {
                                                                        return Promise.reject(
                                                                           "Please enter valid minimum value"
                                                                        );
                                                                     }
                                                                     return Promise.resolve();
                                                                  }
                                                               }
                                                            ]}
                                                            className="inline"
                                                            name={[
                                                               "measurements",
                                                               "depth",
                                                               "minimum"
                                                            ]}
                                                         >
                                                            <Input
                                                               placeholder={t(
                                                                  "productDetails.productCategory.specification.from"
                                                               )}
                                                               style={{
                                                                  width: "50%",
                                                                  textAlign:
                                                                     "left"
                                                               }}
                                                               min="0"
                                                               type="number"
                                                               suffix={
                                                                  <b>mm</b>
                                                               }
                                                            />
                                                         </Form.Item>
                                                         <Form.Item
                                                            noStyle
                                                            rules={[
                                                               {
                                                                  required:
                                                                     false
                                                               },
                                                               {
                                                                  validator(
                                                                     _,
                                                                     value
                                                                  ) {
                                                                     if (
                                                                        value &&
                                                                        form.getFieldValue(
                                                                           "measurements"
                                                                        )?.depth
                                                                           ?.minimum &&
                                                                        Number(
                                                                           value
                                                                        ) <
                                                                           Number(
                                                                              form.getFieldValue(
                                                                                 "measurements"
                                                                              )[
                                                                                 "depth"
                                                                              ][
                                                                                 "minimum"
                                                                              ]
                                                                           )
                                                                     ) {
                                                                        return Promise.reject(
                                                                           "Please enter valid maximum value"
                                                                        );
                                                                     }
                                                                     return Promise.resolve();
                                                                  }
                                                               }
                                                            ]}
                                                            className="inline"
                                                            name={[
                                                               "measurements",
                                                               "depth",
                                                               "maximum"
                                                            ]}
                                                         >
                                                            <Input
                                                               placeholder={t(
                                                                  "productDetails.productCategory.specification.to"
                                                               )}
                                                               style={{
                                                                  width: "50%",
                                                                  textAlign:
                                                                     "left"
                                                               }}
                                                               min={
                                                                  form.getFieldValue(
                                                                     "measurements"
                                                                  )?.depth
                                                                     ?.minimum
                                                                     ? form.getFieldValue(
                                                                          "measurements"
                                                                       )[
                                                                          "depth"
                                                                       ][
                                                                          "minimum"
                                                                       ]
                                                                     : 0
                                                               }
                                                               type="number"
                                                               suffix={
                                                                  <b>mm</b>
                                                               }
                                                            />
                                                         </Form.Item>
                                                      </Input.Group>
                                                   </Form.Item>
                                                </Panel>

                                                {productGroup &&
                                                   lcaMetaMaterials &&
                                                   lcaMetaMaterials.length >
                                                      0 && (
                                                      <Panel
                                                         header="Material"
                                                         key="3"
                                                      >
                                                         <Form.Item
                                                            label={
                                                               productGroup?.lca_meta_label
                                                            }
                                                         >
                                                            <MaterialDropdown
                                                               name={[
                                                                  "label_material"
                                                               ]}
                                                               materials={
                                                                  lcaMetaMaterials
                                                               }
                                                               setMaterialGroup={() => {
                                                                  const fields =
                                                                     form.getFieldsValue();
                                                                  if (
                                                                     fields[
                                                                        "label_material"
                                                                     ]
                                                                        ?.sub_material_id
                                                                  ) {
                                                                     fields[
                                                                        "label_material"
                                                                     ].sub_material_id =
                                                                        undefined;
                                                                     form.setFieldsValue(
                                                                        fields
                                                                     );
                                                                  }
                                                               }}
                                                            />
                                                         </Form.Item>
                                                      </Panel>
                                                   )}
                                             </Collapse>

                                             <Form.Item>
                                                <strong>
                                                   {t(
                                                      "demands.addDemand.services.title"
                                                   )}
                                                </strong>
                                                <p>
                                                   {t(
                                                      "demands.addDemand.services.message"
                                                   )}
                                                </p>
                                             </Form.Item>
                                             <Form.Item
                                                className="checkboxgroup"
                                                name="services"
                                             >
                                                <Checkbox.Group
                                                   options={plainOptions}
                                                   onChange={
                                                      onChangeCheckboxGroup
                                                   }
                                                />
                                             </Form.Item>
                                             <Form.Item
                                                label={t(
                                                   "demands.addDemand.needed_until_label"
                                                )}
                                                name="delivery_date"
                                                rules={[
                                                   {
                                                      required: true,
                                                      message:
                                                         "Please enter date"
                                                   }
                                                ]}
                                             >
                                                <DatePicker
                                                   format={dateFormat}
                                                   placeholder={t(
                                                      "demands.addDemand.enterDate"
                                                   )}
                                                />
                                             </Form.Item>
                                             <Form.Item
                                                label={t(
                                                   "demands.addDemand.location"
                                                )}
                                                name={"location"}
                                                rules={[
                                                   {
                                                      required: true,
                                                      message: t(
                                                         "demands.addDemand.addLocation"
                                                      )
                                                   },
                                                   ({}) => ({
                                                      validator(_, value) {
                                                         if (
                                                            selectedLocation ===
                                                               undefined ||
                                                            selectedLocation ===
                                                               null
                                                         ) {
                                                            return Promise.reject(
                                                               new Error(
                                                                  t(
                                                                     "demands.addDemand.addValidLocation"
                                                                  )
                                                               )
                                                            );
                                                         }
                                                         if (value)
                                                            return Promise.resolve();
                                                      }
                                                   })
                                                ]}
                                             >
                                                <AutoComplete
                                                   open={locationDropDownOpen}
                                                   notFoundContent={
                                                      getPlaces ? (
                                                         <Spin />
                                                      ) : null
                                                   }
                                                   value={valueLocation}
                                                   options={locations}
                                                   onSelect={(value) => {
                                                      cancelRequestLocation();
                                                      setLocationDropDownOpen(
                                                         false
                                                      );
                                                      const selected =
                                                         locations.filter(
                                                            (rec: any) => {
                                                               return (
                                                                  rec.value ===
                                                                  value
                                                               );
                                                            }
                                                         );

                                                      const labelValue =
                                                         selected.pop().label;
                                                      setValueLocation(
                                                         labelValue
                                                      );
                                                      const temp =
                                                         value.split("_");
                                                      setIsNewLocation(
                                                         temp[0] === "new"
                                                      );
                                                      if (temp[0] === "new") {
                                                         setSelectedLocation(
                                                            locationAPI[temp[1]]
                                                         );
                                                      } else {
                                                         setSelectedLocation(
                                                            temp[1]
                                                         );
                                                      }

                                                      form.setFieldsValue({
                                                         location: labelValue
                                                      });
                                                   }}
                                                   onChange={(value) => {
                                                      setValueLocation(value);
                                                      console.log(
                                                         "value",
                                                         value
                                                      );
                                                      setLocationDropDownOpen(
                                                         true
                                                      );
                                                   }}
                                                   onSearch={async (
                                                      searchText: string
                                                   ) => {
                                                      setSelectedLocation(null);
                                                      cancelRequestLocation();
                                                      setLocationDropDownOpen(
                                                         true
                                                      );
                                                      if (
                                                         searchText.length === 0
                                                      ) {
                                                         setLocations(
                                                            locationsOrg
                                                         );
                                                      } else if (
                                                         searchText.length > 0
                                                      ) {
                                                         let temp =
                                                            locationsOrg.filter(
                                                               (rec: any) => {
                                                                  return (
                                                                     rec?.value &&
                                                                     rec?.label
                                                                        .toLowerCase()
                                                                        .includes(
                                                                           searchText.toLowerCase()
                                                                        )
                                                                  );
                                                               }
                                                            );
                                                         console.log(
                                                            temp,
                                                            locationsOrg
                                                         );
                                                         if (
                                                            temp.length === 0 &&
                                                            searchText.length >
                                                               10
                                                         ) {
                                                            await findPlace({
                                                               data: {
                                                                  address: `${searchText}`
                                                               }
                                                            });
                                                         }

                                                         if (
                                                            temp.length === 0 &&
                                                            dataPlaces?.data &&
                                                            dataPlaces?.data
                                                               ?.locations &&
                                                            dataPlaces?.data
                                                               ?.locations
                                                               .length > 0
                                                         ) {
                                                            const tempLocations =
                                                               dataPlaces?.data?.locations.map(
                                                                  (
                                                                     rec: any,
                                                                     key: any
                                                                  ) => {
                                                                     if (
                                                                        rec &&
                                                                        rec?.Label
                                                                     ) {
                                                                        return {
                                                                           value: `new_${key}`,
                                                                           label: rec?.Label
                                                                        };
                                                                     }
                                                                  }
                                                               );
                                                            console.log(
                                                               "tempLocations",
                                                               tempLocations
                                                            );
                                                            setLocationAPI(
                                                               dataPlaces?.data
                                                                  ?.locations
                                                            );
                                                            temp =
                                                               tempLocations;
                                                         }
                                                         setLocations(temp);
                                                         setLocationDropDownOpen(
                                                            true
                                                         );
                                                      }
                                                   }}
                                                   onFocus={() => {
                                                      setLocationDropDownOpen(
                                                         true
                                                      );
                                                   }}
                                                   placeholder={t(
                                                      "demands.addDemand.addLocation"
                                                   )}
                                                ></AutoComplete>
                                             </Form.Item>
                                             <Form.Item>
                                                <Button
                                                   type="primary"
                                                   shape="round"
                                                   size="large"
                                                   htmlType="submit"
                                                   onClick={onFinish}
                                                   loading={confirmLoading}
                                                >
                                                   Senden und kostenfrei
                                                   registrieren
                                                </Button>
                                             </Form.Item>
                                          </>
                                       )}
                                    </Form>
                                 </Spin>
                              )}
                              {!showSignUp && (
                                 <>
                                    <Form
                                       layout="vertical"
                                       form={checkUserForm}
                                       onFinish={async () => {
                                          checkUserForm
                                             .validateFields()
                                             .then(async () => {
                                                const { email } =
                                                   checkUserForm.getFieldsValue();
                                                await checkUserExists({
                                                   data: {
                                                      email
                                                   }
                                                })
                                                   .then((response) => {
                                                      if (
                                                         !!response?.data?.data
                                                            ?.isNewUser
                                                      ) {
                                                         setShowSignUp(true);
                                                         form.setFieldsValue({
                                                            email
                                                         });
                                                      } else if (
                                                         !!!response?.data?.data
                                                            ?.isNewUser &&
                                                         response?.data?.data
                                                            ?.user
                                                      ) {
                                                         history.push(
                                                            `/demand/add`
                                                         );
                                                      }
                                                   })
                                                   .catch((error) => {
                                                      console.log(error);
                                                   });
                                             })
                                             .catch((error) => {});
                                       }}
                                    >
                                       <Form.Item
                                          rules={[
                                             {
                                                type: "email",
                                                required: true,
                                                message:
                                                   I18n.get(
                                                      "Please enter email"
                                                   )
                                             }
                                          ]}
                                          className="inline"
                                          label={t(
                                             "demands.addDemand.yourMail"
                                          )}
                                          name="email"
                                       >
                                          <Input
                                             placeholder={t(
                                                "demands.addDemand.yourMail"
                                             )}
                                          />
                                       </Form.Item>
                                       <Form.Item>
                                          <Button
                                             type="primary"
                                             htmlType="submit"
                                             loading={validateUserLoading}
                                          >
                                             {t("default.continue")}
                                          </Button>
                                       </Form.Item>
                                    </Form>
                                 </>
                              )}
                           </div>
                        </div>
                     </WelcomePage>
                  );
               }}
            />
         </Switch>
      </div>
   );
};
