import { Form, Select } from "antd";
import React, { ReactNode } from "react";
import "./style.scss";

interface IProps {
   items: {
      id: string | number;
      label?: string | ReactNode;
      name?: string | number;
      value?: string | number;
      disabled?: boolean;
   }[];
   selected?: { id: string | number; label?: any; value?: string | number };
   onChange?: (id: any, value?: string) => any;
   label?: string;
   required?: boolean;
   size?: "small" | "middle" | "large";
   fieldName?: string;
   placeholder?: string;
   noMargin?: boolean;
   className?: string;
   fieldErrorMessage?: string;
   loading?: boolean;
   id?: string;
   formItem?: boolean;
   disabled?: boolean;
   style?: any;
   showSearch?: boolean;
}

const { Option } = Select;

const DropdownField = ({
   items,
   selected,
   onChange,
   label,
   required,
   size = "middle",
   fieldName = "",
   placeholder = "-",
   noMargin,
   className,
   fieldErrorMessage,
   loading = false,
   id,
   formItem = true,
   disabled,
   style,
   showSearch = true
}: IProps) => {
   const handleOnChange = (e: any) => {
      onChange &&
         onChange(e, items.find((item) => item.id === e)?.value?.toString());
   };

   return (
      <div className={`${"dropdownField"} ${className}`} style={style}>
         {label && <div className={"label"}>{label}</div>}
         {formItem ? (
            <Form.Item
               wrapperCol={{ span: 24 }}
               name={[fieldName]}
               rules={[{ required: required, message: fieldErrorMessage }]}
               style={noMargin ? { marginBottom: 0 } : {}}
            >
               {selectObject()}
            </Form.Item>
         ) : (
            selectObject()
         )}
      </div>
   );

   function selectObject() {
      return (
         <Select
            showSearch={showSearch}
            style={{ width: "100%" }}
            placeholder={placeholder}
            optionFilterProp="children"
            filterOption={(input, option: any) =>
               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size={size}
            defaultValue={selected?.id}
            value={selected?.id}
            className={size}
            onChange={handleOnChange}
            loading={loading}
            id={id}
            disabled={disabled}
         >
            {items?.length &&
               items.map((item) => {
                  return (
                     <Option
                        key={item.id}
                        value={item.id}
                        disabled={item?.disabled}
                     >
                        {item.label ? item.label : item.name}
                     </Option>
                  );
               })}
         </Select>
      );
   }
};

export default DropdownField;
