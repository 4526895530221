import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PageHeader, Tag, Image, Button } from "antd";
import { PassportList } from "./PassportList";
import { useTranslation } from "react-i18next";
import { getImagePath } from "../../../utils/getImagePath";
//import { Button } from "antd/lib/radio";
import { useMaterialWizard } from "../../../contexts/materialWizard";
import moment from "moment";
import { useParams, useHistory } from "react-router";
import useAxios from "axios-hooks";
import { Resource } from "../../../api/common";
import { LcaPotential } from "../../../components/LcaPotential";
import { Unit } from "../../../types";

export const getDocumentsList = (documents: any[]) => {
   return documents.map((item: any, index) => {
      return (
         <div key={index}>
            <a
               href={item.documents_files?.object_url}
               target="_blank"
               rel="noreferrer"
            >
               {item.document_type} ({index + 1})
            </a>
         </div>
      );
   });
};

export const getMaterialReuseForTag = (tags: any[]) => {
   return tags.map((tag: any) => {
      return <Tag>{tag}</Tag>;
   });
};

type Passport = {
   amount: number;
   amount_unit: Unit;
   condition: string;
   deconstructability: string;
   on_sale_count: number;
   passport_images: Array<{
      id: number;
      uid: string;
      bucket_name: string;
      file_name: string;
      file_path: string;
      object_url: string;
      url: string;
   }>;
   room: Array<string>;
};

export const MaterialDetails = () => {
   const { state }: any = useLocation();
   const { material: _material, showAddPassport } = state || {
      _material: null,
      showAddPassport: false
   };
   const [material, setMaterial] = useState(_material);
   const [t] = useTranslation();
   const [materialImages, setMaterialImages] = useState<any>();
   const [passports, setPassportList] = useState<Passport[]>([]);
   const {
      updateProductDetailsWizardDetails,
      updateReuseWizardDetails,
      updatePassportTypeDetails,
      updatePassportWizardDetails
   } = useMaterialWizard();
   const history = useHistory();
   const params: any = useParams();
   const [productGroupDetails, setProductGroupDetails] = useState<any>();

   // const [
   //    { data: materialData, loading: materialLoading }] = useAxios(
   //    {
   //       url: Resource.path.getMaterialDetailsById(params.material_id),
   //       method: "GET"
   //    },
   //    { manual: false }
   // );

   // useEffect(() => {
   //    fetchMaterialDetail();
   // }, []);

   // useEffect(() => {
   //    setMaterial(materialData);
   // }, [materialData]);

   const [{ data: productGroupData, loading: productGroupLoading }] = useAxios(
      {
         url: `${Resource.path.getProductGroupDetails}/${material?.product_group_id}`,
         method: "GET"
      },
      { manual: false }
   );

   const [
      { data, loading: getProductDetailsLoading, error },
      fetchProductGroupDetails
   ] = useAxios(
      {
         url: `${Resource.path.getMaterials}/${params?.material_id}`
      },
      { useCache: false, manual: false }
   );

   React.useEffect(() => {
      if (data) {
         setMaterial(data?.data);
      }
      if (productGroupData?.data) {
         setProductGroupDetails(productGroupData?.data);
      }
   }, [data, productGroupData]);

   let materialGroupSpecification;

   if (material?.specification_group) {
      materialGroupSpecification = material.specification_group
         .map((item: any, index: number) => {
            if (!item) return null;
            const keys = Object.keys(item);
            return (
               <>
                  {keys.map((key: any, index: number) => {
                     const keyValue = item[key];

                     if (typeof keyValue === "object" && keyValue !== null) {
                        if ("unit" in keyValue && "unitValue" in keyValue) {
                           return (
                              <dl key={index}>
                                 <dt>{key}</dt>
                                 <dd>
                                    {keyValue["unitValue"]} {keyValue["unit"]}
                                 </dd>
                              </dl>
                           );
                        }
                     } else {
                        // test if color
                        var ddClass;
                        if (/^#([0-9A-F]{3}){1,2}$/i.test(item[key])) {
                           ddClass = "color";
                           return (
                              <>
                                 <dl key={index}>
                                    <dt>{t(`${key}`)}</dt>
                                    <dd>
                                       <div
                                          className={ddClass}
                                          style={{
                                             ["background" as any]: item[key],
                                             display: "inline",
                                             padding: "0px 30px 0px",
                                             border: "1px solid #d9d9d9"
                                          }}
                                       ></div>{" "}
                                       <span style={{ display: "inline" }}>
                                          &nbsp;
                                          {item.isRalColorMatched
                                             ? t(
                                                  `colors:${item[
                                                     key
                                                  ].toUpperCase()}`
                                               )
                                             : t(
                                                  `colors:${item[
                                                     key
                                                  ].toUpperCase()}`
                                               ).substr(
                                                  0,
                                                  t(
                                                     `colors:${item[
                                                        key
                                                     ].toUpperCase()}`
                                                  ).indexOf("(")
                                               )}
                                       </span>
                                    </dd>
                                 </dl>
                              </>
                           );
                        } else if (key !== "isRalColorMatched") {
                           return (
                              <dl key={index}>
                                 <dt>{key}</dt>
                                 <dd>{item[key]}</dd>
                              </dl>
                           );
                        }
                     }
                  })}
               </>
            );
         })
         .filter(Boolean);
   }

   const setPassportImageList = (images: any) => {
      const materialImages = images
         ?.map((item: any, index: number) => {
            return (
               item?.object_url && (
                  <Image src={getImagePath(item)} key={index} />
               )
            );
         })
         .filter(Boolean);
      materialImages && setMaterialImages(materialImages);
   };

   const getPreviewCompatibleDocuments = (documents: any) => {
      if (documents && documents.length) {
         const documentsList = documents.map((document: any, index: number) => {
            if (document?.documents_files) {
               return {
                  ...document,
                  documents_files: {
                     ...document.documents_files,
                     id: index,
                     url: document.documents_files.object_url
                  }
               };
            } else {
               return document;
            }
         });

         return documentsList;
      } else {
         return [];
      }
   };

   const getFormaCompatibleSpecification = (specification: any) => {
      if (specification && Object.keys(specification)) {
         const normalizedSpecification: any = {};
         Object.keys(specification).forEach((key) => {
            const value = specification[key];
            if (
               typeof value === "string" &&
               value.length > 9 &&
               moment(
                  moment(value).format("YYYY-MM-DD"),
                  "YYYY-MM-DD",
                  true
               ).isValid()
            ) {
               normalizedSpecification[key] = moment(specification[key]);
            } else {
               normalizedSpecification[key] = specification[key];
            }
         });

         return normalizedSpecification;
      }

      return undefined;
   };

   const navigateBack = () => {
      history.push(`/buildings/${params?.id}/material_inventory`);
   };

   useEffect(() => {
      if (material) {
         updateProductDetailsWizardDetails({
            amount: material?.amount,
            building_location_of: material?.building_location_of,
            category: {
               id: material?.category_id,
               name: material?.category_name
            },
            categories: material?.categories,
            documents: getPreviewCompatibleDocuments(material?.documents) || [],
            ean: material?.ean,
            manufacturer: material?.manufacturer,
            material_current_location: material?.material_current_location,
            materials: material?.materials,
            name: material?.product_name,
            original_price: material?.original_price,
            product_group: material?.product_group,
            product_unit: material?.product_unit,
            specification: getFormaCompatibleSpecification(
               material?.specification
            ),
            weight_per_unit: material?.weight_per_unit
         });

         material?.material_passport_type &&
            updatePassportTypeDetails(material?.material_passport_type);

         updateReuseWizardDetails({
            need_for_reuse: material?.need_for_reuse,
            notes: material?.notes,
            used_as: material?.used_as
         });
         if (material?.images) {
            setPassportImageList(material.images);
         }
      }
   }, [material]);

   useEffect(() => {
      if (passports && passports.length) {
         if (material?.material_passport_type === "WITH_SAME_CONDITION") {
            const [passport] = passports;
            updatePassportWizardDetails({
               ...passport
            });
         } else {
            updatePassportWizardDetails({
               passports: passports
            });
         }
      }
   }, [passports]);

   const [totalAmount, setTotalAmount] = React.useState<number>(0);

   React.useEffect(() => {
      if (passports.length > 0) {
         setTotalAmount(
            passports.reduce((previous, current) => {
               return previous + current.amount;
            }, 0)
         );
      }
   }, [passports]);

   if (productGroupLoading || getProductDetailsLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <>
         <div className="content-wrap material-overview-wrapper">
            <PageHeader title={t("common.back")} onBack={navigateBack}>
               <div className="page-title">
                  <h1>
                     {material?.manufacturer} {material?.product_name}
                  </h1>
                  <span>{material?.product_group_name}</span>
               </div>

               <div className="manage-menu">
                  <Link
                     to={`/buildings/${params?.id}/material_inventory/edit/${params?.material_id}/product_details`}
                  >
                     <Button shape="round">{t("default.edit")}</Button>
                  </Link>
               </div>
            </PageHeader>

            {materialImages && (
               <div
                  className={
                     materialImages.length > 1
                        ? "image-scroll"
                        : "image-scroll single"
                  }
               >
                  {materialImages}
               </div>
            )}
            <div className="page-content">
               <div className="grid col-2">
                  <div className="grid-item">
                     {material?.material_current_location && (
                        <address>{material.material_current_location}</address>
                     )}
                  </div>
                  <div className="grid-item">
                     {material?.notes && (
                        <div className="description">{material?.notes}</div>
                     )}
                  </div>
                  {productGroupDetails && (
                     <div className="grid-item">
                        <LcaPotential
                           amount={totalAmount}
                           productGroup={productGroupDetails}
                           selectedMaterial={
                              data?.data?.selected_material?.sub_material_id
                           }
                           passport={data?.data}
                        />
                     </div>
                  )}
               </div>
            </div>
            <div className="separated"></div>
            {material && (
               <PassportList
                  material={material}
                  //setPassportImageList={setPassportImageList}
                  setPassportList={setPassportList}
                  showAddPassport={showAddPassport}
                  fetch={fetchProductGroupDetails}
               ></PassportList>
            )}

            <div className="separated"></div>
            <div className="page-content">
               <div className="grid col-4">
                  {material?.manufacturer && (
                     <div className="grid-item widget data">
                        <span>{t("materialDashboard.manufacturer")}</span>
                        <strong>{material.manufacturer}</strong>
                     </div>
                  )}
                  {material?.ean && (
                     <div className="grid-item widget data">
                        <span>{t("materialDashboard.ean")}</span>
                        <strong>{material.ean}</strong>
                     </div>
                  )}

                  {material?.yearOfManufacturing && (
                     <div className="grid-item widget data">
                        <span>
                           {t("materialDashboard.yearOfManufacturing")}
                        </span>
                        <strong>{material.yearOfManufacturing}</strong>n
                     </div>
                  )}
               </div>

               <div className="grid material-specifications">
                  <div className="specs widget">
                     <h4>{t("materialDashboard.specifications")}</h4>
                     {material?.weight_per_unit && (
                        <dl>
                           <dt>{t("materialDashboard.weight")}</dt>
                           <dd>{material.weight_per_unit} kg</dd>
                        </dl>
                     )}
                     {materialGroupSpecification}
                     {material?.materials && (
                        <h4>{t("productDetails.material.form.materials")}</h4>
                     )}
                     {material?.materials && (
                        <dl className="material">
                           {material?.materials.map((item: any) => {
                              return (
                                 <>
                                    <dt>{item.material_type}</dt>
                                    {item?.percentage && (
                                       <dd>
                                          {item?.material_sub_type && (
                                             <span>
                                                {item.material_sub_type}:{" "}
                                             </span>
                                          )}
                                          <span>{item?.percentage}%</span>
                                       </dd>
                                    )}
                                 </>
                              );
                           })}
                        </dl>
                     )}
                  </div>
                  <div className="description">
                     {material?.need_for_reuse && (
                        <div className="widget">
                           <h4>
                              {t("reusePotentialSteps.form.neededForReuse")}
                           </h4>
                           {getMaterialReuseForTag(material?.need_for_reuse)}
                        </div>
                     )}

                     {material?.used_as && (
                        <div className="widget">
                           <h4>
                              {t("reusePotentialSteps.form.couldBeUsedAs")}
                           </h4>
                           {material?.used_as.map((item: any) => {
                              return <Tag>{item}</Tag>;
                           })}
                        </div>
                     )}

                     {material?.documents && (
                        <div className="widget">
                           <h4>{t("materialDashboard.documents")}</h4>
                           {getDocumentsList(material.documents)}
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};
