// See official Amplify UI labels: https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui-components/src/common/Translations.ts
export const authLocale = {
   en: {
      BACK_TO_SIGN_IN: "Back to Sign In",
      CHANGE_PASSWORD_ACTION: "Change",
      CHANGE_PASSWORD: "Change Password",
      CODE_LABEL: "Verification code",
      CODE_PLACEHOLDER: "Enter code",
      CONFIRM_SIGN_UP_CODE_LABEL: "Confirmation Code",
      CONFIRM_SIGN_UP_CODE_PLACEHOLDER: "Enter your code",
      CONFIRM_SIGN_UP_HEADER_TEXT: "Confirm Sign up",
      CONFIRM_SIGN_UP_LOST_CODE: "Lost your code?",
      CONFIRM_SIGN_UP_RESEND_CODE: "Resend Code",
      CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT: "Confirm",
      CONFIRM_SMS_CODE: "Confirm SMS Code",
      CONFIRM_TOTP_CODE: "Confirm TOTP Code",
      CONFIRM: "Confirm",
      CREATE_ACCOUNT_TEXT: "Create account",
      EMAIL_LABEL: "Email Address *",
      EMAIL_PLACEHOLDER: "Enter your email address",
      FORGOT_PASSWORD_TEXT: "Forgot your password?",
      LESS_THAN_TWO_MFA_VALUES_MESSAGE: "Less than two mfa types available",
      NEW_PASSWORD_LABEL: "New password",
      NEW_PASSWORD_PLACEHOLDER: "Enter your new password",
      NO_ACCOUNT_TEXT: "No account?",
      PASSWORD_LABEL: "Password *",
      PASSWORD_PLACEHOLDER: "Enter your password",
      PHONE_LABEL: "Phone Number *",
      PHONE_PLACEHOLDER: "(555) 555-1212",
      QR_CODE_ALT: "qrcode",
      RESET_PASSWORD_TEXT: "Reset password",
      RESET_YOUR_PASSWORD: "Reset your password",
      SELECT_MFA_TYPE_HEADER_TEXT: "Select MFA Type",
      SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT: "Verify",
      SEND_CODE: "Send Code",
      SUBMIT: "Submit",
      SETUP_TOTP_REQUIRED: "TOTP needs to be configured",
      SIGN_IN_ACTION: "Sign In",
      SIGN_IN_HEADER_TEXT: "Sign in to your account",
      SIGN_IN_TEXT: "Sign in",
      SIGN_IN_WITH_AMAZON: "Sign in with Amazon",
      SIGN_IN_WITH_AUTH0: "Sign in with Auth0",
      SIGN_IN_WITH_AWS: "Sign in with AWS",
      SIGN_IN_WITH_FACEBOOK: "Sign in with Facebook",
      SIGN_IN_WITH_GOOGLE: "Sign in with Google",
      SIGN_OUT: "Sign Out",
      SIGN_UP_EMAIL_PLACEHOLDER: "Email",
      SIGN_UP_HAVE_ACCOUNT_TEXT: "Have an account?",
      SIGN_UP_HEADER_TEXT: "Create a new account",
      SIGN_UP_PASSWORD_PLACEHOLDER: "Password",
      SIGN_UP_SUBMIT_BUTTON_TEXT: "Create Account",
      SIGN_UP_USERNAME_PLACEHOLDER: "Username",
      SUCCESS_MFA_TYPE: "Success! Your MFA Type is now:",
      TOTP_HEADER_TEXT: "Scan then enter verification code",
      TOTP_LABEL: "Enter Security Code:",
      TOTP_ISSUER: "AWSCognito",
      TOTP_SETUP_FAILURE: "TOTP Setup has failed",
      TOTP_SUBMIT_BUTTON_TEXT: "Verify Security Token",
      TOTP_SUCCESS_MESSAGE: "Setup TOTP successfully!",
      UNABLE_TO_SETUP_MFA_AT_THIS_TIME:
         "Failed! Unable to configure MFA at this time",
      USERNAME_LABEL: "Username *",
      USERNAME_PLACEHOLDER: "Enter your username",
      VERIFY_CONTACT_EMAIL_LABEL: "Email",
      VERIFY_CONTACT_HEADER_TEXT:
         "Account recovery requires verified contact information",
      VERIFY_CONTACT_PHONE_LABEL: "Phone Number",
      VERIFY_CONTACT_SUBMIT_LABEL: "Submit",
      VERIFY_CONTACT_VERIFY_LABEL: "Verify",
      ADDRESS_LABEL: "Address",
      ADDRESS_PLACEHOLDER: "Enter your address",
      NICKNAME_LABEL: "Nickname",
      NICKNAME_PLACEHOLDER: "Enter your nickname",
      BIRTHDATE_LABEL: "Birthday",
      BIRTHDATE_PLACEHOLDER: "Enter your birthday",
      PICTURE_LABEL: "Picture URL",
      PICTURE_PLACEHOLDER: "Enter your picture URL",
      FAMILY_NAME_LABEL: "Family Name",
      FAMILY_NAME_PLACEHOLDER: "Enter your family name",
      PREFERRED_USERNAME_LABEL: "Preferred Username",
      PREFERRED_USERNAME_PLACEHOLDER: "Enter your preferred username",
      GENDER_LABEL: "Gender",
      GENDER_PLACEHOLDER: "Enter your gender",
      PROFILE_LABEL: "Profile URL",
      PROFILE_PLACEHOLDER: "Enter your profile URL",
      "First name": "First Name",
      GIVEN_NAME_PLACEHOLDER: "Enter your first name",
      ZONEINFO_LABEL: "Time zone",
      ZONEINFO_PLACEHOLDER: "Enter your time zone",
      LOCALE_LABEL: "Locale",
      LOCALE_PLACEHOLDER: "Enter your locale",
      UPDATED_AT_LABEL: "Updated At",
      UPDATED_AT_PLACEHOLDER: "Enter the time the information was last updated",
      MIDDLE_NAME_LABEL: "Middle Name",
      MIDDLE_NAME_PLACEHOLDER: "Enter your middle name",
      WEBSITE_LABEL: "Website",
      WEBSITE_PLACEHOLDER: "Enter your website",
      NAME_LABEL: "Full Name",
      NAME_PLACEHOLDER: "Enter your full name",
      PHOTO_PICKER_TITLE: "Picker Title",
      PHOTO_PICKER_HINT:
         "Ancilliary text or content may occupy this space here",
      PHOTO_PICKER_PLACEHOLDER_HINT: "Placeholder hint",
      PHOTO_PICKER_BUTTON_TEXT: "Button",
      IMAGE_PICKER_TITLE: "Add Profile Photo",
      IMAGE_PICKER_HINT: "Preview the image before upload",
      IMAGE_PICKER_PLACEHOLDER_HINT: "Tap to image select",
      IMAGE_PICKER_BUTTON_TEXT: "Upload",
      PICKER_TEXT: "Pick a file",
      TEXT_FALLBACK_CONTENT: "Fallback Content",
      CONFIRM_SIGN_UP_FAILED: "Confirm Sign Up Failed",
      SIGN_UP_FAILED: "Sign Up Failed",
      "Last name": "Last name",
      LAST_NAME_PLACEHOLDER: "Enter your last name",
      "No account?": "No account?",
      "Username cannot be empty": "Username cannot be empty",
      "User does not exist.": "User does not exist.",
      "Have an account?": "Have an account?",
      "Forgot your password?": "Forgot your password?",
      "Back to Sign In": "Back to Sign In",
      "Reset password": "Reset password",
      "Sign in": "Sign in",
      "Reset your password": "Reset your password",
      "Email Address *": "Email Address *",
      "Username/client id combination not found.":
         "Username/client id combination not found.",
      "Enter your email address": "Enter your email address",
      "Send Code": "Send Code",
      "Create Account": "Create Account",
      "An account with the given email already exists.":
         "An account with the given email already exists.",
      "Confirmation Code": "Confirmation Code",
      "Enter your code": "Enter your code",
      "Lost your code?": "Lost your code?",
      "Resend Code": "Resend Code",
      Confirm: "Confirm",
      "Confirmation code cannot be empty": "Confirmation code cannot be empty",
      Username: "Email"
   },
   de: {
      "Create Account": "Konto erstellen",
      "Confirm Password": "Passwort Bestätigen",
      "Sign in": "Einloggen",
      Password: "Passwort",
      BACK_TO_SIGN_IN: "Zurück zur Anmeldung",
      CHANGE_PASSWORD_ACTION: "Ändern",
      "Change Password": "Passwort ändern",
      CODE_LABEL: "Verifizierungscode",
      CODE_PLACEHOLDER: "Code eingeben",
      CONFIRM_SIGN_UP_CODE_LABEL: "Bestätigungscode",
      CONFIRM_SIGN_UP_CODE_PLACEHOLDER: "Code eingeben",
      CONFIRM_SIGN_UP_HEADER_TEXT: "Registrierung bestätigen",
      CONFIRM_SIGN_UP_LOST_CODE: "Code vergessen?",
      CONFIRM_SIGN_UP_RESEND_CODE: "Code nochmal senden",
      CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT: "Bestätigen",
      CONFIRM_SMS_CODE: "SMS Code bestätigen",
      CONFIRM_TOTP_CODE: "TOTP Code bestätigen",
      CONFIRM: "Bestätigen",
      CREATE_ACCOUNT_TEXT: "Konto anlegen",
      EMAIL_LABEL: "E-Mail-Adresse *",
      EMAIL_PLACEHOLDER: "Geben Sie Ihre E-Mail-Adresse ein",
      FORGOT_PASSWORD_TEXT: "Passwort vergessen?",
      LESS_THAN_TWO_MFA_VALUES_MESSAGE: "Less than two mfa types available",
      NEW_PASSWORD_LABEL: "Neues Passwort",
      NEW_PASSWORD_PLACEHOLDER: "Neues Passwort eingeben",
      NO_ACCOUNT_TEXT: "Kein Konto?",
      PASSWORD_LABEL: "Passwort *",
      PASSWORD_PLACEHOLDER: "Geben Sie Ihr Passwort ein",
      PHONE_LABEL: "Telefonnummer *",
      PHONE_PLACEHOLDER: "01234-123 123 123",
      QR_CODE_ALT: "qrcode",
      RESET_PASSWORD_TEXT: "Passwort zurücksetzen",
      RESET_YOUR_PASSWORD: "Ihr Passwort zurücksetzen",
      SELECT_MFA_TYPE_HEADER_TEXT: "Select MFA Type",
      SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT: "Verifizieren",
      SEND_CODE: "Code senden",
      Submit: "Bestätigen",
      SETUP_TOTP_REQUIRED: "TOTP needs to be configured",
      SIGN_IN_ACTION: "Eintragen",
      SIGN_IN_HEADER_TEXT: "Bei Ihrem Konto anmelden",
      SIGN_IN_TEXT: "Eintragen",
      SIGN_IN_WITH_AMAZON: "Sign in with Amazon",
      SIGN_IN_WITH_AUTH0: "Sign in with Auth0",
      SIGN_IN_WITH_AWS: "Sign in with AWS",
      SIGN_IN_WITH_FACEBOOK: "Sign in with Facebook",
      SIGN_IN_WITH_GOOGLE: "Sign in with Google",
      SIGN_OUT: "Abmelden",
      SIGN_UP_EMAIL_PLACEHOLDER: "Email",
      SIGN_UP_HAVE_ACCOUNT_TEXT: "Haben Sie ein Konto?",
      SIGN_UP_HEADER_TEXT: "Neues Konto erstellen",
      SIGN_UP_PASSWORD_PLACEHOLDER: "Passwort",
      SIGN_UP_SUBMIT_BUTTON_TEXT: "Konto erstellen",
      SIGN_UP_USERNAME_PLACEHOLDER: "Benutzername",
      SUCCESS_MFA_TYPE: "Success! Your MFA Type is now:",
      TOTP_HEADER_TEXT: "Scan then enter verification code",
      TOTP_LABEL: "Enter Security Code:",
      TOTP_ISSUER: "AWSCognito",
      TOTP_SETUP_FAILURE: "TOTP Setup has failed",
      TOTP_SUBMIT_BUTTON_TEXT: "Verify Security Token",
      TOTP_SUCCESS_MESSAGE: "Setup TOTP successfully!",
      UNABLE_TO_SETUP_MFA_AT_THIS_TIME:
         "Failed! Unable to configure MFA at this time",
      USERNAME_LABEL: "Username *",
      USERNAME_PLACEHOLDER: "Enter your username",
      VERIFY_CONTACT_EMAIL_LABEL: "Email",
      VERIFY_CONTACT_HEADER_TEXT:
         "Account recovery requires verified contact information",
      VERIFY_CONTACT_PHONE_LABEL: "Phone Number",
      VERIFY_CONTACT_SUBMIT_LABEL: "Submit",
      VERIFY_CONTACT_VERIFY_LABEL: "Verify",
      ADDRESS_LABEL: "Address",
      ADDRESS_PLACEHOLDER: "Enter your address",
      NICKNAME_LABEL: "Nickname",
      NICKNAME_PLACEHOLDER: "Enter your nickname",
      BIRTHDATE_LABEL: "Birthday",
      BIRTHDATE_PLACEHOLDER: "Enter your birthday",
      PICTURE_LABEL: "Picture URL",
      PICTURE_PLACEHOLDER: "Enter your picture URL",
      FAMILY_NAME_LABEL: "Nachname",
      FAMILY_NAME_PLACEHOLDER: "Geben Sie Ihren Nachnamen ein",
      PREFERRED_USERNAME_LABEL: "Preferred Username",
      PREFERRED_USERNAME_PLACEHOLDER: "Enter your preferred username",
      GENDER_LABEL: "Gender",
      GENDER_PLACEHOLDER: "Enter your gender",
      PROFILE_LABEL: "Profile URL",
      PROFILE_PLACEHOLDER: "Enter your profile URL",
      "First name": "Vorname",
      GIVEN_NAME_PLACEHOLDER: "Geben Sie Ihren Vornamen ein",
      ZONEINFO_LABEL: "Time zone",
      ZONEINFO_PLACEHOLDER: "Enter your time zone",
      LOCALE_LABEL: "Locale",
      LOCALE_PLACEHOLDER: "Enter your locale",
      UPDATED_AT_LABEL: "Updated At",
      UPDATED_AT_PLACEHOLDER: "Enter the time the information was last updated",
      MIDDLE_NAME_LABEL: "Middle Name",
      MIDDLE_NAME_PLACEHOLDER: "Enter your middle name",
      WEBSITE_LABEL: "Website",
      WEBSITE_PLACEHOLDER: "Enter your website",
      NAME_LABEL: "Full Name",
      NAME_PLACEHOLDER: "Enter your full name",
      PHOTO_PICKER_TITLE: "Picker Title",
      PHOTO_PICKER_HINT:
         "Ancilliary text or content may occupy this space here",
      PHOTO_PICKER_PLACEHOLDER_HINT: "Placeholder hint",
      PHOTO_PICKER_BUTTON_TEXT: "Button",
      IMAGE_PICKER_TITLE: "Add Profile Photo",
      IMAGE_PICKER_HINT: "Preview the image before upload",
      IMAGE_PICKER_PLACEHOLDER_HINT: "Tap to image select",
      IMAGE_PICKER_BUTTON_TEXT: "Upload",
      PICKER_TEXT: "Pick a file",
      TEXT_FALLBACK_CONTENT: "Fallback Content",
      CONFIRM_SIGN_UP_FAILED: "Confirm Sign Up Failed",
      SIGN_UP_FAILED: "Registrierung fehlgeschlagen",
      "Last name": "Nachname",
      LAST_NAME_PLACEHOLDER: "Geben Sie Ihren Nachnamen ein",
      "Back to Sign In": "Zurück zur Anmeldung",
      "Forgot your password? ": "Passwort vergessen?",
      "Reset password": "Passwort zurücksetzen",
      "No account?": "Kein Konto?",
      "Username cannot be empty": "Der Benutzername darf nicht leer sein",
      "User does not exist.": "Der Benutzer existiert nicht.",
      "Have an account?": "Haben Sie ein Konto?",
      "Sign In": "Einloggen",
      "Reset your password": "Ihr Passwort zurücksetzen",
      "Email Address *": "E-Mail-Adresse *",
      "Username/client id combination not found.":
         "Benutzername/Passwort-Kombination nicht gefunden.",
      "Enter your email": "Geben Sie Ihre E-Mail-Adresse ein",
      "Send code": "Code senden",
      "Create account": "Konto erstellen",
      "An account with the given email already exists.":
         "Ein Konto mit dieser E-Mail existriert bereits.",
      "Confirmation code": "Bestätigungscode",
      "Enter your code": "Code eingeben",
      "Lost your code?": "Code vergessen?",
      "Resend code": "Code nochmal senden",
      Confirm: "Bestätigen",
      "Confirmation code cannot be empty": "Bestätigungscode fehlt",
      Username: "Email"
   }
};
