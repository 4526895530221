import React from "react";

const ProgressBar = (props: {
   bgcolor1: string;
   bgcolor2?: string;
   bgcolor3?: string;
   segment1: number;
   segment2?: number;
   segment3?: number;
}) => {
   const { bgcolor1, bgcolor2, bgcolor3, segment1, segment2, segment3 } = props;

   const containerStyles = {
      height: 10,
      width: "100%",
      backgroundColor: "#e8e8e8",
      borderRadius: 50,
      position: "relative"
   };

   const segmentStyles = {
      height: "100%",
      borderRadius: "inherit",
      textAlign: "right",
      transition: "width 1s ease-in-out",
      position: "absolute"
   };
   const segmentStyles1 = {
      ...segmentStyles,
      width: `${Math.round(segment1)}%`,
      backgroundColor: bgcolor1,
      zIndex: 3
   };
   const segmentStyles2 = {
      ...segmentStyles,
      width: `${Math.round(segment2 || 0)}%`,
      backgroundColor: bgcolor2,
      zIndex: 2
   };
   const segmentStyles3 = {
      ...segmentStyles,
      width: `${Math.round(segment3 || 0)}%`,
      backgroundColor: bgcolor3,
      zIndex: 1
   };

   return (
      <div style={containerStyles}>
         <div style={segmentStyles1}></div>
         {segment2 ? <div style={segmentStyles2}></div> : null}
         {segment3 ? <div style={segmentStyles3}></div> : null}
      </div>
   );
};

export default ProgressBar;
