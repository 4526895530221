import React, { ReactNode } from "react";
import styles from "./style.module.scss";

interface IProps {
   status: "success" | "warning" | "attention";
   children: string | ReactNode;
   className?: string;
   size?: "small" | "middle" | "large" | "";
}

const TagField = ({ status, children, className, size = "" }: IProps) => {
   return (
      <span
         className={`${styles[status]} ${styles.tag} ${className} ${styles[size]}`}
      >
         {children}
      </span>
   );
};

export default TagField;
