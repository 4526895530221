import React from "react";
import { IfcViewerWrapper } from "../../IfcViewer/IfcViewerWrapper";
import { IfcViewerProvider } from "../../IfcViewer/IfcViewerContext";

export function ThreeDInventory() {
   return (
      <IfcViewerProvider>
         <IfcViewerWrapper />
      </IfcViewerProvider>
   );
}
