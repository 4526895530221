import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LogoutOutlined } from "@ant-design/icons";
import SettingsSideBar from "./SettingsSideBar";
import styles from "./Settings.module.scss";
import { Col, Form, message, Popconfirm, Row } from "antd";
import InputField from "../../components/FormFields/InputField";
import ButtonField from "../../components/FormFields/ButtonField";
import { useHistory, useParams } from "react-router";
import { useAuth } from "../../contexts/auth";
import { Organization as OrganizationType } from "./../../types";
import { useForm } from "antd/es/form/Form";
import { Resource } from "../../api/common";
import useAxios from "axios-hooks";

export const OrganizationUserSettings = ({}) => {
   const [t] = useTranslation();
   const params: { id: string } = useParams();
   const { joinedOrganizationsList, user: currentUserInfo } = useAuth();
   const [organization, setOrganization] = useState<OrganizationType>({
      name: ""
   });
   const history = useHistory();
   const [form] = useForm();
   const [adminCounts, setAdminCounts] = useState(0);

   useEffect(() => {
      setOrganization(
         joinedOrganizationsList.find(
            (item: { organizations_id: any }) =>
               item?.organizations_id == +params.id
         )
      );
   }, [joinedOrganizationsList, params.id]);

   useEffect(() => {
      form.setFieldsValue({ title: organization?.title });
      console.log(organization);
   }, [organization]);

   const [{ loading: leaveLoading }, leaveAPI] = useAxios(
      {
         url: `${Resource.path.organization.updateUserSettings}/${organization?.user_organizations_id}`,
         method: "DELETE"
      },
      {
         manual: true,
         useCache: false
      }
   );
   const [{ loading: updateTitleLoading }, updateTitleAPI] = useAxios(
      {
         url: `${Resource.path.organization.updateUserSettings}/${organization?.user_organizations_id}`,
         method: "PUT"
      },
      {
         manual: true,
         useCache: false
      }
   );

   const [{ data: usersData, loading: usersLoading }, getUsersAPI] = useAxios(
      {
         url: Resource.path.organization.getUsers(params?.id),
         method: "GET"
      },
      { manual: false, useCache: true }
   );

   useEffect(() => {
      setAdminCounts(
         usersData?.data.filter(
            (item: { role: string; status: string }) =>
               item.role == "ADMIN" && item.status == "ACCEPTED"
         ).length
      );
   }, [usersData]);

   const handleOnSubmit = async () => {
      form.submit();
      const data = form.getFieldsValue();
      await form.validateFields().then(async () => {
         await updateTitleAPI({
            data: { title: data.title, user_id: currentUserInfo.username }
         })
            .then((resp: any) => {
               console.log(resp);
               message.success(t("settings.successMessage"));
            })
            .catch((err) => {
               console.log(err);
               message.error(t("settings.errorMessage"));
            });
      });
   };

   const confirmLeave = async () => {
      await leaveAPI()
         .then((resp) => {
            console.log(resp);
            message.success(t("settings.successMessage"));
            history.push("/settings/profile");
         })
         .catch((err) => {
            console.log(err);
            message.error(t("settings.errorMessage"));
         });
   };

   if (updateTitleLoading || leaveLoading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <SettingsSideBar
         title={t("settings.profile.userSettings")}
         refreshSideBar={leaveLoading || updateTitleLoading}
      >
         <Row>
            <Col sm={16} xs={24} className={styles.orgUserSettings}>
               <div className={styles.section}>
                  <h3 className={styles.fieldsTitle}>
                     {t("settings.profile.profile")}
                  </h3>
                  <Form form={form} onFinish={handleOnSubmit}>
                     <Row className={styles.row}>
                        <Col sm={12} xs={24} className={styles.label}>
                           {t("settings.organization.title")}
                        </Col>
                        <Col
                           sm={12}
                           xs={24}
                           className={"d-flex-between flex-align-start"}
                        >
                           <InputField
                              fieldName="title"
                              required
                              fieldErrorMessage={t(
                                 "settings.organization.titleRequiredMessage"
                              )}
                              className={styles.title}
                           />
                           <ButtonField
                              type="default"
                              htmlType="submit"
                              loading={updateTitleLoading}
                           >
                              {t("settings.organization.update")}
                           </ButtonField>
                        </Col>
                     </Row>
                  </Form>
               </div>
               {adminCounts > 1 &&
                  currentUserInfo &&
                  organization &&
                  organization.created_by !== currentUserInfo.username && (
                     <div className={styles.section}>
                        <h3 className={styles.fieldsTitle}>
                           {t("settings.organization.organization")}
                        </h3>
                        <Row className={styles.row}>
                           <Col sm={12} xs={24} className={styles.label}>
                              {t("settings.organization.leaveOrganization")}
                           </Col>
                           <Col sm={12} xs={24} className={"d-flex-end"}>
                              <Popconfirm
                                 title={t("settings.organization.areYouSure")}
                                 onConfirm={confirmLeave}
                                 icon={null}
                                 okText={t("default.yes")}
                                 cancelText={t("default.no")}
                                 overlayClassName={"confirm-no-icon"}
                                 okButtonProps={{ loading: leaveLoading }}
                              >
                                 <ButtonField
                                    icon={<LogoutOutlined />}
                                    type="negative"
                                    size="small"
                                 >
                                    {t("settings.organization.leave")}
                                 </ButtonField>
                              </Popconfirm>
                           </Col>
                        </Row>
                     </div>
                  )}
            </Col>
         </Row>
      </SettingsSideBar>
   );
};
