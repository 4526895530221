import // CloudUploadOutlined
//    UserOutlined
//    InsertRowBelowOutlined,
//    LineChartOutlined
"@ant-design/icons";
import { Menu, Layout, Image } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { useBuilding } from "../../contexts/building";
import { Mixpanel } from "../../utils/tracking/mixpanel";
import NA_BUILDING_IMAGE from "./na-building-image.png";
import { NavigationKeyMap } from "./consts.d";
import styles from "./Building.module.scss";
import icon_building from "./../../statics/images/icon_building.svg";
import icon_inventory from "./../../statics/images/icon_inventory.svg";
import icon_user_settings from "./../../statics/images/icon_user_settings.svg";
import icon_operation from "./../../statics/images/icon_operation.svg";
import { ScreenSize } from "../../utils/screenSizes";

const { Sider } = Layout;

export const SideBar = () => {
   let param: any = useParams();
   const [t] = useTranslation();
   const location = useLocation();
   const { id }: any = useParams();
   const [buildingImage, setBuildingImage] = useState(NA_BUILDING_IMAGE);
   const [buildingDetails, setBuildingDetails] = useState<any>();

   const { getBuildingDetail } = useBuilding();
   const history = useHistory();
   const { user: currentUser } = useAuth();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";

   useEffect(() => {
      const buildingDetails = id && getBuildingDetail(id);
      const [firstBuildingImage] = buildingDetails?.building_images || [];
      setBuildingDetails(buildingDetails);

      if (firstBuildingImage?.object_url) {
         setBuildingImage(firstBuildingImage.object_url);
      }
   }, [getBuildingDetail]);

   const selectedKeys = () => {
      return (
         Object.keys(NavigationKeyMap).find((key) => {
            if (location.pathname.includes(key)) {
               return key;
            }
         }) || "building_data"
      );
   };

   const navigateTo = (type: string, links: string) => {
      if (type === "building_details") {
         Mixpanel.track("Visit Building Details", {
            "Building Id": param.id
         });
         history.push(links);
      }
      if (type === "building_inventory") {
         Mixpanel.track("Visit Material Inventory", {
            "Building Id": param.id
         });
         history.push(links);
      }

      if (type === "building_files") {
         Mixpanel.track("Visit Building Files", {
            "Building Id": param.id
         });
         history.push(links);
      }

      if (type === "building_users") {
         Mixpanel.track("Visit Building Users", {
            "Building Id": param.id
         });
         history.push(links);
      }
   };

   return (
      <Sider className={styles.buildingSlider}>
         <div className="responsivePageTitle">
            <div className={styles.viewHeader}>
               <Image
                  src={buildingImage}
                  preview={false}
                  className={styles.image}
               />
               <div className={styles.titles}>
                  <h2 className={styles.viewTitle}>{buildingDetails?.name}</h2>
                  <div className={styles.subtitle}>
                     {buildingDetails?.location_places_api?.PostalCode}{" "}
                     {buildingDetails?.location_places_api?.Municipality}
                  </div>
               </div>
            </div>
         </div>
         <Menu
            className={styles.menu}
            mode={ScreenSize.desktop ? "vertical" : "horizontal"}
            selectedKeys={[selectedKeys()]}
         >
            <Menu.Item
               key="building_data"
               icon={
                  ScreenSize.desktop ? <img src={icon_building} alt="" /> : null
               }
               onClick={() =>
                  navigateTo("building_details", `/buildings/${param.id}`)
               }
               className={styles.menuItem}
            >
               {t("navigation.buildingData")}
            </Menu.Item>
            <Menu.Item
               key="material_inventory"
               icon={
                  ScreenSize.desktop ? (
                     <img src={icon_inventory} alt="" />
                  ) : null
               }
               onClick={() =>
                  navigateTo(
                     "building_inventory",
                     `/buildings/${param.id}/material_inventory`
                  )
               }
               className={styles.menuItem}
            >
               {t("navigation.materialInventory")}
            </Menu.Item>
            <Menu.Item
               key="operations"
               icon={
                  ScreenSize.desktop ? (
                     <img src={icon_operation} alt="" />
                  ) : null
               }
               className={styles.menuItem}
            >
               <Link to={`/buildings/${param.id}/operations`}>
                  {t("navigation.buildingOperations")}
               </Link>
            </Menu.Item>
            {/* {!isPrivateOwner && (
               <Menu.Item
                  key="files"
                  icon={<CloudUploadOutlined />}
                  onClick={() =>
                     navigateTo(
                        "building_files",
                        `/buildings/${param.id}/files`
                     )
                  }
                  className={styles.menuItem}
               >
                  {t("navigation.buildingFiles")}
               </Menu.Item>
               )} */}
            {!isPrivateOwner && (
               <Menu.Item
                  key="access"
                  icon={
                     ScreenSize.desktop ? (
                        <img src={icon_user_settings} alt="" />
                     ) : null
                  }
                  className={styles.menuItem}
               >
                  <Link to={`/buildings/${param.id}/access`}>
                     {t("navigation.access")}
                  </Link>
               </Menu.Item>
            )}
         </Menu>
      </Sider>
   );
};
