import { Input, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

interface IProps {
   items: string[];
   setItems?: (e: any) => any;
   size?: "small" | "middle" | "large";
   regEx?: RegExp;
   placeholder?: string;
   errorText?: string;
   label?: string;
}

// emailReg = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/

const MentionField = ({
   items,
   setItems,
   size,
   regEx,
   placeholder,
   errorText,
   label
}: IProps) => {
   const [tags, setTags] = useState<Array<String>>([]);
   const [value, setValue] = useState("");
   const [error, setError] = useState(false);
   const [t] = useTranslation();

   useEffect(() => {
      setItems && setItems([...tags]);
   }, [tags.length]);

   useEffect(() => {
      setTags([...items]);
   }, [items]);

   const handleChange = (e: any) => {
      const value = e.target.value;
      setValue(value);
      if (
         value.length > 0 &&
         value.length < 256 &&
         regEx &&
         regEx.test(value) === true
      ) {
         setError(false);
      } else {
         setError(true);
      }
   };

   const onKeyDownPressed = (e: any) => {
      if (e.key === "Enter") {
         if (
            value.length > 0 &&
            value.length < 256 &&
            regEx &&
            regEx.test(value) === true
         ) {
            !tags.find((item: String) => item === value) &&
               setTags((state: any) => [...state, value]);
            setValue("");
            setError(false);
         } else {
            setError(true);
         }
      }
   };

   const removeTag = (item: string) => {
      setTags([...tags.filter((tag) => tag !== item)]);
   };

   return (
      <div className={`mention ${styles.mention}`}>
         {label && <span className={styles.label}>{label}</span>}
         <div className={styles.inputValues}>
            {tags.map((item: any) => (
               <Tag
                  className={styles.tag}
                  closable
                  onClose={() => removeTag(item)}
               >
                  {item}
               </Tag>
            ))}
            <Input
               defaultValue={value}
               size={size}
               value={value}
               onChange={handleChange}
               placeholder={placeholder}
               onKeyDown={(e: any) => onKeyDownPressed(e)}
               className={styles.input}
            />
         </div>
         {error && (
            <p className={styles.error}>{`${t(
               "formElement.invalidInput"
            )} ${errorText}!`}</p>
         )}
      </div>
   );
};

export default MentionField;
