import React, { useState, useEffect } from "react";
import {
   PageHeader,
   Button,
   message,
   Modal,
   Select,
   DatePicker,
   Form,
   Empty,
   Input,
   Checkbox,
   Spin
} from "antd";
import { Link, useParams } from "react-router-dom";
import useAxios from "axios-hooks";
import "./style.scss";

import { useTranslation } from "react-i18next";
import { Resource } from "../../../api/common";
import { useBuilding } from "../../../contexts/building/index";
const moment = require("moment");

export const BuildingOperationOverviewView = () => {
   const [t] = useTranslation();
   let param: any = useParams();
   const [isModalVisible, setIsModalVisible] = useState(false);
   const { Option } = Select;
   const [form] = Form.useForm();
   const { id: buildingId }: any = useParams<string>();
   const [operationsInProgress, setOperationsInProgress] = useState<any>([]);
   const [operationsFinished, setOperationsFinished] = useState<any>([]);
   const [isConstructionDone, setIsConstructionDone] = useState(false);
   const [isDeConstructionDone, setIsDeConstructionDone] = useState(false);
   const [operationType, setOperationType] = useState<string>();
   const { getBuildingDetail } = useBuilding();
   const buildingDetails = buildingId && getBuildingDetail(buildingId);
   const [startDateConstruction, setStartDateConstruction] = useState<any>();
   const [startDateDeconstruction, setStartDateDeconstruction] =
      useState<any>();
   const [endDateConstruction, setEndDateConstruction] = useState<any>();
   const [endDateDeconstruction, setEndDateDeconstruction] = useState<any>();
   const [thirdPartyDeconst, setThirdPartyDeconst] = useState(false);
   const [startDateSelfDeconstruction, setStartDateSelfDeconstruction] =
      useState<any>();
   const [endDateSelfDeconstruction, setEndDateSelfDeconstruction] =
      useState<any>();
   const [{ data, loading }, refetch] = useAxios(
      `${Resource.path.operations}/${buildingId}`
   );

   const [{ loading: confirmLoading }, saveOperations] = useAxios(
      {
         url: `${Resource.path.operations}`,
         method: "POST"
      },
      { manual: true }
   );

   useEffect(() => {
      if (data) {
         console.log(data.data.result);
         const tempInProgress: any = [];
         const tempFinished: any = [];
         data.data.result.forEach((record: any) => {
            const { operation_type } = record;
            if (operation_type === "construction") {
               setIsConstructionDone(true);
            }
            if (operation_type === "deconstruction") {
               setIsDeConstructionDone(true);
            }
            if (record.operation_status === "FINISHED") {
               tempFinished.push(record);
            } else {
               tempInProgress.push(record);
            }
         });
         setOperationsInProgress(tempInProgress);
         setOperationsFinished(tempFinished);
      }
   }, [data]);

   // modal
   const showModal = () => {
      setIsModalVisible(true);
   };

   const handleOk = async () => {
      form.submit();
      console.log(form.getFieldsValue());
      await form.validateFields().then(async () => {
         const data: any = {
            name: form.getFieldValue("name"),
            operation_type: form.getFieldValue("operation_type"),
            building_id: buildingId,
            building_name: buildingDetails?.name
         };
         if (form.getFieldValue("start_date_construction") !== undefined) {
            data.start_date_construction = form.getFieldValue(
               "start_date_construction"
            );
         }
         if (form.getFieldValue("start_date_deconstruction") !== undefined) {
            data.start_date_deconstruction = form.getFieldValue(
               "start_date_deconstruction"
            );
         }
         if (form.getFieldValue("end_date_construction") !== undefined) {
            data.end_date_construction = form.getFieldValue(
               "end_date_construction"
            );
         }
         if (form.getFieldValue("end_date_deconstruction") !== undefined) {
            data.end_date_deconstruction = form.getFieldValue(
               "end_date_deconstruction"
            );
         }
         data.third_party_deconstruction = thirdPartyDeconst ? 1 : 0;

         if (
            form.getFieldValue("self_start_date_deconstruction") !== undefined
         ) {
            data.self_start_date_deconstruction = form.getFieldValue(
               "self_start_date_deconstruction"
            );
         }
         if (form.getFieldValue("self_end_date_deconstruction") !== undefined) {
            data.self_end_date_deconstruction = form.getFieldValue(
               "self_end_date_deconstruction"
            );
         }
         await saveOperations({
            data
         })
            .then(() => {
               form.resetFields();
               refetch();
               setIsModalVisible(false);
            })
            .catch((error) => {
               console.log(error);
               message.error(t("buildingOperations.errorMessage"));
            });
      });
   };

   const handleCancel = () => {
      form.resetFields();
      setIsModalVisible(false);
   };

   const Operation = (props: any) => {
      const { data } = props;
      const {
         id,
         name,
         operation_type,
         match_count,
         start_date_construction,
         start_date_deconstruction,
         end_date_construction,
         end_date_deconstruction
      } = data;
      return (
         <>
            <div className="operation-item">
               <div className="operation-type">
                  <Link to={`/buildings/${param.id}/operations/${id}`}>
                     <strong>
                        {name
                           ? name
                           : t(
                                `staticDropdown.upcomingActivities.${operation_type}`
                             )}
                     </strong>
                     {t(`staticDropdown.upcomingActivities.${operation_type}`)}
                  </Link>
               </div>
               <div className="operation-timeline">
                  {start_date_deconstruction && (
                     <>
                        <strong>{t("operations.deconstruction")}: </strong>{" "}
                        <span>
                           {moment(
                              start_date_deconstruction,
                              "YYYY-MM-DD HH:mm:ss"
                           ).format("MMM YYYY")}
                        </span>
                     </>
                  )}
                  {end_date_deconstruction && (
                     <span className="end-timeline">
                        {moment(
                           end_date_deconstruction,
                           "YYYY-MM-DD HH:mm:ss"
                        ).format("MMM YYYY")}
                     </span>
                  )}
                  {start_date_construction && (
                     <>
                        <strong>{t("operations.construction")}: </strong>{" "}
                        <span>
                           {moment(
                              start_date_construction,
                              "YYYY-MM-DD HH:mm:ss"
                           ).format("MMM YYYY")}
                        </span>
                     </>
                  )}
                  {end_date_construction && (
                     <span className="end-timeline">
                        {moment(
                           end_date_construction,
                           "YYYY-MM-DD HH:mm:ss"
                        ).format("MMM YYYY")}
                     </span>
                  )}
               </div>

               {!!match_count && match_count > 0 && (
                  <div className="operation-results">
                     <Link to={`/buildings/${param.id}/operations/${id}`}>
                        <Button shape="round" type="primary" className="lined">
                           {match_count} matches
                        </Button>
                     </Link>
                  </div>
               )}
            </div>
         </>
      );
   };

   if (loading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   if (loading)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <div className="page-wrap building-operations-overview-wrapper">
         <PageHeader title="">
            <div className="page-title">
               <h1>{t("buildingOperations.operations")}</h1>
            </div>
            <div className="manage-menu">
               {
                  <Button type="primary" shape="round" onClick={showModal}>
                     {t("buildingOperations.addOperation")}
                  </Button>
               }

               {isModalVisible && (
                  <Modal
                     confirmLoading={confirmLoading}
                     title={t("operations.form.addOperation")}
                     visible={isModalVisible}
                     onOk={handleOk}
                     onCancel={handleCancel}
                  >
                     <Form name="operation" form={form} layout="vertical">
                        <Form.Item
                           label={t("operations.form.selectType")}
                           name="operation_type"
                           rules={[
                              { required: true },
                              () => ({
                                 validator: (_rule, value) => {
                                    if (value) return Promise.resolve();
                                    return Promise.reject(
                                       t(
                                          "buildingAddEditForm.item.operation_type"
                                       )
                                    );
                                 }
                              })
                           ]}
                        >
                           <Select
                              onChange={(value: string) => {
                                 setOperationType(value);
                              }}
                           >
                              {/* <Option value="renovation">{t('operations.renovation')} ({t('operations.sourcingSelling')})</Option> */}
                              {/* <Option value="extension">{t('operations.extension')} ({t('operations.sourcingSelling')})</Option> */}
                              <Option value="construction">
                                 {t("operations.erection")} (
                                 {t("operations.sourcingOnly")})
                              </Option>
                              <Option value="deconstruction">
                                 {t("operations.deconstruction")} (
                                 {t("operations.sellingOnly")})
                              </Option>
                           </Select>
                        </Form.Item>
                        <Form.Item
                           label={t("operations.form.operationName")}
                           name="name"
                        >
                           <Input
                              placeholder={t(
                                 "operations.form.operationNameExample"
                              )}
                           />
                        </Form.Item>
                        {operationType === "construction" && (
                           <>
                              <Input.Group compact>
                                 <strong>{t("operations.construction")}</strong>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t(
                                       "operations.form.expectedStartOfConstruction"
                                    )}
                                    name="start_date_construction"
                                 >
                                    <DatePicker
                                       onChange={(value: any) => {
                                          setStartDateConstruction(value);
                                       }}
                                    />
                                 </Form.Item>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value) {
                                                if (
                                                   startDateConstruction &&
                                                   value < startDateConstruction
                                                ) {
                                                   return Promise.reject(
                                                      t(
                                                         "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                      )
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t("operations.form.expectedEnd")}
                                    name="end_date_construction"
                                 >
                                    <DatePicker
                                       onChange={(value: any) => {
                                          setEndDateConstruction(value);
                                       }}
                                    />
                                 </Form.Item>
                              </Input.Group>
                           </>
                        )}
                        {operationType === "deconstruction" && (
                           <>
                              <Input.Group compact>
                                 <strong>
                                    {t("operations.deconstruction")}
                                 </strong>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t(
                                       "operations.form.expectedStartOfDeconstruction"
                                    )}
                                    name="start_date_deconstruction"
                                 >
                                    <DatePicker
                                       onChange={(value: any) => {
                                          setStartDateDeconstruction(value);
                                       }}
                                    />
                                 </Form.Item>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value) {
                                                if (
                                                   startDateDeconstruction &&
                                                   value <
                                                      startDateDeconstruction
                                                ) {
                                                   return Promise.reject(
                                                      t(
                                                         "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                      )
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t("operations.form.expectedEnd")}
                                    name="end_date_deconstruction"
                                 >
                                    <DatePicker
                                       onChange={(value: any) => {
                                          setEndDateDeconstruction(value);
                                       }}
                                    />
                                 </Form.Item>
                              </Input.Group>
                           </>
                        )}
                        {(operationType === "renovation" ||
                           operationType === "extension") && (
                           <>
                              <Input.Group compact>
                                 <strong>
                                    {t("operations.deconstruction")}
                                 </strong>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t(
                                       "operations.form.expectedStartOfDeconstruction"
                                    )}
                                    name="start_date_deconstruction"
                                 >
                                    <DatePicker
                                       onChange={(value: any) => {
                                          setStartDateDeconstruction(value);
                                       }}
                                    />
                                 </Form.Item>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value) {
                                                if (
                                                   startDateDeconstruction &&
                                                   value <
                                                      startDateDeconstruction
                                                ) {
                                                   return Promise.reject(
                                                      t(
                                                         "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                      )
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t("operations.form.expectedEnd")}
                                    name="end_date_deconstruction"
                                 >
                                    <DatePicker
                                       onChange={(value: any) => {
                                          setEndDateDeconstruction(value);
                                       }}
                                    />
                                 </Form.Item>
                              </Input.Group>
                              <Input.Group compact>
                                 <strong>{t("operations.construction")}</strong>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value)
                                                return Promise.resolve();
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t(
                                       "operations.form.expectedStartOfConstruction"
                                    )}
                                    name="start_date_construction"
                                 >
                                    <DatePicker
                                       onChange={(value: any) => {
                                          setStartDateConstruction(value);
                                       }}
                                    />
                                 </Form.Item>
                                 <Form.Item
                                    rules={[
                                       () => ({
                                          validator: (_rule, value) => {
                                             if (value) {
                                                if (
                                                   startDateConstruction &&
                                                   value < startDateConstruction
                                                ) {
                                                   return Promise.reject(
                                                      t(
                                                         "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                      )
                                                   );
                                                }
                                                return Promise.resolve();
                                             }
                                             return Promise.reject(
                                                t(
                                                   "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                )
                                             );
                                          }
                                       })
                                    ]}
                                    label={t("operations.form.expectedEnd")}
                                    name="end_date_construction"
                                 >
                                    <DatePicker
                                       onChange={(value: any) => {
                                          setEndDateConstruction(value);
                                       }}
                                    />
                                 </Form.Item>
                              </Input.Group>
                           </>
                        )}
                        {operationType === "deconstruction" && (
                           <Form.Item name="third_party_deconstruction">
                              <Checkbox
                                 checked={!!thirdPartyDeconst}
                                 onChange={(event) => {
                                    setThirdPartyDeconst(event.target.checked);
                                 }}
                              >
                                 {t(
                                    "operations.form.third_party_deconstruction"
                                 )}
                              </Checkbox>
                           </Form.Item>
                        )}
                        {operationType === "deconstruction" &&
                           !!thirdPartyDeconst && (
                              <>
                                 <strong>
                                    {t(
                                       "operations.form.third_party_deconstruction_range"
                                    )}
                                 </strong>
                                 <Input.Group compact>
                                    <Form.Item
                                       rules={[
                                          () => ({
                                             validator: (_rule, value) => {
                                                if (value)
                                                   return Promise.resolve();
                                                return Promise.reject(
                                                   t(
                                                      "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                   )
                                                );
                                             }
                                          })
                                       ]}
                                       label={t(
                                          "operations.form.third_party_deconstruction_range_start"
                                       )}
                                       name="self_start_date_deconstruction"
                                    >
                                       <DatePicker
                                          onChange={(value: any) => {
                                             setStartDateSelfDeconstruction(
                                                value
                                             );
                                          }}
                                       />
                                    </Form.Item>
                                    <Form.Item
                                       rules={[
                                          () => ({
                                             validator: (_rule, value) => {
                                                if (value) {
                                                   if (
                                                      startDateSelfDeconstruction &&
                                                      value <
                                                         startDateSelfDeconstruction
                                                   ) {
                                                      return Promise.reject(
                                                         t(
                                                            "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                         )
                                                      );
                                                   }
                                                   return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                   t(
                                                      "buildingAddEditForm.item.isPlannedReqErrMessage"
                                                   )
                                                );
                                             }
                                          })
                                       ]}
                                       label={t(
                                          "operations.form.third_party_deconstruction_range_end"
                                       )}
                                       name="self_end_date_deconstruction"
                                    >
                                       <DatePicker
                                          onChange={(value: any) => {
                                             setEndDateSelfDeconstruction(
                                                value
                                             );
                                          }}
                                       />
                                    </Form.Item>
                                 </Input.Group>
                              </>
                           )}
                     </Form>
                  </Modal>
               )}
            </div>
         </PageHeader>
         {(operationsInProgress.length > 0 ||
            operationsFinished.length > 0) && (
            <div className="page-content">
               {operationsInProgress.length > 0 && (
                  <>
                     <h3>{t("operations.in_progress")}</h3>
                     <div className="operation-list">
                        {operationsInProgress.length > 0 &&
                           operationsInProgress.map((operation: any) => (
                              <Operation data={operation} />
                           ))}
                     </div>
                  </>
               )}
               {operationsInProgress.length === 0 && (
                  <div className="operations-intro">
                     <h4>Verkaufen, beschaffen und messen.</h4>
                     <p>
                        Mit Vorgängen können Sie Verkauf und Beschaffung von
                        Materialien für Ihre Baumaßnahme automatisieren.
                     </p>
                     <div>
                        {
                           <Button
                              type="primary"
                              shape="round"
                              onClick={showModal}
                           >
                              {t("buildingOperations.addOperation")}
                           </Button>
                        }
                     </div>
                  </div>
               )}
               {operationsFinished.length > 0 && (
                  <>
                     <h3>{t("operations.finished")}</h3>
                     <div className="operation-list">
                        {operationsFinished.length > 0 &&
                           operationsFinished.map((operation: any) => (
                              <Operation data={operation} />
                           ))}
                        {operationsFinished.length === 0 && <Empty />}
                     </div>
                  </>
               )}
            </div>
         )}
         {operationsInProgress.length === 0 && operationsFinished.length === 0 && (
            <div className="page-content">
               <div className="operations-intro">
                  <h4>Verkaufen, beschaffen und messen.</h4>
                  <p>
                     Mit Vorgängen können Sie Verkauf und Beschaffung von
                     Materialien für Ihre Baumaßnahme automatisieren.
                  </p>
                  <div>
                     {
                        <Button
                           type="primary"
                           shape="round"
                           onClick={showModal}
                        >
                           {t("buildingOperations.addOperation")}
                        </Button>
                     }
                  </div>
               </div>
            </div>
         )}
      </div>
   );
};
/*<div className="operation-actions">
    <span className="action-tag">Sourcing</span>
    <span className="action-tag">Selling</span>
</div>*/
