import { Col, message, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonField from "../../components/FormFields/ButtonField";
import styles from "./Subscription.module.scss";
import { ArrowLeftOutlined, CheckOutlined } from "@ant-design/icons";
import icon_passportmanager from "./../../statics/images/icon_passportmanager.svg";
import useAxios from "axios-hooks";
import { Resource } from "../../api/common";
import { Plan as PlanType } from "../../types";
import { BuildingProvider, useBuilding } from "../../contexts/building/index";

const SubscriptionList = () => {
   const [t] = useTranslation();
   const [plans, setPlans] = useState<Array<PlanType>>([]);
   const [customerId, setCustomerId] = useState<string>();
   const { building_id, organisation_id }: any = useParams();
   const { getBuildingDetail } = useBuilding();
   const [loadingPage, setLoadingPage] = useState<boolean>(true);
   const [fetchSubDataFlag, setFetchSubDataFlag] = useState<boolean>(true);
   const [currentPlan, setCurrentPlan] = useState<any>();
   const buildingDetails = building_id && getBuildingDetail(building_id);
   const [onLoad, setOnLoad] = useState<boolean>(true);
   const [{ data: dataSub }, fetchSubData] = useAxios(
      {
         url: `${Resource.path.plansURL}/sub/`,
         method: "get",
         timeout: 900000
      },
      { useCache: false, manual: true, autoCancel: false }
   );

   const [{ data: dataCustomer, loading: loadingCustomer }, fetchCustomer] =
      useAxios(
         {
            url: `${Resource.path.plansURL}/customer/${organisation_id}`,
            method: "get",
            timeout: 900000
         },
         { useCache: false, manual: false, autoCancel: false }
      );

   const [{ loading: loading, data }, getPlans] = useAxios(
      {
         url: `${Resource.path.getAllPlans}`,
         method: "GET"
      },
      {
         manual: false
      }
   );

   useEffect(() => {
      const plans = data?.data;
      plans &&
         setPlans([...plans?.sort((a: any, b: any) => a.price - b.price)]);
      if (dataCustomer?.data) {
         setCustomerId(dataCustomer?.data?.id);
      }
      if (
         buildingDetails &&
         buildingDetails?.subscription_id &&
         fetchSubDataFlag &&
         onLoad
      ) {
         setOnLoad(false);
         fetchSubData({
            url: `${Resource.path.plansURL}/sub/${buildingDetails?.subscription_id}`,
            method: "get",
            timeout: 900000
         });
         setFetchSubDataFlag(false);
      } else if (buildingDetails && !buildingDetails?.subscription_id) {
         setLoadingPage(false);
      }
      if (dataSub?.data) {
         setCurrentPlan(dataSub?.data);
         setLoadingPage(false);
      }
   }, [data, dataCustomer, buildingDetails, dataSub, fetchSubDataFlag, onLoad]);

   const [{ loading: loadingCancel }, cancelSubscription] = useAxios(
      {
         method: "DELETE"
      },
      { useCache: false, manual: true }
   );

   const PlansList = ({ item }: any) => {
      //console.log('currentPlan', currentPlan)
      const currentProdId = currentPlan?.product_id;
      const [{ loading: loadingPayLink }, getPaymentSession] = useAxios(
         {
            url: `${Resource.path.paymentSessionUrl}`,
            method: "post"
         },
         {
            manual: true,
            useCache: false
         }
      );
      const getPaymentLink = async (
         product: any,
         currentProdId: any,
         currentPlan: any
      ) => {
         if (currentProdId !== product.id && currentPlan?.status == "active") {
            await cancelSubscription({
               url: `${Resource.path.plansURL}/customer/sub/${buildingDetails?.subscription_id}`
            });
         }

         await getPaymentSession({
            data: {
               product_id: product.id,
               building_id: building_id,
               customer_id: customerId,
               redirectCancel: `subscription/${organisation_id}/${building_id}`,
               redirectSuccess: `buildings/${building_id}`
            }
         })
            .then((response: any) => {
               console.log(response);
               if (response?.data?.data?.url) {
                  window.location.replace(response?.data?.data?.url);
               }
            })
            .catch(() => {
               message.error(`something went wrong, please try again later`);
            });
      };
      return (
         <Col xl={7}>
            <div className={styles.plan}>
               <div>
                  <div className="d-flex flex-align-center">
                     <img src={icon_passportmanager} alt="" />
                     <span className={styles.name}>{item?.name}</span>
                  </div>
                  <div className={styles.description}>{item?.description}</div>
                  <div>
                     <span className={styles.price}>{`${item?.price} €`}</span>
                     <span className={styles.interval}>
                        {`/${item?.interval}`}
                     </span>
                  </div>
                  <div>
                     <ul className={styles.optionsList}>
                        {Object.values(item?.metadata).map((attr: any) => (
                           <li>
                              <CheckOutlined />
                              <span>{attr}</span>
                           </li>
                        ))}
                     </ul>
                  </div>
               </div>
               <div>
                  {currentProdId == item.id ? (
                     <ButtonField
                        size="middle"
                        disable={
                           currentProdId == item.id &&
                           currentPlan?.status == "active"
                        }
                        onClick={() =>
                           getPaymentLink(item, currentProdId, currentPlan)
                        }
                        loading={loadingPayLink}
                     >
                        {currentProdId == item.id &&
                        currentPlan?.status == "active"
                           ? t("subscriptions.alreadySubscribed")
                           : t("subscriptions.renewSubscription")}
                     </ButtonField>
                  ) : (
                     <ButtonField
                        size="middle"
                        onClick={() =>
                           getPaymentLink(item, currentProdId, currentPlan)
                        }
                        loading={loadingPayLink}
                     >
                        {t("subscriptions.getStarted")}
                     </ButtonField>
                  )}
               </div>
            </div>
         </Col>
      );
   };

   const history = useHistory();

   if (loadingPage) return <div className="loading">{t("common.loading")}</div>;

   return (
      <Spin spinning={loading || loadingCustomer || loadingPage}>
         <Row className={styles.subscription}>
            <Col xl={4} lg={4} xs={24} className={styles.goBack}>
               <ButtonField
                  type="link"
                  onClick={() => history.push("/buildings")}
                  icon={<ArrowLeftOutlined />}
                  className={styles.backButton}
               >
                  {t("default.goBack")}
               </ButtonField>
            </Col>
            <Col xl={20} lg={20} xs={24} className={styles.mainWrapper}>
               <div className={`pageTitle ${styles.title}`}>
                  <h1 className="">{t("subscriptions.choosePlan")}</h1>
               </div>

               <Row className={styles.plans}>
                  {plans.map((item: PlanType, index: number) => (
                     <PlansList item={item} key={`planlist-${index}`} />
                  ))}
               </Row>
            </Col>
         </Row>
      </Spin>
   );
};

const Subscription = () => {
   return (
      <BuildingProvider>
         <SubscriptionList />
      </BuildingProvider>
   );
};

export default Subscription;
