import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Space } from "antd";
import { MinusCircleOutlined, AppstoreAddOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { Resource } from "../../../../../api/common";

export const Materials = ({ form }: any) => {
   const [t] = useTranslation();
   const [ingredients, setIngredient] = useState<any>([]);
   const [{ data, loading, error }, refetch] = useAxios(
      { url: Resource.path.getMaterialIngredients },
      { useCache: false, manual: false }
   );
   const [materialsMap, setMaterialsMap] = useState<any>({});
   const [materialsList, setMaterialsList] = useState<any>([]);
   useEffect(() => {
      if (data?.data?.data) {
         // console.log({ data: data?.data });
         const materialsMap = new Map();
         const materials: any = {};
         const materialGroup = new Set();

         data?.data?.data.forEach((material: any) => {
            const groupName = material["de_group_name"];
            materialGroup.add(groupName);

            const materialName = material["de_material_name"];
            if (materialsMap.has(groupName)) {
               materials[groupName] = [
                  materialName,
                  ...materialsMap.get(groupName)
               ];
               materialsMap.set(groupName, [
                  materialName,
                  ...materialsMap.get(groupName)
               ]);
            } else {
               materialsMap.set(groupName, [materialName]);
               materials[groupName] = materialName;
            }
         });
         const uniqueGroupName = Array.from(materialGroup);
         setMaterialsMap(materials);
         setMaterialsList(uniqueGroupName);
      }
   }, [data]);

   const handleOnMaterialSelect = (value: any, fieldKey: any) => {
      let ingredients = materialsMap[value];
      const fields = form.getFieldsValue(true);
      if (ingredients && !Array.isArray(ingredients)) {
         ingredients = [ingredients];
      }

      const uniqueIngredients = ingredients.filter(function (
         item: any,
         pos: number
      ) {
         return ingredients.indexOf(item) == pos;
      });
      if (
         fields &&
         fields.materials &&
         fields.materials[fieldKey] &&
         fields.materials[fieldKey].material_sub_type
      ) {
         fields.materials[fieldKey].material_sub_type = "";
         form.setFieldsValue(fields);
      }
      setIngredient(uniqueIngredients);
   };

   if (loading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <div>
         <Text>{t("productDetails.material.materialTitle")}</Text>
         <Form.List name="materials">
            {(fields, { add, remove }) => (
               <>
                  {fields.map((field) => (
                     <Space
                        key={field.key}
                        style={{ display: "flex", marginBottom: "-12px" }}
                        align="center"
                     >
                        <Form.Item
                           {...field}
                           name={[field.name, "material_type"]}
                           fieldKey={[field.fieldKey, "material_type"]}
                           rules={[
                              {
                                 required: true,
                                 message: t(
                                    "productDetails.material.form.selectMaterialType"
                                 )
                              }
                           ]}
                        >
                           <Select
                              placeholder={t(
                                 "productDetails.material.form.placeholderMaterial"
                              )}
                              onSelect={(value) =>
                                 handleOnMaterialSelect(value, field.fieldKey)
                              }
                              style={{ width: "150px" }}
                           >
                              {materialsList &&
                                 materialsList.map(
                                    (material: any, index: number) => (
                                       <Select.Option
                                          value={material}
                                          key={`${material}_${index}`}
                                       >
                                          {material}
                                       </Select.Option>
                                    )
                                 )}
                           </Select>
                        </Form.Item>
                        <Form.Item
                           {...field}
                           name={[field.name, "material_sub_type"]}
                           fieldKey={[field.fieldKey, "material_sub_type"]}
                           rules={[
                              {
                                 required: true,
                                 message: t(
                                    "productDetails.material.form.missingMaterialSubType"
                                 )
                              }
                           ]}
                        >
                           <Select
                              placeholder={t(
                                 "productDetails.material.form.ingredients"
                              )}
                              style={{ width: "150px" }}
                           >
                              {ingredients &&
                                 Array.isArray(ingredients) &&
                                 ingredients.map((ingredient: any) => (
                                    <Select.Option
                                       value={ingredient}
                                       key={ingredient}
                                    >
                                       {ingredient}
                                    </Select.Option>
                                 ))}
                           </Select>
                        </Form.Item>
                        <Form.Item
                           {...field}
                           name={[field.name, "percentage"]}
                           fieldKey={[field.fieldKey, "percentage"]}
                           style={{ width: "140px" }}
                           rules={[
                              {
                                 required: true,
                                 message: t(
                                    "productDetails.material.form.missingMaterialPercentage"
                                 )
                              }
                           ]}
                        >
                           <Input type="text" addonAfter="%" />
                        </Form.Item>
                        <MinusCircleOutlined
                           onClick={() => remove(field.name)}
                        />
                     </Space>
                  ))}
                  <Form.Item>
                     <Button
                        type="primary"
                        shape="round"
                        icon={<AppstoreAddOutlined />}
                        onClick={() => add()}
                     >
                        {t("productDetails.material.form.addMaterialBtn")}
                     </Button>
                  </Form.Item>
               </>
            )}
         </Form.List>
      </div>
   );
};
