import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
   PageHeader,
   Tag,
   Image,
   Button,
   Popconfirm,
   message,
   Input,
   Form,
   Select,
   Switch
} from "antd";
import { useTranslation } from "react-i18next";
import { getDocumentsList, getMaterialReuseForTag } from "./MaterialDetails";
import { getImagePath } from "../../../../utils/getImagePath";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Resource } from "../../../../api/common";
import useAxios from "axios-hooks";
import Modal from "antd/lib/modal/Modal";
import { ImageUploader } from "../../../../components/ImageUploader";
import { getPassportWithUploadedMaterialImagesLinks } from "../AddMaterialWizard/ReusePotential/getUploadedMaterialPassportImagesLinks";
import { getAxiosInstance } from "../../../../utils/axios";
import QRCode from "../../../../components/ui@concular/QRCode";

const { Option } = Select;

export const MaterialPassportDetails = () => {
   const {
      pathname,
      state: { material, passport }
   }: any = useLocation();
   const history = useHistory();
   const [t] = useTranslation();
   const [addItemLoading, setAddItemLoading] = useState(false);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [passportFormInitialValue, setPassportFormInitialValue] = useState({});
   const [isModalVisibleOnSaleEdit, setIsModalVisibleOnSaleEdit] =
      useState(false);
   const [contactPollutedMaterial, setContactPollutedMaterial] = useState<any>(
      passport?.contact_polluted_material
   );
   const [onSaleCount, setOnSaleCount] = useState(passport.on_sale_count);
   const [isModalVisibleOnSaleDelete, setIsModalVisibleOnSaleDelete] =
      useState(false);
   const [materialImages, setMaterialImages] = useState<any>();
   const [{ data, loading, error }, executeDelete] = useAxios(
      {
         url: `${Resource.path.deletePassportDetailsHub}/${passport.id}`,
         method: "delete"
      },
      { manual: true }
   );
   const conditions = [
      {
         label: t("staticDropdown.condition.originalPacked"),
         value: t("staticDropdown.condition.originalPacked")
      },
      {
         label: t("staticDropdown.condition.new"),
         value: t("staticDropdown.condition.new")
      },
      {
         label: t("staticDropdown.condition.likeNew"),
         value: t("staticDropdown.condition.likeNew")
      },
      {
         label: t("staticDropdown.condition.refurbished"),
         value: t("staticDropdown.condition.refurbished")
      },
      {
         label: t("staticDropdown.condition.signOfUsages"),
         value: t("staticDropdown.condition.signOfUsages")
      },
      {
         label: t("staticDropdown.condition.signOfAge"),
         value: t("staticDropdown.condition.signOfAge")
      },
      {
         label: t("staticDropdown.condition.heavilyUsed"),
         value: t("staticDropdown.condition.heavilyUsed")
      },
      {
         label: t("staticDropdown.condition.damaged"),
         value: t("staticDropdown.condition.damaged")
      },
      {
         label: t("staticDropdown.condition.broken"),
         value: t("staticDropdown.condition.broken")
      }
   ];
   const deconstructability = [
      {
         label: t("staticDropdown.deconstructability.easyLoose"),
         value: t("staticDropdown.deconstructability.easyLoose")
      },
      {
         label: t("staticDropdown.deconstructability.easyPlugged"),
         value: t("staticDropdown.deconstructability.easyPlugged")
      },
      {
         label: t("staticDropdown.deconstructability.easyScrewed"),
         value: t("staticDropdown.deconstructability.easyScrewed")
      },
      {
         label: t("staticDropdown.deconstructability.easyBuild"),
         value: t("staticDropdown.deconstructability.easyBuild")
      },
      {
         label: t("staticDropdown.deconstructability.moderateBuild"),
         value: t("staticDropdown.deconstructability.moderateBuild")
      },
      {
         label: t("staticDropdown.deconstructability.elaborateBuild"),
         value: t("staticDropdown.deconstructability.elaborateBuild")
      },
      {
         label: t("staticDropdown.deconstructability.hardSpecialSkillsNeeded"),
         value: t("staticDropdown.deconstructability.hardSpecialSkillsNeeded")
      },
      {
         label: t("staticDropdown.deconstructability.notEconomicallyFeasible"),
         value: t("staticDropdown.deconstructability.notEconomicallyFeasible")
      },
      {
         label: t("staticDropdown.deconstructability.notPossible"),
         value: t("staticDropdown.deconstructability.notPossible")
      }
   ];

   const customPollutance = [
      {
         label: t("staticDropdown.customPollutance.unknown"),
         value: t("staticDropdown.customPollutance.unknown")
      },
      {
         label: t("staticDropdown.customPollutance.toBeProved"),
         value: t("staticDropdown.customPollutance.toBeProved")
      },
      {
         label: t("staticDropdown.customPollutance.potentiallyHarmless"),
         value: t("staticDropdown.customPollutance.potentiallyHarmless")
      },
      {
         label: t("staticDropdown.customPollutance.potentiallyHarmful"),
         value: t("staticDropdown.customPollutance.potentiallyHarmful")
      },
      {
         label: t("staticDropdown.customPollutance.harmlessChecked"),
         value: t("staticDropdown.customPollutance.harmlessChecked")
      },
      {
         label: t("staticDropdown.customPollutance.harmfulChecked"),
         value: t("staticDropdown.customPollutance.harmfulChecked")
      }
   ];

   const categoryName = material?.category?.name;
   let materialGroupSpecification;
   if (material?.specification_group) {
      materialGroupSpecification = material.specification_group
         .map((item: any, index: number) => {
            if (!item) return null;

            const keys = Object.keys(item);

            return (
               <>
                  {keys.map((key: any, index: number) => {
                     const keyValue = item[key];

                     if (typeof keyValue === "object" && keyValue !== null) {
                        if ("unit" in keyValue && "unitValue" in keyValue) {
                           return (
                              <dl>
                                 <dt>{key}</dt>
                                 <dd>
                                    {keyValue["unitValue"]} {keyValue["unit"]}
                                 </dd>
                              </dl>
                           );
                        }
                     } else {
                        // test if color
                        var ddClass;
                        if (/^#([0-9A-F]{3}){1,2}$/i.test(item[key])) {
                           ddClass = "color";
                           return (
                              <dl>
                                 <dt>{t(`${key}`)}</dt>
                                 <dd>
                                    <div
                                       className={ddClass}
                                       style={{
                                          ["background" as any]: item[key],
                                          display: "inline",
                                          padding: "0px 30px 0px",
                                          border: "1px solid #d9d9d9"
                                       }}
                                    ></div>{" "}
                                    <span style={{ display: "inline" }}>
                                       &nbsp;
                                       {item.isRalColorMatched
                                          ? t(
                                               `colors:${item[
                                                  key
                                               ].toUpperCase()}`
                                            )
                                          : t(
                                               `colors:${item[
                                                  key
                                               ].toUpperCase()}`
                                            ).substr(
                                               0,
                                               t(
                                                  `colors:${item[
                                                     key
                                                  ].toUpperCase()}`
                                               ).indexOf("(")
                                            )}
                                    </span>
                                 </dd>
                              </dl>
                           );
                        } else if (key !== "isRalColorMatched") {
                           return (
                              <dl>
                                 <dt>{key}</dt>
                                 <dd>{item[key]}</dd>
                              </dl>
                           );
                        }
                     }
                  })}
               </>
            );
         })
         .filter(Boolean);
   }

   const getPassportImages = () => {
      return (
         passport &&
         passport.passport_images?.map((item: any) => {
            return <Image src={getImagePath(item)} />;
         })
      );
   };

   const getPassportImagesList = () => {
      const passportImageLists =
         passport?.passport_images?.length >= 1
            ? passport.passport_images
            : material?.images?.length >= 1
            ? material?.images
            : [];

      return (
         passport &&
         passportImageLists.map((item: any) => {
            return {
               original: getImagePath(item),
               thumbnail: getImagePath(item)
            };
         })
      );
   };

   const handleOnDelete = async () => {
      if (onSaleCount !== 0) {
         setIsModalVisibleOnSaleDelete(true);
      } else {
         executeDelete().then(() => {
            history.goBack();
            message.success(t("passportDetails.deleteSuccessMessage"));
         });
      }
   };

   const onFinish = async (values: any) => {
      console.log(values);
      if (addItemLoading) return;

      setAddItemLoading(true);

      const passportImagesLinks =
         await getPassportWithUploadedMaterialImagesLinks(values, material.id);

      const axios = await getAxiosInstance();
      axios
         .put(Resource.path.editPassportDetailsHub + `/${passport.id}`, {
            ...values,
            passport_images: passportImagesLinks
         })
         .then(() => {
            message.success(t("reusePotentialSteps.successMessage"));
            setIsModalVisible(false);
            setAddItemLoading(false);
            window.history.back();
         })
         .catch((error) => {
            console.log(error);
            message.error("Some error occurred while processing this request");
            setIsModalVisible(false);
            setAddItemLoading(false);
         });
   };

   const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
   };

   const handleCancel = () => {
      setIsModalVisible(false);
   };

   const handleOnEdit = () => {
      if (onSaleCount !== 0) {
         setIsModalVisibleOnSaleEdit(true);
      } else {
         setIsModalVisible(true);
         setPassportFormInitialValue({
            ...passport
         });
      }
   };

   const [{ loading: confirmLoading }, updateSelling] = useAxios(
      {
         url: `${Resource.path.operationsHub}/removeFromSelling/${material.id}`,
         method: "DELETE"
      },
      { manual: true, useCache: false }
   );
   const setPassportImageList = (images: any) => {
      const materialImages = images
         ?.map((item: any, index: number) => {
            return (
               item?.object_url && (
                  <Image src={getImagePath(item)} key={index} />
               )
            );
         })
         .filter(Boolean);
      materialImages && setMaterialImages(materialImages);
   };

   useEffect(() => {
      if (passport?.passport_images) {
         setPassportImageList(passport?.passport_images);
      }
   }, [passport]);

   const rooms = [];
   for (let i = 10; i < 30; i++) {
      rooms.push(
         <Option value={i.toString(36) + i} key={i.toString(36) + i}>
            {i.toString(36) + i}
         </Option>
      );
   }
   /*    if (isLoadingFlag) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   } */
   return (
      <div className="content-wrap passport-overview-wrapper">
         <PageHeader
            title={t("common.back")}
            onBack={() => window.history.back()}
         >
            <QRCode
               src={
                  "https://api.qrserver.com/v1/create-qr-code/?data=" +
                  passport.passport_identifier +
                  "%0ConcularPassport&amp;size=220x220&amp;margin=0"
               }
            ></QRCode>

            <div className="page-title">
               {passport?.passport_identifier && (
                  <strong className="passport-id">
                     <span className="light">#</span>
                     {passport.passport_identifier}
                  </strong>
               )}
               <h1>
                  {material.manufacturer} {material.product_name}
               </h1>
               <span>
                  {categoryName} &rsaquo; {material?.product_group_name}
               </span>
            </div>
            <div className="manage-menu">
               <Button onClick={handleOnEdit}>
                  <EditOutlined />
               </Button>
               <Popconfirm
                  title={t("passportDetails.deletePassport")}
                  onConfirm={handleOnDelete}
                  okText={t("passportDetails.yes")}
                  cancelText={t("passportDetails.no")}
               >
                  <Button>
                     <DeleteOutlined />
                  </Button>
               </Popconfirm>
            </div>
         </PageHeader>
         <div className="passport-subhead">
            <div className="grid">
               <div className="grid-item">
                  <h3>{t("passportDetails.condition")}</h3>
                  <dl>
                     <dt>{t("materialDashboard.condition")}</dt>
                     <dd>{passport.condition}</dd>
                     <dt>{t("materialDashboard.deconstructability")}</dt>
                     <dd>{passport.deconstructability}</dd>
                     {passport.custom_pollutance && (
                        <>
                           <dt>{t("materialDashboard.pollutance")}</dt>
                           <dd>{passport.custom_pollutance}</dd>
                        </>
                     )}
                  </dl>
               </div>
               <div className="grid-item">
                  <h3>{t("passportDetails.location")}</h3>
                  <p>
                     {passport?.room && (
                        <span className="tag">{passport.room}</span>
                     )}
                     {passport?.location_note && (
                        <span className="tag">{passport.location_note}</span>
                     )}
                  </p>
               </div>

               <div className="grid-item">
                  <h3>{t("passportDetails.upcoming")}</h3>
                  <p>--</p>
               </div>
               {passport?.contact_polluted_material && (
                  <div className="grid-item">
                     <span className="tag">
                        {t("passportDetails.contactPollutedMaterial")}
                     </span>
                  </div>
               )}
            </div>
         </div>
         <Modal
            title={t("default.add")}
            visible={isModalVisible}
            footer={null}
            onCancel={handleCancel}
         >
            <Form
               name="basic"
               labelCol={{
                  span: 8
               }}
               wrapperCol={{
                  span: 16
               }}
               onFinish={onFinish}
               initialValues={passportFormInitialValue}
               onFinishFailed={onFinishFailed}
            >
               <Form.Item name="amount" label="Amount">
                  <Input disabled defaultValue={1} style={{ width: "100px" }} />
               </Form.Item>
               <b className="amount-unit"> {material.product_unit}</b>

               <Form.Item
                  name="condition"
                  label={t("passportDetails.condition")}
                  rules={[
                     {
                        required: true,
                        message: t("passportDetails.selectCondition")
                     }
                  ]}
               >
                  <Select
                     placeholder={t("passportDetails.placeholderCondition")}
                     style={{ minWidth: "120px" }}
                  >
                     {conditions.map((item) => (
                        <Select.Option key={item.label} value={item.value}>
                           {item.value}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>

               <Form.Item
                  name="deconstructability"
                  label={t("passportDetails.deconstructability")}
                  rules={[
                     {
                        required: true,
                        message: t(
                           "passportDetails.requiredDeconstructabilityMessage"
                        )
                     }
                  ]}
               >
                  <Select
                     placeholder={t(
                        "passportDetails.placeholderDeconstructability"
                     )}
                     style={{ minWidth: "120px" }}
                  >
                     {deconstructability.map((item) => (
                        <Select.Option key={item.label} value={item.value}>
                           {item.value}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>

               <Form.Item
                  name="custom_pollutance"
                  label={t("passportDetails.customPollutance")}
               >
                  <Select
                     placeholder={t(
                        "passportDetails.placeholderCustomPollutance"
                     )}
                     style={{ minWidth: "120px" }}
                  >
                     {customPollutance.map((item) => (
                        <Select.Option key={item.label} value={item.value}>
                           {item.value}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
               <Form.Item
                  name="contact_polluted_material"
                  label={t("passportDetails.contactPollutedMaterial")}
               >
                  <Switch
                     checked={contactPollutedMaterial}
                     onChange={(value: any) => {
                        setContactPollutedMaterial(value);
                     }}
                  />
               </Form.Item>
               <Form.Item
                  label={t("default.room")}
                  name="room"
                  rules={[
                     {
                        required: false,
                        message: "Enter room!"
                     }
                  ]}
               >
                  <Select mode="tags" placeholder="Add or select room">
                     {rooms}
                  </Select>
               </Form.Item>
               <Form.Item
                  name="location_note"
                  label={t("passportDetails.locationNote")}
               >
                  <Input.TextArea
                     placeholder={t("passportDetails.placeholderLocationNote")}
                  />
               </Form.Item>

               <Form.Item
                  name="other_note"
                  label={t("passportDetails.otherNote")}
               >
                  <Input.TextArea
                     placeholder={t("passportDetails.placeholderOtherNote")}
                  />
               </Form.Item>

               <Form.Item
                  name="passport_images"
                  label={t("passportDetails.uploadImage")}
               >
                  <ImageUploader></ImageUploader>
               </Form.Item>

               <Form.Item
                  wrapperCol={{
                     offset: 8,
                     span: 16
                  }}
               >
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={addItemLoading}
                  >
                     Save
                  </Button>
               </Form.Item>
            </Form>
         </Modal>

         {
            // @TODO
            //<div className="images-scroll"></div>
            // show passport images in scrollable lightbox like material details page
         }
         <div className="page-content">
            {/*         {(!!passport?.passport_images?.length ||
            !!material?.images?.length) && (
            <div className="images widget">
              <ImageGallery
                items={getPassportImagesList()}
                lazyLoad
                thumbnailPosition="bottom"
                useBrowserFullscreen="false"
              />
            </div>
          )} */}
            {materialImages && (
               <div
                  className={
                     materialImages.length > 1
                        ? "image-scroll"
                        : "images-scroll single"
                  }
               >
                  {materialImages}
               </div>
            )}
         </div>
         {passport.other_note && (
            <div className="page-content">
               <div className="grid col-2">
                  <div className="grid-item"></div>

                  {passport.other_note && (
                     <div className="grid-item">
                        <div className="description">{passport.other_note}</div>
                     </div>
                  )}
               </div>
            </div>
         )}

         <div className="page-content">
            <h4>{t("passportDetails.specifications")}</h4>
            <div className="grid col-4">
               {material.ean && (
                  <div className="grid-item widget data">
                     <span>{t("materialDashboard.ean")}</span>
                     <strong>{material.ean}</strong>
                  </div>
               )}
               {material.yearOfManufacturing && (
                  <div className="grid-item widget data">
                     <span>{t("materialDashboard.yearOfManufacturing")}</span>
                     <strong>{material.yearOfManufacturing}</strong>n
                  </div>
               )}
            </div>

            <div className="grid material-specifications">
               <div className="specs widget">
                  <dl>
                     <dt>{t("materialDashboard.weight")}</dt>
                     <dd>{material.weight_per_unit} kg</dd>
                  </dl>
                  {materialGroupSpecification}
                  {material.materials && (
                     <h4>{t("productDetails.material.form.materials")}</h4>
                  )}
                  {material.materials && (
                     <dl className="material">
                        {material.materials.map((item: any) => {
                           return (
                              <>
                                 <dt>{item.material_type}</dt>
                                 {item?.percentage && (
                                    <dd>
                                       {item?.material_sub_type && (
                                          <span>
                                             {item.material_sub_type}:{" "}
                                          </span>
                                       )}
                                       <span>{item.percentage}%</span>
                                    </dd>
                                 )}
                              </>
                           );
                        })}
                     </dl>
                  )}
               </div>
               <div className="description">
                  {material?.need_for_reuse && (
                     <div className="widget">
                        <h4>{t("reusePotentialSteps.form.neededForReuse")}</h4>
                        {getMaterialReuseForTag(material?.need_for_reuse)}
                     </div>
                  )}

                  {material?.used_as && (
                     <div className="widget">
                        <h4>{t("reusePotentialSteps.form.couldBeUsedAs")}</h4>
                        {material?.used_as.map((item: any) => {
                           return <Tag>{item}</Tag>;
                        })}
                     </div>
                  )}
                  {material?.notes && (
                     <div className="widget">
                        <h4>{t("materialDashboard.notes")}</h4>
                        {material?.notes}
                     </div>
                  )}

                  {material.documents && (
                     <div className="widget">
                        <h4>{t("materialDashboard.documents")}</h4>
                        {getDocumentsList(material.documents)}
                     </div>
                  )}
               </div>
            </div>
         </div>
         <Modal
            title="Some items of this material are on sale in the operation."
            onCancel={() => {
               setIsModalVisibleOnSaleEdit(false);
            }}
            confirmLoading={confirmLoading}
            onOk={async () => {
               await updateSelling({
                  data: {
                     condition: passport.condition,
                     custom_pollutance: passport.custom_pollutance,
                     deconstructability: passport.deconstructability,
                     amount_unit: passport.amount_unit
                  }
               })
                  .then(() => {
                     setIsModalVisibleOnSaleEdit(false);
                     setOnSaleCount(0);
                     setIsModalVisible(true);
                     setPassportFormInitialValue({
                        ...passport
                     });
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            }}
            okText="Stop selling and continue"
            closable={false}
            visible={isModalVisibleOnSaleEdit}
         >
            <p>You have to stop the selling to continue.</p>
            <p>You might lose existing requests.</p>
         </Modal>
         <Modal
            title="Some items of this material are on sale in the operation."
            onCancel={() => {
               setIsModalVisibleOnSaleDelete(false);
            }}
            confirmLoading={confirmLoading}
            onOk={async () => {
               await updateSelling({
                  data: {
                     condition: passport.condition,
                     custom_pollutance: passport.custom_pollutance,
                     deconstructability: passport.deconstructability,
                     amount_unit: passport.amount_unit
                  }
               })
                  .then(async () => {
                     setOnSaleCount(0);
                     setIsModalVisibleOnSaleDelete(false);
                     await executeDelete().then(() => {
                        history.goBack();
                        message.success(
                           t("passportDetails.deleteSuccessMessage")
                        );
                     });
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            }}
            okText="Stop selling and continue"
            closable={false}
            visible={isModalVisibleOnSaleDelete}
         >
            <p>You have to stop the selling to continue.</p>
            <p>You might lose existing requests.</p>
         </Modal>
      </div>
   );
};
