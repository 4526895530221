import { message, Spin } from "antd";
import useAxios from "axios-hooks";
import React, { useContext, useEffect } from "react";
import { Resource } from "../../api/common";
import { useBuildingAction } from "./useBuildingAction";
import { useTranslation } from "react-i18next";
import { useAuth } from "../auth/index";

const BuildingContext = React.createContext({} as any);

export const INITIAL_STATE = {
   buildings: []
};

export const BuildingProvider = ({ children }: any) => {
   const { joinedOrganizationsList } = useAuth();
   const [{ data, loading, error }, refetch] = useAxios(
      Resource.path.listBuilding,
      {
         useCache: false
      }
   );

   const {
      state,
      getBuildingDetail,
      addBuilding,
      resetBuildings,
      canDelete,
      canEdit
   } = useBuildingAction();
   const [t] = useTranslation();

   useEffect(() => {
      if (data?.data) {
         resetBuildings(data?.data);
      }
      if (error) {
         message.error("Something went wrong, please try again!");
      }
   }, [data, loading, error]);

   const refetchBuildings = async () => {
      await refetch();
   };

   const acl = (organisation_id: any) => {
      if (organisation_id && joinedOrganizationsList) {
         const response = joinedOrganizationsList.filter((rec: any) => {
            return rec.organizations_id == organisation_id;
         });
         if (response.length) {
            if (
               response[0].role === "ADMIN" &&
               response[0].status === "ACCEPTED"
            ) {
               return true;
            } else if (
               response[0].role === "USER" &&
               response[0].invited &&
               response[0].status === "ACCEPTED"
            ) {
               return true;
            } else {
               return false;
            }
         } else {
            return false;
         }
      } else {
         return false;
      }
   };

   if (loading) {
      (window as any).NProgress.start();
   } else {
      (window as any).NProgress.done();
   }

   return (
      <Spin spinning={loading} tip={t("common.loading")}>
         <BuildingContext.Provider
            value={{
               buildingDataLoading: loading,
               buildingDataLoadingError: error,
               buildings: state?.buildings || [],
               addBuilding,
               getBuildingDetail,
               refetchBuildings,
               acl,
               canEdit,
               canDelete
            }}
         >
            {children}
         </BuildingContext.Provider>
      </Spin>
   );
};

export const useBuilding = () => useContext(BuildingContext);
