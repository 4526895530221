import { Button, Spin, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import "./buildingListView.scss";
import { PlusOutlined, LockFilled } from "@ant-design/icons";
import {
   BuildingProvider,
   useBuilding
} from "../../../contexts/building/index";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePermissionStatus } from "../../../hooks/usePermissionStatus";
import { UnAuthorizedMessage } from "../../../components/UnAuthorizedMessage/index";
import BigAddButton from "../../../components/ui@concular/AddBuildingButton";
import BuildingItem from "./BuildingItem";
import { useAuth } from "../../../contexts/auth/index";
import Organization from "../../Onboarding/CreateOrganization/Organization";
import icon_shared from "../../../statics/images/icon_shared.svg";
import icon_nobuildings from "../../../statics/images/icon_nobuildings.svg";
import { getOrganizationsDetails } from "../../Onboarding/getOrganizationsDetails";

const AddBuildingButton = () => {
   const history = useHistory();
   const [t] = useTranslation();
   const handleOnAddBuilding = () => {
      history.push("buildings/add");
   };

   return (
      <Button type="primary" shape="round" onClick={handleOnAddBuilding}>
         {t("buildingList.addBuildingButton")}
      </Button>
   );
};

const AddBuildingBigButton = ({ orgId }: any) => {
   const history = useHistory();
   const [t] = useTranslation();

   const handleOnAddBuilding = () => {
      history.push({
         pathname: "buildings/add",
         state: { organizationId: orgId }
      });
   };

   return (
      <BigAddButton onClick={handleOnAddBuilding}>
         <PlusOutlined />
         {t("buildingList.addBuildingButton")}
      </BigAddButton>
   );
};

const Building = () => {
   const history = useHistory();
   const { buildings } = useBuilding();
   const hasPermission = usePermissionStatus();
   const [t] = useTranslation();
   const {
      user: currentUser,
      organizationTypes,
      joinedOrganizationsList
   } = useAuth();
   const [categorizedBuildings, setCategorizedOrganizations] = useState<any>(
      {}
   );
   const [personalBuildings, setPersonalBuildings] = useState<any>([]);
   const [sharedBuildings, setSharedBuildings] = useState<any>([]);
   const [organizationDetails, setOrganizationDetails] = useState<any>([]);
   const [existingOrgsIds, setExistingsOrgsIds] = useState<any>([]);
   const [loading, setLoading] = useState(false);

   const getDetails = async () => {
      setLoading(true);
      const existingsIds = getExistingsOrganizations();
      const details = await getOrganizationsDetails(existingsIds);
      setOrganizationDetails([...details]);
      setLoading(false);
   };

   useEffect(() => {
      joinedOrganizationsList && getDetails();
   }, [joinedOrganizationsList, categorizedBuildings]);

   useEffect(() => {
      getBuildingList(buildings);
   }, [buildings]);

   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";

   if (hasPermission !== undefined && !hasPermission) {
      const pageName = t("sidebarMenus.building");
      return <UnAuthorizedMessage currentPageTitle={pageName} />;
   }
   // console.log(buildings, "cat");
   const getBuildingList = (buildings: any) => {
      const sharedBuildings = buildings.filter(
         (item: { isShared: boolean; organisation_id: number }) =>
            item.isShared && !item.organisation_id
      );
      const personalBuildings = buildings.filter(
         (item: { organisation_id: number; isOwned: boolean }) =>
            item.isOwned && !item.organisation_id
      );
      const otherBuildings = buildings.filter(
         (item: {
            organisation_id: number;
            isShared: boolean;
            isOwned: boolean;
         }) => item.organisation_id
      );
      const groupByOrganisationId = otherBuildings.reduce(
         (item: any, curr: any) => {
            if (!item[curr.organisation_id]) item[curr.organisation_id] = [];
            item[curr.organisation_id].push(curr);
            return item;
         },
         {}
      );
      setPersonalBuildings(personalBuildings);
      setSharedBuildings(sharedBuildings);
      setCategorizedOrganizations(groupByOrganisationId);
   };

   const getExistingsOrganizations = () => {
      let existingOrganizations = [];
      let joinedItemsIds = [];
      if (joinedOrganizationsList) {
         joinedItemsIds = joinedOrganizationsList.map(
            (item: { organizations_id: any }) => item.organizations_id
         );
      }
      const otherIds = Object.keys(categorizedBuildings).map((item) => +item);
      existingOrganizations = [...joinedItemsIds, ...otherIds];
      existingOrganizations = Array.from(new Set(existingOrganizations));
      setExistingsOrgsIds([...existingOrganizations]);
      return existingOrganizations;
   };

   const OrganizationNoBuilding = () => {
      return (
         <div className="org-no-building">
            <div className="header">
               <img src={icon_nobuildings} alt="no-building" />
               <h3 className="title">{t("buildingListView.noBuildingsYet")}</h3>
            </div>
            <div className="desc">
               <LockFilled />
               {t("buildingListView.onlyAdminsCan")}
            </div>
         </div>
      );
   };

   if (!buildings)
      return (
         <div className="loading">
            <Spin tip={t("common.loading")} />
         </div>
      );

   return (
      <div className="building-list-overview  wrapper-style grey">
         <div className="">
            {!!!isPrivateOwner && (
               <h1 className="responsivePageTitle">
                  {t("buildingListView.portfolio")}
               </h1>
            )}
            {!!isPrivateOwner && (
               <Row>
                  <Col span={18}>
                     <h1 className="responsivePageTitle">
                        {t("buildingListView.portfolio")}
                     </h1>
                  </Col>
                  <Col>
                     <Button
                        onClick={() => {
                           history.push("buildings/add");
                        }}
                     >
                        <PlusOutlined />
                        {t("buildingList.addBuildingButton")}
                     </Button>
                  </Col>
               </Row>
            )}
         </div>
         <Spin spinning={loading}>
            <div className="">
               {!!isPrivateOwner && buildings.length === 0 && (
                  <div className="intro-buildings">
                     Legen Sie ein Gebäude an, um Material für Baumaßnahmen zu
                     beschaffen oder vor dem Rückbau zu verkaufen.
                  </div>
               )}
               {!Object.values(categorizedBuildings).length &&
               !sharedBuildings.length &&
               !personalBuildings.length &&
               !joinedOrganizationsList?.filter(
                  (item: { owner: string }) => item.owner == "ADMIN"
               ).length ? (
                  <div className={"noBuilding"}>
                     <img src={icon_nobuildings} alt="no-building" />
                     <h3 className="title">
                        {t("buildingListView.noBuildingFound")}
                     </h3>
                     <div className="text">
                        {t("buildingListView.noBuildingText1")}
                     </div>
                     <div>{t("buildingListView.noBuildingText2")}</div>
                  </div>
               ) : null}
               <div className="building-list">
                  <>
                     {existingOrgsIds.map((orgId: any, index: number) =>
                        (categorizedBuildings[orgId] &&
                           categorizedBuildings[orgId].length) ||
                        joinedOrganizationsList.find(
                           (item: { organizations_id: any; owner: string }) =>
                              item.organizations_id === orgId &&
                              item.owner === "ADMIN" //TODO: remove admin
                        ) ? (
                           <div className="org-row" key={index}>
                              <Organization
                                 logo={
                                    getCurrentOrganization(
                                       organizationDetails,
                                       orgId
                                    )?.image
                                       ? getCurrentOrganization(
                                            organizationDetails,
                                            orgId
                                         )?.image[0]?.object_url
                                       : ""
                                 }
                                 name={
                                    getCurrentOrganization(
                                       organizationDetails,
                                       orgId
                                    )?.name
                                 }
                                 type={
                                    organizationDetails
                                       ? organizationTypes?.find(
                                            (item: { id: any }) =>
                                               item.id ==
                                               getCurrentOrganization(
                                                  organizationDetails,
                                                  orgId
                                               )?.organization_type_id
                                         )?.label
                                       : ""
                                 }
                              />
                              <div className="buildings">
                                 {categorizedBuildings[orgId] &&
                                 categorizedBuildings[orgId].length
                                    ? categorizedBuildings[orgId].map(
                                         (rec: any) => (
                                            <BuildingItem
                                               building={rec}
                                               key={index}
                                               shared={
                                                  !joinedOrganizationsList.find(
                                                     (item: any) =>
                                                        item.organizations_id ==
                                                        orgId
                                                  )
                                               }
                                            ></BuildingItem>
                                         )
                                      )
                                    : joinedOrganizationsList.find(
                                         (item: any) =>
                                            item.organizations_id === orgId &&
                                            item.owner === "USER"
                                      ) && <OrganizationNoBuilding />}
                                 {joinedOrganizationsList.find(
                                    (item: {
                                       organizations_id: any;
                                       owner: string;
                                    }) => item.organizations_id === orgId
                                 )?.owner === "ADMIN" && (
                                    <AddBuildingBigButton orgId={orgId} />
                                 )}
                              </div>
                           </div>
                        ) : null
                     )}
                     {sharedBuildings.length ? (
                        <div className="org-row">
                           <Organization
                              logo={icon_shared}
                              name={t("buildingListView.sharedWithMe")}
                           />
                           <div className="buildings">
                              {sharedBuildings.map(
                                 (building: any, index: number) => (
                                    <BuildingItem
                                       building={building}
                                       key={index}
                                    ></BuildingItem>
                                 )
                              )}
                           </div>
                        </div>
                     ) : null}
                     {personalBuildings.length ? (
                        <div className="org-row">
                           <Organization
                              name={t("buildingListView.personal")}
                           />
                           <div className="buildings">
                              {personalBuildings.map(
                                 (building: any, index: number) => (
                                    <BuildingItem
                                       building={building}
                                       key={index}
                                    ></BuildingItem>
                                 )
                              )}
                           </div>
                        </div>
                     ) : null}
                     {/* {!!(
                     personalBuildings.length == 0 ||
                     (Object.values(categorizedBuildings).length == 0 &&
                        joinedOrganizationsList &&
                        joinedOrganizationsList.filter((rec: any) => {
                           return rec.role === "ADMIN";
                        }).length > 0)
                  ) && <AddBuildingBigButton />} */}
                  </>
               </div>
            </div>
         </Spin>
      </div>
   );
};

export const BuildingListView = () => {
   const { loadingUserInfo, onBoarded, user: currentUser } = useAuth();
   const [t] = useTranslation();
   const history = useHistory();
   const isPrivateOwner =
      currentUser &&
      currentUser["attributes"] &&
      currentUser["attributes"]["custom:user_type"] === "private-owner";
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   useEffect(() => {
      //console.log(onBoarded, loadingUserInfo, user);
      if (currentUser) {
         setIsLoading(false);
      }
      if (!onBoarded && !loadingUserInfo && !isPrivateOwner) {
         history.push("/onboarding");
      }
   }, [onBoarded, loadingUserInfo, isPrivateOwner, currentUser]);

   if (isLoading) return <div className="loading">{t("common.loading")}</div>;

   return (
      <BuildingProvider>
         <Building />
      </BuildingProvider>
   );
};
function getCurrentOrganization(organizationDetails: any, id: any) {
   return organizationDetails.find((item: { id: number }) => item.id == id);
}
