import { PageHeader } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./UnAuthorizedMessage.scss";
import { useTranslation } from "react-i18next";
import { LockOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { usePermissionOfView } from "../../hooks/usePermissionStatus";

export const UnAuthorizedMessage = (props: any) => {
   const permissionCatalogue = usePermissionOfView("catalogue");
   const permissionBuildings = usePermissionOfView("buildings");
   const permissionHub = usePermissionOfView("hub");
   const currentPageTitle = props.currentPageTitle;

   const [t] = useTranslation();
   function GetAccess() {
      return (
         <Link
            to="//www.concular.de/de/get-started"
            target="_blank"
            className="button"
         >
            <LockOutlined />
            &nbsp; Jetzt freischalten
         </Link>
      );
   }
   function AccessBuildings() {
      if (permissionBuildings) {
         return (
            <Link to="/buildings" className="button lined">
               Zu meinen Gebäude
            </Link>
         );
      }
      return <GetAccess />;
   }
   function AccessCatalogue() {
      if (permissionCatalogue) {
         return (
            <Link to="/catalogue" className="button lined">
               Zum Materialkatalog
            </Link>
         );
      }
      return <GetAccess />;
   }
   function AccessHub() {
      if (permissionHub) {
         return (
            <Link to="/hub" className="button lined">
               Materialzentrum
            </Link>
         );
      }
      return <GetAccess />;
   }
   return (
      <div>
         <h1 className="responsivePageTitle">{currentPageTitle}</h1>
         <PageHeader title="">
            <div className="page-title">
               <h1>
                  <span className="noaccess">
                     <LockOutlined /> Nicht freigeschaltet
                  </span>
               </h1>
               <Link
                  to="//www.concular.de/de/get-started"
                  target="_blank"
                  className="button"
               >
                  <LockOutlined />
                  &nbsp; Jetzt freischalten
               </Link>
            </div>
         </PageHeader>
         <div className="unauthorized-teaser">
            <div className="teaser-body">
               <h2>Mehr Funktionen im Überblick</h2>
               <div className="feature-grid">
                  <div className="grid-item">
                     <div className="inner">
                        <h3>{t("sidebarMenus.building")}</h3>
                        <p>
                           Organisieren Sie Ihr bestehendes oder geplantes
                           Gebäude mit Material- und Produktpässen.
                        </p>
                        <i>Bald verfügbar:</i>
                        <ul>
                           <li>Automatisierte Wertberechnung</li>
                        </ul>
                     </div>
                     <AccessBuildings />
                  </div>

                  <div className="grid-item">
                     <div className="inner">
                        <h3>Service für Materialerfassung im Bestand</h3>
                        <p>
                           Wir erfassen Ihre Materialien im Bestand und bewerten
                           Sie für den Rückbau und eine Weitervermittlung.
                           Sprechen Sie uns gerne an.
                        </p>
                     </div>
                     <Link
                        to="//www.concular.de/de/get-started"
                        target="_blank"
                        className="button"
                     >
                        Service anfragen
                     </Link>
                  </div>

                  <div className="grid-item">
                     <div className="inner">
                        <h3>{t("sidebarMenus.catalogue")}</h3>
                        <p>
                           Lassen Sie sich inspirieren von wiedergewonnenen
                           Materialien zum Wiedereinsatz in Ihrem Bauprojekt.
                        </p>
                     </div>
                     <AccessCatalogue />
                  </div>

                  <div className="grid-item">
                     <div className="inner">
                        <h3>{t("sidebarMenus.materialHub")}</h3>
                        <p>
                           Lassen Sie sich inspirieren von wiedergewonnenen
                           Materialien zum Wiedereinsatz in Ihrem Bauprojekt.
                        </p>
                     </div>
                     <AccessHub />
                  </div>

                  <div className="grid-item">
                     <div className="inner">
                        <h3>
                           Materialpilot&nbsp;{" "}
                           <span className="comingsoon">
                              <FieldTimeOutlined /> Bald verfügbar
                           </span>
                        </h3>
                        <p>
                           Behalten Sie den Überblick über Ihre
                           Materialveräußerung oder Beschaffung.
                        </p>
                        <i>Bald verfügbar:</i>
                        <ul>
                           <li>Bestell- und Liefermanagement</li>
                           <li>Automatisierte Ökobilanzierung</li>
                        </ul>
                     </div>
                     <Link
                        to="//www.concular.de/de/get-started"
                        target="_blank"
                        className="button lined"
                     >
                        Service anfragen
                     </Link>
                  </div>

                  <div className="grid-item">
                     <div className="inner">
                        <h3>
                           Lagermanagement&nbsp;{" "}
                           <span className="comingsoon">
                              <FieldTimeOutlined /> Bald verfügbar
                           </span>
                        </h3>
                        <p>
                           Verwalten Sie Ihr Lager und bieten Sie
                           inventarisierte Materialien über Concular an.
                        </p>
                     </div>
                  </div>

                  <div className="grid-item">
                     <div className="inner">
                        <h3>Partnerdatenbank</h3>
                        <p>
                           Bieten Sie Ihre Leistungen für kreislaufgerechtes
                           Bauen interessierten Kunden an.
                        </p>
                     </div>
                     <Link
                        to="//www.concular.de/de/get-started"
                        target="_blank"
                        className="button"
                     >
                        Eintragen
                     </Link>
                  </div>

                  <div className="grid-item feedback">
                     <div className="inner">
                        <h3>Ihr Feedback ist uns wichtig</h3>
                        <p>
                           Helfen Sie uns unsere Anwendung für Sie optimal
                           nutzbar zu machen. Schreiben Sie uns, was Ihnen fehlt
                           oder was wir noch verbessern sollten.
                        </p>
                     </div>
                     <Link
                        to="//www.concular.de/de/get-started"
                        target="_blank"
                        className="button yellow"
                     >
                        Feedback schreiben
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
